import { isEmpty, isNil } from "lodash";
import { useFetchTeamMetricsConventionQuery } from "../services/performanceApi.service";
import { METRIC_CATEGORIES, flattenTeamDashMetrics } from "./useTeamPlayers";
import { METRICS_PLAYER } from "../services/mockData";
import { usePersistentTeamDashColumns } from "./usePersistentTeamDashColumns";
import { reorderListDropHandler } from "../utils/dragDrop";

export interface TeamDashColumn {
  label: string;
  key: string;
  category: string;
  index: METRICS_PLAYER;
  group?: string;
}

function toIndexGradeMetric(metric: typeof METRIC_CATEGORIES[number]) {
  const label = `${metric.label} Index Grade`;
  return { ...metric, label, variable: label };
}

export function adaptMetricsData(teamMetricsConventionData: any[]) {
  return teamMetricsConventionData
    .map((it) => {
      const current = METRIC_CATEGORIES.find(
        (column) => column.category === it.key
      );

      const extraMetrics =
        !isNil(current) && current.key.includes("index_grade")
          ? [toIndexGradeMetric(current)]
          : [];

      return current
        ? {
            ...current,
            children: [...it.children, ...extraMetrics],
          }
        : null;
    })
    .filter((it) => !isEmpty(it));
}

export function useUpdateMetric({
  initialState: overrideInitialState,
}: {
  initialState?: TeamDashColumn[];
} = {}) {
  const { data: teamMetricsConventionData = [] } =
    useFetchTeamMetricsConventionQuery();

  const allItems = adaptMetricsData(teamMetricsConventionData);

  const { metrics, setMetrics, resetToDefaultMetrics } =
    usePersistentTeamDashColumns({
      overrideInitialState,
      allMetrics: allItems,
    });

  const updateMetric = (current: any, newMetricKey: string) => {
    const flatItems = flattenTeamDashMetrics(allItems);

    const newMetric = flatItems.find(
      (metric: any) => metric?.key === newMetricKey
    );
    if (newMetric) {
      const updatedMetrics = metrics.map((it: any, index: any) =>
        current.key === it.key && current.index === index ? newMetric : it
      );

      setMetrics(updatedMetrics);
    }
  };

  const handleDrop = reorderListDropHandler(metrics, setMetrics);

  return {
    updateMetric,
    allItems,
    metrics,
    setMetrics,
    resetToDefaultMetrics,
    handleDrop,
  };
}
