import { useState } from "react";
import {
  useFetchMetricsQuery,
  useFetchTrendsSelfTimeSeriesQuery,
  useFetchTimeSeriesDataCompQuery,
  useFetchTrendsTimeSeriesQuery,
} from "../services/performanceApi.service";
import { useSelector } from "react-redux";
import {
  selectMovement,
  selectPeriodSelectorValue,
  selectSelectedMotionType,
  selectSelfCompOptions,
} from "../redux/selectors";
import { TRENDS_TIME_SERIES_METRIC } from "../services/mockTrendsMetrics";
import { formatSelfData } from "../utils/metrics";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import useFramesData from "./useFramesData";
import { first } from "lodash";
import dayjs from "dayjs";
import { getVarValue, interpolateColor } from "../utils/charts";

const METRIC = "mKHShort";

export function useTimeSeriesVariableData() {
  const [selfComp, setSelfComp] = useState<boolean>(false);
  const [showDataComp, setShowDataComp] = useState<boolean>(false);
  const { player, trial } = useSelector(selectMovement);
  const timePeriod = useSelector(selectPeriodSelectorValue);
  const selfCompOptions = useSelector(selectSelfCompOptions);
  const motionType = useSelector(selectSelectedMotionType);
  const { syncTrials } = useFramesData();
  const [metricSelected, setMetricSelected] = useState({
    id: TRENDS_TIME_SERIES_METRIC[0].id,
    label: TRENDS_TIME_SERIES_METRIC[0].label,
  });
  const { data: metricsData = [] } = useFetchMetricsQuery({
    metricId: METRIC,
    pitch: trial,
    motionType,
  });
  const { data, isLoading } = useFetchTrendsTimeSeriesQuery({
    metricId: metricSelected?.id,
    playerId: player?.id,
    startDate: timePeriod?.startDate,
    endDate: timePeriod?.endDate,
  });

  const { data: rawDataComp = [] } = useFetchTimeSeriesDataCompQuery(
    player?.id ? player : skipToken
  );

  const dataComp = rawDataComp
    ?.filter((it) => it?.id?.includes(metricSelected?.id))
    .map((it) => ({ ...it, visible: showDataComp }));

  const onChangeMetric = (e: any) => {
    setMetricSelected({ id: e?.key, label: e?.domEvent?.target?.textContent });
  };

  const metricId = (
    metricSelected?.id.endsWith("_")
      ? `${metricSelected?.id}TS`
      : metricSelected?.id
  ).toLowerCase();

  const { data: rawSelfData = [{ data: [], brFrame: 0 }] } =
    useFetchTrendsSelfTimeSeriesQuery(
      metricSelected?.id && timePeriod?.startDate && timePeriod?.endDate
        ? {
            player,
            metricId,
            selfCompOptions,
            startDate: timePeriod?.startDate,
            endDate: timePeriod?.endDate,
          }
        : skipToken
    );

  const selfData = rawSelfData?.map((it, index) =>
    first(
      formatSelfData({
        metric: {
          ...metricSelected,
          date: dayjs(it?.date, "YYYY-MM-DD"),
        },
        data: syncTrials(it?.data, it?.brFrame),
        color: interpolateColor(
          getVarValue("var(--ptd-time-series-variable-start)"),
          getVarValue("var(--ptd-time-series-variable-end)"),
          index / (rawSelfData?.length - 1)
        ),
      })
    )
  );

  return {
    metricSelected,
    metricsData,
    data,
    dataComp,
    selfData,
    isLoading,
    onChangeMetric,
    selfComp,
    setSelfComp,
    showDataComp,
    setShowDataComp,
  };
}
