import { AcquisitionGroup } from "../Scouting/scoutingUtils";
import PlayerBioAvatar from "../images/PlayerBioAvatar.svg";

export const totalInchesToHeightString = (inches) => {
  return inches
    ? `${parseInt(inches / 12)}'${Math.round(inches % 12, 1)}"`
    : null;
};

// allow-list instead of deny-list
// when new levels are added (like JC recently, HS summer league soon),
// we don't want them to show up unless we opt-in to them
// also allow for easier selection of levels by acquisition group
export const levelsToShow = {
  [AcquisitionGroup.DOMESTIC_PROFESSIONAL]: [
    1, // MLB
    11, // AAA
    12, // AA
    13, // A+
    14, // A
    15, // A (Short Season)
    1000, // Complex Leagues
    1001, // DSL
  ],
  [AcquisitionGroup.DOMESTIC_AMATEUR]: [
    // Note from JLefko:
    //   Hide 22 and 586 until DE/Analytics can clean 'em up
    // Additionally, hide JuCo until https://nymets.atlassian.net/browse/MDPSI-1962 is done
    // 22, // College Baseball
    // 586, // HS
    1003, // NCAA
    1004, // Ama Summer League
    1008, // HS Summer League
    // 1009, // JuCo
    1013, // MLBDL
  ],
  [AcquisitionGroup.INTERNATIONAL_PROFESSIONAL]: [], // TBD
  [AcquisitionGroup.INTERNATIONAL_AMATEUR]: [], // TBD
};

export const videoDomesticAmateurLevels = [
  22, // College Baseball
  586, // HS (gumbo)
  1003, // NCAA
  1004, // Ama Summer League
  1007, // HS
  1008, // HS Summer League
  1009, // JuCo
  1010, // NAIA
  1012, // INT_AMA
  1013, // MLBDL
  2004, // AMA_PRACTICE
];

export const getPlayerHeadshot = (player) =>
  player.headshot ? player.headshot : PlayerBioAvatar;
export const isProPlayer = (acqGroup) =>
  [
    AcquisitionGroup.DOMESTIC_PROFESSIONAL,
    AcquisitionGroup.INTERNATIONAL_PROFESSIONAL,
  ].includes(acqGroup);

export const getCountryLabel = (birthCountry) => {
  // EXPLICIT US BIAS - don't render country when country = USA
  let country = "";
  if (birthCountry) {
    country = [
      "us",
      "usa",
      "united states",
      "united states of america",
    ].includes(birthCountry.trim().toLowerCase())
      ? ""
      : birthCountry;
  }
  return country;
};
