import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import {
  selectComparingMovement,
  selectExtraMovements,
  selectMovement,
  selectSelectedMotionType,
} from "../redux/selectors";
import { useFetchTrialKeyframesQuery } from "../services/performanceApi.service";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useFetchExtraMovementsDetails } from "./useFetchExtraMovementsDetails";
import { useMemo } from "react";
import { MotionType } from "../components/common/MotionType";
const findSyncReferenceFrame = (motionType: MotionType, keyFrames: any[]) => {
  const referenceFrame = motionType === MotionType.Batting ? "BC" : "BR";
  return keyFrames.find((it) => it.label === referenceFrame)?.frame || 0;
};
const useFetchMovementKeyFrames = (movement: any) => {
  const motionType = useSelector(selectSelectedMotionType);
  const { data } = useFetchTrialKeyframesQuery(
    !isEmpty(movement?.trial)
      ? { trial: movement?.trial, motionType }
      : skipToken
  );

  const value = useMemo(() => data || [], [data]);
  return value;
};
export function useTrialsVideoSyncParams() {
  const movement = useSelector(selectMovement);
  const mainKeyFrames = useFetchMovementKeyFrames(movement);
  const comparingMovement = useSelector(selectComparingMovement);
  const comparingKeyFrames = useFetchMovementKeyFrames(comparingMovement);
  const extraMovements = useSelector(selectExtraMovements);
  const motionType = useSelector(selectSelectedMotionType);
  const { extraMovementsDetails } =
    useFetchExtraMovementsDetails(extraMovements);
  const mainBR = useMemo(
    () => findSyncReferenceFrame(motionType, mainKeyFrames),
    [mainKeyFrames]
  );
  const comparingBR = useMemo(
    () =>
      !comparingMovement?.trial || isEmpty(comparingKeyFrames)
        ? 0
        : findSyncReferenceFrame(motionType, comparingKeyFrames),
    [comparingMovement, comparingKeyFrames]
  );
  const offsetBR = useMemo(
    () => (!comparingBR ? 0 : comparingBR - mainBR),
    [comparingBR, mainBR]
  );
  const extraMovementsOffsets = useMemo(
    () =>
      extraMovementsDetails.map((it) => {
        const extraBR =
          !it?.movement?.trial || isEmpty(it?.keyFrames)
            ? 0
            : findSyncReferenceFrame(motionType, it?.keyFrames);
        const offsetBR = !extraBR ? 0 : extraBR - mainBR;
        return { ...it, offsetBR };
      }),
    [extraMovementsDetails]
  );
  return { offsetBR, mainKeyFrames, comparingKeyFrames, extraMovementsOffsets };
}
