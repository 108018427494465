import React, { useMemo, useState } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import { Menu, Popover, Typography as TypographyAntd } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import AverageIcon from "./AverageIcon";
import "./MetricBox.css";
import {
  selectCurrentMetric,
  selectIsDataCompare,
  selectIsSelfCompare,
  selectMovement,
  selectSelectedMotionType,
} from "../../redux/selectors";
import { useSelector } from "react-redux";
import { useFetchMetricsQuery } from "../../services/performanceApi.service";
import { MetricTooltip } from "../common/MetricTooltip";
import {
  fixMetricId,
  removeEmptyMetrics,
  removeSpecificMetrics,
} from "../../utils/metrics";
import {
  METRICS_TO_REMOVE,
  UNITS_TO_REMOVE,
} from "../../hooks/useThrowEventsData";

interface MetricBoxProps {
  id: number;
  title: string;
  value: number;
  averageRange: {
    from: number;
    to: number;
  };
  min: number;
  max: number;
  unit: string;
  accentColor?: "main" | "secondary" | "self";
  isMainData?: boolean;
  onChangeMetric: (id: number, metricName: string, isMainData: boolean) => void;
}

const MetricBox = ({
  id,
  title,
  value,
  averageRange,
  unit,
  accentColor,
  onChangeMetric,
  min,
  max,
  isMainData = false,
}: MetricBoxProps) => {
  const { trial } = useSelector(selectMovement);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const isDataCompare = useSelector(selectIsDataCompare);
  const isSelfCompare = useSelector(selectIsSelfCompare);
  const currentMetric = useSelector(selectCurrentMetric);
  const motionType = useSelector(selectSelectedMotionType);
  const { data: metricsData = [] } = useFetchMetricsQuery({
    metricId: fixMetricId(currentMetric),
    pitch: trial,
    motionType,
  });

  const items = useMemo(() => {
    const filteredMetrics = removeSpecificMetrics(
      metricsData,
      METRICS_TO_REMOVE,
      UNITS_TO_REMOVE
    );
    return removeEmptyMetrics(filteredMetrics);
  }, [metricsData]);

  const { Text } = TypographyAntd;

  const onClick = (e: any) => {
    onChangeMetric(
      id,
      e?.domEvent?.target?.textContent || e.keyPath[0],
      isMainData
    );
    setMenuIsOpen(false);
  };

  const ChangeMetric = () => (
    <Menu onClick={onClick} mode="vertical" items={items} />
  );

  return (
    <MetricTooltip
      max={max}
      min={min}
      averageRange={averageRange}
      value={value}
      title={title}
      unit={unit}
      disableOpen={menuIsOpen}
      isUnitInTitle
    >
      <Grid
        container
        height="105px"
        width="95px"
        flexDirection="column"
        justifyContent="center"
        className={`ptd-${accentColor}-compare-data ptd-metric-box-container`}
        whiteSpace={"nowrap"}
      >
        <Grid container item className="ptd-metric-box-menu-button-container">
          <Popover
            open={menuIsOpen}
            onOpenChange={() => setMenuIsOpen(!menuIsOpen)}
            trigger={"click"}
            placement="right"
            overlayClassName="ptd-inner-menu"
            content={ChangeMetric}
          >
            <IconButton className="ptd-metric-box-menu-button" size="small">
              <MoreOutlined className="ptd-metric-box-menu-button" />
            </IconButton>
          </Popover>
        </Grid>

        <Grid container item width="95%" justifyContent="center">
          <Grid
            container
            width="100%"
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Typography className="ptd-metric-box-main-value">
              {value + unit}
            </Typography>

            <AverageIcon
              value={value}
              average={averageRange}
              isCompare={isDataCompare || isSelfCompare}
            />
          </Grid>
        </Grid>

        <Grid container item justifyContent="center">
          <Text className="ptd-metric-box-second-value">{title}</Text>
        </Grid>
      </Grid>
    </MetricTooltip>
  );
};

export default MetricBox;
