import { useDispatch, useSelector } from "react-redux";
import { selectSyncReferenceKeyFrame } from "./redux/selectors";
import { setSyncReferenceKeyFrame as setSyncReferenceKeyFrameAction } from "./redux/movementSlice";

export const useSyncReferenceKeyFrame = () => {
  const syncReferenceKeyFrame = useSelector(selectSyncReferenceKeyFrame);
  const dispatch = useDispatch();

  const setSyncReferenceFrame = (it: string): void => {
    dispatch(setSyncReferenceKeyFrameAction(it));
  };
  return { syncReferenceKeyFrame, setSyncReferenceFrame };
};
