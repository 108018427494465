import { compact, isEmpty, isNil, isNumber, merge } from "lodash";

export function isStringNumber(value?: string) {
  return (
    !isNil(value) &&
    !isNaN(parseFloat(value)) &&
    !isNaN(Number(value)) &&
    isNumber(parseFloat(value))
  );
}

export const unlessEmpty = (value: string | undefined, nonEmptyValue: string) =>
  isEmpty(value) ? undefined : nonEmptyValue;

export const stringFromParts = (
  parts: (string | undefined)[],
  separator = " "
) => compact(parts).join(separator);

export const formatDiscreteDataToCSV = (
  rawHeader: string[],
  data: { [key: string]: number | string }[]
) => {
  let text = "";
  const header = [...rawHeader, "\n"];
  const body = data.map((it) => [
    ...rawHeader.map((key: string) => it[key]),
    "\n",
  ]);

  [header, ...body].forEach((it) => {
    text += it;
  });

  return text;
};

export const toLocaleString = (
  value: number | undefined,
  options?: { decimals?: number; truncateZeros?: boolean }
) => {
  const { decimals, truncateZeros } = merge(
    { decimals: 1, truncateZeros: true },
    options
  );
  return isNil(value)
    ? "-"
    : value.toLocaleString("en-US", {
        minimumFractionDigits: truncateZeros ? 0 : decimals,
        maximumFractionDigits: decimals,
      });
};

export const toCsvLine = (object: any) => {
  return Object.values(object).join(",");
};
