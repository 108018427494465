import { get, isNil } from "lodash";

export enum SpineMobilityValue {
  Pass = "Pass",
  PassMobility = "Pass-Mobility",
  PassStability = "Pass-Stability",
  Mobility = "Mobility",
  Stability = "Stability",
}

interface SpineMobilityProps {
  shortLabel: string;
  result: string;
  reason?: string;
  resultColor?: string;
  reasonColor?: string;
}

const mobilityColor = "var(--ptd-team-dashboard-blue)";
const stabilityColor = "var(--ptd-red)";

const spineMobilityProps: Record<SpineMobilityValue, SpineMobilityProps> = {
  [SpineMobilityValue.Pass]: {
    shortLabel: "Pass",
    result: "Pass",
  },
  [SpineMobilityValue.PassMobility]: {
    shortLabel: "Pass-Mob",
    result: "Pass",
    reason: "Mobility",
    reasonColor: mobilityColor,
  },
  [SpineMobilityValue.PassStability]: {
    shortLabel: "Pass-Stab",
    result: "Pass",
    reason: "Stability",
    reasonColor: stabilityColor,
  },
  [SpineMobilityValue.Mobility]: {
    shortLabel: "Fail-Mob",
    result: "Fail",
    reason: "Mobility",
    resultColor: mobilityColor,
    reasonColor: mobilityColor,
  },
  [SpineMobilityValue.Stability]: {
    shortLabel: "Fail-Stab",
    result: "Fail",
    reason: "Stability",
    resultColor: stabilityColor,
    reasonColor: stabilityColor,
  },
};

export function isSpineMobilityMetric(metricId: string) {
  return (
    !isNil(metricId) &&
    ["rom_trunk_lumb", "rom_trunk_thor_pass", "rom_trunk_cerv"].some((it) =>
      metricId.startsWith(it)
    )
  );
}

export function parseSpineMobilityValue(value?: string) {
  if (isNil(value)) {
    return undefined;
  }

  const sanitizedValue = value.trim().replaceAll(" ", "-").toLowerCase();
  return Object.values(SpineMobilityValue).find(
    (it) => it.toLowerCase() === sanitizedValue
  );
}

export function spineMobilityProp(
  value: string,
  prop: string, // keyof SpineMobilityProp
  defaultValue: any
) {
  const parsedValue = parseSpineMobilityValue(value);
  return isNil(parsedValue)
    ? defaultValue
    : get(spineMobilityProps, [parsedValue, prop]);
}
