import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Grid } from "@mui/material";
import { Typography } from "antd";
import MetricBox from "../../../common/MetricBox";
import BulletChart from "../../../charts/BulletChart";
import { Metric } from "../../../../services/mockMetrics";
import InfiniteScroll from "react-infinite-scroll-component";
import { isEmpty, take } from "lodash";
import {
  selectComparingMovement,
  selectSelectedMotionType,
} from "../../../../redux/selectors";
import { useSelector } from "react-redux";
import { MotionType } from "../../../common/MotionType";

const PAGE_SIZE = 10;

interface ThrowEventsLayoutProps {
  favouriteData: any[];
  alternativeFavouriteData: any[];
  alternativeData: any[];
  isSelfCompare: boolean;
  referenceMetricData: Metric[];
  onMainMetricChange: (id: number, newMetricName: string) => void;
  onSecondaryMetricChange: (id: number, newMetricName: string) => void;
}

function ThrowEventsLayout({
  favouriteData,
  alternativeFavouriteData,
  alternativeData,
  isSelfCompare,
  onMainMetricChange,
  referenceMetricData,
  onSecondaryMetricChange,
}: ThrowEventsLayoutProps) {
  const { Text } = Typography;
  const { trial: secondaryTrial } = useSelector(selectComparingMovement);
  const [renderableMetrics, setRenderableMetrics] = useState(
    take(referenceMetricData, PAGE_SIZE)
  );
  const motionType = useSelector(selectSelectedMotionType);

  useEffect(() => {
    if (isEmpty(renderableMetrics) && !isEmpty(referenceMetricData)) {
      setRenderableMetrics(take(referenceMetricData, PAGE_SIZE));
    }
    return () => {
      setRenderableMetrics(take(referenceMetricData, PAGE_SIZE));
    };
  }, [referenceMetricData]);

  const onNext = useCallback(() => {
    const newRenderableMetrics = referenceMetricData.slice(
      0,
      renderableMetrics.length + PAGE_SIZE
    );
    setRenderableMetrics(newRenderableMetrics);
  }, [referenceMetricData, renderableMetrics]);

  const scrollableTarget =
    motionType === MotionType.Batting
      ? "Swing Events-scrollableDiv"
      : "Throw Events-scrollableDiv";
  const cachedScrollData = useMemo(
    () => (
      <InfiniteScroll
        style={{ width: "100%" }}
        dataLength={renderableMetrics.length}
        next={onNext}
        hasMore={renderableMetrics.length < referenceMetricData.length}
        loader={<>Loading...</>}
        endMessage={<></>}
        scrollableTarget={scrollableTarget}
      >
        <Grid container item xs={12}>
          {renderableMetrics.map((it, index) => {
            const {
              value,
              averageRange,
              label,
              metricName,
              unit,
              systematicName,
              decimals,
            } = it;
            const secondaryValue = alternativeData?.find(
              (it) => it.systematicName === systematicName
            )?.value;
            return (
              <Grid item key={index} xs={6}>
                <BulletChart
                  key={"main" + metricName + index}
                  value={value}
                  averageRange={averageRange}
                  title={label}
                  unit={unit}
                  secondaryValue={secondaryValue}
                  isSelf={isSelfCompare}
                  decimals={decimals}
                />
              </Grid>
            );
          })}
        </Grid>
      </InfiniteScroll>
    ),
    [JSON.stringify(renderableMetrics), JSON.stringify(alternativeData)]
  );

  return (
    <Grid container>
      <Grid item xs={12} my={1}>
        <Text underline>TRIAL 1</Text>
      </Grid>
      <Grid container item xs={12}>
        {favouriteData.map(
          (
            { id, value, averageRange, label, metricName, unit, min, max },
            index
          ) => (
            <MetricBox
              key={"main-" + metricName + index}
              id={id}
              value={value}
              min={min}
              max={max}
              averageRange={averageRange}
              title={label}
              unit={unit}
              accentColor={!!alternativeData?.length ? "main" : undefined}
              onChangeMetric={onMainMetricChange}
            />
          )
        )}
      </Grid>

      <Grid container item xs={12}>
        {secondaryTrial?.id &&
          alternativeFavouriteData.map(
            ({
              id,
              value,
              averageRange,
              label,
              metricName,
              unit,
              min,
              max,
            }) => (
              <MetricBox
                id={id}
                key={metricName + id}
                value={value}
                min={min}
                max={max}
                averageRange={averageRange}
                title={label}
                unit={unit}
                accentColor={isSelfCompare ? "self" : "secondary"}
                onChangeMetric={onSecondaryMetricChange}
              />
            )
          )}
      </Grid>

      <Grid item xs={12} my={2}>
        <Text underline>REFERENCE DATA</Text>
      </Grid>

      {cachedScrollData}
    </Grid>
  );
}

export default ThrowEventsLayout;
