import React, { useState } from "react";
import { PlayerSession } from "../../services/mockData";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useFetchSelfCompSessionsQuery } from "../../services/performanceApi.service";
import {
  selectSecondarySelfCompOptions,
  selectSelfCompOptions,
} from "../../redux/selectors";
import { useSelector } from "react-redux";
import { SessionPickerTable } from "./SessionPickerTable";

export function SelfCompSessionPicker({
  onChange,
  playerId,
  pageSize = 5,
  noSelect = false,
  isLoading: forceLoading = false,
  isSecondary = false,
}: {
  onChange: (value: PlayerSession) => void;
  playerId?: string;
  pageSize?: number;
  noSelect?: boolean;
  isLoading?: boolean;
  isSecondary?: boolean;
}) {
  const [currentPage, setCurrentPage] = useState(0);
  const selfCompOptions = useSelector(
    isSecondary ? selectSecondarySelfCompOptions : selectSelfCompOptions
  );
  const sup75Perc = selfCompOptions.payload?.sup75Perc || false;
  const pitchType = selfCompOptions.payload?.pitchType;
  const { data, isFetching } = useFetchSelfCompSessionsQuery(
    playerId && pitchType
      ? { pitcherId: playerId, sup75Perc, pitchType }
      : skipToken
  );

  const { sessions: rawSessions, count: totalItems } = data || {
    sessions: [],
    count: 0,
  };

  return (
    <SessionPickerTable
      playerId={playerId}
      rawSessions={rawSessions}
      totalItems={totalItems}
      pageSize={pageSize}
      isFetching={isFetching}
      noSelect={noSelect}
      onChange={onChange}
      forceLoading={forceLoading}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      calculatePagesLocally
    />
  );
}
