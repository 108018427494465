import React, { useMemo } from "react";
import { Table } from "antd";
import {
  useLazyFetchRawTrialCoordinatesQuery,
  useLazyFetchRawTrialDiscreteQuery,
  useLazyFetchRawTrialRawMotionSequenceQuery,
  useLazyFetchRawTrialTimeSeriesQuery,
} from "../../../services/performanceApi.service";
import { Pitch } from "../../../services/mockData";
import { triggerFileDownload } from "../../../three-js/loadCSV";
import { get, isString } from "lodash";
import { selectSelectedMotionType } from "../../../redux/selectors";
import { useSelector } from "react-redux";
const { Column } = Table;

export interface TrialDataRow {
  name: string;
  pathOrGetter:
    | string
    | ((props: Pick<TrialMetadataProps, "player" | "trial">) => string);
}

interface TrialMetadataProps {
  player: any;
  trial: any;
  dataRows: TrialDataRow[];
  includeCsvRows?: boolean;
}

export function TrialMetadata({
  dataRows,
  includeCsvRows = false,
  ...props
}: TrialMetadataProps) {
  const { trial } = props;
  const [fetchTrialCoordinates] = useLazyFetchRawTrialCoordinatesQuery();
  const [fetchTrialDiscrete] = useLazyFetchRawTrialDiscreteQuery();
  const [fetchTrialTimeSeries] = useLazyFetchRawTrialTimeSeriesQuery();
  const [fetchTrialRawMotionSequence] =
    useLazyFetchRawTrialRawMotionSequenceQuery();
  const motionType = useSelector(selectSelectedMotionType);
  const downloadMotionFile = async (aTrial: Pitch) => {
    const csv = await fetchTrialCoordinates({
      trial: aTrial,
      motionType,
    }).unwrap();
    triggerFileDownload("cbma_motion_sequence.csv", csv);
  };
  const downloadDiscreteFile = async (aTrial: Pitch) => {
    const csv = await fetchTrialDiscrete({
      trial: aTrial,
      motionType,
    }).unwrap();
    triggerFileDownload("cbma_discrete.csv", csv);
  };
  const downloadTimeSeriesFile = async (aTrial: Pitch) => {
    const csv = await fetchTrialTimeSeries({
      trial: aTrial,
      motionType,
    }).unwrap();
    triggerFileDownload("cbma_time_series.csv", csv);
  };

  const downloadRawMotionSequenceFile = async (aTrial: Pitch) => {
    const csv = await fetchTrialRawMotionSequence({
      trial: aTrial,
      motionType,
    }).unwrap();
    triggerFileDownload("motion_sequence.csv", csv);
  };

  const csvRows = useMemo(
    () =>
      includeCsvRows
        ? [
            {
              name: "CBMA_motion_sequence.csv",
              value: "CBMA_motion_sequence.csv",
              onClick: () => downloadMotionFile(trial),
            },
            {
              name: "CBMA_discrete.csv",
              value: "CBMA_discrete.csv",
              onClick: () => downloadDiscreteFile(trial),
            },
            {
              name: "CBMA_time_series.csv",
              value: "CBMA_time_series.csv",
              onClick: () => downloadTimeSeriesFile(trial),
            },
            {
              name: "motion_sequence.csv",
              value: "motion_sequence.csv",
              onClick: () => downloadRawMotionSequenceFile(trial),
            },
          ]
        : [],
    [includeCsvRows, trial]
  );

  const rows = [
    ...dataRows.map(({ name, pathOrGetter }) => ({
      name,
      value: isString(pathOrGetter)
        ? get(props, pathOrGetter)
        : pathOrGetter(props),
    })),
    ...csvRows,
  ];

  return (
    <>
      <Table dataSource={rows} pagination={false}>
        <Column
          title="Variable"
          dataIndex={"action"}
          className="ptd-column ptd-column-variable"
          render={(_, metric: { name: string; value: any }) => (
            <span style={{ whiteSpace: "nowrap" }}>{metric.name}</span>
          )}
        />
        <Column
          title="Value"
          dataIndex={"action"}
          className="ptd-column ptd-column-variable"
          render={(_, metric: { name: string; value: any; onClick?: any }) => {
            if (!metric.onClick) {
              return (
                <span style={{ whiteSpace: "nowrap" }}>
                  {metric.value ?? "-"}
                </span>
              );
            }
            return <a onClick={metric.onClick}>{metric.value}</a>;
          }}
        />
      </Table>
    </>
  );
}
