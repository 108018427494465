import { isEmpty } from "lodash";
import _ from "lodash";

export function syncTrialsToKeyFrame({
  jointCoordinates,
  comparingJointCoordinates,
  mainKeyFrames,
  comparingKeyFrames,
  keyFrame = "BR",
}: {
  jointCoordinates: any[];
  comparingJointCoordinates: any[];
  mainKeyFrames: any[];
  comparingKeyFrames: any[];
  keyFrame?: string;
}) {
  if (
    [
      jointCoordinates,
      comparingJointCoordinates,
      mainKeyFrames,
      comparingKeyFrames,
    ].some(isEmpty)
  )
    return [];
  const findKeyFrame = (keyFrames: any[]) => {
    return keyFrames.find((it) => it.label === keyFrame)?.frame;
  };
  const mainKeyFrame: number = findKeyFrame(mainKeyFrames);
  const comparingKeyFrame = findKeyFrame(comparingKeyFrames);
  let syncedTrial = [];
  //If they are synced, take as many frames as the main movement has
  if (comparingKeyFrame === mainKeyFrame) {
    syncedTrial = _.take(comparingJointCoordinates, jointCoordinates.length);
  } else {
    //Calculate first part
    const rawFirstHalf = _.take(
      comparingJointCoordinates,
      comparingKeyFrame + 1
    );
    if (comparingKeyFrame > mainKeyFrame) {
      //If comparing movement BR occurrs later cap to same number of frames as main movement before BR
      const firstHalf = _.drop(rawFirstHalf, comparingKeyFrame - mainKeyFrame);
      const secondHalf = _.drop(
        comparingJointCoordinates,
        comparingKeyFrame + 1
      );
      syncedTrial = _.take(
        firstHalf.concat(secondHalf),
        jointCoordinates.length
      );
    } else {
      //If comparing movement BR occurrs earlier, pad the trial with repeated first frame to match trial length
      const firstHalf = _.times(
        mainKeyFrame - comparingKeyFrame,
        () => rawFirstHalf[0]
      ).concat(rawFirstHalf);
      const secondHalf = _.drop(
        comparingJointCoordinates,
        comparingKeyFrame + 1
      );
      syncedTrial = _.take(
        firstHalf.concat(secondHalf),
        jointCoordinates.length
      );
    }
  }
  //In any case, if synced trial is shorter, pad ending with repeated final frame
  if (syncedTrial.length < jointCoordinates.length) {
    syncedTrial = syncedTrial.concat(
      _.times(jointCoordinates.length - syncedTrial.length, () =>
        _.last(comparingJointCoordinates)
      )
    );
  }
  return syncedTrial;
}
