import React from "react";

interface ScrollableProps {
  children: React.ReactNode;
  height?: number;
  prefix?: string;
}

const Scrollable = ({ children, height, prefix = "" }: ScrollableProps) => (
  <div
    id={prefix + "-scrollableDiv"}
    style={{ overflow: "auto", height: height }}
  >
    {children}
  </div>
);

export default Scrollable;
