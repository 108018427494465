import { curry } from "lodash";

export function truncToDecimalPlaces(value: number, decimalPlaces = 0) {
  const multiplier = Math.pow(10, decimalPlaces);
  const roundedNumber = Math.floor(value * multiplier) / multiplier;
  return roundedNumber;
}

export function percentageChange(originalValue = 0, newValue = 0) {
  if (originalValue === 0) {
    return 0.0;
  }
  const change = newValue - originalValue;
  const percentageChange = (change / originalValue) * 100;
  return percentageChange;
}
export const centerAboutZero = (value: number) => (value - 0.5) * 2;
export const capBetween = curry((min: number, max: number, value: number) =>
  Math.min(Math.max(value, min), max)
);
export const relativize = curry(
  (mean: number, stdDev: number, value: number) =>
    (value - (mean - 2 * stdDev)) / (4 * stdDev)
);
export const increaseFixedAmount = curry((amount: number, value: number) =>
  value > 0 ? value + amount : value - amount
);
