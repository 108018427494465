import React from "react";
import { Grid, Typography } from "@mui/material";
import "./TrendsPanelModal.css";
import dayjs from "dayjs";
import { Popover } from "antd";

interface SelfHorizontalBarIndicatorProps {
  data: any[];
  hoveredColor: React.CSSProperties["color"];
}

const SelfHorizontalBarIndicator = ({
  data,
  hoveredColor,
}: SelfHorizontalBarIndicatorProps) => (
  <Grid container>
    <div className="ptd-gradient-line" />
    <Grid container justifyContent="space-between">
      {data.map((metric, index) =>
        dayjs(metric?.date).isValid() ? (
          <Grid item xs="auto" key={index} position="relative">
            <Popover
              placement="top"
              className="ptd-popover-time-series"
              overlayStyle={{
                width: "auto",
              }}
              overlayInnerStyle={{
                border: `solid 1px ${metric?.lineColor}`,
              }}
              showArrow={false}
              open={hoveredColor === metric?.lineColor}
              content={
                <Typography
                  sx={{
                    padding: "4px",
                    borderRadius: "4px",
                  }}
                  fontSize={10}
                >
                  {dayjs(metric?.date).format("MM/DD/YYYY")}
                </Typography>
              }
            >
              <div
                style={{
                  width: "1px",
                  height: "6px",
                  backgroundColor: "#626262",
                  position: "absolute",
                  left: "50%",
                }}
              />
            </Popover>
            <Typography fontSize={12} sx={{ marginTop: 1 }}>
              {dayjs(metric?.date).format("M/D")}
            </Typography>
          </Grid>
        ) : (
          <></>
        )
      )}
    </Grid>
  </Grid>
);

export default SelfHorizontalBarIndicator;
