import React, { ReactNode } from "react";
import { Grid, MenuItem, Select, Typography } from "@mui/material";
import { Switch, Radio } from "antd";
import "./LabeledSwitch.css";
import { DataSwitch } from "../../services/mockData";
import SelfMenu from "./SelfMenu";
export interface LabeledSwitchProps {
  label: string | ReactNode;
  checked: boolean;
  className?: string;
  onClick: (params?: any) => void;
  data?: DataSwitch[];
  value?: DataSwitch;
  onHandleData?: (value: any) => void;
  isSelf?: boolean;
  disabled?: boolean;
  noMenu?: boolean;
  color?: string;
  isRadio?: boolean;
  textTransform?: React.CSSProperties["textTransform"];
  textColor?: React.CSSProperties["color"];
  shouldUseDefaultColor?: boolean;
  selfValue?: any;
  setSelfValue?: any;
  isSecondarySelf?: boolean;
}
const LabeledSwitch = ({
  label,
  checked,
  className,
  onClick,
  data,
  value,
  onHandleData,
  color,
  isSelf = false,
  disabled = false,
  noMenu = false,
  isRadio = false,
  textTransform = "capitalize",
  textColor,
  shouldUseDefaultColor = true,
  selfValue,
  setSelfValue,
  isSecondarySelf = false,
}: LabeledSwitchProps) => (
  <Grid flexDirection="row" alignItems="center" margin="8px" display="flex">
    <Typography
      className="label"
      style={{
        color: textColor ? textColor : color ? color : undefined,
        textTransform,
      }}
    >
      {label}
    </Typography>

    {noMenu ? (
      <></>
    ) : data && !isSelf ? (
      <Select
        variant="standard"
        size="small"
        className="ptd-switch"
        displayEmpty
        renderValue={() => (value ?? data?.[0])?.label}
        value={value?.id}
        disabled={disabled}
        onChange={(ev) => {
          if (onHandleData !== undefined) {
            onHandleData(ev.target.value);
          }
        }}
        sx={{
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
          marginLeft: "12px",
        }}
      >
        {data.map((item) => (
          <MenuItem
            key={`${item.label}-${item.year}`}
            sx={{ width: isSelf ? 220 : "auto", fontSize: 14 }}
            value={`${item.id}`}
          >
            {isRadio ? (
              <Radio.Group value={value?.id}>
                <Radio value={item.id}>{item.label}</Radio>
              </Radio.Group>
            ) : (
              <Grid container justifyContent={"space-between"}>
                <Grid item>
                  {item.label} {item.year}
                </Grid>
                <Grid item>{item.mph}</Grid>
              </Grid>
            )}
          </MenuItem>
        ))}
      </Select>
    ) : (
      <SelfMenu
        data={data}
        value={selfValue}
        onChange={setSelfValue}
        isSecondarySelf={isSecondarySelf}
      />
    )}

    <Switch
      className={`switch ${
        shouldUseDefaultColor ? "default-color-label" : ""
      } ${className}`}
      checked={checked}
      onClick={onClick}
      size="small"
      disabled={disabled}
      style={{ backgroundColor: checked ? color : undefined }}
    />
  </Grid>
);

export default LabeledSwitch;
