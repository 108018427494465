import React, { useEffect, useMemo, useState } from "react";
import Popover from "@mui/material/Popover";
import { Button, Card, Divider, Flex, Slider } from "antd";
import { SliderMarks } from "antd/es/slider";
import { Box, Stack, Typography } from "@mui/material";
import { Range } from "../../services/performanceApi.service";
import _, { isNil } from "lodash";
import { TrendsDiscreteMetricData } from "./TrendsInfoTable";
import {
  DataIndexingValue,
  formatDataIndexingXCoordinate,
} from "../PlayerDashboard/ExpandedMetricsDataIndexing";
import "./RangeSelectionPopover.css";

export interface RangeSelectionPopoverProps {
  anchorEl: HTMLButtonElement | null;
  onClose: () => void;
  onApply: (regressionNumber: number, range: Range) => void;
  onReset: (regressionNumber: number) => void;
  range?: Range;
  regressionNumber: number;
  values?: TrendsDiscreteMetricData["values"];
}

export default function RangeSelectionPopover({
  anchorEl,
  onClose,
  onApply,
  onReset,
  range,
  regressionNumber,
  values,
}: RangeSelectionPopoverProps) {
  const [value, setValue] = useState([range?.from ?? 0, range?.to ?? 0]);

  useEffect(() => {
    if (isNil(range)) {
      return;
    }
    setValue([range.from, range.to]);
  }, [range]);

  const handleClose = () => {
    onClose();
  };

  const handleApply = () => {
    onApply(regressionNumber, { from: value[0], to: value[1] });
    onClose();
  };

  const handleReset = () => {
    onReset(regressionNumber);
    onClose();
  };

  const handleChange = (newValue: number[]) => {
    setValue(newValue);
  };

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const id = useMemo(
    () => (open ? "range-selection-popover" : undefined),
    [open]
  );

  const xValues = useMemo(
    () =>
      values?.map((it: DataIndexingValue) => formatDataIndexingXCoordinate(it)),
    [values]
  );

  const marks: SliderMarks = useMemo(
    () =>
      _(xValues)
        .map((it, index) => [index + 1, it])
        .fromPairs()
        .value(),
    [xValues]
  );

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Card
        size="small"
        title={`Split Regression ${regressionNumber}`}
        style={{ width: 370 }}
      >
        <Stack>
          <Typography variant="body2">
            Select desired range for Split Regression {regressionNumber}.
          </Typography>
          <Box px={1}>
            <Slider
              range
              marks={marks}
              step={null}
              min={1}
              max={xValues?.length}
              value={value}
              onChange={handleChange}
              className="range-selection-slider"
            />
          </Box>
          <Divider style={{ margin: "12px 0" }} />
          <Flex justify="space-between">
            <Button type="link" size="small" onClick={() => handleReset()}>
              Reset
            </Button>
            ,
            <Button type="primary" size="small" onClick={() => handleApply()}>
              Apply
            </Button>
          </Flex>
        </Stack>
      </Card>
    </Popover>
  );
}
