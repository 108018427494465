import React from "react";
import { Table } from "antd";
import { BodyPartMetric } from "../../../../services/mockData";
import { toLower } from "lodash";
import { getTableCellColorByValue } from "../../../../utils/colors";
import { useSelector } from "react-redux";
import {
  selectIsDataCompare,
  selectSelectedMotionType,
} from "../../../../redux/selectors";
import { MetricTooltip } from "../../../common/MetricTooltip";
import { findDataCompValuesRangeForMetric } from "./findDataCompAverageRangeForMetric";
import { getBodyPartColumns } from "../../../../utils/metrics";
const { Column } = Table;

interface TableWithoutCompareProps {
  data: BodyPartMetric[];
  handleMetric: (metric: BodyPartMetric) => void;
  selectedRowKey: number;
  setSelectedRowKey: (key: number) => void;
}

const TableWithoutCompare = ({
  data,
  handleMetric,
  selectedRowKey,
  setSelectedRowKey,
}: TableWithoutCompareProps) => {
  const isDataCompare = useSelector(selectIsDataCompare);
  const motionType = useSelector(selectSelectedMotionType);
  const columns = getBodyPartColumns(motionType);

  return (
    <Table
      dataSource={data}
      rowSelection={{
        type: "radio",
        columnTitle: "Active",
        selectedRowKeys: [selectedRowKey],
        onChange: (_, [selected]) => {
          handleMetric(selected);
          setSelectedRowKey(selected.id);
        },
        renderCell(_value, _record, _index, node) {
          return <div style={{ height: "12px" }}>{node}</div>;
        },
      }}
      bordered
      className="ptd-body-part-table ptd-table-blank-background"
      size="small"
      pagination={false}
    >
      <Column
        title="Variable"
        dataIndex={"action"}
        className="ptd-column ptd-column-variable"
        render={(_, metric: BodyPartMetric) => (
          <span className="ptd-cell">
            {metric.name} ({metric.unit})
          </span>
        )}
      />

      {columns.map((column, index) => (
        <Column
          title={column}
          key={index}
          className="ptd-column"
          dataIndex={["events", toLower(column)]}
          render={(value, metric: BodyPartMetric) => {
            const { from, to, max, min } = findDataCompValuesRangeForMetric(
              column,
              metric
            );
            const averageRange = { from, to };

            return {
              props: {
                style: isDataCompare
                  ? {
                      background: getTableCellColorByValue(value, averageRange),
                    }
                  : {},
              },
              children: (
                <MetricTooltip
                  max={max}
                  min={min}
                  averageRange={averageRange}
                  value={value}
                  title={metric.name}
                  unit={metric.unit}
                  isUnitInTitle
                >
                  <span className="ptd-cell">{value}</span>
                </MetricTooltip>
              ),
            };
          }}
        />
      ))}
    </Table>
  );
};

export default TableWithoutCompare;
