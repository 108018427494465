import React, { useState } from "react";
import { Popover } from "antd";
import "./MetricBox.css";

import {
  MetricDescriptionTooltipContent,
  MetricDescriptionTooltipProps,
} from "./MetricDescriptionTooltipContent";
import { isNil } from "lodash";

export const MetricTooltip = ({
  children,
  disableOpen = false,
  isUnitInTitle = false,
  ...tooltipContentProps
}: MetricDescriptionTooltipProps & {
  children: React.ReactNode;
  disableOpen?: boolean;
}) => {
  const props = {
    ...tooltipContentProps,
    title:
      isUnitInTitle && !isNil(tooltipContentProps?.unit)
        ? `${tooltipContentProps?.title} (${tooltipContentProps?.unit})`
        : tooltipContentProps?.title,
  };

  const MetricDescription = <MetricDescriptionTooltipContent {...props} />;
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

  return (
    <Popover
      content={MetricDescription}
      placement="right"
      trigger={"click"}
      onOpenChange={() => setTooltipIsOpen(!tooltipIsOpen)}
      open={!disableOpen && tooltipIsOpen}
    >
      {children}
    </Popover>
  );
};
