import { Grid } from "@mui/material";
import React, { createRef, memo, useMemo } from "react";
import { find } from "lodash";
import { MovementAngleVideo } from "./MovementAngleVideo";
import { Video } from "./Video";

export interface TabsWithRef {
  label: string;
  videoRef: React.RefObject<HTMLVideoElement>;
  mainAngleRef: React.RefObject<HTMLVideoElement>;
  secondaryAngleRef: React.RefObject<HTMLVideoElement>;
  videoUrl: string;
  comparingVideoUrl: string;
  extraVideos?: any[];
}

interface VideoReproductionProps {
  currentTab: string;
  tabsWithRef: TabsWithRef[];
  setSelectedTab: (tab: string) => void;
  syncWorldAndVideo: () => void;
}

function VideoReproduction({
  currentTab,
  tabsWithRef,
  setSelectedTab,
  syncWorldAndVideo,
}: VideoReproductionProps) {
  const {
    videoUrl: angleVideoUrl,
    comparingVideoUrl: angleComparingVideoUrl,
    mainAngleRef,
    secondaryAngleRef,
    extraVideos,
  } = useMemo(
    () =>
      find(tabsWithRef, function (tab) {
        return tab.label === currentTab;
      }) || {
        videoUrl: "",
        comparingVideoUrl: "",
        mainAngleRef: createRef<HTMLVideoElement>(),
        secondaryAngleRef: createRef<HTMLVideoElement>(),
        extraVideos: [],
      },
    [tabsWithRef]
  );

  return (
    <>
      {currentTab === "All" ? (
        <Grid
          container
          justifyContent="center"
          alignContent={"center"}
          spacing={1}
        >
          {tabsWithRef.map(({ label, videoRef, videoUrl }, index) => (
            <Grid key={label + index} item md={6} lg={4}>
              <Video
                videoRef={videoRef}
                videoUrl={videoUrl}
                onClick={() => {
                  setSelectedTab(label);
                }}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <MovementAngleVideo
          videoRef={mainAngleRef}
          videoUrl={angleVideoUrl}
          comparingVideoRef={secondaryAngleRef}
          comparingVideoUrl={angleComparingVideoUrl}
          extraVideos={extraVideos}
          syncWorldAndVideo={syncWorldAndVideo}
        />
      )}
    </>
  );
}

export default memo(VideoReproduction);
