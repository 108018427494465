import React from "react";
import { Select } from "antd";
import { Speed } from "../../hooks/use3DAnimationVideoScrubber";

interface VideoSpeedSelectProps {
  currentSpeed: number;
  onChange: (val: Speed) => void;
}

function VideoSpeedSelect({ currentSpeed, onChange }: VideoSpeedSelectProps) {
  return (
    <Select
      bordered={false}
      suffixIcon={null}
      onChange={onChange}
      value={currentSpeed}
      style={{ width: 60 }}
      options={[
        { label: "1/4 x", value: Speed.SUPER_SLOW },
        { label: "1/2 x", value: Speed.SLOW },
        { label: "1 x", value: Speed.NORMAL },
        { label: "2 x", value: Speed.FAST },
      ]}
    />
  );
}

export default VideoSpeedSelect;
