import React from "react";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import "./MetricBox.css";

interface AverageIconProps {
  value: number;
  average: {
    from: number;
    to: number;
  };
  isCompare: boolean;
}

const AverageIcon = ({
  value,
  average: { from, to },
  isCompare,
}: AverageIconProps) => {
  if (isCompare) {
    if (value < from) {
      return (
        <CaretDownOutlined
          className="ptd-average-icon"
          style={{ color: "var(--hoveredDigitalRed)" }}
        />
      );
    }

    if (value > to) {
      return (
        <CaretUpOutlined
          className="ptd-average-icon"
          style={{ color: "var(--hoveredLightBlue)" }}
        />
      );
    }
  }

  return (
    <CaretUpOutlined
      className="ptd-average-icon"
      style={{ color: "transparent" }}
    />
  );
};

export default AverageIcon;
