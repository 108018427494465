import React, { useEffect, useMemo, useState } from "react";
import { Grid } from "@mui/material";
import {
  formatSplitRegressionSeries,
  formatToCandlestickData,
  generateCandleStickMarker,
  getDateValue,
  makeRegressionSeries,
  makeTooltipBox,
  parseObjecToStringHTML,
  updateOrReplaceSeries,
} from "../../utils/charts";
import { DataPoint } from "regression";
import {
  DateIndexingValue,
  InningSplitValue,
} from "../../services/performanceApi.service";
import {
  dataIndexingValueToDataPoint,
  dataIndexingValueToXCoordinate,
  ExpandedMetricsDataIndexing,
  formatSecondarySeriesXAxis,
} from "../PlayerDashboard/ExpandedMetricsDataIndexing";
import dayjs from "dayjs";
import { VisibleChartSeries } from "../TrendsPanelModal/VisibleChartSeriesToggles";
import MemoizedHighcharts from "./MemoizedHighcharts";

interface RegressionPillChartProps {
  data: (InningSplitValue | DateIndexingValue)[];
  regressionsData?: DataPoint[];
  dataComp?: any;
  selfComp?: any;
  isDataCompActive?: boolean;
  isSelfCompActive?: boolean;
  splitRegression1Data?: DataPoint[];
  splitRegression2Data?: DataPoint[];
  formatXAsDate?: boolean;
  dataIndexing?: ExpandedMetricsDataIndexing;
  visibleChartSeries?: VisibleChartSeries;
}

const RegressionPillChart = ({
  data = [],
  dataComp,
  selfComp,
  regressionsData,
  isDataCompActive,
  isSelfCompActive,
  splitRegression1Data,
  splitRegression2Data,
  formatXAsDate = false,
  dataIndexing,
  visibleChartSeries,
}: RegressionPillChartProps) => {
  const [chart, setChart] = useState<any>(null);
  const isDateIndexing = dataIndexing === ExpandedMetricsDataIndexing.Date;

  const mainSeries = useMemo(
    () => ({
      type: "line",
      lineColor: visibleChartSeries?.linePlot ? "#505050" : "transparent",
      zIndex: 2,
      data: data.map((it, index) => {
        const date = dayjs(it?.date, "YYYY-MM-DD").valueOf();

        if (isDateIndexing) {
          const [x, y] = dataIndexingValueToDataPoint(it);
          return { x, y, date };
        }
        return { x: index + 1, y: it?.value, date };
      }),
      marker: {
        symbol: "circle",
      },
    }),
    [data, dataIndexing, visibleChartSeries?.linePlot]
  );

  const bounds = useMemo(
    () => (isDataCompActive ? dataComp[0] || { high: 0, low: 0 } : undefined),
    [isDataCompActive, dataComp]
  );

  const candleStickSeries = useMemo(
    () => ({
      type: "candlestick",
      upColor: "transparent",
      lineColor: "transparent",
      color: "transparent",
      pointWidth: -1,
      borderWidth: -1,
      zIndex: 1,
      visible: visibleChartSeries?.errorBar,
      data: formatToCandlestickData(data, bounds, dataIndexing),
      dataLabels: {
        enabled: true,
        formatter: function () {
          const chart: any = this;

          return generateCandleStickMarker(
            chart,
            "var(--ptd-candlestick-base-color)"
          );
        },
        useHTML: true,
      },
    }),
    [data, bounds, dataIndexing, visibleChartSeries?.errorBar]
  );

  const regressionSeries = useMemo(
    () =>
      makeRegressionSeries(
        regressionsData?.filter(([x]: number[], index: number) => {
          const element = data[index];

          return isDateIndexing
            ? x === dataIndexingValueToXCoordinate(element)
            : data.findIndex((it) => getDateValue(it) === x);
        }),
        visibleChartSeries?.fullRegression
      ),
    [regressionsData, dataIndexing, visibleChartSeries?.fullRegression]
  );

  const splitRegression1Series = useMemo(
    () =>
      makeRegressionSeries(
        formatSplitRegressionSeries(splitRegression1Data, data, isDateIndexing),
        visibleChartSeries?.splitRegression1
      ),
    [splitRegression1Data, dataIndexing, visibleChartSeries?.splitRegression1]
  );

  const splitRegression2Series = useMemo(
    () =>
      makeRegressionSeries(
        formatSplitRegressionSeries(splitRegression2Data, data, isDateIndexing),
        visibleChartSeries?.splitRegression2
      ),
    [splitRegression2Data, dataIndexing, visibleChartSeries?.splitRegression2]
  );

  const secondarySeries = useMemo(
    () => [
      {
        color: "var(--activeDigitalBlue)",
        dashStyle: "longDash",
        marker: false,
        enableMouseTracking: false,
        zIndex: 0,

        visible: isDataCompActive,
        data: isDataCompActive
          ? formatSecondarySeriesXAxis(data, dataComp, isDateIndexing)
          : [],
      },
      {
        color: "var(--activeDigitalOrange)",
        dashStyle: "longDash",
        marker: false,
        enableMouseTracking: false,
        zIndex: 0,

        visible: isSelfCompActive,
        data: isSelfCompActive
          ? formatSecondarySeriesXAxis(data, selfComp, isDateIndexing)
          : [],
      },
    ],
    [isDataCompActive, isSelfCompActive, dataComp, selfComp, dataIndexing]
  );

  const series = useMemo(
    () => [
      regressionSeries,
      mainSeries,
      candleStickSeries,
      ...secondarySeries,
      splitRegression1Series,
      splitRegression2Series,
    ],
    [
      regressionSeries,
      mainSeries,
      candleStickSeries,
      ...secondarySeries,
      splitRegression1Series,
      splitRegression2Series,
      dataIndexing,
    ]
  );

  // Highcharts doesn't play well with dynamic data, so we completely replace the series when data changes.
  // If we don't it this way, the chart can crash with the message "Cannot read properties of null (reading 'length')".
  useEffect(() => {
    if (chart) {
      series.forEach((series, index) => {
        updateOrReplaceSeries(chart, { ...series, index });
      });
    }
  }, [chart, series]);

  const options = {
    chart: {
      height: 312,
      marginBottom: 36,
      animation: false,
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
      events: {
        load: function () {
          setChart(this);
        },
      },
    },
    title: { text: "" },
    tooltip: {
      enabled: true,
      split: true,
      shared: true,
      outside: true,
      useHTML: true,
      style: {
        opacity: 0,
        zIndex: 1000,
      },
      crosshairs: {
        color: "var(--ptd-tooltip-accent-color)",
        dashStyle: "solid",
      },
      formatter: function (): string {
        const chart: any = this;
        const xValue = dayjs(chart.point.date).format("MM/DD/YY");
        const yValue = Math.floor(Number(chart.y)).toString();

        const yTooltipBox = makeTooltipBox({
          children: yValue,
          color: "var(--ptd-tooltip-accent-color)",
        });
        const xTooltipBox = makeTooltipBox({
          children: xValue,
          color: "var(--ptd-tooltip-accent-color)",
          style: { alignSelf: "end" },
        });

        return parseObjecToStringHTML({
          children: `${yTooltipBox}${xTooltipBox}`,
          style: {
            position: "absolute",
            top: "-40px",
            left: "24px",
            display: "grid",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "stretch",
            height: `${options.chart.height + 16}px`,
          },
        });
      },
      positioner: function (
        labelWidth: number,
        labelHeight: number,
        point: any
      ) {
        const { chart }: any = this;
        const y = (chart.chartHeight + labelHeight) * -1;
        return {
          x: point.clientX + labelWidth / 2 - 12,
          y,
        };
      },
    },
    legend: false,
    credits: false,
    xAxis: {
      type: isDateIndexing ? "datetime" : "string",
      labels: {
        format:
          formatXAsDate && isDateIndexing ? "{value:%m/%d/%y}" : undefined,
      },
    },
    yAxis: {},
    plotOptions: {
      series: {
        marker: {
          enabled: true,
          lineWidth: 1,
          radius: 4,
          states: {
            hover: {
              fillColor: "var(--ptd-tooltip-accent-color)",
            },
            normal: {
              fillColor: "#505050",
              lineColor: "transparent",
            },
          },
        },
      },
    },
    series: [],
  };

  return (
    <Grid container justifyContent="center">
      <MemoizedHighcharts options={options} />
    </Grid>
  );
};

export default RegressionPillChart;
