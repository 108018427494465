import React from "react";
import ThrowEventsLayout from "./ThrowEventsLayout";
import { useThrowEventsData } from "../../../../hooks/useThrowEventsData";
import { useFetchDiscreteEventDataQuery } from "../../../../services/performanceApi.service";
import { useSelector } from "react-redux";
import { selectSelectedMotionType } from "../../../../redux/selectors";
import { skipToken } from "@reduxjs/toolkit/dist/query";

const DiscreteTrialEvent = ({ event }: { event: string }) => {
  const motionType = useSelector(selectSelectedMotionType);
  const props = useThrowEventsData({
    useFetchPrimaryData: (pitch) =>
      useFetchDiscreteEventDataQuery(
        pitch === skipToken ? skipToken : { pitch, event, motionType }
      ),
  });
  return <ThrowEventsLayout {...props} />;
};

export default DiscreteTrialEvent;
