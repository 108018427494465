import { useEffect, useState } from "react";
import { readCSVFromFile } from "../three-js/loadCSV";

export function useLoadCSVCoordinatesFromFile() {
  const [jointCoordinates, setJointCoordinates] = useState([]);
  const [movementFile, setMovementFile] = useState<FileList | null>(null);
  useEffect(() => {
    if (movementFile) {
      // Load joint coordinates from CSV file
      readCSVFromFile(movementFile)
        .then((coordinates) => setJointCoordinates(coordinates))
        .catch((error) => console.log("Error loading joints from CSV", error));
    }
  }, [movementFile]);

  return { jointCoordinates, setMovementFile };
}
