import { isNil, merge } from "lodash";
import { buildStore } from "./Components/Redux/store";
import { isPerformanceDashboard } from "./Components/App/isPerformanceDashboard";

export async function createReduxStore() {
  const reduxModules = [];
  if (isPerformanceDashboard()) {
    reduxModules.push(await loadPerformanceApiModule());
    reduxModules.push(await loadPerformanceSliceModule());
  }
  const storeExtraConfig = mergeReduxModules(reduxModules);
  const appStore = buildStore(storeExtraConfig);
  return appStore;
}

function mergeReduxModules(reduxModules) {
  return reduxModules.reduce(
    (config, reduxModule) => {
      const reducer = merge({}, config.reducer, reduxModule.reducer);
      const middlewares = config.middlewares.concat(
        reduxModule.middlewares || []
      );
      return { reducer, middlewares };
    },
    { reducer: {}, middlewares: [] }
  );
}

async function loadPerformanceApiModule() {
  return await loadReduxModule({
    reduxModule: await import(
      "./performance-dashboard/services/performanceApi.service"
    ),
    reducerPath: "performanceApi",
  });
}
async function loadPerformanceSliceModule() {
  return await loadReduxModule({
    reduxModule: await import(
      "./performance-dashboard/redux/performanceReduxModule"
    ),
  });
}
async function loadReduxModule({ reduxModule, reducerPath }) {
  return {
    reducer: isNil(reducerPath)
      ? reduxModule.reducer
      : {
          [reducerPath]: reduxModule[reducerPath].reducer,
        },
    middlewares: isNil(reducerPath)
      ? reduxModule.middleware
      : reduxModule[reducerPath].middleware,
  };
}
