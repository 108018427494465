import dayjs from "dayjs";
import { shouldLoadLiteMode } from "./shouldLoadLiteMode";
const today = dayjs().format("MM/DD");

export const shouldLoadHalloweenEasterEgg =
  !shouldLoadLiteMode &&
  (["10/30", "10/31"].includes(today) ||
    window.location.href.includes("halloween"));
export const shouldLoadStarWarsEasterEgg =
  !shouldLoadLiteMode &&
  (["05/04"].includes(today) || window.location.href.includes("starwars"));
