import React from "react";
import { Route, Routes } from "react-router-dom";
import { LoginCallback, Security } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { BrowserRouter } from "react-router-dom";
import App from "../Components/App/App";
import { Spin } from "antd";
import { RequiredAuth } from "./SecureRoute";

const OktaLogin = () => {
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    window.location.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  const oktaAuth = new OktaAuth({
    issuer: process.env.REACT_APP_OKTA_ISSUER,
    redirectUri: `${window.location.origin}/login/callback`,
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    pkce: true,
    services: {
      autoRenew: true,
      autoRemove: true,
      syncStorage: true,
    },
  });

  return (
    <BrowserRouter>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <Routes>
          <Route
            path="/login/callback"
            element={<LoginCallback loadingElement={<Spin />} />}
          />
          <Route path="/*" element={<RequiredAuth />}>
            <Route path="*" element={<App />} />
          </Route>
        </Routes>
      </Security>
    </BrowserRouter>
  );
};

export default OktaLogin;
