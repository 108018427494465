import React, { memo, useMemo, useState } from "react";
import { DatePicker, Menu, Popover, Select } from "antd";
import { Box, Grid, MenuItem, Switch } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import dayjs from "dayjs";
import { SEASONS_RANGE, SEASON_MENU } from "../../services/mockData";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useDispatch, useSelector } from "react-redux";
import {
  selectMovement,
  selectSecondarySelfCompOptions,
  selectSelfCompOptions,
} from "../../redux/selectors";
import { setSelfCompOptions } from "../../redux/movementSlice";
import { useFetchSelfCompPitchTypesQuery } from "../../services/performanceApi.service";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { SelfCompSessionPicker } from "./SelfCompSessionPicker";
import { isNil } from "lodash";
const ENABLED_SELF_COMP_FILTERS = ["Session"];

interface SelfCompMenuProps {
  selfValue: SelfCompSelectedValue;
  setSelfValue: (it: SelfCompSelectedValue) => void;
  isSecondarySelf?: boolean;
}
export interface SelfCompSelectedValue {
  type: "Session" | "Session Range" | "Season";
  payload: {
    date?: string | null;
    endDate?: string | null;
    season?: string;
    sup75Perc?: boolean;
    pitchType?: string;
  };
}
interface SelfMenuProps {
  data: any;
  value?: any;
  onChange?: any;
  isSecondarySelf?: boolean;
}

function SelfMenu({ data, value, onChange, isSecondarySelf }: SelfMenuProps) {
  const dispatch = useDispatch();
  const selfValue = !isNil(value)
    ? value
    : useSelector(
        isSecondarySelf ? selectSecondarySelfCompOptions : selectSelfCompOptions
      );
  const setSelfValue = (it: SelfCompSelectedValue) => {
    if (!isNil(onChange)) {
      onChange(it);
    } else {
      dispatch(setSelfCompOptions(it));
    }
  };

  const SeasonMenu = (
    <Menu
      onClick={(e: any) => {
        setSelfValue({
          ...selfValue,
          type: "Season",
          //TODO: Check season when input becomes active
          payload: { ...selfValue.payload, season: e },
        });
      }}
      mode="vertical"
      items={SEASON_MENU}
    />
  );

  const childrenComponent: any = {
    Session: (
      <SessionComponent
        selfValue={selfValue}
        setSelfValue={setSelfValue}
        isSecondarySelf={isSecondarySelf}
      />
    ),
    Season: SeasonMenu,
    "Session Range": (
      <SessionRangeComponent
        selfValue={selfValue}
        setSelfValue={setSelfValue}
      />
    ),
  };

  return (
    <Box sx={{ ml: 1 }}>
      <Popover
        trigger={"click"}
        placement="bottomLeft"
        overlayClassName="ptd-inner-menu"
        content={data?.map((item: any) => (
          <Popover
            trigger={"hover"}
            placement="right"
            key={`${item.label}`}
            content={childrenComponent[item.label]}
          >
            <MenuItem
              key={`${item.label}`}
              value={item.id}
              disabled={!ENABLED_SELF_COMP_FILTERS.includes(item.label)}
            >
              <Grid container justifyContent={"space-between"}>
                <Grid item xs>
                  {item.label}
                </Grid>
                <Grid item xs="auto">
                  <KeyboardArrowRightIcon />
                </Grid>
              </Grid>
            </MenuItem>
          </Popover>
        ))}
      >
        <Box sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
          {selfValue.payload.date
            ? dayjs(selfValue.payload.date).format("YYYY-MM-DD")
            : null}{" "}
          {selfValue.payload.pitchType
            ? `(${selfValue.payload.pitchType})`
            : null}
          <ArrowDropDownIcon style={{ color: "grey" }} />
        </Box>
      </Popover>
    </Box>
  );
}

const SessionComponent = ({
  selfValue,
  setSelfValue,
  isSecondarySelf,
}: SelfCompMenuProps) => {
  const { player } = useSelector(selectMovement);
  const { data, isFetching, isLoading } = useFetchSelfCompPitchTypesQuery(
    player?.id || skipToken
  );
  const pitchTypes = useMemo(
    () =>
      (data || []).map(({ pitch_type }, i) => ({
        label: pitch_type,
        key: pitch_type,
        value: pitch_type,
        id: i,
      })),
    [data]
  );
  const pitchType = selfValue.payload.pitchType;
  const isStuff = useMemo(
    () => !["4FB", "2FB", "CT"].includes(pitchType || ""),
    [pitchType]
  );
  return (
    <Grid container>
      <Grid
        container
        item
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid item xs>
          <Select
            loading={isFetching || isLoading}
            value={pitchType}
            onChange={(value: string) => {
              setSelfValue({
                ...selfValue,
                type: "Session",
                payload: { ...selfValue.payload, pitchType: value },
              });
            }}
            style={{ width: "100%" }}
            options={pitchTypes}
          />
        </Grid>
        <Grid item xs="auto">
          <span>{`>75%ile ${isStuff ? "Stuff" : "MPH"}`}</span>
          <Switch
            size="small"
            className="switch"
            checked={selfValue.payload.sup75Perc}
            onChange={(e) => {
              setSelfValue({
                ...selfValue,
                type: "Session",
                payload: { ...selfValue.payload, sup75Perc: e.target.checked },
              });
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} mt={2}>
        <SelfCompSessionPicker
          isSecondary={isSecondarySelf}
          playerId={player?.id}
          onChange={({ date }) => {
            setSelfValue({
              ...selfValue,
              type: "Session",
              payload: { ...selfValue.payload, date },
            });
          }}
          noSelect
        />
      </Grid>
    </Grid>
  );
};

const SessionRangeComponent = ({
  selfValue,
  setSelfValue,
}: SelfCompMenuProps) => {
  const [seasonsFrom, setSeasonsFrom] = useState(SEASONS_RANGE[0].value);
  return (
    <Grid container justifyContent={"center"}>
      <Grid item xs="auto">
        <Select
          options={SEASONS_RANGE}
          value={seasonsFrom}
          optionRender={(option: any) => <p>{option?.key}</p>}
          onChange={(value: any) => setSeasonsFrom(value.value)}
        />
      </Grid>
      <Grid item xs={12} mt={2}>
        <DatePicker
          onChange={(_, date: any) => {
            setSelfValue({
              ...selfValue,
              type: "Session Range",
              payload: { ...selfValue.payload, date },
            });
          }}
          style={{ width: "100%" }}
        />
      </Grid>
    </Grid>
  );
};

export default memo(SelfMenu);
