import { client } from "../api";

export const addCredentialsToClientConfig = (customConfig, apiToken) => {
  const headers = {
    ...customConfig.headers,
    "x-access-token": `Bearer: ${apiToken}`,
  };

  return {
    ...customConfig,
    headers,
  };
};

export function skylineApiClient(apiToken) {
  const credentialedClient = {};
  credentialedClient.get = (path, customConfig = {}) => {
    return client.get(
      path,
      addCredentialsToClientConfig(customConfig, apiToken)
    );
  };

  credentialedClient.post = (path, body, customConfig = {}) => {
    return client.post(
      path,
      body,
      addCredentialsToClientConfig(customConfig, apiToken)
    );
  };

  credentialedClient.delete = (path, body, customConfig = {}) => {
    return client.delete(
      path,
      body,
      addCredentialsToClientConfig(customConfig, apiToken)
    );
  };

  credentialedClient.put = (path, body, customConfig = {}) => {
    return client.put(
      path,
      body,
      addCredentialsToClientConfig(customConfig, apiToken)
    );
  };
  return credentialedClient;
}
