import React, { useMemo } from "react";
import {
  BodyPartMetric,
  BodyPartMetricEvents,
} from "../../../../services/mockData";
import { Table } from "antd";
import { find, mapValues, toLower } from "lodash";
import { MetricType } from "../../../../hooks/useBodyPartData";
import {
  selectIsDataCompare,
  selectSelectedMotionType,
} from "../../../../redux/selectors";
import { useSelector } from "react-redux";
import { getTableCellColorByValue } from "../../../../utils/colors";
import { MetricTooltip } from "../../../common/MetricTooltip";
const { Column, ColumnGroup } = Table;
import "./BodyPartTable.css";
import { findDataCompValuesRangeForMetric } from "./findDataCompAverageRangeForMetric";
import { getBodyPartColumns } from "../../../../utils/metrics";

interface TableWithCompareProps {
  data: BodyPartMetric[];
  comparingData: BodyPartMetric[];
  handleMetric: (metric: MetricType) => void;
  isSelfCompare: boolean;
  selectedRowKey: number;
  setSelectedRowKey: (key: number) => void;
}

interface DataSourceType {
  events: {
    mkh: number[];
    hs: number[];
    fc: number[];
    mer: number[];
    br: number[];
    max: number[];
  };
  id: number;
  name: string;
  bodyPart: string;
  unit: string;
  data: number[];
}

const TableWithCompare = ({
  data,
  comparingData,
  handleMetric,
  selectedRowKey,
  setSelectedRowKey,
  isSelfCompare,
}: TableWithCompareProps) => {
  const isDataCompare = useSelector(selectIsDataCompare);
  const motionType = useSelector(selectSelectedMotionType);
  const columns = getBodyPartColumns(motionType);

  const addCompareValue = (events: BodyPartMetricEvents, name: string) => {
    const compare: any = find(comparingData, function (event) {
      return event.name === name;
    });
    return mapValues(events, function (val, key: any) {
      return [val, compare?.events[key]];
    });
  };

  const dataSource = useMemo(() => {
    return data.map((movement) => ({
      ...movement,
      events: addCompareValue(movement.events, movement.name),
    }));
  }, [data, comparingData]);

  const onHandleMetric = (metric: DataSourceType) => {
    handleMetric({
      id: metric.id,
      label: metric.name,
      data: metric.data,
      unit: metric.unit,
    });
  };

  return (
    <>
      {comparingData && (
        <Table
          dataSource={dataSource}
          rowSelection={{
            type: "radio",
            columnTitle: "Active",
            defaultSelectedRowKeys: [selectedRowKey],
            onChange: (_, [selected]) => {
              onHandleMetric(selected);
              setSelectedRowKey(selected.id);
            },
            renderCell(_value, _record, _index, node) {
              return <div style={{ height: "12px" }}>{node}</div>;
            },
          }}
          bordered
          className="ptd-body-part-table ptd-table-blank-background"
          pagination={false}
          size="small"
        >
          <Column
            title="Variable"
            dataIndex={"action"}
            render={(_, metric: BodyPartMetric) => (
              <span className="ptd-cell">
                {metric.name} ({metric.unit})
              </span>
            )}
          />

          {columns.map((column, index) => (
            <ColumnGroup title={column} key={index}>
              <Column
                title="T1"
                className={`ptd-column ${
                  isDataCompare ? "ptd-column-compare" : "ptd-column-t1"
                }`}
                dataIndex={["events", toLower(column), "0"]}
                render={(value, metric: BodyPartMetric) => {
                  const { from, to, max, min } =
                    findDataCompValuesRangeForMetric(column, metric);
                  const averageRange = { from, to };
                  return {
                    props: {
                      style: isDataCompare
                        ? {
                            background: getTableCellColorByValue(
                              value,
                              averageRange
                            ),
                          }
                        : {},
                    },
                    children: (
                      <MetricTooltip
                        max={max}
                        min={min}
                        averageRange={averageRange}
                        value={value}
                        title={metric.name}
                        unit={metric.unit}
                        isUnitInTitle
                      >
                        <span className="ptd-cell">{value}</span>
                      </MetricTooltip>
                    ),
                  };
                }}
              />

              <Column
                className={`ptd-column ${
                  isDataCompare
                    ? "ptd-column-compare"
                    : `ptd-column-t2${isSelfCompare ? "-self" : ""}`
                }`}
                title={isSelfCompare ? "Self" : "T2"}
                dataIndex={["events", toLower(column), "1"]}
                render={(value, metric: BodyPartMetric) => {
                  const { from, to, max, min } =
                    findDataCompValuesRangeForMetric(column, metric);

                  const averageRange = { from, to };

                  return {
                    props: {
                      style: isDataCompare
                        ? {
                            background: getTableCellColorByValue(
                              value,
                              averageRange
                            ),
                          }
                        : {},
                    },
                    children: (
                      <MetricTooltip
                        max={max}
                        min={min}
                        averageRange={averageRange}
                        value={value}
                        title={metric.name}
                        unit={metric.unit}
                        isUnitInTitle
                      >
                        <span className="ptd-cell">{value}</span>
                      </MetricTooltip>
                    ),
                  };
                }}
              />
            </ColumnGroup>
          ))}
        </Table>
      )}
    </>
  );
};

export default TableWithCompare;
