import React from "react";
import { getColorBySource } from "../../utils/colors";
import { SourcePitch } from "../../services/mockData";

export function SourceDot({
  source,
  visible = true,
}: {
  source: SourcePitch;
  visible?: boolean;
}) {
  return (
    <span
      style={{
        width: "4px",
        height: "4px",
        flexGrow: 0,
        borderRadius: "100%",
        backgroundColor: getColorBySource(source),
        display: visible ? "block" : "none",
      }}
    />
  );
}
