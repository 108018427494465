// A wrapper around fetch(), based on this work:
// https: *kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper

export const getApiLocation = () => {
  if (process.env.NODE_ENV === "test") {
    return "http://localhost"; // Because it's a test env, this could be anything that started with 'http://'
  }

  // Alternatives for non-production
  if (process.env.NODE_ENV !== "production") {
    /**
     * REACT_APP_ALLOW_LOCAL_ACCESS: 192.168.*, i.e. your current local network
     *     Useful for access by phone / another device on same network.
     *     Could allow too much access within a local network, so only
     *     for specific tasks, not by default.
     *
     * REACT_APP_ALLOW_VPN_ACCESS: 10.128.*, i.e. likely local networks
     *     Useful for sharing access within VPN to co-workers, etc.
     *
     * Caveat: double-check your IPs before use to make sure these assumptions are
     * correct for your setup.
     */
    if (
      (process.env.REACT_APP_ALLOW_LOCAL_ACCESS &&
        window.location.host.startsWith("192.168")) ||
      (process.env.REACT_APP_ALLOW_VPN_ACCESS &&
        window.location.host.startsWith("10.128"))
    ) {
      // Use same http/https as received, pointing to API port.
      return `${window.location.protocol}//${window.location.hostname}:8080`;
    }
  }

  if (window.location.host.startsWith("localhost")) {
    return process.env.REACT_APP_API_URL;
  } else if (
    window.location.host.endsWith("web.app") ||
    window.location.host.endsWith("firebaseapp.com")
  ) {
    // firebase app deploys should always hit dev
    return `https://dev.skyline.nymets.com`;
  }

  return `https://${window.location.host}`;
};

const endpoint = getApiLocation();

export async function client(path, { body, ...customConfig } = {}) {
  const fullEndpoint = `${endpoint}${path}`;
  const headers = { "Content-Type": "application/json" };

  const config = {
    method: body ? "POST" : "GET",
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  // If the client has specified multipart/form-data, we don't actually want to set the content-type.
  // Instead, we should let the browser set the content-type so that multipart/form-data boundary
  // is correct. (If you set the content type, you get a boundary error from the api)
  if (config.headers["Content-Type"] === "multipart/form-data") {
    delete config.headers["Content-Type"];
  }

  if (body && config.headers["Content-Type"] === "application/json") {
    config.body = JSON.stringify(body);
  } else {
    // If the content type is not application/json, just set the body without json-ifying it:
    config.body = body;
  }

  let data;
  try {
    const response = await fetch(`${fullEndpoint}`, config);
    if (response.status !== 204) {
      // 204 doesn't send any message
      data = await response.json();
    }
    if (response.ok) {
      return {
        status: response.status,
        data,
        headers: response.headers,
        url: response.url,
      };
    }

    // If the response provides an "error" field, we should use that specific info, else the generic response statusText
    throw new Error(data && data.error ? data.error : response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data);
  }
}

/**
 * @param {Objet} parameterObject - A JS object to turn into Query String Params
 * @returns a string of key-value pairs with arrays exploded
 */
export function buildParamString(parameterObject) {
  let paramString = "";
  const paramKeys = Object.keys(parameterObject);
  for (let i = 0; i < paramKeys.length; i += 1) {
    const paramName = paramKeys[i];
    const param = parameterObject[paramName];
    if (Array.isArray(param)) {
      paramString +=
        paramName + "=" + param.map(encodeURIComponent).join(`&${paramName}=`);
    } else if (typeof param === "object") {
      paramString +=
        paramName + "=" + encodeURIComponent(JSON.stringify(param));
    } else {
      paramString += paramName + "=" + encodeURIComponent(param);
    }
    // if the param is an array, add an entry for each value
    // add a & to prep for the next key value pair
    paramString += "&";
  }
  // remove the final & since there are no more params
  // and return the string
  return paramString.slice(0, -1);
}

client.get = function (path, customConfig = {}) {
  return client(path, { ...customConfig, method: "GET" });
};

client.post = function (path, body, customConfig = {}) {
  return client(path, { ...customConfig, body });
};

client.delete = function (path, body, customConfig = {}) {
  return client(path, { ...customConfig, method: "DELETE", body });
};

client.put = function (path, body, customConfig = {}) {
  return client(path, { ...customConfig, method: "PUT", body });
};
