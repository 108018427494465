import { round } from "lodash";
import { BodyPartMetric } from "../../../../services/mockData";

export function findDataCompValuesRangeForMetric(
  event: string,
  metric: BodyPartMetric
): FindDataCompValuesRangeForMetricReturn {
  const fixedEvent = event === "FC" ? "FFC" : event;
  const dataComp = metric.dataComp?.find(
    (it) => it.event.toLowerCase() === fixedEvent.toLowerCase()
  );
  const averageRange = dataComp?.averageRange || {
    from: 20,
    to: 40,
  };
  return {
    from: round(averageRange.from, 1),
    to: round(averageRange.to, 1),
    max: round(dataComp?.max, 1),
    min: round(dataComp?.min, 1),
  };
}

interface FindDataCompValuesRangeForMetricReturn {
  from: number;
  to: number;
  max: number;
  min: number;
}
