import {
  MutableRefObject,
  RefObject,
  createRef,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import {
  setCurrentFrameValue,
  setFirstFrameValue,
  setLastFrameValue,
  setVideoDurationValue,
  setMaxValueScrubb,
} from "../redux/movementSlice";
import { use3DAnimationVideoScrubber } from "./use3DAnimationVideoScrubber";
import { isEmpty, isNil } from "lodash";
import { isBatter } from "../three-js/Player3D";
import { useSyncReferenceKeyFrame } from "../useSyncReferenceKeyFrame";
import { VideoAnimationViewerProps } from "../VideoAnimationViewer";

const MIN_TABS = 8;
const IS_WEDNESDAY = new Date().getDay() === 3;

export const useVideoAnimationViewer = (
  {
    jointCoordinates,
    mainPlayer,
    comparingJointCoordinates,
    comparingPlayer,
    extraMovementsDetails = [],
  }: VideoAnimationViewerProps,
  customWidth?: number,
  customHeight?: number,
  onHandleScrubber?: (newCurrentFrame: number, options?: any) => void,
  onHandlePlay?: () => void,
  hideControlsGui?: boolean
) => {
  const [loopVideo, setLoopVideo] = useState(true);
  const [shouldShowStadium, setShouldShowStadium] = useState(IS_WEDNESDAY);
  const [segmentFrames, setSegmentFrames] = useState<{
    startFrame?: number;
    endFrame?: number;
  }>({});
  const dispatch = useDispatch();
  const wrapper = useRef<HTMLDivElement>(null);
  const videoRefs: MutableRefObject<RefObject<HTMLVideoElement>[]> = useRef(
    Array(MIN_TABS * 6)
      .fill(0)
      .map(() => createRef<HTMLVideoElement>())
  );
  const [videoDuration, setVideoDuration] = useState(0);
  const maxValueScrubb = useMemo(
    () => jointCoordinates.length - 2,
    [jointCoordinates]
  );
  const syncReferenceKeyFrameProps = useSyncReferenceKeyFrame();

  useEffect(() => {
    if (segmentFrames?.endFrame && segmentFrames?.endFrame !== 1) {
      dispatch(setFirstFrameValue(segmentFrames.startFrame));
      dispatch(setLastFrameValue(segmentFrames.endFrame));
    } else {
      dispatch(setFirstFrameValue(1));
      dispatch(setLastFrameValue(maxValueScrubb));
    }

    dispatch(setMaxValueScrubb(maxValueScrubb));
    dispatch(setVideoDurationValue(videoDuration));
  }, [
    maxValueScrubb,
    videoDuration,
    videoRefs.current[0]?.current?.duration,
    segmentFrames,
  ]);

  const { world, ...videoScrubberProps } = use3DAnimationVideoScrubber({
    wrapper,
    videoRefs,
    maxValueScrubb,
    onHandleScrubber,
    onHandlePlay,
    hideControlsGui,
  });

  const syncWorldAndVideo = () => {
    videoScrubberProps.syncWorldAndVideo();
    setTimeout(() => videoScrubberProps.syncWorldAndVideo(), 1000);
  };

  useEffect(() => {
    world.render(battingMode);
    if (customWidth && customHeight) {
      world.setDimensions(customWidth, customHeight);
    }
    return () => {
      world.destroy();
    };
  }, [world]);

  useEffect(() => {
    if (world.shouldShowMainPerson()) {
      world.setUpPerson(
        jointCoordinates,
        mainPlayer?.handedness,
        "pitch",
        !isEmpty(comparingJointCoordinates)
      );
      syncWorldAndVideo();
    }
  }, [
    world,
    jointCoordinates,
    comparingJointCoordinates,
    world.onlyShowPlayer,
  ]);

  useEffect(() => {
    if (
      !isEmpty(comparingJointCoordinates) &&
      world.shouldShowComparingPerson()
    ) {
      world.setUpComparingPerson(
        comparingJointCoordinates,
        comparingPlayer?.handedness,
        "pitch"
      );
      syncWorldAndVideo();
    } else {
      world.removeComparingPerson();
    }
  }, [world, comparingJointCoordinates, world.onlyShowPlayer]);

  useEffect(() => {
    [0, 1, 2, 3].forEach((i) => world.removeExtraPerson(i));
    if (!isEmpty(extraMovementsDetails)) {
      extraMovementsDetails.forEach(({ movement, coordinates }, i) => {
        world.setUpExtraPerson(coordinates, i, movement.player?.handedness);
      });
      syncWorldAndVideo();
    }
  }, [world, extraMovementsDetails]);

  useEffect(() => {
    world.characterSpeed = videoScrubberProps.currentSpeed;
  }, [world, videoScrubberProps.currentSpeed]);
  useEffect(() => {
    world.loopVideo = loopVideo;
  }, [world, loopVideo]);
  useEffect(() => {
    world.mainKeyFrames = videoScrubberProps.keyframesData;
  }, [world, videoScrubberProps.keyframesData]);
  useEffect(() => {
    world.setSyncReferenceFrameProps(syncReferenceKeyFrameProps);
  }, [world, syncReferenceKeyFrameProps.syncReferenceKeyFrame]);

  useEffect(() => {
    if (!isNil(segmentFrames.startFrame)) {
      world.startFrame = segmentFrames.startFrame;
      if (world.currentFrame < world.startFrame) {
        world.currentFrame = world.startFrame;
        syncWorldAndVideo();
      }
    }
    if (!isNil(segmentFrames.endFrame)) {
      world.endFrame = segmentFrames.endFrame;
      if (world.currentFrame > world.endFrame) {
        world.currentFrame = world.endFrame;
        syncWorldAndVideo();
      }
    }
  }, [world, segmentFrames]);

  useEffect(() => {
    const current = videoRefs.current[0].current;

    if (current !== null) {
      const handleLoadMetadata = () => {
        if (current?.duration !== videoDuration) {
          setVideoDuration(current?.duration);
        }
      };

      current.addEventListener("loadedmetadata", handleLoadMetadata, false);
    }
  }, [videoRefs.current[0].current?.duration]);

  useEffect(() => {
    const { currentFrame } = videoScrubberProps;

    if (currentFrame) {
      dispatch(setCurrentFrameValue(currentFrame));
    }
  }, [videoScrubberProps.currentFrame]);

  const battingMode = useMemo(
    () => isBatter(jointCoordinates),
    [jointCoordinates]
  );
  useEffect(() => {
    world.setShouldShowStadium(shouldShowStadium, battingMode);
  }, [shouldShowStadium, battingMode]);

  return {
    wrapper,
    videoRefs,
    videoDuration,
    maxValueScrubb,
    setLoopVideo,
    loopVideo,
    segmentFrames,
    setSegmentFrames,
    shouldShowStadium,
    setShouldShowStadium,
    syncWorldAndVideo,
    videoScrubberProps,
    battingMode,
    world,
  };
};
