import React from "react";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import "../TeamDashboard.css";
import {
  usePerformanceAppDispatch,
  usePerformanceAppSelector,
} from "../../../redux/hooks";
import { setTeamMetricsMode } from "../../../redux/movementSlice";

export enum TeamMetricsMode {
  PlayerData = "Player Data",
  Compliance = "Compliance",
}

export const getIsPlayerDataMode = (teamMetricsMode: TeamMetricsMode) =>
  teamMetricsMode === TeamMetricsMode.PlayerData;

function TeamMetricsModeSelector() {
  const dispatch = usePerformanceAppDispatch();
  const mode = usePerformanceAppSelector(
    (state) => state.movement.teamMetricsMode
  );

  const handleChange = (
    _event: React.MouseEvent<HTMLElement>,
    newMode: TeamMetricsMode
  ) => {
    if (newMode) {
      dispatch(setTeamMetricsMode(newMode));
    }
  };

  return (
    <ToggleButtonGroup
      size="small"
      value={mode}
      defaultValue={TeamMetricsMode.PlayerData}
      onChange={handleChange}
      exclusive
      className="ptd-team-positions-selector-container"
    >
      {Object.values(TeamMetricsMode).map((it) => (
        <ToggleButton
          key={it}
          value={it}
          className="ptd-team-positions-selector"
        >
          {it}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}

export default TeamMetricsModeSelector;
