import { isNil } from "lodash";
import { OnDragEndResponder } from "react-beautiful-dnd";

export function reorderListDropHandler<T>(
  value: T[],
  setValue: (value: T[]) => void
): OnDragEndResponder {
  return (droppedItem) => {
    if (isNil(droppedItem.destination)) {
      return;
    }
    const updatedList = [...value];
    const [reorderedMetric] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedMetric);
    setValue(updatedList);
  };
}
