import React, { Fragment, useCallback, useMemo, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { Video } from "./Video";
import OverlaySettings from "./OverlaySettings";
import { Else, If, Then } from "react-if";

interface OverlayVideoProps {
  videoRef: any;
  videoUrl: any;
  comparingVideoRef: any;
  comparingVideoUrl: any;
  comparingMovement: any;
  mirrorMainTrialVideo?: boolean;
  movement: any;
  extraVideos?: any[];
}

export interface OverlaySettingsType {
  opacity: number;
  translateX: number;
  translateY: number;
  scale: number;
}

const defaultOverlaySettings = {
  opacity: 50,
  translateX: 0,
  translateY: 0,
  scale: 100,
};

function OverlayVideo({
  comparingVideoRef,
  comparingVideoUrl,
  comparingMovement,
  mirrorMainTrialVideo,
  videoRef,
  videoUrl,
  movement,
  extraVideos,
}: OverlayVideoProps) {
  const [openSettings, setOpenSettings] = useState(false);
  const [overlaySettings, setOverlaySettings] = useState<OverlaySettingsType>(
    defaultOverlaySettings
  );
  const buildOpacityName = useCallback((name: any, trial: any) => {
    const fullName = name.split(" ");
    return `${fullName[1]} P${trial.index}(  ${
      trial.inning || trial.strikes || trial.outs || "Side"
    })`;
  }, []);
  const leftOpacityName = useMemo(
    () => buildOpacityName(movement.player?.name, movement.trial),
    [movement]
  );

  const rightOpacityName = useMemo(
    () =>
      buildOpacityName(
        comparingMovement?.player?.name,
        comparingMovement?.trial
      ),
    [comparingMovement]
  );
  return (
    <Fragment>
      <Grid container item justifyContent={"space-between"} xs={12} py={1}>
        <Grid item>
          <FullNameOverlay
            fullName={movement.player?.name}
            primary
            trial={movement?.trial}
          />
        </Grid>
        <Grid item>
          <FullNameOverlay
            fullName={comparingMovement.player?.name}
            trial={comparingMovement?.trial}
          />
        </Grid>
        {extraVideos?.map((it, i) => {
          const playerFullName = `${it.playerDetails?.firstName} ${it.playerDetails?.lastName}`;
          return (
            <Grid item key={i}>
              <FullNameOverlay
                fullName={playerFullName}
                trial={it.movement?.trial || {}}
                color={it.color}
              />
            </Grid>
          );
        })}
      </Grid>
      <Grid item xs={12} position={"relative"} overflow={"hidden"}>
        <Video
          width={"100%"}
          height={"100%"}
          videoRef={videoRef}
          videoUrl={videoUrl}
          mirror={mirrorMainTrialVideo}
        />
        <Video
          width="100%"
          height="100%"
          videoRef={comparingVideoRef}
          videoUrl={comparingVideoUrl}
          comparative
          className="ptd-overlay-video"
          overlaySettings={overlaySettings}
        />
        {extraVideos?.map((it, i) => (
          <Video
            key={i}
            width="100%"
            height="100%"
            videoRef={it.videoRef}
            videoUrl={it.videoUrl}
            borderColor={it.color}
            comparative
            className="ptd-overlay-video"
            overlaySettings={overlaySettings}
          />
        ))}
      </Grid>
      <Grid container item justifyContent="center" xs={12}>
        <If condition={!openSettings}>
          <Then>
            <Grid item xs="auto">
              <OverlayButton
                label="Open Overlay Settings"
                onClick={() => setOpenSettings(true)}
              />
            </Grid>
          </Then>
          <Else>
            <Grid item xs="auto">
              <OverlayButton
                label="Close Overlay Settings"
                onClick={() => setOpenSettings(false)}
              />
            </Grid>
            <Grid item xs={12}>
              <OverlaySettings
                setOverlaySettings={setOverlaySettings}
                overlaySettings={overlaySettings}
                leftName={leftOpacityName}
                rightName={rightOpacityName}
              />
            </Grid>
          </Else>
        </If>
      </Grid>
    </Fragment>
  );
}

const FullNameOverlay = ({
  fullName,
  trial,
  primary = false,
  color,
}: {
  fullName: string;
  trial: any;
  primary?: boolean;
  color?: string;
}) => {
  const nameSplited = fullName.split(" ");
  return (
    <Typography
      sx={{
        color:
          color ||
          (primary ? "var(--hoveredDigitalBlue)" : "var(--digitalOrange)"),
      }}
      component={"span"}
      fontSize={13}
    >{`${nameSplited[1]}, ${nameSplited[0]} - P${trial?.index}(${
      trial?.inning ||
      trial?.strikes ||
      trial?.outs ||
      trial?.strikesOrOuts ||
      "Side"
    })`}</Typography>
  );
};

const OverlayButton = ({ onClick, label }: { onClick: any; label: string }) => (
  <Button size="small" onClick={onClick} sx={{ textTransform: "capitalize" }}>
    {label}
  </Button>
);

export default OverlayVideo;
