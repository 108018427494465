import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  selectComparingMovement,
  selectIsSelfCompare,
} from "../redux/selectors";
import { isEmpty, take } from "lodash";

interface useThrowEventDataManipulationProp {
  mainDataList: any[];
  secondaryData: any[];
  selfData: any[];
}

function useThrowEventDataManipulation({
  mainDataList,
  secondaryData,
  selfData,
}: useThrowEventDataManipulationProp) {
  const isSelfCompare = useSelector(selectIsSelfCompare);
  const secondaryTrial = useSelector(selectComparingMovement);
  const [favouriteData, setFavouriteData] = useState<any[]>(
    take(mainDataList, 5)
  );
  const [alternativeFavouriteData, setAlternativeFavouriteData] = useState<
    any[]
  >([]);
  const [alternativeData, setAlternativeData] = useState<any[]>([]);

  const secondaryDataChanged = useMemo(
    () => JSON.stringify(secondaryData) !== JSON.stringify(alternativeData),
    [secondaryData, alternativeData]
  );

  useEffect(() => {
    if (!isEmpty(secondaryData)) {
      setAlternativeFavouriteData(take(secondaryData, 5));
    }
  }, [secondaryData]);

  useEffect(() => {
    if (!isEmpty(mainDataList)) {
      setFavouriteData(take(mainDataList, 5));
    }
  }, [mainDataList]);

  useEffect(() => {
    if (!secondaryTrial?.id && !isSelfCompare) {
      if (!isEmpty(alternativeData)) {
        setAlternativeData([]);
      }
    } else if (isSelfCompare) {
      setAlternativeData(selfData);
    } else if (secondaryDataChanged) {
      setAlternativeData(secondaryData);
    }
  }, [
    secondaryData,
    secondaryDataChanged,
    isSelfCompare,
    selfData,
    secondaryTrial,
  ]);

  return {
    favouriteData,
    alternativeFavouriteData,
    alternativeData,
    setFavouriteData,
    setAlternativeFavouriteData,
  };
}

export { useThrowEventDataManipulation };
