import { some, startsWith } from "lodash";

export const PLAYER_DASH_VARIABLE_NAMES = [
  "rom_shoulder_trot_d",
  "rom_shoulder_er_d",
  "rom_shoulder_ir_d",
  "rom_shoulder_flex_p",
  "rom_shoulder_flex_u",
  "rom_shoulder_add_d",
  "rom_elbow_ext_d",
  "rom_elbow_flex_d",
  "rom_hip_trot_d",
  "rom_hip_er_d",
  "rom_hip_ir_d",
  "rom_hip_flex_slr",
  "rom_hamstring_90",
  "rom_ankle_flex",
  "rom_trunk_cerv",
  "rom_trunk_thor_pass",
  "rom_trunk_lumb",
  "rom_trunk_thor_d",
  "msk_hip_er_nd",
  "msk_hip_ir_nd",
  "msk_shoulder_er_d",
  "msk_shoulder_ir_d",
  "msk_shoulder_ir_sup_d",
  "msk_hip_abd_nd",
  "msk_hip_add_nd",
  "msk_knee_flex_d",
  "msk_core_rot_d",
  "msk_grip_90_d",
  "msk_grip_0_d",
  "msk_grip_fds_ext_d",
  "msk_grip_fds_ext",
  "pwr_cmj_conc_impulse",
  "pwr_cmj_rsi_modified",
  "pwr_cmj_height",
  "pwr_cmj_conc_mean_power_bm",
  "pwr_cmj_contract_time",
  "pwr_cmj_ecc_braking_impulse",
  "pwr_sj_conc_impulse",
  "pwr_sj_jump_height",
  "pwr_sj_conc_mean_power_bm",
  "pwr_sj_contract_time",
  "pwr_core_test",
  "pwr_iso_mtp",
  "pwr_iso_squat_peak_force",
  "pwr_row_test_combined",
  "pwr_iso_hamstring",
  "pwr_iso_hamstring_asym",
  "bcmp_height",
  "bcmp_weight",
  "bcmp_age",
  "bcmp_wingspan",
  "bcmp_hand_size",
  "bcmp_bodyfat",
  "bcmp_body_fat_percent",
  "bcmp_skinfolds",
  "bcmp_goal_weight_lower",
  "bcmp_goal_weight_upper",
  "bcmp_hydration_color",
  /*"spd_metscast",
  "spd_30y_sprint_time",
  "spd_power6_time",
  "spd_505_agility_time",
  "spd_30y_sprint_vel",*/
  "visl_reaction_time",
  "visl_concentration_time",
  "visl_core_score_b",
  "visn_perf_working_memory",
  "visn_perf_timing",
  "visn_perf_attention",
  "visn_perf_inhibition",
  "visn_perf_visual_stability",
  "gps_acceleration_count",
  "gps_deceleration_count",
  "gps_distance_70",
  "gps_distance_80",
  "gps_distance_90",
  "gps_distance_95",
  "gps_efforts_70",
  "gps_efforts_80",
  "gps_efforts_90",
  "gps_efforts_95",
  "gps_max_acceleration",
  "gps_max_deceleration",
  "gps_max_velocity",
  "gps_total_distance_m",
  "obu_ankle_rocking_eversion_nohold_left",
  "obu_ankle_rocking_inversion_nohold_left",
  "obu_ankle_rocking_eversion_hold_left",
  "obu_ankle_rocking_inversion_hold_left",
  "obu_sl_hinge_stability_left",
  "obu_sl_hinge_foot_position_left",
  "obu_ankle_rocking_eversion_nohold_right",
  "obu_ankle_rocking_inversion_nohold_right",
  "obu_ankle_rocking_eversion_hold_right",
  "obu_ankle_rocking_inversion_hold_right",
  "obu_sl_hinge_stability_right",
  "obu_sl_hinge_foot_position_right",
  "obu_deep_squat_depth",
  "obu_deep_squat_stability",
  "obu_hip_ir_nohold_left",
  "obu_hip_ir_hold_left",
  "obu_hip_ir_rotex_left",
  "obu_hip_er_left",
  "obu_hip_ir_nohold_right",
  "obu_hip_ir_hold_right",
  "obu_hip_ir_rotex_right",
  "obu_hip_er_right",
  "obu_pelvic_tilt_rom",
  "obu_pelvic_tilt_posture",
  "obu_pelvic_tilt_stability",
  "obu_pelvic_rotation_nohold_left",
  "obu_pelvic_rotation_hold_left",
  "obu_pelvic_rotation_coordination_left",
  "obu_trunk_rotation_nohold_left",
  "obu_trunk_rotation_hold_left",
  "obu_pelvic_rotation_nohold_right",
  "obu_pelvic_rotation_hold_right",
  "obu_pelvic_rotation_coordination_right",
  "obu_trunk_rotation_nohold_right",
  "obu_trunk_rotation_hold_right",
  "obu_lat_mobility",
  "obu_shoulder_er_left",
  "obu_shoulder_elbowup_left",
  "obu_shoulder_er_right",
  "obu_shoulder_elbowup_right",
  "obu_shoulder_add_posture",
  "obu_shoulder_handslide_left",
  "obu_wrist_rotation_pronation_left",
  "obu_wrist_rotation_supination_left",
  "obu_shoulder_handslide_right",
  "obu_wrist_rotation_pronation_right",
  "obu_wrist_rotation_supination_right",
].flatMap((it) =>
  some(
    ["rom_", "msk_"],
    (prefix) =>
      startsWith(it, prefix) ||
      ["pwr_iso_hamstring", "pwr_core_test"].includes(it) //Some power variables are sided
  )
    ? ["left", "right"].map((side) => `${it}_${side}`)
    : [it]
);
