import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { flatMap, flow, isNaN, isNil } from "lodash";
import { getBounds, getPlayerMetricColor, hasSide } from "../../utils/metrics";
import { useNavigate } from "react-router-dom";
import { updateOrReplaceSeries } from "../../utils/charts";
import {
  relativize,
  capBetween,
  centerAboutZero,
  increaseFixedAmount,
} from "../../utils/numbers";

interface HistogramChartProps {
  data: {
    history: any[];
    value: any;
    label: string;
    metric: string;
    unit?: string;
    decimals: number;
  }[][];
  means?: any;
  stdDevs?: any;
  width?: string | number;
  player?: any;
}

const normalize = flow(
  relativize,
  capBetween(0, 1),
  centerAboutZero,
  increaseFixedAmount(0.5)
);

const ommittedGroups = ["gps", "obu"];

const HistogramChart = ({
  data = [],
  means,
  stdDevs,
  width,
  player,
}: HistogramChartProps) => {
  const navigate = useNavigate();
  const [chart, setChart] = useState<any>(null);
  const filteredData = data.filter((it) => {
    const currentGroup = it[0]?.metric?.split("_")[0];

    return !ommittedGroups.includes(currentGroup);
  });

  const formattedData = flatMap(filteredData, (metric) =>
    metric.map((it) => {
      const originalValue = it.value?.value;
      const mean = means[it.metric] ?? 0;
      const stdDev = stdDevs[it.metric] ?? 0;
      const side = hasSide(it.metric)
        ? ` (${it.metric.includes(player?.dominantSide) ? "Dom" : "Non-Dom"})`
        : "";
      const isValid =
        !isNil(originalValue) &&
        !isNaN(Number(originalValue)) &&
        mean > 0 &&
        stdDev > 0;
      const y = isValid ? normalize(mean, stdDev, originalValue) : 0;

      return {
        y,
        label: it.label,
        originalValue,
        side,
        unit: it.unit,
        color: getPlayerMetricColor(it.metric.split("_")[0]),
        id: it.metric,
        decimals: it.decimals,
      };
    })
  );
  const series = [{ data: formattedData }];
  const bounds = getBounds(formattedData.map((it: any) => it.y));

  useEffect(() => {
    if (chart) {
      series.forEach((series, index) => {
        updateOrReplaceSeries(chart, { ...series, index });
      });
    }
  }, [chart, series]);

  const options = {
    chart: {
      type: "column",
      animation: false,
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
      height: 80,
      width,
      marginBottom: 0,
      events: {
        load: function () {
          setChart(this);
        },
      },
    },
    credits: { enabled: false },
    tooltip: {
      useHTML: true,
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      formatter: function () {
        const chart: any = this;
        return `<div style="text-align: center;"><p style="color:white">${
          chart?.point?.label
        }${chart?.point?.options?.side || ""}${
          chart?.point?.options?.unit ? ` (${chart?.point?.options?.unit})` : ""
        }<p/><p style="color:white">${Number(
          chart?.point?.originalValue
        ).toFixed(chart?.point?.decimals)}<p></div>`;
      },
    },
    title: { text: "" },
    legend: { enabled: false },
    xAxis: { visible: false },
    yAxis: {
      min: bounds.min,
      max: bounds.max,
      title: { text: "" },
      gridLineWidth: 0,
      lineWidth: 0,
      labels: { enabled: false },
      plotLines: [
        {
          color: "gray",
          width: 2,
          zIndex: 5,
          value: 0,
        },
      ],
    },
    series: [
      {
        data: [],
      },
    ],
    plotOptions: {
      series: {
        cursor: "pointer",
        events: {
          click: function ({ point }: any) {
            navigate(`#${point.id}`, { state: { lastUpdate: new Date() } });
          },
        },
      },
      column: {
        pointWidth: 12,
        borderRadius: 0,
        pointPadding: 0.5,
      },
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default HistogramChart;
