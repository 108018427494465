import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";

export class ModelLoader {
  constructor(scene) {
    this.scene = scene;
  }
  async loadRawModel(modelPath) {
    const gltfLoader = new GLTFLoader();
    return await new Promise((resolve, reject) => {
      gltfLoader.load(
        modelPath,
        (gltf) => {
          resolve(gltf);
        },
        undefined,
        function (error) {
          console.error("Error loading model", error);
          reject(error);
        }
      );
    });
  }

  async loadModel(modelInfo) {
    const {
      modelPath,
      scaleFactor,
      initialPosition,
      colorHex,
      objectName,
      returnScene,
    } = modelInfo;
    const { scene } = await this.loadRawModel(modelPath);

    scene.scale.set(scaleFactor, scaleFactor, scaleFactor);
    if (initialPosition) {
      scene.position.x = initialPosition.x;
      scene.position.y = initialPosition.y;
      scene.position.z = initialPosition.z;
    }
    if (!returnScene) {
      this.scene.add(scene);
    }

    scene.traverse((node) => {
      if (node.isMesh) {
        const material = node.material;

        if (material && material.color) {
          material.color.setHex(colorHex);
          material.transparent = true;
          material.opacity = 0.5;
        }
      }
    });
    const modelObject = scene.getObjectByName(objectName);
    return returnScene ? { scene, modelObject } : modelObject;
  }
}
