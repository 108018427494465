import React from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

interface PaginationTableProps {
  currentPage: number;
  totalPages: number;
  totalItems: number;
  pageSize: number;
  currentItemsCount: number;
  totalItemsCount: number;
  goToNextPage: () => void;
  goToPrevPage: () => void;
  zeroBasedIndex?: boolean;
}

function PaginationTable({
  currentPage,
  totalPages,
  totalItems,
  goToNextPage,
  goToPrevPage,
  currentItemsCount,
  totalItemsCount,
  zeroBasedIndex,
}: PaginationTableProps) {
  const page = zeroBasedIndex ? currentPage + 1 : currentPage;
  const canGoPrev = page > 1;
  const canGoNext = page < totalPages;

  return (
    <Grid container justifyContent="space-between" mt={1} mb={2}>
      <Grid container item xs="auto" alignItems="center" spacing={1}>
        <Grid item>
          <IconButton onClick={goToPrevPage} disabled={!canGoPrev} size="small">
            <LeftOutlined />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography fontSize={14}>
            {page} {totalPages ? `of ${Math.ceil(totalPages)}` : ""}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={goToNextPage} disabled={!canGoNext} size="small">
            <RightOutlined />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item xs={"auto"}>
        <Typography fontSize={12} sx={{ color: "rgba(0, 0, 0, 0.45)" }}>
          {`${currentItemsCount}-${totalItemsCount} of ${totalItems} rows`}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default PaginationTable;
