import React, { memo, useCallback, useMemo, useState } from "react";
import { MetricDescriptionTooltipContent } from "../../common/MetricDescriptionTooltipContent";
import { useMetricData } from "../../../hooks/useMetricData";
import { Menu, Popover } from "antd";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { isEmpty, isNil, times } from "lodash";
import "../style.css";
import { Grid, IconButton } from "@mui/material";
import { AddAndRemoveControls } from "./AddAndRemoveControls";
import { BiomechanicsMetricsPanelProps } from "../BiomechanicsMetricsPanel";

type SingleMetricProps = BiomechanicsMetricsPanelProps & {
  useLocalState?: boolean;
};

const SingleMetric = memo(function SingleMetric({
  keyframesData,
  handleScrubber,
  useLocalState = false,
}: SingleMetricProps) {
  const {
    selectedMetric,
    comparingMetric,
    isComparingTrial,
    isSelfCompare,
    handleOpenMetricChange,
    menuIsOpen,
    setMenuIsOpen,
    extraValues,
    items,
  } = useMetricData({ useLocalState });

  const changeMetricAndGoToKeyFrame = useCallback(
    (e: any) => {
      handleOpenMetricChange(e);
      const metricHasKeyFrames = e.keyPath?.length === 3;
      if (metricHasKeyFrames) {
        const targetKeyFrame = keyframesData?.find(
          (it) => it.label === e.key
        )?.frame;
        if (targetKeyFrame) {
          handleScrubber(targetKeyFrame);
        }
      }
    },
    [keyframesData, handleOpenMetricChange, handleScrubber]
  );
  const ChangeMetric = useMemo(
    () => (
      <Menu
        onClick={changeMetricAndGoToKeyFrame}
        mode="vertical"
        items={items}
      />
    ),
    [changeMetricAndGoToKeyFrame, items]
  );

  const MetricSelector = useMemo(
    () => (
      <Popover
        open={menuIsOpen}
        onOpenChange={() => setMenuIsOpen(!menuIsOpen)}
        trigger={"click"}
        placement="rightBottom"
        overlayClassName="ptd-inner-menu"
        content={ChangeMetric}
      >
        <IconButton>
          <KeyboardArrowDownIcon />
        </IconButton>
      </Popover>
    ),
    [menuIsOpen, setMenuIsOpen, ChangeMetric]
  );

  const label = selectedMetric?.label;
  const unit = selectedMetric?.unit ? "(" + selectedMetric?.unit + ")" : "";
  const event =
    selectedMetric?.event === selectedMetric?.label ||
    isNil(selectedMetric?.event)
      ? ""
      : ["MAX", "TMAX"].includes(selectedMetric?.systematicName)
      ? selectedMetric?.event
      : `at ${selectedMetric?.event}`;

  return isEmpty(selectedMetric) ? (
    <></>
  ) : (
    <MetricDescriptionTooltipContent
      {...selectedMetric}
      extraValues={extraValues}
      secondaryMetric={!isEmpty(comparingMetric) ? comparingMetric : undefined}
      isComparingTrial={isComparingTrial}
      isSelfCompare={isSelfCompare}
      title={`${label} ${unit} ${event}`}
      imageToggle
      MetricSelector={MetricSelector}
    />
  );
});

const Metric = (videoScrubberProps: BiomechanicsMetricsPanelProps) => {
  const [extraMetricsCount, setExtraMetricsCount] = useState(0);
  const removeMetric = () => setExtraMetricsCount((it) => it - 1);
  const addMetric = () => setExtraMetricsCount((it) => it + 1);

  const ExtraMetrics = useMemo(
    () =>
      times(extraMetricsCount, (i) => (
        <SingleMetric key={i} {...videoScrubberProps} useLocalState />
      )),
    [extraMetricsCount, videoScrubberProps]
  );

  const canAddMetric = extraMetricsCount < 4;
  return (
    <>
      <SingleMetric {...videoScrubberProps} />
      {ExtraMetrics}
      <Grid
        container
        item
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
      >
        <AddAndRemoveControls
          removeMetric={removeMetric}
          addMetric={addMetric}
          canAddMetric={canAddMetric}
          canRemoveMetric={extraMetricsCount > 0}
        />
      </Grid>
    </>
  );
};
export default Metric;
