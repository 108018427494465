import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { useEffect } from "react";
import { PitchIndexing } from "../PlayerDashboard/PitchIndexing";
import { setPitchIndexing } from "../../redux/movementSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectPitchIndexing } from "../../redux/selectors";

interface PitchSelectorProps {
  showAllPitches?: boolean;
}

function PitchSelector({ showAllPitches }: PitchSelectorProps) {
  const pitchIndexing = useSelector(selectPitchIndexing);
  const dispatch = useDispatch();
  const handlePitchIndexingChange = (
    _: React.MouseEvent<HTMLElement> | undefined,
    newPitchIndexing: PitchIndexing
  ) => {
    if (newPitchIndexing) {
      dispatch(setPitchIndexing(newPitchIndexing));
    }
  };
  useEffect(() => {
    if (showAllPitches) {
      handlePitchIndexingChange(undefined, PitchIndexing.AllPitches);
    }
  }, [showAllPitches]);
  return (
    <ToggleButtonGroup
      className="ptd-toggle-container"
      value={pitchIndexing}
      exclusive
      size="small"
      onChange={handlePitchIndexingChange}
    >
      <ToggleButton
        className={`ptd-expanded-metrics-values ${
          showAllPitches && "ptd-disabled-pitch-selector"
        }`}
        value={PitchIndexing.AllPitches}
        disabled={showAllPitches}
      >
        All Pitches
      </ToggleButton>
      {!showAllPitches && (
        <ToggleButton
          className="ptd-expanded-metrics-values"
          value={PitchIndexing.InningSplit}
        >
          Inning Split
        </ToggleButton>
      )}
    </ToggleButtonGroup>
  );
}

export default PitchSelector;
