import * as THREE from "three";

export class SegmentLine3D {
  constructor({
    liteMode,
    liteColors,
    scene,
    segment,
    distalName,
    proximalName,
    jointCoordinates,
  }) {
    Object.assign(this, {
      liteMode,
      liteColors,
      scene,
      segment,
      distalName,
      proximalName,
      jointCoordinates,
    });
  }
  render(frame) {
    try {
      //Delete removeLine to see trajectory
      this.removeLine();
      const { line, sphere } = this.drawSegmentLine(
        this.jointCoordinates,
        this.segment,
        frame,
        this.distalName,
        this.proximalName
      );
      this.line = line;
      this.sphere = sphere;
    } catch (e) {
      //Offsets for trial sync might create an out of bounds array access. Ignore it.
    }
  }
  removeLine() {
    if (this.line) {
      this.scene.remove(this.line);
      this.line.geometry.dispose();
      this.line.material.dispose();
      this.line = null;
    }
    if (this.sphere) {
      this.scene.remove(this.sphere);
      this.sphere.geometry.dispose();
      this.sphere.material.dispose();
      this.sphere = null;
    }
  }
  drawSegmentLine(
    jointCoordinates,
    segment,
    currentFrame,
    nameDistal,
    nameProximal
  ) {
    if (segment) {
      const drawableFrame = Math.min(
        this.jointCoordinates.length - 2,
        currentFrame
      );
      // get the joint coordinates
      var dcmProximal = jointCoordinates[drawableFrame][nameProximal].elements;
      var dcmDistal = jointCoordinates[drawableFrame][nameDistal].elements;

      let positionProximal = new THREE.Vector3();
      let positionDistal = new THREE.Vector3();

      positionProximal.set(dcmProximal[3], dcmProximal[7], dcmProximal[11]);
      positionDistal.set(dcmDistal[3], dcmDistal[7], dcmDistal[11]);

      // create the line geometry and material
      const lineGeometry = new THREE.BufferGeometry().setFromPoints([
        positionDistal,
        positionProximal,
      ]);
      const lineMaterial = new THREE.LineBasicMaterial({
        color: this.liteMode ? this.liteColors.line || 0x004dbf : 0xb0e0e6,
      });

      // create the line
      const line = new THREE.Line(lineGeometry, lineMaterial);

      // add the line to the scene
      this.scene.add(line);
      let sphere;
      if (this.liteMode) {
        // create the sphere geometry and material
        const sphereGeometry = new THREE.SphereGeometry(0.02); // radius = 0.5
        const sphereMaterial = new THREE.MeshBasicMaterial({
          color: this.liteColors.sphere || 0x2974d6,
        }); // blue

        // create the sphere
        sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);

        // set the sphere's position to the rightAnkle joint center
        sphere.position.copy(positionDistal);
        this.scene.add(sphere);
      }

      // add the sphere to the scene
      return { line, sphere };
    }
  }
}
