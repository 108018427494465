import { compact, uniq } from "lodash";
import { Player } from "../services/mockData";

export const PITCHER_TYPES = ["P", "SP", "RP", "TWP"];
export const SIDE_PITCHER_TYPES = ["LHS", "RHS", "LHR", "RHR"];

export const getPitcherTypesWithSides = (
  pitcherTypes: string[] = PITCHER_TYPES
) =>
  [...pitcherTypes, ...SIDE_PITCHER_TYPES].filter(
    (it) => !["SP", "RP"].includes(it)
  );

export const mapPlayerPosition = (player: Player) => {
  if (player.position === "RP") {
    return `${player.throws}HR`;
  }

  if (player.position === "SP") {
    return `${player.throws}HS`;
  }

  return player?.position || "";
};

export const getPlayersPositions = (playersData: any[]) =>
  compact(uniq(playersData.map((it: any) => it.position || "")));
