import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import LabeledSwitch, { LabeledSwitchProps } from "../common/LabeledSwitch";
import { useSelector } from "react-redux";
import {
  setIsSelfCompare,
  setIsDataCompare,
  setDataCompValue,
  setSelfCompValue,
  setSelfCompOptions,
  setSecondarySelfCompValue,
  setIsSecondarySelfCompare,
  setSecondarySelfCompOptions,
} from "../../redux/movementSlice";
import {
  selectIsDataCompare,
  selectDataCompSwitch,
  selectMovement,
  selectSelectedMotionType,
} from "../../redux/selectors";
import {
  useFetchDataCompSwitchQuery,
  useFetchSelfCompSwitchQuery,
} from "../../services/performanceApi.service";
import { BASE_SELF_COMP_OPTION, DataSwitch } from "../../services/mockData";
import { isNil } from "lodash";
import {
  usePerformanceAppDispatch,
  usePerformanceAppSelector,
} from "../../redux/hooks";
import { Unless } from "react-if";
import SelfCompSwitches from "../common/SelfCompSwitches";

export const findCompValue = (data: DataSwitch[], id: number) => {
  return data.find((item) => item.id == id);
};

const validtabs = ["Time Series"];

export default function CompSwitches({
  dataCompLabelProps,
  selfCompLabelProps,
  activeTab,
}: {
  dataCompLabelProps?: Partial<LabeledSwitchProps>;
  selfCompLabelProps?: Partial<LabeledSwitchProps> & { hidden?: boolean };
  activeTab?: string;
}) {
  const [currentPlayer, setCurrentPlayer] = useState<any>(null);
  const { player } = useSelector(selectMovement);
  const isDataCompare = useSelector(selectIsDataCompare);
  const selectedDataComp = useSelector(selectDataCompSwitch);
  const motionType = usePerformanceAppSelector(selectSelectedMotionType);

  const dispatch = usePerformanceAppDispatch();

  const { data: dataCompSwitch } = useFetchDataCompSwitchQuery(motionType);
  const { data: selfCompSwitch } = useFetchSelfCompSwitchQuery();

  useEffect(() => {
    if (isNil(selectedDataComp) && !isNil(dataCompSwitch)) {
      dispatch(setDataCompValue(dataCompSwitch[0]));
    }
  });

  const handleClickDataCompare = (value: any) => {
    dispatch(setIsDataCompare(value));
  };

  const handleClickSelfCompare = (value: any) => {
    dispatch(setIsSelfCompare(value));
  };

  const handleDataCompSwitch = (id: number) => {
    if (dataCompSwitch) {
      const value = findCompValue(dataCompSwitch, id);
      dispatch(setDataCompValue(value));
    }
  };

  const resetStates = () => {
    handleClickSelfCompare(false);
    dispatch(setIsSecondarySelfCompare(false));
    dispatch(setSelfCompOptions(BASE_SELF_COMP_OPTION));
    dispatch(setSecondarySelfCompOptions(BASE_SELF_COMP_OPTION));
    dispatch(setSelfCompValue(undefined));
    dispatch(setSecondarySelfCompValue(undefined));
  };

  useEffect(() => {
    if (player !== currentPlayer && !isNil(currentPlayer)) {
      resetStates();
    }

    setCurrentPlayer(player);
  }, [player]);

  return (
    <Grid
      container
      item
      xs="auto"
      flexDirection="row"
      justifyContent="flex-end"
    >
      <LabeledSwitch
        label="Data Comp"
        checked={isDataCompare}
        onClick={handleClickDataCompare}
        data={dataCompSwitch ? dataCompSwitch : []}
        onHandleData={handleDataCompSwitch}
        value={selectedDataComp}
        {...dataCompLabelProps}
      />

      <Unless condition={selfCompLabelProps?.hidden}>
        <SelfCompSwitches
          data={selfCompSwitch || []}
          isMultiple={validtabs.includes(activeTab || "")}
        />
      </Unless>
    </Grid>
  );
}
