import React, { useEffect, useMemo, useState } from "react";
import { isEmpty, last } from "lodash";
import { Grid } from "@mui/material";
import LineChart from "../../../charts/LineChart";
import { MetricType } from "../../../../hooks/useBodyPartData";
import { genericMarker } from "../../../../utils/charts";
import {
  addIndex,
  basicFrame,
  toPlotLineMetrics,
} from "../../../../utils/metrics";
import useFramesData from "../../../../hooks/useFramesData";
import LegendItem from "../../../common/LegendItem";
import { useSelector } from "react-redux";
import { selectDataCompSwitch } from "../../../../redux/selectors";
interface BodyPartLineChartProps {
  metrics: MetricType[];
  secondaryData?: any[];
  isSelfCompare: boolean;
  isDataCompare: boolean;
  keyframesData?: any[];
  container?: any;
}

const lineColors = ["hoveredDigitalBlue", "digitalOrange"];
const secondaryLineColor = "borderBlue";

function BodyPartLineChart({
  metrics,
  secondaryData = [],
  keyframesData = [],
  container = null,
}: BodyPartLineChartProps) {
  const selectedDataComp = useSelector(selectDataCompSwitch);
  const [chart, setChart] = useState<null | any>();
  const [dataCompValue, setDataCompValue] = useState({ x: 0, y: 0 });

  const { lastFrameValue, toMillisecondsFromBrOffset, currentFrame } =
    useFramesData();

  useEffect(() => {
    if (chart) {
      const lastSeries: any = last(chart.series);
      const points = lastSeries?.points || [];
      const currentMsFrame = toMillisecondsFromBrOffset(currentFrame);
      const pointInCurrentTime: any = points.find(
        (it: any) =>
          Number(it.x).toFixed(0) === Number(currentMsFrame).toFixed(0)
      );

      if (pointInCurrentTime) {
        setDataCompValue({
          x: Number(pointInCurrentTime.x.toFixed(1)),
          y: Number(pointInCurrentTime.y.toFixed(1)),
        });
      }
    }
  }, [chart, currentFrame]);

  const mappedPrimaryMetrics = useMemo(
    () =>
      metrics.map((metric: any, index: number) => {
        const color = lineColors[index] || lineColors[0];

        return formatMetric(metric, color);
      }),
    [metrics]
  );

  const fixedLabel = useMemo(
    () =>
      `${secondaryData[0]?.label}: ${
        selectedDataComp ? selectedDataComp?.label : ""
      } (${dataCompValue.x}° to ${dataCompValue.y}°)`,
    [secondaryData, selectedDataComp, dataCompValue]
  );
  const mappedSecondaryMetric = useMemo(
    () =>
      formatMetric(
        { ...secondaryData[0], label: fixedLabel },
        secondaryLineColor
      ),
    [secondaryData, fixedLabel]
  );

  const mappedMetrics = useMemo(
    () => [...mappedPrimaryMetrics, mappedSecondaryMetric],
    [mappedPrimaryMetrics, mappedSecondaryMetric]
  );

  const mappedMetricsWithIndex = useMemo(
    () => addIndex(mappedMetrics),
    [mappedMetrics]
  );

  const fixedWidth: any = useMemo(
    () => (container ? container.clientWidth - 104 : undefined),
    [container]
  );

  const fixedKeyFrames = useMemo(
    () =>
      toPlotLineMetrics([
        basicFrame(0),
        ...keyframesData,
        basicFrame(lastFrameValue),
      ]).map((it) => ({
        ...it,
        value: toMillisecondsFromBrOffset(it.value),
      })),
    [keyframesData, lastFrameValue]
  );

  const extra = useMemo(() => ({ setChart }), []);

  return (
    <>
      {!isEmpty(metrics) && (
        <Grid container item xs={12} justifyContent="center">
          <Grid
            mt={8}
            item
            xs={1}
            justifyContent="start"
            textAlign="center"
            maxWidth="24px"
          >
            <span className="ptd-metric-name">
              {metrics[0]?.label} ({metrics[0]?.unit})
            </span>
          </Grid>

          <Grid container item mt={1} xs={11} height="325px">
            <LineChart
              data={mappedMetricsWithIndex}
              secondaryData={secondaryData}
              height="295px"
              width={fixedWidth}
              showTooltip
              plotLines={fixedKeyFrames}
              marginTop={10}
              marginBottom={5}
              extra={extra}
              stickyTracking
            />
          </Grid>

          <Grid
            item
            container
            justifyContent="center"
            flexDirection="row"
            alignItems="center"
            xs={12}
            mt={-2}
          >
            {mappedMetrics.map((metric: any, index: number) => (
              <Grid item key={index} xs="auto" mr={2}>
                <LegendItem
                  metric={metric}
                  color={metric.color}
                  noTooltip={!metric.unit}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </>
  );
}

const formatMetric = (metric: any, color: string) => ({
  ...metric,
  color,
  marker: genericMarker(color),
});

export default BodyPartLineChart;
