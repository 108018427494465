import React, { useCallback, useMemo, useState } from "react";
import CollapsiblePanel from "../common/CollapsiblePanel";
import { Tabs } from "antd";
import DiscreteMetric from "./DiscreteMetric";
import Correlation from "./Correlation";
import TimeSeriesVariable from "./TimeSeriesVariable";
import Scrollable from "../common/Scrollable";
import { useFeatureFlags } from "../../hooks/useFeatureFlags";
import {
  initialVisibleSeries,
  VisibleChartSeries,
} from "./VisibleChartSeriesToggles";

const tabs = [
  { key: "1", label: "Discrete Metric", children: <DiscreteMetric /> },
  { key: "2", label: "Time Series Variable", children: <TimeSeriesVariable /> },
  { key: "3", label: "Correlation", children: <Correlation /> },
];

export enum TabNames {
  DISCRETE = "Discrete Metric",
  TIME_SERIES = "Time series",
  CORRELATION = "Correlation",
}

function TrendsPanelModal({ disabled }: { disabled?: boolean }) {
  const [activeTab, setActiveTab] = useState(tabs[0].label);
  const { ptdEnabledTrendsPanelModal: enabledTrendsPanelModal } =
    useFeatureFlags();
  const [containerWidth, setContainerWidth] = useState(0);
  const [visibleChartSeries, setVisibleChartSeries] =
    useState<VisibleChartSeries>(initialVisibleSeries);
  const onChangeVisibleChartSeries = useCallback(
    (series: "linePlot" | "errorBar") => {
      setVisibleChartSeries({
        ...visibleChartSeries,
        [series]: !visibleChartSeries[series],
      });
    },
    [visibleChartSeries]
  );
  const mappedTabs = useMemo(
    () =>
      tabs.map((tab) => ({
        ...tab,
        children: {
          ...tab.children,
          props: {
            ...tab.children.props,
            containerWidth: containerWidth - 24,
            visibleChartSeries,
            onChangeVisibleChartSeries,
          },
        },
      })),
    [tabs, visibleChartSeries, onChangeVisibleChartSeries]
  );

  return (
    <CollapsiblePanel
      title="Trends"
      subtitle="TRENDS"
      top={270}
      innerPosition={{
        top: "0px",
      }}
      orientation="left"
      leftResize
      minWidth={600}
      initialWidth={700}
      minHeight={75}
      setContainerWidth={setContainerWidth}
      tabZIndex={10}
      disabled={disabled || !enabledTrendsPanelModal}
      removeOnCollapsed
    >
      {(height) => {
        return (
          <Tabs
            activeKey={activeTab}
            onChange={setActiveTab}
            defaultActiveKey="1"
            tabBarStyle={{ margin: 0, padding: 0 }}
            items={mappedTabs.map(({ label, children }) => {
              return {
                label,
                key: label,
                children: activeTab === label && (
                  <Scrollable height={height - 110}>{children}</Scrollable>
                ),
              };
            })}
          />
        );
      }}
    </CollapsiblePanel>
  );
}

export default TrendsPanelModal;
