import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LOAD_STATUS } from "../../constants";

const initialState = {
  // State for rendering the users tab:
  userInfo: {
    loadStatus: LOAD_STATUS.UNINITIALIZED,
    users: [],
  },
  // State for rendering the roles tab:
  roleInfo: {
    loadStatus: LOAD_STATUS.UNINITIALIZED,
    rolesByDepartment: [],
  },
  rolePermissionInfo: {
    loadStatus: LOAD_STATUS.UNINITIALIZED,
    resources: [],
    saveChangesStatus: LOAD_STATUS.UNINITIALIZED,
  },
};

export const fetchAllUsers = createAsyncThunk(
  "app/fetchAllUsers",
  async (args) => {
    const { skylineApi } = args;
    const response = await skylineApi.get("/api/user/all");
    return response.data;
  }
);

export const fetchAllRoles = createAsyncThunk(
  "app/fetchAllRoles",
  async (args) => {
    const { skylineApi } = args;
    const response = await skylineApi.get("/api/role/all");
    return response.data;
  }
);

export const fetchRolePermissions = createAsyncThunk(
  "app/fetchRolePermissions",
  async ({ roleId, skylineApi }) => {
    const response = await skylineApi.get(
      `/api/role/permissions?roleId=${roleId}`
    );
    return response.data;
  }
);

/**
 * Parse a skyline api user object and return it as an object suitable for storing in react-redux state
 * @param {object} user a json object from the skyline api representing a single user
 * @return a parsed user object for storing in react-redux state
 */
const parseUser = (user) => {
  return {
    key: user.id,
    id: user.id,
    name: `${user.lastName}, ${user.firstName}`,
    role: user.role,
    department: user.department,
    email: user.email,
  };
};

const parseDepartmentRoles = (d) => {
  return {
    department: d.department,
    roles: d.roles,
  };
};

/**
 * savePermissionChanges is a thunk that takes three arguments:
 * @roleId the integer id of the role whose permissions are being updated
 * @permissionChanges a list of objects representing the permissions to be updated
 * @skylineApi the skylineApi object has provided by the hook useSkylineApi
 */
export const savePermissionChanges = createAsyncThunk(
  "app/savePermissionChanges",
  async (args) => {
    const { roleId, permissionChanges, skylineApi } = args;
    const response = await skylineApi.post("/api/role/permissions", {
      roleId,
      permissions: permissionChanges,
    });
    return response.data;
  }
);

/**
 * Given a permission change to apply to a resource and a tree representing resources, updatePermissionTree()
 * recursively searches 'tree' until it finds the resource node that 'permissionChange' applies to, then it
 * modifies the access level of that node.
 *
 * @param {object} permissionChange the change to make in 'tree'. Has two string fields: resource and accessLevel
 * @param {*} tree an object representing a tree. Each node has an (resource) 'id' and an array of children nodes
 */
const updatePermissionTree = (permissionChange, tree) => {
  let foundNode = false;
  if (tree.id === permissionChange.resource) {
    tree.accessLevel = permissionChange.accessLevel;
    foundNode = true;
    return;
  }

  if (!foundNode) {
    tree.children.forEach((child) =>
      updatePermissionTree(permissionChange, child)
    );
  }
};

/**
 * modifyPermissions() takes a set of permission changes and an array of resource trees
 * available in user admin and applies each change to the relevant node, wherever it is
 * located within 'resourceTrees'.
 * @param {array} changes an array of objects, each of which has two string fields: 'resource' and 'accessLevel'
 * @param {array} resourceTrees an array of tree objects representing the resources available in user admin
 */
const modifyPermissions = (changes, resourceTrees) => {
  changes.forEach((change) => {
    resourceTrees.forEach((tree) => {
      updatePermissionTree(change, tree);
    });
  });
};

const userAdminSlice = createSlice({
  name: "userAdmin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // fetchAllUsers
      .addCase(fetchAllUsers.pending, (state) => {
        state.userInfo.loadStatus = LOAD_STATUS.LOADING;
      })
      .addCase(fetchAllUsers.fulfilled, (state, action) => {
        state.userInfo.users = action.payload.users.map(parseUser);
        state.userInfo.loadStatus = LOAD_STATUS.READY;
      })
      .addCase(fetchAllUsers.rejected, (state) => {
        state.userInfo.loadStatus = LOAD_STATUS.ERROR;
      })
      // fetchAllRoles
      .addCase(fetchAllRoles.pending, (state) => {
        state.roleInfo.loadStatus = LOAD_STATUS.LOADING;
      })
      .addCase(fetchAllRoles.fulfilled, (state, action) => {
        state.roleInfo.rolesByDepartment =
          action.payload.results.map(parseDepartmentRoles);
        state.roleInfo.loadStatus = LOAD_STATUS.READY;
      })
      .addCase(fetchAllRoles.rejected, (state) => {
        state.roleInfo.loadStatus = LOAD_STATUS.ERROR;
      })
      // fetchRolePermissions
      .addCase(fetchRolePermissions.pending, (state) => {
        state.rolePermissionInfo.loadStatus = LOAD_STATUS.LOADING;
      })
      .addCase(fetchRolePermissions.fulfilled, (state, action) => {
        state.rolePermissionInfo.resources = action.payload.rolePermissions;
        state.rolePermissionInfo.loadStatus = LOAD_STATUS.READY;
      })
      .addCase(fetchRolePermissions.rejected, (state) => {
        state.rolePermissionInfo.loadStatus = LOAD_STATUS.ERROR;
      })
      // savePermissionChanges
      .addCase(savePermissionChanges.pending, (state) => {
        state.rolePermissionInfo.saveChangesStatus = LOAD_STATUS.LOADING;
      })
      .addCase(savePermissionChanges.fulfilled, (state, action) => {
        // permissionChanges here is recalled from the initial action dispatch via meta.arg:
        const { permissionChanges } = action.meta.arg;

        // Because the save has succeeded, the server has the saved view of permissions but
        // the client (here) has the old state plus the changes that were just saved. To codify
        // the changes on the client (again, here) we run modifyPermissions() to edit the
        // state's resource trees:
        modifyPermissions(
          permissionChanges,
          state.rolePermissionInfo.resources
        );
        state.rolePermissionInfo.saveChangesStatus = LOAD_STATUS.READY;
      })
      .addCase(savePermissionChanges.rejected, (state) => {
        state.rolePermissionInfo.saveChangesStatus = LOAD_STATUS.ERROR;
      });
  },
});

export default userAdminSlice.reducer;
