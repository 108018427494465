import * as THREE from "three";
import { MiddleJoint3D } from "./PitcherMiddleJoint3D";
import { SegmentLine3D } from "./PitcherSegmentLine3D";
import { ModelLoader } from "./ModelLoader";
import {
  shouldLoadHalloweenEasterEgg,
  shouldLoadStarWarsEasterEgg,
} from "./shouldLoadHalloweenEasterEgg";
import { BatterMiddleJoint3D } from "./BatterMiddleJoint3D";
import { compact, isNil, omitBy } from "lodash";
const textureLoader = new THREE.TextureLoader();
export class Player3D {
  constructor(scene) {
    this.scene = scene;
    this.group = new THREE.Group();
    this.scene.add(this.group);
    this.joints = [];
    this.jointsMap = {};
    this.lines = [];
    this.jointCoordinates = [];
    this.guiData = [];
    this.skeletonColor = [];
    this.handedness = [];
  }

  async initialize(
    jointCoordinates,
    {
      zTranslation = 0,
      opacity,
      texture,
      color,
      liteMode,
      liteColors,
      handedness,
    } = {}
  ) {
    this.jointCoordinates = jointCoordinates;
    this.liteMode = liteMode;
    this.liteColors = liteColors || {};
    /*const jointsMap = */ await this.initializeJoints(jointCoordinates, {
      opacity,
      texture,
      color,
      liteMode,
      handedness,
    });
    //this.initializeLines(jointCoordinates, jointsMap);
    if (zTranslation) this.group.translateZ(zTranslation);
  }

  render(currentFrame) {
    this.joints.forEach((joint) => joint.render(currentFrame));
    this.lines.forEach((line) => line.render(currentFrame));
  }

  remove() {
    this.scene.remove(this.group);
  }

  async loadJoints(jointCoordinates, { opacity = 1, handedness } = {}) {
    const gltf = await this.loadModel();
    const {
      baseball,
      baseballCap,
      baseballGloveLeft,
      baseballGloveRight,
      baseballHelmet,
      darthHelmet,
      lightSaber,
    } = await this.loadBaseballProps(handedness);

    const { scene: halloweenModel, modelObject: pumpkinHead } =
      await this.loadPumpkinHead();
    const model = gltf.scene;
    this.model = model;
    model.scale.set(0.1, 0.1, 0.1);
    const isBatter = this.isBatter(jointCoordinates);
    const bat = model.getObjectByName("Baseball_Bat");
    const knob = shouldLoadStarWarsEasterEgg ? lightSaber : bat;
    bat.visible = isBatter && !shouldLoadStarWarsEasterEgg;
    lightSaber.visible = isBatter && shouldLoadStarWarsEasterEgg;
    darthHelmet.visible = shouldLoadStarWarsEasterEgg;
    knob.visible = isBatter;
    baseballHelmet.visible = isBatter && !shouldLoadStarWarsEasterEgg;
    baseballCap.visible = !isBatter && !shouldLoadStarWarsEasterEgg;

    const finalBaseballCap = shouldLoadStarWarsEasterEgg
      ? darthHelmet
      : baseballCap;
    const finalBaseballHelmet = shouldLoadStarWarsEasterEgg
      ? darthHelmet
      : baseballHelmet;
    const leftShin = model.getObjectByName("LeftShin");
    const rightShin = model.getObjectByName("RightShin2");
    const leftThigh = model.getObjectByName("LeftThigh");
    const rightThigh = model.getObjectByName("RightThigh");
    const spine = model.getObjectByName("Spine");
    const leftForearm = model.getObjectByName("LeftForeArm");
    const rightForearm = model.getObjectByName("RightForeArm");
    const leftUpperArm = model.getObjectByName("LeftUpperArm");
    const rightUpperArm = model.getObjectByName("RightUpperArm");
    const leftWrist = model.getObjectByName("LeftWrist");
    const rightWrist = model.getObjectByName("RightWrist");
    const head = shouldLoadHalloweenEasterEgg
      ? pumpkinHead
      : model.getObjectByName("Head");
    const rightAnkle = model.getObjectByName("RightAnkle");
    const leftAnkle = model.getObjectByName("LeftAnkle");
    const jointHead = model.getObjectByName("JointHead");
    const jointSpine = model.getObjectByName("JointSpine");
    const hips = model.getObjectByName("Hips");
    const jointLeftThigh = model.getObjectByName("JointLeftThigh");
    const jointRightThigh = model.getObjectByName("JointRightThigh");
    jointHead.visible = false;
    if (leftShin.material.opacity != opacity) {
      leftShin.material.opacity = opacity;
      leftShin.material.transparent = true;
    }
    if (!this.liteMode) {
      this.group.add(model);
    }
    if (!this.liteMode && shouldLoadHalloweenEasterEgg) {
      this.group.add(halloweenModel);
      model.getObjectByName("Head").visible = false;
    }
    const scaleBat = 4.8;
    const defaultOne = 1;
    const scaleDefault = defaultOne;
    const scaleTorso = 0.85;
    const scaleUpperThigh = 1.65;
    const scaleBicep = 1.15;
    const scaleShins = 1.5;
    const scaleHands = 0.35;
    const scaleSpineJoint = 1.3;

    const scaleFeet = 0.7;
    const scaleForearms = 0.95;
    const scaleHead = shouldLoadHalloweenEasterEgg ? 0.1 : 0.55;
    const scaleBall = 1.08;

    const scaleHipJoint = scaleDefault;
    // Extra x rotation
    const extraXShin = -5;
    const extraXThigh = -5;
    const extraXBicep = -5;
    const extraXForearm = 0;

    //const rotationHead = new THREE.Vector3(145, 90, 45);
    const rotationHead = new THREE.Vector3(0, 0, 180);

    const rotationDefault = new THREE.Vector3(0, 0, 180);
    const rotationThigh = new THREE.Vector3(0, 0, 180);
    const rotationShin = new THREE.Vector3(0, 0, 180);

    const rotationTorso = new THREE.Vector3(0, 0, 180);
    const rotationHands = new THREE.Vector3(0, 0, 180);
    const rotationSpineJoint = new THREE.Vector3(0, 0, 180);
    /*const rotationLeftHip = new THREE.Vector3(45, -90, 0);
    const rotationRightHip = new THREE.Vector3(45, 90, 0);*/
    const rotationLeftHip = new THREE.Vector3(0, 0, 180);
    const rotationRightHip = new THREE.Vector3(0, 0, 180);
    const rotationFeet = new THREE.Vector3(20, 0, 180);
    const positionWeightDefault = 0.5;
    const positionWeightHips = 0.3;
    //const positionWeightHead = 0.2;
    const positionWeightNeck = 0.5;
    const positionWeightTorso = 0.7;
    const positionWeightFeet = 0.8;
    const jointsMap = {
      //applyTransformationsMiddleProps(,,extraX,scaleCapSkip);
      baseballCap: new MiddleJoint3D(finalBaseballCap, {
        jointCoordinates,
        name: "Head",
        rotationExtra: rotationHead,
        scaleSeg: 1,
        skipScaling: true,
        distalName: "Neck",
        positionWeight: positionWeightNeck,
      }),
      baseballHelmet: new MiddleJoint3D(finalBaseballHelmet, {
        jointCoordinates,
        name: "Head",
        rotationExtra: rotationHead,
        scaleSeg: 1,
        skipScaling: true,
        distalName: "Neck",
        positionWeight: positionWeightNeck,
      }),
      baseballGloveLeft: new MiddleJoint3D(baseballGloveLeft, {
        jointCoordinates,
        name: "LeftWrist",
        rotationExtra: rotationHands,
        scaleSeg: 1,
        skipScaling: true,
        distalName: "LeftHand",
        positionWeight: positionWeightNeck,
      }),
      baseballGloveRight: new MiddleJoint3D(baseballGloveRight, {
        jointCoordinates,
        name: "RightWrist",
        rotationExtra: rotationHands,
        scaleSeg: 1,
        skipScaling: true,
        distalName: "RightHand",
        positionWeight: positionWeightNeck,
      }),
      leftShin: new MiddleJoint3D(leftShin, {
        jointCoordinates,
        name: "LeftShin",
        rotationExtra: rotationShin,
        scaleSeg: scaleShins,
        distalName: "LeftAnkle",
        positionWeight: positionWeightDefault,
        extraX: extraXShin,
      }),
      knob: isBatter
        ? new MiddleJoint3D(knob, {
            jointCoordinates,
            name: "Knob",
            rotationExtra: rotationDefault,
            scaleSeg: scaleBat,
            distalName: "Top",
            positionWeight: positionWeightDefault,
          })
        : null,
      ball: isBatter
        ? new BatterMiddleJoint3D(baseball, {
            jointCoordinates,
            name: "Center",
            rotationExtra: rotationDefault,
            scaleSeg: scaleBall,
            distalName: "Center",
            scale: 0,
            positionWeight: positionWeightDefault,
          })
        : null,
      rightShin: new MiddleJoint3D(rightShin, {
        jointCoordinates,
        name: "RightShin",
        rotationExtra: rotationDefault,
        scaleSeg: scaleShins,
        distalName: "RightAnkle",
        positionWeight: positionWeightDefault,
        extraX: extraXShin,
      }),
      leftThigh: new MiddleJoint3D(leftThigh, {
        jointCoordinates,
        name: "LeftThigh",
        rotationExtra: rotationThigh,
        scaleSeg: scaleUpperThigh,
        distalName: "LeftShin",
        positionWeight: positionWeightDefault,
        extraX: extraXThigh,
      }),
      rightThigh: new MiddleJoint3D(rightThigh, {
        jointCoordinates,
        name: "RightThigh",
        rotationExtra: rotationThigh,
        scaleSeg: scaleUpperThigh,
        distalName: "RightShin",
        positionWeight: positionWeightDefault,
        extraX: extraXThigh,
      }),
      spine: new MiddleJoint3D(spine, {
        jointCoordinates,
        name: "Spine",
        rotationExtra: rotationTorso,
        scaleSeg: scaleTorso,
        distalName: "Neck",
        positionWeight: positionWeightTorso,
      }),
      leftForearm: new MiddleJoint3D(leftForearm, {
        jointCoordinates,
        name: "LeftForearm",
        rotationExtra: rotationDefault,
        scaleSeg: scaleForearms,
        distalName: "LeftWrist",
        positionWeight: positionWeightDefault,
        extraX: extraXForearm,
      }),
      rightForearm: new MiddleJoint3D(rightForearm, {
        jointCoordinates,
        name: "RightForearm",
        rotationExtra: rotationDefault,
        scaleSeg: scaleForearms,
        distalName: "RightWrist",
        positionWeight: positionWeightDefault,
        extraX: extraXForearm,
      }),
      leftUpperArm: new MiddleJoint3D(leftUpperArm, {
        jointCoordinates,
        name: "LeftUpperArm",
        rotationExtra: rotationDefault,
        scaleSeg: scaleBicep,
        distalName: "LeftForearm",
        positionWeight: positionWeightDefault,
        extraX: extraXBicep,
      }),
      rightUpperArm: new MiddleJoint3D(rightUpperArm, {
        jointCoordinates,
        name: "RightUpperArm",
        rotationExtra: rotationDefault,
        scaleSeg: scaleBicep,
        distalName: "RightForearm",
        positionWeight: positionWeightDefault,
        extraX: extraXBicep,
      }),
      leftWrist: new MiddleJoint3D(leftWrist, {
        jointCoordinates,
        name: "LeftWrist",
        rotationExtra: rotationHands,
        scaleSeg: scaleHands,
        distalName: "LeftHand",
        positionWeight: positionWeightDefault,
      }),
      rightWrist: new MiddleJoint3D(rightWrist, {
        jointCoordinates,
        name: "RightWrist",
        rotationExtra: rotationHands,
        scaleSeg: scaleHands,
        distalName: "RightHand",
        positionWeight: positionWeightDefault,
      }),
      head: new MiddleJoint3D(head, {
        jointCoordinates,
        name: "Head",
        rotationExtra: rotationHead,
        scaleSeg: scaleHead,
        distalName: "Neck",
        positionWeight: positionWeightNeck,
      }),
      jointSpine: new MiddleJoint3D(jointSpine, {
        jointCoordinates,
        name: "JointSpine",
        rotationExtra: rotationSpineJoint,
        scaleSeg: scaleSpineJoint,
        distalName: "Spine",
        positionWeight: positionWeightDefault,
      }),
      hips: new MiddleJoint3D(hips, {
        jointCoordinates,
        name: "Hips",
        rotationExtra: rotationSpineJoint,
        scaleSeg: scaleSpineJoint,
        distalName: "JointSpine",
        positionWeight: positionWeightDefault,
        skipScaling: true,
      }),
      jointLeftThigh: new MiddleJoint3D(jointLeftThigh, {
        jointCoordinates,
        name: "JointLeftThigh",
        rotationExtra: rotationLeftHip,
        scaleSeg: scaleHipJoint,
        distalName: "LeftThigh",
        positionWeight: positionWeightHips,
        skipScaling: true,
      }),
      jointRightThigh: new MiddleJoint3D(jointRightThigh, {
        jointCoordinates,
        name: "JointRightThigh",
        rotationExtra: rotationRightHip,
        scaleSeg: scaleHipJoint,
        distalName: "RightThigh",
        positionWeight: positionWeightHips,
        skipScaling: true,
      }),
      rightAnkle: new MiddleJoint3D(rightAnkle, {
        jointCoordinates,
        name: "RightAnkle",
        distalName: "RightFoot",
        rotationExtra: rotationFeet,
        scaleSeg: scaleFeet,
        positionWeight: positionWeightFeet,
      }),
      leftAnkle: new MiddleJoint3D(leftAnkle, {
        jointCoordinates,
        name: "LeftAnkle",
        distalName: "LeftFoot",
        rotationExtra: rotationFeet,
        scaleSeg: scaleFeet,
        positionWeight: positionWeightFeet,
      }),
    };
    return omitBy(jointsMap, isNil);
  }

  async loadBaseballProps(handedness = "right") {
    const gltf2 = await this.loadModel(
      "/3d/models/BaseballProps_And_StarWars_05072024_v4.glb"
    );
    const model = gltf2.scene;

    model.scale.set(0.1, 0.1, 0.1);
    const baseball = model.getObjectByName("baseball_01");
    const baseballCap = model.getObjectByName("Baseball_cap");
    const baseballGloveLeft = model.getObjectByName("Baseball_gloveLeft");
    const baseballGloveRight = model.getObjectByName("Baseball_gloveRight");
    const baseballHelmet = model.getObjectByName("Baseball_helmet");
    var position = new THREE.Vector3();
    position.set(0, -100.1, 0);
    baseball.position.copy(position);

    model.traverse((node) => {
      if (node.isMesh) {
        const material = node.material;
        const name = node.name;
        if (material && material.color && name != "baseball_01") {
          material.color.setHex(0xffffff); // Setting to red as an example
        }
      }
    });
    let darthColor = 0x040404;
    const modelParts = ["DarthHelmet", "LightSaber"];
    modelParts.forEach((modelName) => {
      const modelPart = model.getObjectByName(modelName);
      if (modelPart.isMesh && modelName != "baseball_01") {
        if (Array.isArray(modelPart.material)) {
          modelPart.material.forEach((material) => {
            material.emissive.set(0x000000); // Set emissive color to black
            if (material.emissiveIntensity !== undefined) {
              material.emissiveIntensity = 0; // Turn off emissive intensity
            }
          });
        } else {
          // Single material
          if (modelName != "baseball_01") {
            modelPart.material.emissive.set(0x000000); // Set emissive color to black
            if (modelPart.material.emissiveIntensity !== undefined) {
              modelPart.material.emissiveIntensity = 0; // Turn off emissive intensity
            }
          }
        }
        if (modelName != "baseball_01") {
          modelPart.material.color.setHex(skeletonColor); // Setting to red as an example
        }
      }
      if (modelName === "DarthHelmet") {
        modelPart.traverse((children) => {
          if (children.material) {
            children.material = new THREE.MeshStandardMaterial({
              color: darthColor,
              emissive: 0x000000,
              emissiveIntensity: 0,
            });
          }
        });
        modelPart.visible = shouldLoadStarWarsEasterEgg;
      } else if (modelName == "LightSaber") {
        modelPart.traverse((children) => {
          if (children.material && children.name == "Cylinder002_2") {
            children.material = new THREE.MeshStandardMaterial({
              color: 0xdc0000, // Warm sun color
              emissive: 0xff0b0b, // Make the suns glow
              fog: false, // Ensure suns are not affected by scene fog if used
              toneMapped: false,
              emissiveIntensity: 12.5,
            });
          } else if (children.material) {
            children.material.emissive = 0x000000;
            children.material.color.setHex(0x000000);
            children.material.emissiveIntensity = 0;
          }
        });
        modelPart.visible = shouldLoadStarWarsEasterEgg;
      } else {
        //Everything else in the glb model is not visible
        modelPart.visible = false;
      }
    });
    const playerIsBatting = this.isBatter();
    baseballGloveLeft.visible = !playerIsBatting;
    baseballGloveRight.visible = !playerIsBatting;

    baseballHelmet.visible = false;
    if (handedness == "left") {
      baseballGloveLeft.visible = false;
    } else {
      baseballGloveRight.visible = false;
    }

    this.group.add(model);
    return {
      baseball,
      baseballCap,
      baseballGloveLeft,
      baseballGloveRight,
      baseballHelmet,
      darthHelmet: model.getObjectByName("DarthHelmet"),
      lightSaber: model.getObjectByName("LightSaber"),
      model,
    };
  }

  async loadBallIfNeeded() {
    if (this.isBatter(this.jointCoordinates)) {
      const ballGlft = await this.loadModel(
        "/3d/models/baseball_01_4k.gltf/baseball_01_4k.gltf"
      );

      const ballModel = ballGlft.scene;
      const ball = ballModel.getObjectByName("baseball_01");
      var position = new THREE.Vector3();
      position.set(0, 1.1, 0);
      ball.position.copy(position);
      this.group.add(ballModel);
      return ball;
    }
    return null;
  }

  async loadModel(modelPath = "/3d/models/model3.glb") {
    return await new ModelLoader(this.scene).loadRawModel(modelPath);
  }

  async loadPumpkinHead() {
    return await new ModelLoader(this.scene).loadModel({
      modelPath: "/3d/models/halloween/pumpkinFace8.glb",
      scaleFactor: 0.1,
      colorHex: 0xff7518,
      objectName: "PumpkinFace",
      returnScene: true,
    });
  }

  initializeLines(jointCoordinates, { rightShin, leftShin }) {
    let shinLineRight,
      shinLineLeft,
      thighLineLeft,
      thighLineRight,
      bicepLineLeft,
      bicepLineRight,
      forearmLineRight,
      forearmLineLeft,
      spineLine,
      pelvisLine,
      clavicleLineLeft,
      clavicleLineRight;
    // Draw some basic lines for alignment
    ({
      shinLineRight,
      shinLineLeft,
      thighLineRight,
      thighLineLeft,
      pelvisLine,
      bicepLineRight,
      bicepLineLeft,
      forearmLineRight,
      forearmLineLeft,
      spineLine,
      clavicleLineLeft,
      clavicleLineRight,
    } = this.generateAligmentLines(
      shinLineRight,
      jointCoordinates,
      rightShin,
      shinLineLeft,
      leftShin,
      thighLineRight,
      thighLineLeft,
      pelvisLine,
      bicepLineRight,
      bicepLineLeft,
      forearmLineRight,
      forearmLineLeft,
      spineLine,
      clavicleLineLeft,
      clavicleLineRight
    ));
    const lines = [
      shinLineRight,
      shinLineLeft,
      thighLineRight,
      thighLineLeft,
      pelvisLine,
      bicepLineRight,
      bicepLineLeft,
      forearmLineRight,
      forearmLineLeft,
      spineLine,
      clavicleLineLeft,
      clavicleLineRight,
    ];
    this.lines = lines;
  }

  generateAligmentLines(
    shinLineRight,
    jointCoordinates,
    rightShin,
    shinLineLeft,
    leftShin,
    thighLineRight,
    thighLineLeft,
    pelvisLine,
    bicepLineRight,
    bicepLineLeft,
    forearmLineRight,
    forearmLineLeft,
    spineLine,
    clavicleLineLeft,
    clavicleLineRight
  ) {
    shinLineRight = new SegmentLine3D({
      liteMode: this.liteMode,
      liteColors: this.liteColors,
      scene: this.group,
      jointCoordinates,
      segment: rightShin,
      distalName: "RightAnkle",
      proximalName: "RightShin",
    });

    // Left Ankle to Left Shin
    shinLineLeft = new SegmentLine3D({
      liteMode: this.liteMode,
      liteColors: this.liteColors,
      scene: this.group,
      jointCoordinates,
      segment: leftShin,
      distalName: "LeftAnkle",
      proximalName: "LeftShin",
    });

    // Right Shin to Right Thigh
    thighLineRight = new SegmentLine3D({
      liteMode: this.liteMode,
      liteColors: this.liteColors,
      scene: this.group,
      jointCoordinates,
      segment: rightShin,
      distalName: "RightShin",
      proximalName: "RightThigh",
    });

    // Left Shin to Left Thigh
    thighLineLeft = new SegmentLine3D({
      liteMode: this.liteMode,
      liteColors: this.liteColors,
      scene: this.group,
      jointCoordinates,
      segment: leftShin,
      distalName: "LeftShin",
      proximalName: "LeftThigh",
    });

    // Right Thigh to Left Thigh
    pelvisLine = new SegmentLine3D({
      liteMode: this.liteMode,
      liteColors: this.liteColors,
      scene: this.group,
      jointCoordinates,
      segment: leftShin,
      distalName: "RightThigh",
      proximalName: "LeftThigh",
    });

    // Right Upper Arm to Right Forearm
    bicepLineRight = new SegmentLine3D({
      liteMode: this.liteMode,
      liteColors: this.liteColors,
      scene: this.group,
      jointCoordinates,
      segment: rightShin,
      distalName: "RightUpperArm",
      proximalName: "RightForearm",
    });

    // Left Upper Arm to Left Forearm
    bicepLineLeft = new SegmentLine3D({
      liteMode: this.liteMode,
      liteColors: this.liteColors,
      scene: this.group,
      jointCoordinates,
      segment: leftShin,
      distalName: "LeftUpperArm",
      proximalName: "LeftForearm",
    });

    // Right Forearm to Right Wrist
    forearmLineRight = new SegmentLine3D({
      liteMode: this.liteMode,
      liteColors: this.liteColors,
      scene: this.group,
      jointCoordinates,
      segment: rightShin,
      distalName: "RightForearm",
      proximalName: "RightWrist",
    });

    // Left Forearm to Left Wrist
    forearmLineLeft = new SegmentLine3D({
      liteMode: this.liteMode,
      liteColors: this.liteColors,
      scene: this.group,
      jointCoordinates,
      segment: leftShin,
      distalName: "LeftForearm",
      proximalName: "LeftWrist",
    });

    // Hips to Neck
    spineLine = new SegmentLine3D({
      liteMode: this.liteMode,
      liteColors: this.liteColors,
      scene: this.group,
      jointCoordinates,
      segment: leftShin,
      distalName: "Hips",
      proximalName: "Neck",
    });

    // Left Upper Arm to Neck
    clavicleLineLeft = new SegmentLine3D({
      liteMode: this.liteMode,
      liteColors: this.liteColors,
      scene: this.group,
      jointCoordinates,
      segment: leftShin,
      distalName: "LeftUpperArm",
      proximalName: "Neck",
    });

    // Right Upper Arm to Neck
    clavicleLineRight = new SegmentLine3D({
      liteMode: this.liteMode,
      liteColors: this.liteColors,
      scene: this.group,
      jointCoordinates,
      segment: leftShin,
      distalName: "RightUpperArm",
      proximalName: "Neck",
    });
    return {
      shinLineRight,
      shinLineLeft,
      thighLineRight,
      thighLineLeft,
      pelvisLine,
      bicepLineRight,
      bicepLineLeft,
      forearmLineRight,
      forearmLineLeft,
      spineLine,
      clavicleLineLeft,
      clavicleLineRight,
    };
  }

  async initializeJoints(jointCoordinates, loadOptions) {
    this.jointsMap = await this.loadJoints(jointCoordinates, loadOptions);
    const {
      baseballCap,
      baseballHelmet,
      baseballGloveLeft,
      baseballGloveRight,
      leftShin,
      knob,
      ball,
      rightShin,
      leftThigh,
      rightThigh,
      spine,
      leftForearm,
      rightForearm,
      leftUpperArm,
      rightUpperArm,
      leftWrist,
      rightWrist,
      head,
      rightAnkle,
      leftAnkle,
      jointSpine,
      hips,
      jointLeftThigh,
      jointRightThigh,
    } = this.jointsMap;

    const joints = compact([
      baseballCap,
      baseballHelmet,
      baseballGloveLeft,
      baseballGloveRight,
      rightAnkle,
      leftAnkle,
      leftShin,
      knob,
      ball,
      rightShin,
      leftThigh,
      rightThigh,
      spine,
      leftForearm,
      rightForearm,
      leftUpperArm,
      rightUpperArm,
      leftWrist,
      rightWrist,
      head,
      jointSpine,
      hips,
      jointLeftThigh,
      jointRightThigh,
    ]);

    this.joints = joints;

    this.initializeJointsTexture(loadOptions);

    return this.jointsMap;
  }

  initializeJointsTexture(loadOptions) {
    const opacityOptions = isFinite(loadOptions.opacity)
      ? { opacity: loadOptions.opacity, transparent: true }
      : null;
    const appearance = loadOptions.color
      ? { color: loadOptions.color, ...opacityOptions }
      : loadOptions.texture
      ? { matcap: textureLoader.load(loadOptions.texture) }
      : {};

    const boneMaterial = new THREE.MeshMatcapMaterial(appearance);
    this.joints.forEach((joint) => {
      if (!shouldLoadHalloweenEasterEgg || joint.name !== "Head") {
        joint.segment.material = boneMaterial;
      }
    });
  }

  isBatter(jointCoordinates = this.jointCoordinates) {
    return isBatter(jointCoordinates);
  }
}

export function isBatter(jointCoordinates) {
  return !!jointCoordinates?.[0]?.Knob;
}
