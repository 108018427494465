import { useEffect, useMemo, useState } from "react";
import { computeSplitRegression, defaultSplitRanges } from "./splitRegressions";
import { Range } from "../../services/performanceApi.service";
import { isNil } from "lodash";
import { TrendsDiscreteMetricData } from "./TrendsInfoTable";
import { DataPoint } from "regression";
import { dataIndexingValueToDataPoint } from "../PlayerDashboard/ExpandedMetricsDataIndexing";

export default function useSplitRegressions(
  data: TrendsDiscreteMetricData | undefined
) {
  const [splitRegression1Range, setRange1] = useState<Range | undefined>(
    undefined
  );

  const [splitRegression2Range, setRange2] = useState<Range | undefined>(
    undefined
  );

  const dataForSplitRegression: DataPoint[] | undefined = useMemo(
    () =>
      isNil(data) ? undefined : data.values.map(dataIndexingValueToDataPoint),
    [data]
  );

  useEffect(() => {
    if (isNil(dataForSplitRegression)) {
      return;
    }

    const [r1, r2] = defaultSplitRanges(dataForSplitRegression ?? []);
    setRange1(r1);
    setRange2(r2);
  }, [dataForSplitRegression]);

  const splitRegression1 = computeSplitRegression(
    dataForSplitRegression,
    splitRegression1Range
  );

  const splitRegression2 = computeSplitRegression(
    dataForSplitRegression,
    splitRegression2Range
  );

  const onApply = (regressionNumber: number, range: Range) => {
    regressionNumber === 1 ? setRange1(range) : setRange2(range);
  };

  const onReset = (regressionNumber: number) => {
    if (isNil(dataForSplitRegression)) {
      return;
    }

    const [r1, r2] = defaultSplitRanges(dataForSplitRegression);
    if (regressionNumber === 1) {
      setRange1(r1);
    } else {
      setRange2(r2);
    }
  };

  return { splitRegression1, splitRegression2, onApply, onReset };
}
