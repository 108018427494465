import React from "react";
import { OverlaySettingsType } from "./OverlayVideo";
import { useLoadVideo } from "./useLoadVideo";
interface VideoProps {
  videoRef?: React.RefObject<HTMLVideoElement>;
  width?: React.CSSProperties["width"];
  height?: React.CSSProperties["height"];
  comparative?: boolean;
  mirror?: boolean;
  videoUrl: string;
  onClick?: () => void;
  className?: string;
  overlaySettings?: OverlaySettingsType;
  borderColor?: string;
  cursor?: string;
}

export function Video({
  width = "100%",
  height = "auto",
  comparative = false,
  mirror = false,
  cursor = "pointer",
  videoRef,
  videoUrl,
  onClick,
  className,
  borderColor,
  overlaySettings = {
    opacity: 100,
    translateX: 0,
    translateY: 0,
    scale: 100,
  },
}: VideoProps) {
  const color =
    borderColor ||
    (!comparative ? "var(--hoveredDigitalBlue)" : "var(--digitalOrange)");
  videoRef?.current?.setAttribute(
    "data-is-comparative",
    comparative.toString()
  );
  useLoadVideo(videoRef, videoUrl);

  return (
    <video
      id="myVideo"
      preload="auto"
      width={width}
      height={height}
      ref={videoRef}
      style={{
        border: `3px solid ${color}`,
        lineHeight: 0,
        cursor,
        opacity: overlaySettings.opacity / 100,
        transform: `translateY(${overlaySettings.translateY}px) translateX(${
          overlaySettings.translateX
        }px) 
        ${mirror ? "rotateY(180deg)" : ""}
        scale(${overlaySettings.scale / 100})`,
      }}
      onClick={onClick}
      className={className}
    >
      <source src={videoUrl} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
}
