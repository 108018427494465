import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useThrowEventReduxData } from "./useThrowEventReduxData";
import { useThrowEventDataManipulation } from "./useThrowEventDataManipulation";
import { Metric, defaultThrowEventMetric } from "../services/mockMetrics";
import { useMemo } from "react";
import { useFetchDiscreteSelfDataQuery } from "../services/performanceApi.service";
import { useSelector } from "react-redux";
import { selectSelfCompOptions } from "../redux/selectors";
import { shouldFetchSelfCompData } from "../utils/metrics";

interface useThrowEventsDataProps {
  useFetchPrimaryData: (props: any) => any;
}

export const METRICS_TO_REMOVE = [
  "Throwing Wrist Pronation",
  "Throwing Wrist Ulnar Deviation",
  "Lead Wrist Pronation",
  "Lead Wrist Ulnar Deviation",
  "Lead Wrist Flexion",
  "Back Knee Valgus",
  "Lead Knee Valgus",
  "Throwing Forearm Elevation",
  "Glove Arm Rotation",
  "Lead Leg Rotation",
  "Rotation Velocity",
  "Rotation Energy",
  "Spine Lateral Tilt Velocity",
  "Spine Flexion Velocity",
  "Momentum",
  "Displacement",
  "Linear Velocity",
  "Force",
  "Torque",
];

export const UNITS_TO_REMOVE = ["°/s", "J"];

function useThrowEventsData({ useFetchPrimaryData }: useThrowEventsDataProps) {
  const {
    isSelfCompare,
    mainTrial,
    secondaryTrial,
    player,
    //referenceMetricData,
  } = useThrowEventReduxData();

  const { data: mainDataList = [] } = useFetchPrimaryData(
    mainTrial?.id ? mainTrial : skipToken
  );
  const referenceMetricData = useMemo(() => {
    return mainDataList.filter(
      (metric: any) =>
        !METRICS_TO_REMOVE.some(
          (metricToRemove) =>
            metric?.label.includes(metricToRemove) ||
            UNITS_TO_REMOVE.includes(metric?.unit)
        )
    );
  }, [mainDataList]);
  const { data: secondaryData = [] } = useFetchPrimaryData(
    secondaryTrial?.id ? secondaryTrial : skipToken
  );

  const selfCompOptions = useSelector(selectSelfCompOptions);
  const { data: selfData = [] } = useFetchDiscreteSelfDataQuery(
    shouldFetchSelfCompData(player, selfCompOptions)
      ? { playerId: player?.id, selfCompOptions }
      : skipToken
  );

  const {
    favouriteData,
    alternativeFavouriteData,
    alternativeData,
    setFavouriteData,
    setAlternativeFavouriteData,
  } = useThrowEventDataManipulation({ mainDataList, secondaryData, selfData });
  const onMetricChange = (
    id: number,
    newMetricLabel: string,
    setState: (prevState: any) => void,
    data: any[]
  ) => {
    const newMetric = data.find(
      (metricToReplace: Metric) => metricToReplace.label === newMetricLabel
    );

    const metricToSave = newMetric
      ? newMetric
      : { ...defaultThrowEventMetric, label: newMetricLabel };
    setState((prevState: any[]) =>
      prevState.map((metric: any) => {
        return metric.id !== id ? metric : { ...metricToSave };
      })
    );
  };

  const onMainMetricChange = (id: number, newMetricName: string) => {
    onMetricChange(id, newMetricName, setFavouriteData, mainDataList);
  };

  const onSecondaryMetricChange = (id: number, newMetricName: string) => {
    onMetricChange(
      id,
      newMetricName,
      setAlternativeFavouriteData,
      secondaryData
    );
  };

  return {
    favouriteData,
    alternativeFavouriteData,
    alternativeData,
    isSelfCompare,
    referenceMetricData,
    onMainMetricChange,
    onSecondaryMetricChange,
  };
}

export { useThrowEventsData };
