export function isPerformanceDashboard() {
  return (
    process.env.REACT_APP_IS_STANDALONE_PERFORMANCE === "true" ||
    process.env.REACT_APP_IS_PERFORMANCE === "true" ||
    window.location.origin.indexOf("performance.nymets") >= 0
  );
}

export function isStandalonePerformanceDashboard() {
  return (
    process.env.REACT_APP_IS_STANDALONE_PERFORMANCE === "true" ||
    window.location.href.indexOf("standalone") >= 0
  );
}
