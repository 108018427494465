import { isNil, isEmpty, uniqBy, isArray, identity } from "lodash";
import { ReportsTabItem } from "./ReportSummary";
import { orderDataByEvent } from "../../utils/metrics";
import { REPORT_SUMMARY_SECTIONS } from "./reportSummaryData";

export const orderMetrics = (
  data: any[],
  referenceMetricsKeys: string[],
  indicator: string = "id",
  toLower = false
) => {
  const mappedData = referenceMetricsKeys?.map((key) =>
    data?.find((it) => {
      if (isArray(it)) {
        return it.find((inner) =>
          referenceMetricsKeys.includes(inner[indicator])
        );
      }

      if (toLower) {
        return it[indicator].toLowerCase() === key.toLowerCase();
      }

      return it[indicator] === key;
    })
  );
  const filteredData = mappedData.filter((it) => !isNil(it));

  return filteredData;
};

export const filterKeyFramesBySection = (
  section: ReportsTabItem,
  data: any[]
) => {
  const dictionary: { [key: string]: string[] } = {
    lead: ["ILO", "MKH", "HS", "MS"],
    lHalfLoad: ["MKH", "HS", "MS"],
    uHalfLoad: ["HS", "MS", "FFC", "BR"],
    separation: ["HS", "MS", "FFC", "MER", "BR"],
    land: ["MS", "FFC", "MER", "BR"],
  };
  const currentEvents = dictionary[section.value];

  return data.filter((it) => currentEvents.includes(it.label.text));
};

export type Extreme = { event: string; offset: number };
export type Extremes = { min: Extreme; max: Extreme };

export const getInitialZoomExtremesBySection = (
  section: ReportsTabItem,
  keyFrames: any[],
  format: (value: number) => number
) => {
  const dictionary: { [key: string]: Extremes } = {
    lead: {
      min: { event: "ILO", offset: -10 },
      max: { event: "MS", offset: 15 },
    },
    lHalfLoad: {
      min: { event: "MKH", offset: -15 },
      max: { event: "FFC", offset: 15 },
    },
    uHalfLoad: {
      min: { event: "HS", offset: -15 },
      max: { event: "BR", offset: 15 },
    },
    separation: {
      min: { event: "HS", offset: -15 },
      max: { event: "BR", offset: 15 },
    },
    land: {
      min: { event: "MS", offset: -15 },
      max: { event: "BR", offset: 15 },
    },
  };
  const { min, max } = dictionary[section?.value];
  const currentMin = keyFrames?.find((it) => it.label.text === min.event);
  const currentMax = keyFrames?.find((it) => it.label.text === max.event);

  return {
    min: currentMin?.value + format(min.offset),
    max: currentMax?.value + format(max.offset),
  };
};

export const isVelocityRot = (id: any) =>
  (id?.id ? id.id : id)?.includes("_velocity_rot_ts");

export const isSeparation = (section?: ReportsTabItem) =>
  section?.value === "separation";

const mapCurrentTimeSeriesData = (
  timeSeriesData: any[],
  timeFromBrData: any[]
) =>
  timeSeriesData.map((it) => ({
    ...it,
    data: it?.data?.map((y: number[], index: number) => {
      const current = Number(timeFromBrData?.[index]) || 0;
      const x = current * 100;

      return [x, y];
    }),
  }));

export const mapTimeSeriesData = (timeSeriesData: any[] = []) => {
  const timeFromBrData = timeSeriesData?.find(
    ({ id }) => id === "time_from_br"
  )?.data;
  const mappedTimeSeriesData = mapCurrentTimeSeriesData(
    timeSeriesData,
    timeFromBrData
  );
  const filteredTimeSeriesData = mappedTimeSeriesData?.filter(
    ({ id }) => id !== "time_from_br" && !isVelocityRot(id)
  );
  const velocities = timeSeriesData?.filter((it) => isVelocityRot(it.id));
  const mappedVelocities = mapCurrentTimeSeriesData(velocities, timeFromBrData);

  const allTimeSeriesData = [...filteredTimeSeriesData, mappedVelocities];

  return allTimeSeriesData;
};

export const getTimeSeriesData = (data: any[], dataMetricKeys: string[]) => {
  const orderedMetrics = orderMetrics(data, dataMetricKeys);

  return uniqBy(!isEmpty(orderedMetrics) ? orderedMetrics : data, "id");
};

export const getPointsByColorKey = (colorKey: string, points: any[]) =>
  points?.filter((point: any) => point.color.includes(colorKey));

type FormatNumberDictionary = { [metricId: string]: (value: number) => number };
type FormatStringDictionary = {
  [metricId: string]: (value: string) => string;
};

const formatValueDictionary: FormatNumberDictionary = {
  km_wb_spine_angle_rot_ts: (value) => value * -1,
  km_wb_spine_angle_rot_ts_min: (value) => value * -1,
  km_wb_spine_angle_forwardtilt_ts: (value) => value * -1,
  km_wb_spine_angle_forwardtilt_ts_min: (value) => value * -1,
  km_wb_pelvis_velocity_rot_ts_tmax: (value) => value * 1000,
  km_wb_trunk_velocity_rot_ts_tmax: (value) => value * 1000,
  pelvistrunk_peakvelocitysep_time_total: (value) => value * 1000,
  pos_g_com_velocity_x_ts_ilo: (value) => value * 100,
  pos_g_com_velocity_x_ts_mkh: (value) => value * 100,
};
const formatLabelDictionary: FormatStringDictionary = {
  km_wb_spine_angle_rot_ts: () => "Hip Shoulder Sep. Rotation",
  km_wb_spine_angle_rot_ts_min: () => "Hip Shoulder Sep. Rotation",
  km_wb_spine_angle_forwardtilt_ts: () => "Hip Shoulder Sep. Extension",
  km_wb_spine_angle_forwardtilt_ts_min: () => "Hip Shoulder Sep. Extension",
  km_wb_spine_angle_glovelean_ts: () => "Hip Shoulder Sep. Lateral Tilt",
  km_wb_spine_angle_glovelean_ts_max: () => "Hip Shoulder Sep. Lateral Tilt",
  km_wb_pelvis_velocity_rot_ts: () => "Pelvis Rotation Velocity",
  km_wb_pelvis_velocity_rot_ts_max: () => "Pelvis Rotation Velocity",
  km_wb_trunk_velocity_rot_ts: () => "Trunk Rotation Velocity",
  km_wb_trunk_velocity_rot_ts_max: () => "Trunk Rotation Velocity",
  km_wb_pelvis_velocity_rot_ts_tmax: () =>
    "Pelvis Rotation Velocity Peak Timing",
  km_wb_trunk_velocity_rot_ts_tmax: () => "Trunk Rotation Velocity Peak Timing",
  pelvistrunk_peakvelocitysep_time_total: () =>
    "Trunk Pelvis Rotation Velocity Peak Timing difference",
};
const formatEventDictionary: FormatStringDictionary = {
  km_wb_spine_angle_rot_ts_min: () => "PK",
  km_wb_spine_angle_forwardtilt_ts_min: () => "PK",
  km_wb_spine_angle_glovelean_ts_max: () => "PK",
  km_wb_pelvis_velocity_rot_ts_max: () => "PK",
  km_wb_trunk_velocity_rot_ts_max: () => "PK",
  km_wb_pelvis_velocity_rot_ts_tmax: () => "",
  km_wb_trunk_velocity_rot_ts_tmax: () => "",
  pelvistrunk_peakvelocitysep_time_total: () => "",
};
const formatUnitDictionary: FormatStringDictionary = {
  km_wb_spine_angle_rot_ts_min: () => "°",
  km_wb_spine_angle_forwardtilt_ts_min: () => "°",
  km_wb_spine_angle_glovelean_ts_max: () => "°",
  km_wb_pelvis_velocity_rot_ts_max: () => "°/s",
  km_wb_trunk_velocity_rot_ts_max: () => "°/s",
  km_wb_pelvis_velocity_rot_ts_tmax: () => "ms",
  km_wb_trunk_velocity_rot_ts_tmax: () => "ms",
  pelvistrunk_peakvelocitysep_time_total: () => "ms",
  pos_g_com_velocity_x_ts: () => "cm/s",
};

export const mapMetricFieldsIfNeeded = (metric: any) => {
  const mappedKey = metric?.key?.toLowerCase();
  const mapMetricValue = formatValueDictionary[mappedKey] || identity;
  const mapMetricLabel = formatLabelDictionary[mappedKey] || identity;
  const mapMetricEvent = formatEventDictionary[mappedKey] || identity;
  const mapMetricUnit = formatUnitDictionary[mappedKey] || identity;

  return {
    ...metric,
    value: mapMetricValue(metric?.value),
    mean: mapMetricValue(metric?.mean),
    dataComp: {
      from: mapMetricValue(metric?.dataComp?.from),
      to: mapMetricValue(metric?.dataComp?.to),
    },
    label: mapMetricLabel(metric?.label),
    event: mapMetricEvent(metric?.event),
    unit: mapMetricUnit(metric?.unit),
    decimals: 0,
  };
};

export const findDataComp = (metric: any, rawDataComp: any[]) =>
  rawDataComp.find(
    (it) =>
      (metric?.key || metric)?.toLocaleLowerCase() === it.id.toLocaleLowerCase()
  );

export const mapLandMetricIfNeeded = (
  rawMetric: any,
  rawDiscrete: any,
  rawDataComp: any
) => {
  const metricsToUse: { [key: string]: { metric: string; brMetric: string } } =
    {
      L_Knee_Extension_Total: {
        metric: "KM_L_Knee_Angle_Flexion_TS_FFC",
        brMetric: "KM_L_Knee_Angle_Flexion_TS_BR",
      },
    };

  const currentMetricsToUse = metricsToUse[rawMetric?.key];

  if (isNil(currentMetricsToUse)) {
    return rawMetric;
  }

  const metric = currentMetricsToUse?.metric;
  const brMetric = currentMetricsToUse?.brMetric;
  const metricValue: number = rawDiscrete[metric];
  const brFrameValue: number = rawDiscrete[brMetric];
  const metricMean: number = findDataComp(metric, rawDataComp)?.mean;
  const brMetricMean: number = findDataComp(brMetric, rawDataComp)?.mean;

  const value = metricValue - brFrameValue;
  const mean = metricMean - brMetricMean;
  const stdDev = Math.sqrt((metricValue ^ 2) + (brFrameValue ^ 2));

  return {
    ...rawMetric,
    value,
    mean,
    dataComp: {
      from: mean - stdDev,
      to: mean + stdDev,
    },
  };
};

export const isValidAvgValue = (value: number) =>
  !isNaN(value) && !isNil(value);

const separationReferenceMetrics = REPORT_SUMMARY_SECTIONS.separation;

export const orderDataIfNeeded = (data: any[], section: ReportsTabItem) => {
  if (section.value === "separation") {
    const referenceMetricsKeys = separationReferenceMetrics.filter(
      (it) => !it.endsWith("_ts")
    );

    return orderMetrics(data, referenceMetricsKeys, "key", true);
  }

  return orderDataByEvent(data);
};
