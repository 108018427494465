import React, { CSSProperties } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { Typography as AntdTypography } from "antd";
import { getSliderColorByValue } from "../../utils/colors";
import { selectIsDataCompare } from "../../redux/selectors";
import { useSelector } from "react-redux";
import "./ValueBox.css";
import { parseMetricValue } from "../../utils/metrics";
import { isStringNumber, toLocaleString } from "../../utils/strings";
import { isNil, isNumber, startCase } from "lodash";
import { obuValues } from "./obuValues";
import { Unless } from "react-if";
import { getHydrationColor } from "./hydrationColors";

export interface ValueBoxProps {
  value: string | number;
  spineMobilityValue?: string;
  spineMobilityColor?: string;
  accentColor?: string;
  noValue?: boolean;
  range?: {
    from: number;
    to: number;
  };
  label?: string;
  isSecondary?: boolean;
  isSpineMobility?: boolean;
  style?: CSSProperties;
  fontStyle?: CSSProperties;
  decimals?: number;
  metric?: any;
  isHydration?: boolean;
  noValueColor?: CSSProperties["color"];
  shouldUseStartCase?: boolean;
}

export const defaultFixedAccentColor = "var(--hoveredGrey)";

const ValueBox = ({
  value,
  noValue,
  range,
  accentColor,
  spineMobilityValue,
  spineMobilityColor,
  isSecondary,
  isSpineMobility = false,
  style,
  label,
  fontStyle,
  decimals = 0,
  metric,
  isHydration,
  noValueColor,
  shouldUseStartCase = true,
}: ValueBoxProps) => {
  const isObu = metric?.metric.startsWith("obu_");
  const isDataCompare = useSelector(selectIsDataCompare);

  let fixedAccentColor = defaultFixedAccentColor;

  if (
    isDataCompare &&
    (isNumber(value) || isStringNumber(value)) &&
    !isNil(range)
  ) {
    fixedAccentColor = getSliderColorByValue(+value, range);
  }

  if (accentColor) {
    fixedAccentColor = accentColor;
  }

  if (isSpineMobility) {
    fixedAccentColor =
      parseMetricValue(value?.toString(), decimals, spineMobilityColor) ??
      defaultFixedAccentColor;
  }

  if (isObu) {
    const currentValue = value?.toString();
    const obuValue = obuValues[metric?.metric];

    if (obuValue?.positive === currentValue) {
      fixedAccentColor = "var(--ptd-dashboard-green)";
    } else if (obuValue?.negative?.includes(currentValue)) {
      fixedAccentColor = "var(--ptd-dashboard-red)";
    }
  }

  if (isHydration) {
    const currentValue = value?.toString();
    const hydrationColor = getHydrationColor(currentValue);

    fixedAccentColor = hydrationColor;
  }

  const hasNoValue = noValue || isNil(value);
  const metricValue = hasNoValue
    ? "-"
    : parseMetricValue(
        value.toString(),
        decimals,
        spineMobilityValue,
        isHydration
      ) ?? "-";

  const displayValue = isNumber(metricValue)
    ? toLocaleString(metricValue, { decimals, truncateZeros: false })
    : shouldUseStartCase
    ? startCase(metricValue)
    : metricValue;

  return (
    <Stack alignItems="center" spacing={0.5}>
      <Box
        className="ptd-value-box-container"
        sx={{
          borderColor: hasNoValue
            ? !isNil(noValueColor)
              ? noValueColor
              : "var(--gray-2)"
            : fixedAccentColor,
          marginLeft: isSecondary ? "4px" : 0,
        }}
        style={{ ...style }}
      >
        <Typography
          className="ptd-value-box-main-value"
          style={{
            ...fontStyle,
            color: hasNoValue
              ? !isNil(noValueColor)
                ? noValueColor
                : "var(--hoveredGrey)"
              : undefined,
          }}
        >
          {displayValue}
        </Typography>
      </Box>
      <Unless condition={isNil(label)}>
        <AntdTypography.Text type="secondary" style={{ fontSize: "12px" }}>
          {label}
        </AntdTypography.Text>
      </Unless>
    </Stack>
  );
};

export default ValueBox;
