import React, { useState } from "react";
import TrendsChartLayout from "./TrendsChartLayout";
import { TabNames } from "./TrendsPanelModal";
import CorrelationChart from "./CorrelationChart";
import {
  useFetchMetricsQuery,
  useFetchTrendsCorrelationDataQuery,
} from "../../services/performanceApi.service";
import { useSelector } from "react-redux";
import {
  selectMovement,
  selectPeriodSelectorValue,
  selectSelectedMotionType,
  selectSelfCompOptions,
} from "../../redux/selectors";
import { TrendFetchDataProps } from "./TimeSeriesVariable";
import { Grid } from "@mui/material";
import MetricSelector from "./MetricSelector";
import TrendsInfoTable from "./TrendsInfoTable";
import { isNil } from "lodash";
import { skipToken } from "@reduxjs/toolkit/dist/query";
// eslint-disable-next-line no-unused-vars
import { MenuProps } from "antd";
import { VisibilityOfSeries } from "./VisibleChartSeriesToggles";
import ExportCSVButton from "./ExportCSVButton";

export interface CorrelationChartProps extends TrendFetchDataProps {
  metricId2: string;
}

const METRIC = "metric";
const firstMetric = {
  id: "KM_WB_Pelvis_Angle_ROT_TS_MKH",
  label: "Pelvis Rotation (Max Knee Height)",
};
const secondMetric = {
  id: "KM_WB_Stride_Length_ht",
  label: "Stride Length (%ht)",
};

interface CorrelationProps extends VisibilityOfSeries {}

function Correlation({
  visibleChartSeries,
  onChangeVisibleChartSeries,
}: CorrelationProps) {
  const { player, trial } = useSelector(selectMovement);
  const motionType = useSelector(selectSelectedMotionType);
  const { data: metricsData = [] } = useFetchMetricsQuery({
    metricId: METRIC,
    pitch: trial,
    motionType,
  });
  const [metric1, setMetric1] = useState(firstMetric);
  const [metric2, setMetric2] = useState(secondMetric);
  const timePeriod = useSelector(selectPeriodSelectorValue);
  const { data } = useFetchTrendsCorrelationDataQuery(
    isNil(player) || isNil(trial)
      ? skipToken
      : {
          metricId: metric1.id,
          metricId2: metric2.id,
          playerId: player.id,
          trialType: trial.type,
          startDate: timePeriod.startDate,
          endDate: timePeriod.endDate,
        }
  );

  const onChangeMetric: (
    setter: (params: { id: string; label: string }) => void
  ) => MenuProps["onClick"] = (setter) => (ev) => {
    // @ts-ignore
    const itemLabel = ev?.domEvent?.target?.innerText;
    const parentLabel = ev?.keyPath?.[1];
    const showParentLabel = (ev.keyPath?.length ?? 0) > 2;
    setter({
      id: ev?.key,
      label: showParentLabel ? `${parentLabel} (${itemLabel})` : itemLabel,
    });
  };

  const {
    payload: { pitchType, sup75Perc },
  } = useSelector(selectSelfCompOptions);

  return (
    <TrendsChartLayout tabName={TabNames.CORRELATION}>
      <Grid container justifyContent="right">
        <Grid item mt={-2}>
          <ExportCSVButton
            fileName="CorrelationMetricsData.csv"
            data={data?.values?.map(([, mean, date]: any) => {
              const currentDate = data?.commonValues?.find(
                (it) => it.date === date
              );

              return {
                player: player.id,
                pitchType,
                sup75Perc,
                metric1: metric1?.id,
                metric2: metric2?.id,
                value1: currentDate?.[metric1?.id],
                value2: currentDate?.[metric2?.id],
                date,
                mean,
              };
            })}
            disabled={isNil(data?.values) || isNil(data?.commonValues)}
            headers={[
              "player",
              "pitchType",
              "sup75Perc",
              "metric1",
              "metric2",
              "value1",
              "value2",
              "date",
              "mean",
            ]}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid container item direction="column" alignItems="center" xs={1}>
          <MetricSelector
            metric={metric2}
            onChange={onChangeMetric(setMetric2)}
            items={metricsData}
            rotate
          />
        </Grid>
        <Grid item xs={11}>
          {data?.values && (
            <CorrelationChart
              data={data?.values}
              regression={data?.regression}
              visibleChartSeries={visibleChartSeries}
              metrics={{ metric1, metric2 }}
              commonValues={data?.commonValues}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <MetricSelector
            metric={metric1}
            onChange={onChangeMetric(setMetric1)}
            items={metricsData}
          />
        </Grid>
        <Grid item xs={12}>
          <TrendsInfoTable
            data={data}
            countLabel="Sessions"
            visibleChartSeries={visibleChartSeries}
            onChangeVisibleChartSeries={onChangeVisibleChartSeries}
          />
        </Grid>
      </Grid>
    </TrendsChartLayout>
  );
}

export default Correlation;
