import React from "react";
import { Grid, Stack } from "@mui/material";
import { BulletChart } from "../../charts";
import { useFetchBatDynamicsDataQuery } from "../../../services/performanceApi.service";
import { useSelector } from "react-redux";
import {
  selectComparingMovement,
  selectMovement,
  selectSelectedMotionType,
} from "../../../redux/selectors";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { isNil } from "lodash";

interface BatDynamicsProps {}

const getValue = (data: any[], item: any) =>
  data?.find((it: any) => it.id === item.id)?.value;

const BatDynamics = ({}: BatDynamicsProps) => {
  const { trial: mainTrial } = useSelector(selectMovement);
  const { trial: secondaryTrial } = useSelector(selectComparingMovement);
  const motionType = useSelector(selectSelectedMotionType);
  const { data: mainTrialData = [] } = useFetchBatDynamicsDataQuery(
    mainTrial?.id
      ? {
          trial: mainTrial,
          motionType,
        }
      : skipToken
  );
  const { data: secondaryTrialData = [] } = useFetchBatDynamicsDataQuery(
    secondaryTrial?.id
      ? {
          trial: secondaryTrial,
          motionType,
        }
      : skipToken
  );

  const alternativeData = !isNil(secondaryTrial?.id) ? secondaryTrialData : [];

  return (
    <Stack spacing={2} py={2} px={1}>
      <Grid container>
        {mainTrialData?.map((item: any, index: number) => {
          const secondaryValue = getValue(alternativeData, item);

          return (
            <Grid key={item.label + index} item xs={6}>
              <BulletChart
                value={item.value}
                averageRange={item.averageRange}
                mean={item.mean}
                title={item.label}
                unit={item.unit}
                secondaryValue={secondaryValue}
                decimals={item.decimals}
              />
            </Grid>
          );
        })}
      </Grid>
    </Stack>
  );
};

export default BatDynamics;
