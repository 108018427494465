import React from "react";
import { useSequence } from "../../../hooks/useSequence";
import SequenceGraph from "./Sequence/SequenceGraph";
import SequencePeakValues from "./Sequence/SequencePeakValues";
import Scrollable from "../../common/Scrollable";
import { Tabs } from "antd";

interface SequenceProps {
  parentHeight?: number;
}

const Sequence = (props: SequenceProps) => {
  const { rangeValues, ...graphProps } = useSequence();

  const tabs = [
    { label: "Graph", children: <SequenceGraph {...graphProps} {...props} /> },
    {
      label: "Peak Values",
      children: <SequencePeakValues rangeValues={rangeValues} />,
    },
  ];
  return (
    <Tabs
      items={tabs.map(({ label, children }, index) => {
        return {
          label,
          key: `${label}-${index}`,
          children: <Scrollable>{children}</Scrollable>,
        };
      })}
    />
  );
};

export default Sequence;
