import React, { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { OverlaySettingsType } from "./OverlayVideo";
import { VideoSlider } from "../common/Slider";
interface OverlaySettingsProps {
  setOverlaySettings: React.Dispatch<React.SetStateAction<OverlaySettingsType>>;
  overlaySettings: OverlaySettingsType;
  leftName?: string;
  rightName?: string;
}

interface SliderWithLabelsProps {
  leftLabel: string;
  rightLabel: string;
  onChange: (value: number, name: string) => void;
  onChangeName: string;
  min?: number;
  max?: number;
  value?: number;
  legend?: string;
}

function OverlaySettings({
  setOverlaySettings,
  overlaySettings,
  leftName = "",
  rightName = "",
}: OverlaySettingsProps) {
  const [initialOverlaySettings] = useState(overlaySettings);
  const onChangeValue = (value: number, name: string) => {
    setOverlaySettings((prevState) => ({ ...prevState, [name]: value }));
  };
  const resetOverlaySettings = () => setOverlaySettings(initialOverlaySettings);

  return (
    <Grid container item xs={12} textAlign={"center"} spacing={1}>
      <SliderWithLabels
        leftLabel={leftName}
        rightLabel={rightName}
        onChange={onChangeValue}
        onChangeName={"opacity"}
        max={100}
        min={0}
        value={overlaySettings.opacity}
        legend={`Opacity (${overlaySettings.opacity}%)`}
      />
      <SliderWithLabels
        leftLabel="L"
        rightLabel="R"
        onChange={onChangeValue}
        value={overlaySettings.translateX}
        onChangeName={"translateX"}
      />
      <SliderWithLabels
        leftLabel="Down"
        rightLabel="Up"
        onChange={onChangeValue}
        value={overlaySettings.translateY}
        onChangeName={"translateY"}
      />
      <SliderWithLabels
        leftLabel="Zoom -"
        rightLabel="Zoom +"
        onChange={onChangeValue}
        onChangeName={"scale"}
        value={overlaySettings.scale}
        min={0}
        max={200}
      />
      <Grid container justifyContent="center">
        <Grid item xs="auto">
          <Button
            size="small"
            onClick={resetOverlaySettings}
            sx={{ textTransform: "capitalize" }}
          >
            Restore defaults
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
const SliderWithLabels = ({
  leftLabel,
  rightLabel,
  onChange,
  onChangeName,
  max = 150,
  min = -150,
  value = 0,
  legend = "",
}: SliderWithLabelsProps) => (
  <Grid container item xs={12} alignItems={"center"}>
    <Grid item xs="auto">
      <Typography fontSize={13}>{leftLabel}</Typography>
    </Grid>

    <Grid item xs>
      <Grid item xs={12}>
        <Typography fontSize={13}>{legend}</Typography>
      </Grid>
      <VideoSlider
        max={max}
        min={min}
        value={value}
        onChange={(_, newValue: any) => onChange(newValue, onChangeName)}
        sx={{ width: "90%" }}
      />
    </Grid>
    <Grid item xs="auto">
      <Typography fontSize={13}>{rightLabel}</Typography>
    </Grid>
  </Grid>
);

export default OverlaySettings;
