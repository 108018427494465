import { useOktaAuth as okta_useOktaAuth } from "@okta/okta-react";

/**
 * Usage: set REACT_APP_FAKE_OKTA_AUTH and optionally REACT_APP_FAKE_OKTA_AUTH_EMAIL.
 *
 * For production instances: defaults to actual Okta functionality.
 *
 * For non-production instances, when REACT_APP_FAKE_OKTA_AUTH=true is set,
 * fakes an Okta token (bypassing its requirements, particularly
 * its HTTPS requirements). User will be logged in the email
 * address provided in REACT_APP_FAKE_OKTA_AUTH_EMAIL, or else a hard-coded
 * default.
 */
export const useOktaAuth = () => {
  // Non-production only.
  if (process.env.NODE_ENV !== "production") {
    if (process.env.REACT_APP_FAKE_OKTA_AUTH) {
      const fakeUserEmail =
        process.env.REACT_APP_FAKE_OKTA_AUTH_EMAIL || "dpurkayastha@nymets.com";
      return {
        oktaAuth: {
          email: fakeUserEmail,
        },
        authState: {
          isAuthenticated: true,
          accessToken: {
            accessToken: "dummytoken",
            claims: {
              sub: fakeUserEmail,
            },
            tokenType: "dummy",
            userinfoUrl: "dummy",
            expiresAt: 0,
            authorizeUrl: "dummy",
            scopes: [],
          },
        },
      };
    }
  }

  // Default to actual Okta functionality.
  return okta_useOktaAuth();
};
