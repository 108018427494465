import React from "react";
import { IconButton } from "@mui/material";
import { formatDiscreteDataToCSV } from "../../utils/strings";
import { triggerFileDownload } from "../../three-js/loadCSV";
import { FileTextOutlined } from "@ant-design/icons";

interface ExportCSVButtonProps {
  fileName: string;
  headers: string[];
  disabled: boolean;
  data?: any[];
}

export const ExportCSVButton = ({
  fileName,
  headers,
  disabled,
  data = [],
}: ExportCSVButtonProps) => (
  <IconButton
    disabled={disabled}
    onClick={() => {
      const mappedData = formatDiscreteDataToCSV(headers, data);

      triggerFileDownload(fileName, mappedData);
    }}
  >
    <FileTextOutlined />
  </IconButton>
);

export default ExportCSVButton;
