import React, { useMemo, useState } from "react";
import { Switch, Table } from "antd";
import {
  FetchTrendsDiscreteInningSplitResponse,
  FetchTrendsDiscreteAllPitchesResponse,
  FetchTrendsDiscreteDateIndexingResponse,
  FetchTrendsCorrelationDataResponse,
} from "../../services/performanceApi.service";
import { castArray, compact, isArray, isNil } from "lodash";
import { Link, Stack, Typography } from "@mui/material";
import RangeSelectionPopover, {
  RangeSelectionPopoverProps,
} from "./RangeSelectionPopover";
import { SplitRegression } from "./splitRegressions";
import "./TrendsInfoTable.css";
import {
  VisibilityOfSeries,
  VisibilityOfSeriesKeys,
} from "./VisibleChartSeriesToggles";
import {
  formatRegression,
  formatRegressionNumber,
  getRegressionData,
} from "../../utils/metrics";

const { Column } = Table;

export type TrendsDiscreteMetricData =
  | FetchTrendsDiscreteInningSplitResponse
  | FetchTrendsDiscreteAllPitchesResponse
  | FetchTrendsDiscreteDateIndexingResponse
  | FetchTrendsCorrelationDataResponse;

export interface TrendsInfoTableProps extends VisibilityOfSeries {
  data?: TrendsDiscreteMetricData;
  countLabel?: string;
  splitRegression1?: SplitRegression;
  splitRegression2?: SplitRegression;
  onApply?: RangeSelectionPopoverProps["onApply"];
  onReset?: RangeSelectionPopoverProps["onReset"];
}

interface TableRow {
  label: string;
  value: string;
  regressionNumber?: number;
}

const includeIf = <T,>(condition: boolean, value: T) =>
  condition ? value : undefined;

const makeStatsRows = (data?: TrendsDiscreteMetricData) => {
  if (isNil(data)) {
    return [
      {
        label: "Mean, Stdev",
        value: "-",
      },
      {
        label: "Min, Max",
        value: "-",
      },
    ];
  }

  return castArray(data.stats).flatMap((stats, index) => {
    const coordinate = index === 0 && isArray(data.stats) ? "X" : "Y";
    return [
      {
        label: `Mean, Stdev (${coordinate})`,
        value: `${formatRegressionNumber(
          stats.mean
        )} ± ${formatRegressionNumber(stats.stdDev)}`,
      },
      {
        label: `Min, Max (${coordinate})`,
        value: `${formatRegressionNumber(stats.low)}, ${formatRegressionNumber(
          stats.high
        )}`,
      },
    ];
  });
};

export default function TrendsInfoTable(props: TrendsInfoTableProps) {
  const {
    data,
    splitRegression1,
    splitRegression2,
    countLabel = "Pitches",
    onApply = () => {},
    onReset = () => {},
    onChangeVisibleChartSeries,
    visibleChartSeries,
  } = props;
  const [popoverAnchorEl, setPopoverAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);
  const [editingRegressionNumber, setEditingRegressionNumber] = useState(1);
  const showSplitRegressionRows = useMemo(
    () => !isNil(splitRegression1),
    [splitRegression1]
  );

  const popoverRange = useMemo(
    () =>
      editingRegressionNumber === 1
        ? splitRegression1?.range
        : splitRegression2?.range,
    [editingRegressionNumber, splitRegression1, splitRegression2]
  );

  const handleClick = (
    event: React.MouseEvent<any>,
    regressionNumber: number
  ) => {
    setPopoverAnchorEl(event.currentTarget);
    setEditingRegressionNumber(regressionNumber);
  };

  const handleClose = () => {
    setPopoverAnchorEl(null);
  };

  const dataSource: TableRow[] = useMemo(
    () =>
      compact([
        {
          label: "Full Regression",
          value: formatRegression(data?.regression),
          regressionNumber: 0,
        },
        includeIf(showSplitRegressionRows, {
          label: "Split Regression 01",
          value: formatRegression(splitRegression1?.result),
          regressionNumber: 1,
        }),
        includeIf(showSplitRegressionRows, {
          label: "Split Regression 02",
          value: formatRegression(splitRegression2?.result),
          regressionNumber: 2,
        }),
        {
          label: "Count (N)",
          value: isNil(data) ? "-" : `${data?.values.length} ${countLabel}`,
        },
        ...makeStatsRows(data),
      ]),
    [data, splitRegression1, splitRegression2]
  );

  const keys: { [index: number]: VisibilityOfSeriesKeys } = {
    0: "fullRegression",
    1: "splitRegression1",
    2: "splitRegression2",
  };

  const regressionData = (regressionNumber?: number) =>
    getRegressionData({ regressionNumber, visibleChartSeries, keys, props });

  return (
    <>
      <Table
        dataSource={dataSource}
        pagination={false}
        size="small"
        className="ptd-trends-info-table"
      >
        <Column
          title="Measure"
          dataIndex="label"
          render={(value: string, row: TableRow) => {
            const { currentKey, visible, disabled } = regressionData(
              row?.regressionNumber
            );
            if (
              visibleChartSeries &&
              currentKey &&
              onChangeVisibleChartSeries
            ) {
              // @ts-expect-error
              if (visibleChartSeries[currentKey] !== visible) {
                onChangeVisibleChartSeries(currentKey);
              }
            }

            return (
              <Stack display="flex" flexDirection="row" alignItems="center">
                {!isNil(row.regressionNumber) && (
                  <Stack mr={2}>
                    <Switch
                      className="ptd-switch"
                      disabled={disabled}
                      checked={visible}
                      onClick={() => {
                        if (onChangeVisibleChartSeries && currentKey) {
                          onChangeVisibleChartSeries(currentKey);
                        }
                      }}
                      size="small"
                    />
                  </Stack>
                )}
                <Typography>
                  {isNil(row.regressionNumber) ||
                  row?.regressionNumber === 0 ? (
                    value
                  ) : (
                    <Link
                      onClick={(event) =>
                        handleClick(event, row.regressionNumber ?? 1)
                      }
                      color="inherit"
                    >
                      {value}
                    </Link>
                  )}
                </Typography>
              </Stack>
            );
          }}
        />
        <Column
          title="Value"
          dataIndex="value"
          render={(value: string, row: TableRow) => {
            const { currentKey, visible, disabled } = regressionData(
              row?.regressionNumber
            );

            return (
              <Stack width="350px">
                <Typography>
                  {!currentKey || (visible && !disabled)
                    ? value
                    : "y = [] R²=[]"}
                </Typography>
              </Stack>
            );
          }}
        />
      </Table>
      <RangeSelectionPopover
        onClose={handleClose}
        onApply={onApply}
        onReset={onReset}
        anchorEl={popoverAnchorEl}
        range={popoverRange}
        regressionNumber={editingRegressionNumber}
        values={data?.values}
      />
    </>
  );
}
