import React, { memo } from "react";
import { Col, Row } from "antd";
import "antd/dist/reset.css";

const humanizeTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const totalRemaindingSeconds = seconds - minutes * 60;
  const remaindingSeconds = Math.floor(totalRemaindingSeconds);
  const remaindingMilis = Math.floor(
    (totalRemaindingSeconds - remaindingSeconds) * 1000
  );
  const paddedSeconds = remaindingSeconds.toString().padStart(2, "0");
  const paddedMilis = remaindingMilis.toString().padStart(3, "0");
  return `${minutes}:${paddedSeconds}:${paddedMilis}`;
};

interface VideoTimeProps {
  currentFrame: number;
  lastFrame: number;
  videoDuration: number;
}

function VideoTime({
  currentFrame,
  lastFrame,
  videoDuration: rawVideoDuration,
}: VideoTimeProps) {
  const videoDuration = rawVideoDuration / 10;
  const frameDuration = lastFrame / videoDuration;
  const humanVideoDuration = humanizeTime(videoDuration);
  const humanCurrentVideoTime = humanizeTime(currentFrame / frameDuration);

  return (
    <Row justify={"center"}>
      <Col span={24}>
        <p style={{ fontSize: 16, margin: 0 }}>
          {humanCurrentVideoTime}
          <span>/</span>
          {humanVideoDuration}
        </p>
      </Col>
      <Col span={24}>
        <p style={{ fontSize: 12, margin: 0 }}>
          FRAME &nbsp;
          {Math.floor(currentFrame)}
          <span>/</span>
          {Math.max(lastFrame, 0)}
        </p>
      </Col>
    </Row>
  );
}

export default memo(VideoTime);
