import { startsWith } from "lodash";
import * as THREE from "three";

export function triggerFileDownload(filename, text) {
  var element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(text)
  );
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export async function downloadParsedCSV(url) {
  const data = await downloadCSV(url);
  return parseCSVToArray(data);
}

export function parseCSVToArray(text, delimiter = ",") {
  // Parses CSV into an array of strings separated by comma, ignoring those enclosed in quotes.
  let p = "",
    row = [""],
    ret = [row],
    i = 0,
    r = 0,
    s = !0,
    l;

  for (l of text) {
    if ('"' === l) {
      if (s && l === p) row[i] += l;
      s = !s;
    } else if (delimiter === l && s) l = row[++i] = "";
    else if ("\n" === l && s) {
      if ("\r" === p) row[i] = row[i].slice(0, -1);
      row = ret[++r] = [(l = "")];
      i = 0;
    } else row[i] += l;
    p = l;
  }

  return ret;
}

export async function downloadCSV(url) {
  const formattedUrl = url.includes("http")
    ? url
    : startsWith(url, "/")
    ? url
    : `/${url}`;
  const response = await fetch(formattedUrl);
  const data = await response.text();

  return data;
}

export async function loadCSV(url) {
  const data = await downloadCSV(url);
  return await readCSV(data);
}

export async function readCSV(data) {
  const lines = data.split("\n");

  const header = lines[0].split(" ");
  const contentLines = lines.slice(1);

  const jointTransformations = contentLines.map((line) => {
    const elements = line.split(" ").map(parseFloat);

    const joints = {};
    for (let i = 1; i < elements.length; i += 16) {
      //const jointName = header[Math.floor(i/16)+1].slice(0, -4);
      const jointName = header[i + 1].slice(0, -4);
      //console.log(elements[i+3]);
      //console.log(elements);
      let matrixArray = [
        elements[i + 0],
        elements[i + 1],
        elements[i + 2],
        elements[i + 3],
        elements[i + 4],
        elements[i + 5],
        elements[i + 6],
        elements[i + 7],
        elements[i + 8],
        elements[i + 9],
        elements[i + 10],
        elements[i + 11],
        elements[i + 12],
        elements[i + 13],
        elements[i + 14],
        elements[i + 15],
      ];
      //console.log(matrixArray);
      let matrix = new THREE.Matrix4();
      matrix.fromArray(matrixArray);

      joints[jointName] = matrix;
    }
    return joints;
  });

  return jointTransformations;
}

export async function readCSVFromFile(files) {
  var file = files[0];
  var reader = new FileReader();
  reader.readAsText(file);
  return new Promise((resolve) => {
    reader.onload = async function (event) {
      var csvdata = event.target.result;
      resolve(await readCSV(csvdata));
    };
  });
}

export const TIME_SERIES_FRIENDLY_HEADERS = [
  "Pelvis Rotation",
  "Pelvis Lateral Tilt",
  "Pelvis Flexion",
  "Trunk Rotation",
  "Trunk Lateral Tilt",
  "Trunk Flexion",
  "Spine Rotation",
  "Spine Lateral Tilt",
  "Spine Flexion",
  "Head Rotation",
  "Head Lateral Tilt",
  "Head Flexion",
  "Neck Rotation",
  "Neck Lateral Tilt",
  "Neck Flexion",
  "Throwing Shoulder Rotation",
  "Throwing Shoulder Abduction",
  "Throwing Shoulder Horizontal Abduction",
  "Lead Shoulder Rotaiton",
  "Lead Shoulder Abduction",
  "Lead Shoulder Horizontal Abduction",
  "Throwing Elbow Flexion",
  "Throwing Wrist Flexion",
  "Throwing Wrist Pronation",
  "Throwing Wrist Ulnar Deviation",
  "Lead Elbow Flexion",
  "Lead Wrist Flexion",
  "Lead Wrist Pronation",
  "Lead Wrist Ulnar Deviation",
  "Back Hip Rotation",
  "Back Hip Abduction",
  "Back Hip Flexion",
  "Lead Hip Rotation",
  "Lead Hip Abduction",
  "Lead Hip Flexion",
  "Back Knee Flexion",
  "Back Knee Valgus",
  "Lead Knee Flexion",
  "Lead Knee Valgus",
  "Back Ankle Flexion",
  "Back Ankle Pronation",
  "Back Ankle Abduction",
  "Back Foot Rotation Angle",
  "Back Foot Elevation",
  "Lead Ankle Flexion",
  "Lead Ankle Pronation",
  "Lead Ankle Abduction",
  "Lead Foot Rotation",
  "Lead Foot Elevation",
  "Back Shin Vertical Angle (Sagittal)",
  "Back Shin Vertical Angle (Frontal)",
  "Throwing Forearm Elevation",
  "Glove Arm Rotation",
  "Lead Leg Rotation",
  "Pelvis Rotation Velocity",
  "Pelvis Lateral Tilt Velocity",
  "Pelvis Flexion Velocity",
  "Pelvis Total Angular Velocity",
  "Trunk Rotation Velocity",
  "Trunk Lateral Tilt Velocity",
  "Trunk Flexion Velocity",
  "Trunk Total Angular Velocity",
  "Spine Rotation Velocity",
  "Spine Lateral Tilt Velocity",
  "Spine Flexion Velocity",
  "Spine Total Angular Velocity",
  "Throwing Shoulder Rotation Velocity",
  "Throwing Shoulder Abduction Velocity",
  "Throwing Shoulder Horizontal Abduction Velocity",
  "Throwing Elbow Flexion Velocity",
  "Throwing Wrist Flexion Velocity",
  "Throwing Wrist Pronation Velocity",
  "Throwing Wrist Ulnar Deviation Velocity",
  "Back Hip Rotation Velocity",
  "Back Hip Abduction Velocity",
  "Back Hip Flexion Velocity",
  "Lead Hip Rotation Velocity",
  "Lead Hip Abduction Velocity",
  "Lead Hip Flexion Velocity",
  "Back Knee Extension Velocity",
  "Lead Knee Extension Velocity",
  "Back Shin Vertical Angle Velocity (Sagittal)",
  "Back Shin Vertical Angle Velocity (Frontal)",
  "Back Ankle Flexion Velocity",
  "Lead Ankle Flexion Velocity",
  "Glove Arm Yaw Velocity",
  "Glove Arm Pitch/Elevation Velocity",
  "Glove Arm Total Angular Velocity",
  "Pelvis Rotation Energy",
  "Trunk Rotation Energy",
  "Trunk Lateral Tilt Energy",
  "Trunk Flexion Energy",
  "Back Knee Flexion Energy",
  "Lead Knee Flexion Energy",
  "Throwing Shoulder Rotation Energy",
  "Throwing Elbow Flexion Energy",
  "Throwing Wrist Flexion Energy",
  "Trunk Angular Momentum (x)",
  "Trunk Angular Momentum (y)",
  "Trunk Angular Momentum (z)",
  "Trunk Total Angular Momentum",
  "Throwing Bicep Angular Momentum (x)",
  "Throwing Bicep Angular Momentum (y)",
  "Throwing Bicep Angular Momentum (z)",
  "Throwing Bicep Total Momentum",
  "Throwing Forearm Angular Momentum (x)",
  "Throwing Forearm Angular Momentum (y)",
  "Throwing Forearm Angular Momentum (z)",
  "Throwing Forearm Total Angular Momentum",
  "Lead Leg Angular Momentum (x)",
  "Lead Leg Angular Momentum (y)",
  "Lead Leg Angular Momentum (z)",
  "Lead Leg Total Angular Momentum",
  "Throwing Hand Linear Momentum (x)",
  "Throwing Hand Linear Momentum (y)",
  "Throwing Hand Linear Momentum (z)",
  "Throwing Hand Total Linear Momentum",
  "Lead Hand Linear Momentum (x)",
  "Lead Hand Linear Momentum (y)",
  "Lead Hand Linear Momentum (z)",
  "Lead Hand Total Linear Momentum",
  "Head Displacement (x)",
  "Head Displacement (y)",
  "Head Displacement (z)",
  "COM Displacement (x)",
  "COM Displacement (y)",
  "COM Displacement (z)",
  "COM Linear Velocity (x)",
  "COM Linear Velocity (x)",
  "COM Linear Velocity (x)",
  "COM Total Linear Velocity",
  "Elbow Valgus Torque",
  "Shoulder Rotation Torque",
  "Shoulder Abduction Torque",
  "Shoulder Horizontal Abduction Torque",
  "Elbow Anterior Force",
  "Elbow Distraction Force",
  "Elbow Medial Force",
  "Shoulder Anterior Force",
  "Shoulder Distraction Force",
  "Shoulder Superior Force",
];
