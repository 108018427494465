import { Player3D } from "./Player3D";
import * as THREE from "three";
let mat = new THREE.Matrix4();
let dcm;
let dcmDistal;
let nullPosition = new THREE.Vector3();
let skeletonColor = 0x2c2c2c;
nullPosition.x = 0;
nullPosition.y = -100;
nullPosition.z = 0;
let a_1 = new THREE.Vector3();
let b_1 = new THREE.Vector3();
let c_1 = new THREE.Vector3();

let a_2 = new THREE.Vector3();
let b_2 = new THREE.Vector3();
let c_2 = new THREE.Vector3();
let p_1 = new THREE.Vector3();
let p_2 = new THREE.Vector3();
let scale2 = 1;

let pSweet = 0.8;
let pSp = new THREE.Vector3();

export class AbstractGfx {
  initStyles(gui, world) {
    gui.domElement.style.position = "absolute";
    gui.domElement.style.top = "10px";
    gui.domElement.style.left = "100px";
    if (world.hideControlsGui) {
      gui.domElement.style.display = "none";
    }
  }

  translatePerson(val, world, type) {
    for (let key in world) {
      if (world[key] instanceof Player3D) {
        if (key == "person") {
          if (type == "X") {
            world[key].group.position.x = val;
          } else {
            world[key].group.position.z = val;
          }
        }
      }
    }
  }
  updateLineVisibility(scrubberValue, world) {
    for (let key in world) {
      if (world[key] instanceof Player3D) {
        if (world[key].guiData.segmentLineToggles) {
          world[key].guiData.segmentLineToggles.drawn.forEach(
            (drawn, index) => {
              const visible =
                world[key].guiData.segmentLineToggles.visible[index];
              if (drawn === true && visible === true) {
                updateLinePathGeometryForFrame(
                  scrubberValue,
                  world[key].group,
                  world[key].jointCoordinates,
                  world[key].guiData.segmentLineToggles.names[index],
                  world[key].guiData.segmentLineToggles.uuids[index]
                );
              } else {
                let toggleMesh = findChildByUUID(
                  world[key].group,
                  world[key].guiData.segmentLineToggles.uuids[index]
                );
                if (toggleMesh) {
                  toggleMesh.visible = false;
                  world[key].guiData.segmentLineToggles.paths[
                    index
                  ].visible = false;
                  world[key].guiData.segmentLineToggles.visible[index] = false;
                }
              }
            }
          );
        }
      }
    }
  }

  changeSyncReferenceKeyFrame(world, syncReferenceFrame) {
    world.changeSyncReferenceKeyFrame(syncReferenceFrame);
  }

  toggleReflection(world) {
    for (let key in world) {
      if (key === "person") {
        let count = 0;
        for (let jointKey in world[key].joints) {
          if (count === 0) {
            count++;
            let joint = world[key].joints[jointKey];
            let reflectedJointCoordinates = reflectJointCoordinates(
              joint.jointCoordinates
            );
            joint.jointCoordinates = reflectedJointCoordinates;
          }
        }
        world.currentFrame = world.currentFrame + 1;
        if (world.currentFrame >= world.endFrame) {
          world.currentFrame = world.currentFrame - 2;
        }
      }
    }
  }
  toggleLinePaths(motionName2, rotation, world, value) {
    const nPlayers = getNumPlayers(world);
    let motionName = [];
    if (motionName2.endsWith("2")) {
      motionName = "Spine";
      rotation = 90.0;
    } else {
      motionName = motionName2;
      rotation = 0;
    }
    let scrubberValue = world.currentFrame;
    for (let key in world) {
      if (world[key] instanceof Player3D) {
        const index =
          world[key].guiData.segmentLineToggles.names.indexOf(motionName2);
        let clr = world[key].guiData.segmentLineToggles.colors[index];
        if (typeof clr === "string") {
          clr = clr.replace("#", "0x");
        }
        if (nPlayers > 1 && world.shouldRenderComparingPerson) {
          clr = world[key].skeletonColor;
          if (typeof clr === "string") {
            clr = clr.replace("#", "0x");
          }
        }

        if (world[key].guiData.segmentLineToggles.drawn[index]) {
          let toggleMesh = findChildByUUID(
            world[key].group,
            world[key].guiData.segmentLineToggles.uuids[index]
          );
          toggleMesh.visible = value;
          world[key].guiData.segmentLineToggles.paths[index].visible = value;

          world[key].guiData.segmentLineToggles.visible[index] = value;
        } else if (
          !world[key].guiData.segmentLineToggles.drawn[index] &&
          value === true
        ) {
          world[key].guiData.segmentLineToggles.uuids[index] =
            drawLinePathInvisible(
              world[key],
              motionName,
              clr,
              scrubberValue,
              rotation,
              index
            );

          world[key].guiData.segmentLineToggles.drawn[index] = true;
          world[key].guiData.segmentLineToggles.visible[index] = true;
        }
      }
    }
  }
  toggleMotionPaths(motionName, world, value) {
    const nPlayers = getNumPlayers(world);

    for (let key in world) {
      if (world[key] instanceof Player3D) {
        const index =
          world[key].guiData.motionPathsToggles.names.indexOf(motionName);
        let clr = world[key].guiData.motionPathsToggles.colors[index];
        if (typeof clr === "string") {
          clr = clr.replace("#", "0x");
        }
        if (nPlayers > 1 && world.shouldRenderComparingPerson) {
          clr = world[key].skeletonColor;
          if (typeof clr === "string") {
            clr = clr.replace("#", "0x");
          }
        }

        if (world[key].guiData.motionPathsToggles.drawn[index]) {
          const uuidsArray = world[key].guiData.motionPathsToggles.uuids[index];

          if (Array.isArray(uuidsArray) && uuidsArray.length > 0) {
            for (let i = 0; i < uuidsArray.length; i++) {
              const uuid = uuidsArray[i];
              let toggleMesh = findChildByUUID(world[key].group, uuid);
              if (toggleMesh) {
                toggleMesh.visible = !toggleMesh.visible;
              }
            }
          }
          world[key].guiData.motionPathsToggles.visible[index] = value;
        } else if (
          !world[key].guiData.motionPathsToggles.drawn[index] &&
          value === true
        ) {
          world[key].guiData.motionPathsToggles.uuids[index] =
            drawMotionPathInvisible(
              world[key],
              world[key].jointCoordinates,
              motionName,
              clr,
              index
            );

          world[key].guiData.motionPathsToggles.drawn[index] = true;
          world[key].guiData.motionPathsToggles.visible[index] = true;
        }
      }
    }
  }
  togglePlanePathVisibility(world, value) {
    const nPlayers = getNumPlayers(world);

    for (let key in world) {
      if (world[key] instanceof Player3D) {
        let clr = "0x0066ff";
        if (typeof clr === "string") {
          clr = clr.replace("#", "0x");
        }
        if (nPlayers > 1 && world.shouldRenderComparingPerson) {
          clr = world[key].skeletonColor;
          if (typeof clr === "string") {
            clr = clr.replace("#", "0x");
          }
        }
        let ind = 0; // 0 = plane, 1 = ope
        if (world[key].guiData.swingPlaneToggles.drawn[ind]) {
          const uuidsArray =
            world[key].guiData.swingPlaneToggles.uuidsPlane[ind];

          if (Array.isArray(uuidsArray) && uuidsArray.length > 0) {
            for (let i = 0; i < uuidsArray.length; i++) {
              const uuid = uuidsArray[i];
              let toggleMesh = findChildByUUID(world[key].group, uuid);
              if (toggleMesh) {
                toggleMesh.visible = value;
              }
            }
          }
          const uuidsArrayComponents =
            world[key].guiData.swingPlaneToggles.uuidsComponents[ind];

          if (
            Array.isArray(uuidsArrayComponents) &&
            uuidsArrayComponents.length > 0
          ) {
            for (let i = 0; i < uuidsArrayComponents.length; i++) {
              const uuid = uuidsArrayComponents[i];
              let toggleMesh = findChildByUUID(world[key].group, uuid);
              if (toggleMesh) {
                toggleMesh.visible = value;
              }
            }
          }

          world[key].guiData.swingPlaneToggles.visible[ind] = value;
        } else if (
          !world[key].guiData.swingPlaneToggles.drawn[ind] &&
          value === true
        ) {
          const { name, distalName } = this.getNameAndDistalNameForPlaneToggle(
            world,
            key
          );
          world[key].guiData.swingPlaneToggles.uuidsPlane[ind] =
            drawBatPathInvisible(
              world[key],
              world[key].jointCoordinates,
              name,
              distalName,
              clr,
              ind
            );

          world[key].guiData.swingPlaneToggles.drawn[ind] = true;
          world[key].guiData.swingPlaneToggles.visible[ind] = true;
        }
      }
    }
  }
}

export function drawLinePathInvisible(
  world,
  motionName,
  motionColor,
  scrubberValue,
  rotation,
  index
) {
  let jointCoordinates = world.jointCoordinates;
  let cylMaterial = new THREE.MeshBasicMaterial({
    color: skeletonColor,
  });
  cylMaterial.blending = THREE.NormalBlending;

  let meshCylLine = createLinePathGeometryForFrame(
    scrubberValue,
    jointCoordinates,
    motionName,
    cylMaterial,
    rotation
  );
  meshCylLine.material.color.setHex(motionColor);
  meshCylLine.visible = true;
  meshCylLine.name = "JointLine";
  world.group.add(meshCylLine);
  world.guiData.segmentLineToggles.uuids[index] = meshCylLine.uuid;
  return meshCylLine.uuid;
}

export function drawBatPathComponents(
  world,
  jointCoordinates,
  name,
  distalName,
  clr,
  ind
) {
  let lineMaterial = new THREE.MeshBasicMaterial({
    color: 0xffffff,
    transparent: true,
    opacity: 0.1,
    side: THREE.DoubleSide,
  });
  lineMaterial.color.setHex(clr);
  let sphereMaterial = new THREE.MeshBasicMaterial({
    color: 0x4a77ff,
    transparent: true,
    opacity: 0.1,
    side: THREE.DoubleSide,
  });
  sphereMaterial.color.setHex(clr);

  let numFrames = jointCoordinates.length - 1;
  let skip = 2;
  for (let i = 2; i < numFrames - 1; i = i + skip) {
    if (jointCoordinates[i][name]?.elements) {
      for (let j = 0; j < 1; j++) {
        let geometry = createLineComponentGeometryForFrame(
          i,
          jointCoordinates,
          name,
          distalName
        );
        let line = new THREE.Line(geometry, lineMaterial);
        line.visible = true;
        world.group.add(line);
        world.guiData.swingPlaneToggles.uuidsComponents[ind].push(line.uuid);
      }
      for (let j = 0; j < 3; j++) {
        let geometry = createLineBackComponentGeometryForFrame(
          i,
          j,
          jointCoordinates,
          name,
          distalName,
          skip
        );
        let line = new THREE.Line(geometry, lineMaterial);
        line.visible = true;
        world.group.add(line);
        world.guiData.swingPlaneToggles.uuidsComponents[ind].push(line.uuid);
      }
      for (let j = 0; j < 3; j++) {
        let sphereOut = createSphereComponentGeometryForFrame(
          i,
          j,
          jointCoordinates,
          name,
          distalName
        );
        let geometry = sphereOut.geo;
        pSp = sphereOut.pOut;
        let sphere = new THREE.Mesh(geometry, sphereMaterial);
        sphere.position.set(pSp.x, pSp.y, pSp.z);
        sphere.visible = true;
        world.group.add(sphere);
        world.guiData.swingPlaneToggles.uuidsComponents[ind].push(sphere.uuid);
      }
    }
  }
  return world.guiData.swingPlaneToggles.uuidsComponents[ind];
}

export function createSphereComponentGeometryForFrame(
  i,
  j,
  jointCoordinates,
  name,
  distalName
) {
  dcm = jointCoordinates[i][name].elements;
  dcmDistal = jointCoordinates[i][distalName].elements;
  a_1.set(dcm[3] * scale2, dcm[7] * scale2, dcm[11] * scale2);
  b_1.set(dcmDistal[3] * scale2, dcmDistal[7] * scale2, dcmDistal[11] * scale2);
  c_1.set(
    a_1.x * (1 - pSweet) + b_1.x * pSweet,
    a_1.y * (1 - pSweet) + b_1.y * pSweet,
    a_1.z * (1 - pSweet) + b_1.z * pSweet
  );
  const pOut = new THREE.Vector3();
  const sphereGeometry = new THREE.SphereGeometry(0.008, 15, 15);
  if (j == 1) {
    pOut.set(a_1.x, a_1.y, a_1.z);
  } else if (j == 2) {
    pOut.set(b_1.x, b_1.y, b_1.z);
  } else {
    pOut.set(c_1.x, c_1.y, c_1.z);
  }

  return { geo: sphereGeometry, pOut };
}
export function createLineBackComponentGeometryForFrame(
  i,
  j,
  jointCoordinates,
  name,
  distalName,
  skip
) {
  let startInd = i - skip;
  if (startInd < 0) {
    startInd = 0;
  }
  dcm = jointCoordinates[startInd][name].elements;
  dcmDistal = jointCoordinates[startInd][distalName].elements;
  a_1.set(dcm[3] * scale2, dcm[7] * scale2, dcm[11] * scale2);
  b_1.set(dcmDistal[3] * scale2, dcmDistal[7] * scale2, dcmDistal[11] * scale2);
  dcm = jointCoordinates[i][name].elements;
  dcmDistal = jointCoordinates[i][distalName].elements;
  a_2.set(dcm[3] * scale2, dcm[7] * scale2, dcm[11] * scale2);
  b_2.set(dcmDistal[3] * scale2, dcmDistal[7] * scale2, dcmDistal[11] * scale2);
  c_1.set(
    a_1.x * (1 - pSweet) + b_1.x * pSweet,
    a_1.y * (1 - pSweet) + b_1.y * pSweet,
    a_1.z * (1 - pSweet) + b_1.z * pSweet
  );
  c_2.set(
    a_2.x * (1 - pSweet) + b_2.x * pSweet,
    a_2.y * (1 - pSweet) + b_2.y * pSweet,
    a_2.z * (1 - pSweet) + b_2.z * pSweet
  );
  if (j == 1) {
    p_1.set(a_1.x, a_1.y, a_1.z);
    p_2.set(a_2.x, a_2.y, a_2.z);
  } else if (j == 2) {
    p_1.set(b_1.x, b_1.y, b_1.z);
    p_2.set(b_2.x, b_2.y, b_2.z);
  } else {
    p_1.set(c_1.x, c_1.y, c_1.z);
    p_2.set(c_2.x, c_2.y, c_2.z);
  }
  const geometry = new THREE.BufferGeometry().setFromPoints([p_1, p_2]);

  return geometry;
}
export function createLineComponentGeometryForFrame(
  i,
  jointCoordinates,
  name,
  distalName
) {
  dcm = jointCoordinates[i][name].elements;
  dcmDistal = jointCoordinates[i][distalName].elements;
  a_1.set(dcm[3] * scale2, dcm[7] * scale2, dcm[11] * scale2);
  b_1.set(dcmDistal[3] * scale2, dcmDistal[7] * scale2, dcmDistal[11] * scale2);
  const geometry = new THREE.BufferGeometry().setFromPoints([a_1, b_1]);

  return geometry;
}

export function updateLinePathGeometryForFrame(
  i,
  group,
  jointCoordinates,
  name2,
  uuids
) {
  let name = [];
  let rotation = 0;
  if (name2.endsWith("2")) {
    name = "Spine";
    rotation = 90;
  } else {
    name = name2;
  }
  if (jointCoordinates[i][name].elements) {
    const dcm = jointCoordinates[i][name].elements;
    const a_1 = new THREE.Vector3(
      dcm[3] * scale2,
      dcm[7] * scale2,
      dcm[11] * scale2
    );

    const quaternion = new THREE.Quaternion().setFromRotationMatrix(
      new THREE.Matrix4().set(
        dcm[0],
        dcm[1],
        dcm[2],
        0,
        dcm[4],
        dcm[5],
        dcm[6],
        0,
        dcm[8],
        dcm[9],
        dcm[10],
        0,
        0,
        0,
        0,
        1
      )
    );

    if (rotation === 90) {
      const additionalRotation = new THREE.Quaternion().setFromAxisAngle(
        new THREE.Vector3(1, 0, 0.0),
        Math.PI / 2
      );
      quaternion.multiply(additionalRotation);
    } else {
      const additionalRotation = new THREE.Quaternion().setFromAxisAngle(
        new THREE.Vector3(0, 0, 1),
        Math.PI / 2
      );
      quaternion.multiply(additionalRotation);
    }
    let cylGeo = findChildByUUID(group, uuids);
    cylGeo.position.copy(a_1);
    cylGeo.quaternion.copy(quaternion);
    cylGeo.updateMatrix();
  }
}

export function getNumPlayers(world) {
  let count = 0;
  for (let key in world) {
    if (world[key] instanceof Player3D) {
      count++;
    }
  }
  return count;
}

export function findChildByUUID(group, uuid) {
  let foundChild = null;
  group.traverse((child) => {
    if (child.uuid === uuid) {
      foundChild = child;
    }
  });
  return foundChild;
}

export function createLinePathGeometryForFrame(
  i,
  jointCoordinates,
  name,
  cylMaterial,
  rotation
) {
  dcm = jointCoordinates[i][name].elements;
  a_1.set(dcm[3] * scale2, dcm[7] * scale2, dcm[11] * scale2);
  const distance = 1.5;
  mat.set(
    dcm[0],
    dcm[1],
    dcm[2],
    dcm[3],
    dcm[4],
    dcm[5],
    dcm[6],
    dcm[7],
    dcm[8],
    dcm[9],
    dcm[10],
    dcm[11],
    0,
    0,
    0,
    1
  );
  const quaternion = new THREE.Quaternion().setFromRotationMatrix(
    new THREE.Matrix4().set(
      dcm[0],
      dcm[1],
      dcm[2],
      0,
      dcm[4],
      dcm[5],
      dcm[6],
      0,
      dcm[8],
      dcm[9],
      dcm[10],
      0,
      0,
      0,
      0,
      1
    )
  );

  if (rotation === 90) {
    const additionalRotation = new THREE.Quaternion().setFromAxisAngle(
      new THREE.Vector3(1, 0, 0),
      Math.PI / 2
    );
    quaternion.multiply(additionalRotation);
  } else {
    const additionalRotation = new THREE.Quaternion().setFromAxisAngle(
      new THREE.Vector3(0, 0, 1),
      Math.PI / 2
    );
    quaternion.multiply(additionalRotation);
  }

  let radius = 0.01;
  const geometry = new THREE.CylinderGeometry(radius, radius, distance, 32);
  const cylinder = new THREE.Mesh(geometry, cylMaterial);
  cylinder.position.copy(a_1);
  cylinder.quaternion.copy(quaternion);
  cylinder.updateMatrix();
  return cylinder;
}

export function drawMotionPathInvisible(
  world,
  jointCoordinates,
  motionName,
  motionColor,
  index
) {
  let cylMaterial = new THREE.MeshBasicMaterial();
  cylMaterial.color.setHex(motionColor);
  cylMaterial.blending = THREE.NormalBlending;
  let numFrames = jointCoordinates.length - 2;

  for (let i = 1; i < numFrames - 1; i++) {
    if (jointCoordinates[i][motionName]?.elements) {
      let meshCyl = createMotionPathGeometryForFrame(
        i,
        jointCoordinates,
        motionName,
        cylMaterial
      );
      meshCyl.visible = true;
      world.group.add(meshCyl);
      world.guiData.motionPathsToggles.uuids[index].push(meshCyl.uuid);
    }
  }
  return world.guiData.motionPathsToggles.uuids[index];
}

export function createMotionPathGeometryForFrame(
  i,
  jointCoordinates,
  name,
  cylMaterial
) {
  dcm = jointCoordinates[i - 1][name].elements;
  a_1.set(dcm[3] * scale2, dcm[7] * scale2, dcm[11] * scale2);

  dcm = jointCoordinates[i][name].elements;
  a_2.set(dcm[3] * scale2, dcm[7] * scale2, dcm[11] * scale2);
  const distance = a_1.distanceTo(a_2);
  const midpoint = new THREE.Vector3().addVectors(a_1, a_2).multiplyScalar(0.5);
  let radius = 0.005;
  const geometry = new THREE.CylinderGeometry(radius, radius, distance, 32);
  const cylinder = new THREE.Mesh(geometry, cylMaterial);
  cylinder.position.copy(midpoint);
  const direction = new THREE.Vector3().subVectors(a_2, a_1).normalize();
  const up = new THREE.Vector3(0, 1, 0);
  cylinder.quaternion.setFromUnitVectors(up, direction);

  return cylinder;
}

export function drawBatPathInvisible(
  world,
  jointCoordinates,
  name,
  distalName,
  clr,
  ind
) {
  let numFrames = jointCoordinates.length - 1;
  let planeMaterial = new THREE.MeshBasicMaterial({
    transparent: true,
    opacity: 0.2,
    depthWrite: false,
    side: THREE.DoubleSide,
  });
  planeMaterial.color.setHex(clr);
  for (let i = 1; i < numFrames - 1; i++) {
    if (jointCoordinates[i][name]?.elements) {
      let geometry = createGeometryForFrame(
        i,
        jointCoordinates,
        name,
        distalName
      );
      let mesh = new THREE.Mesh(geometry, planeMaterial);
      mesh.visible = true;
      world.group.add(mesh);
      world.guiData.swingPlaneToggles.uuidsPlane[ind].push(mesh.uuid);
    }
  }
  return world.guiData.swingPlaneToggles.uuidsPlane[ind];
}
export function createGeometryForFrame(i, jointCoordinates, name, distalName) {
  const geometry = new THREE.BufferGeometry();
  dcm = jointCoordinates[i - 1][name].elements;
  dcmDistal = jointCoordinates[i - 1][distalName].elements;
  a_1.set(dcm[3] * scale2, dcm[7] * scale2, dcm[11] * scale2);
  b_1.set(dcmDistal[3] * scale2, dcmDistal[7] * scale2, dcmDistal[11] * scale2);
  dcm = jointCoordinates[i][name].elements;
  dcmDistal = jointCoordinates[i][distalName].elements;
  a_2.set(dcm[3] * scale2, dcm[7] * scale2, dcm[11] * scale2);
  b_2.set(dcmDistal[3] * scale2, dcmDistal[7] * scale2, dcmDistal[11] * scale2);
  const vertices = new Float32Array([
    a_1.x,
    a_1.y,
    a_1.z,
    b_1.x,
    b_1.y,
    b_1.z,
    b_2.x,
    b_2.y,
    b_2.z,
    a_2.x,
    a_2.y,
    a_2.z,
  ]);
  geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3));
  geometry.setIndex([0, 1, 2, 2, 3, 0]);

  return geometry;
}
export function reflectTransformAboutXAxis(matrix) {
  let reflectedElements = matrix.elements;
  reflectedElements[0] = -reflectedElements[0];
  reflectedElements[10] = -reflectedElements[10];
  reflectedElements[11] = -reflectedElements[11];
  return { elements: reflectedElements };
}

export function reflectJointCoordinates(jointCoordinates) {
  return jointCoordinates.map((sample) => {
    let reflectedSample = {};
    for (let jointName in sample) {
      reflectedSample[jointName] = reflectTransformAboutXAxis(
        sample[jointName]
      );
    }
    return reflectedSample;
  });
}
