interface StrikeDotsProps {
  x: number;
  y: number;
}

function useStrikeDots({ x, y }: StrikeDotsProps) {
  const pxToLocRatio = 0.09;
  const pzToLocRatio = 0.09;
  const CENTER_PX = 7.8;
  let finalX = 0;
  let pxAdj = Math.abs(x / pxToLocRatio);
  if (x === 0) {
    finalX = 0;
  } else if (x < 0) {
    finalX = 0 - pxAdj;
  } else {
    finalX = 0 + pxAdj;
  }
  finalX += CENTER_PX - 3.1;

  const CENTER_PZ = 10;
  let finalY = 0;
  let pzZero = 2.5 - y;
  let pzAdj = Math.abs(pzZero / pzToLocRatio);
  if (pzZero === 0) {
    finalY = 0;
  } else if (pzZero < 0) {
    finalY = 0 - pzAdj;
  } else {
    finalY = 0 + pzAdj;
  }
  finalY += CENTER_PZ - 3.1;

  return {
    finalY,
    finalX,
  };
}

export { useStrikeDots };
