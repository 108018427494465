import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LOAD_STATUS } from "../../constants";

const initialState = {
  user: {
    role: {
      name: "",
      department: "",
      id: -1,
      permissions: [],
    },
    userName: "",
    preference: {},
    id: -1,
    email: -1,
    loadStatus: LOAD_STATUS.UNINITIALIZED,
    scoutingPermissions: {},
  },

  impersonatedUser: {
    role: {
      name: "",
      department: "",
      id: -1,
      permissions: [],
    },
    userName: "",
    id: -1,
    email: -1,
    loadStatus: LOAD_STATUS.UNINITIALIZED,
    scoutingPermissions: {},
  },
};

const fetchUserRole = async (args) => {
  const { email, skylineApi } = args;
  const response = await skylineApi.get(`/api/user?email=${email}`);
  return response.data;
};

/**
 * @param {Object} apiUserInfo, the result of a call to skyline api fetchUserRole()
 * @returns {Object} if role info is present, an object, else null. Object has the shape:
 * {
 *   name: <str>,
 *   id: <int>,
 *   department: <string>
 *   permissions: list of objects
 * }
 */
export const parseApiRoleInfo = (apiUserInfo) => {
  if (apiUserInfo.role) {
    return {
      name: apiUserInfo.role.name,
      id: apiUserInfo.role.id,
      department: apiUserInfo.role.department,
      permissions: apiUserInfo.role.permissions,
    };
  }

  return null;
};
const parseApiPreferenceInfo = (preference) => {
  if (preference) {
    return preference;
  }
  return {};
};

const fetchOpenImpersonations = async (args) => {
  const { email, skylineApi } = args;
  const response = await skylineApi.get(`/api/user/impersonate?email=${email}`);
  return response.data;
};

/**
 * Returns scoutingPermissions response data, or undefined
 * if there is no response data or an error in the request.
 * The error isn't raised further, because auth logic is
 * checked elsewhere.
 * @returns {object|undefined}
 */
const fetchUserScoutingPermissions = async (args) => {
  const { email, skylineApi } = args;
  let response;
  try {
    response = await skylineApi.get(`/api/user/scoutingPermissions/${email}`);
  } catch (e) {
    // Log debugging message, but take no further action.
    response = undefined;
  }
  return response?.data;
};

const parseApiScoutingInfo = async (permissions) => {
  if (permissions) {
    return {
      ama_access: permissions.ama_access || null,
      int_access: permissions.int_access || null,
      pro_access: permissions.pro_access || null,
    };
  }
  return {};
};

export const fetchUserLoginInfo = createAsyncThunk(
  "app/fetchUserLoginInfo",
  async (args) => {
    try {
      const { email, skylineApi } = args;

      // Fetch user info and impersonated user info
      const [userInfo, impersonatedUserInfo] = await Promise.all([
        fetchUserRole({ email, skylineApi }),
        fetchOpenImpersonations({ email, skylineApi }),
      ]);

      const emailToUse = impersonatedUserInfo.email || email;

      const [userScoutingPermissionInfo] = await Promise.all([
        fetchUserScoutingPermissions({
          email: emailToUse,
          skylineApi,
        }),
      ]);
      const scoutingPermissions = await parseApiScoutingInfo(
        userScoutingPermissionInfo
      );

      const user = buildUserObject(userInfo, email, scoutingPermissions);

      const impersonatedUser = impersonatedUserInfo.id
        ? buildUserObject(
            impersonatedUserInfo,
            impersonatedUserInfo.email,
            scoutingPermissions
          )
        : null;

      return { user, impersonatedUser };
    } catch (error) {
      console.error("Error fetching user login info:", error);
      throw error;
    }
  }
);

const buildUserObject = (userInfo, email, scoutingPermissions) => {
  return {
    userName: getUserFullName(userInfo),
    role: parseApiRoleInfo(userInfo),
    preference: parseApiPreferenceInfo(userInfo?.preference),
    id: userInfo?.id || -1,
    email,
    playerId: userInfo?.playerId,
    scoutingPermissions: scoutingPermissions || {},
    playerInfo: userInfo.player,
  };
};

const getUserFullName = (userInfo) => {
  if (userInfo?.player?.searchable_name) {
    return userInfo.player.searchable_name;
  }
  return `${userInfo?.firstName} ${userInfo?.lastName}`;
};

export const startImpersonation = createAsyncThunk(
  "app/startImpersonation",
  async (args) => {
    const { targetEmail, skylineApi } = args;
    const response = await skylineApi.post(`/api/user/impersonate`, {
      targetEmail,
    });

    const targetRoleInfo = response.data;

    const result = {
      role: parseApiRoleInfo(targetRoleInfo),
      id: targetRoleInfo.id,
      email: targetEmail,
    };
    return result;
  }
);

export const stopImpersonation = createAsyncThunk(
  "app/stopImpersonation",
  async (args) => {
    const { realEmail, skylineApi } = args;
    await skylineApi.post(`/api/user/stopImpersonate`, {
      realEmail,
    });
  }
);

export const updateAccessibility = (newAccessibility) => ({
  type: "UPDATE_ACCESSIBILITY",
  payload: newAccessibility,
});

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setApiToken: (state, action) => ({
      ...state,
      apiToken: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserLoginInfo.pending, (state) => {
        state.user.loadStatus = LOAD_STATUS.LOADING;
      })
      .addCase(fetchUserLoginInfo.fulfilled, (state, action) => {
        const { user, impersonatedUser } = action.payload;
        state.user.role = user.role;
        state.user.id = user.id;
        state.user.email = user.email;
        state.user.preference = user.preference;
        state.user.userName = user.userName;
        state.user.loadStatus = LOAD_STATUS.READY;
        state.user.playerId = user.playerId;
        state.user.scoutingPermissions = user.scoutingPermissions;
        state.user.playerInfo = user.playerInfo;
        if (impersonatedUser) {
          state.impersonatedUser.role = impersonatedUser.role;
          state.impersonatedUser.id = impersonatedUser.id;
          state.impersonatedUser.email = impersonatedUser.email;
          state.impersonatedUser.loadStatus = LOAD_STATUS.READY;
          state.impersonatedUser.userName = impersonatedUser.userName;
          state.impersonatedUser.playerId = impersonatedUser.playerId;
          state.user.scoutingPermissions = user.scoutingPermissions;
          state.impersonatedUser.scoutingPermissions =
            impersonatedUser.scoutingPermissions;
          state.impersonatedUser.playerInfo = impersonatedUser.playerInfo;
        }
      })
      .addCase(fetchUserLoginInfo.rejected, (state) => {
        state.user.loadStatus = LOAD_STATUS.ERROR;
      })
      .addCase(startImpersonation.pending, (state) => {
        state.impersonatedUser.loadStatus = LOAD_STATUS.LOADING;
      })
      .addCase(startImpersonation.fulfilled, (state, action) => {
        state.impersonatedUser.role = action.payload.role;
        state.impersonatedUser.id = action.payload.id;
        state.impersonatedUser.email = action.payload.email;
        state.impersonatedUser.loadStatus = LOAD_STATUS.READY;
      })
      .addCase(startImpersonation.rejected, (state) => {
        state.impersonatedUser.loadStatus = LOAD_STATUS.ERROR;
      })
      .addCase(stopImpersonation.pending, (state) => {
        state.impersonatedUser.loadStatus = LOAD_STATUS.LOADING;
      })
      .addCase(stopImpersonation.fulfilled, (state) => {
        state.impersonatedUser.role = initialState.impersonatedUser.role;
        state.impersonatedUser.id = initialState.impersonatedUser.id;
        state.impersonatedUser.email = initialState.impersonatedUser.email;
        state.impersonatedUser.loadStatus = LOAD_STATUS.READY;
      })
      .addCase(stopImpersonation.rejected, (state) => {
        state.impersonatedUser.loadStatus = LOAD_STATUS.ERROR;
      })
      .addCase("UPDATE_ACCESSIBILITY", (state, action) => {
        if (state && state.user && state.user.preference) {
          state.user.preference.accessibility = action.payload;
        }
      });
  },
});

export default appSlice;
