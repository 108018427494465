import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { BELL_CHARTS_POINTS } from "./mockBellChart";
import { Grid } from "@mui/material";
import { checkPlayerSummaryValue } from "../../utils/metrics";

interface BellChartProps {
  value: any;
  color?: string;
  min?: number;
  max?: number;
}

const BellChart = ({ value: valueProp, color, min = 20 }: BellChartProps) => {
  const value = checkPlayerSummaryValue(valueProp) ? valueProp : "-";
  const data = BELL_CHARTS_POINTS;
  const fixedValue = value - min;

  const chartOptions: Highcharts.Options = {
    chart: { type: "areaspline", height: 90, marginBottom: 0, marginTop: 0 },
    credits: { enabled: false },
    tooltip: { enabled: false },
    title: { text: "" },
    legend: { enabled: false },
    xAxis: { visible: false },
    yAxis: { visible: false },
    plotOptions: {
      bellcurve: { color },
      series: {
        animation: false,
        enableMouseTracking: false,
        threshold: 0,
        marker: {
          fillColor: "transparent",
        },
      },
    },
    series: [
      {
        type: "areaspline",
        color: "grey",
        data,
        zoneAxis: "x",
        zones: [
          {
            color,
            value: fixedValue,
          },
        ],
      },
    ],
  };

  return (
    <Grid container flexDirection="column" mt={-4}>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </Grid>
  );
};

export { BellChart };
