import React from "react";
import Scrollable from "../../common/Scrollable";
import { Tabs } from "antd";
import ThrowingArm from "./BodyPart/ThrowingArm";
import GloveArm from "./BodyPart/GloveArm";
import TrunkAndPelvis from "./BodyPart/TrunkAndPelvis";
import LeadLeg from "./BodyPart/LeadLeg";
import BackLeg from "./BodyPart/BackLeg";
import { getIsPitching } from "../../../utils/motionType";
import { useSelector } from "react-redux";
import { selectSelectedMotionType } from "../../../redux/selectors";
import { BodyPartTabs } from "../../../services/mockData";
import BattingContainer from "./BodyPart/BattingContainer";

const pitchTabs = [
  { label: "Throwing Arm", children: <ThrowingArm /> },
  { label: "Glove Arm", children: <GloveArm /> },
  { label: "Trunk & Pelvis", children: <TrunkAndPelvis /> },
  { label: "Lead Leg", children: <LeadLeg /> },
  { label: "Back Leg", children: <BackLeg /> },
];

const battingTabs = [
  {
    label: "Back Leg",
    children: <BattingContainer tab={BodyPartTabs.BackLeg} />,
  },
  {
    label: "Lead Leg",
    children: <BattingContainer tab={BodyPartTabs.LeadLeg} />,
  },
  {
    label: "Pelvis/Trunk",
    children: <BattingContainer tab={BodyPartTabs.PelvisTrunk} />,
  },
  {
    label: "Spine & Shoulder",
    children: <BattingContainer tab={BodyPartTabs.SpineAndShoulder} />,
  },
  {
    label: "Elbow & Wrist",
    children: <BattingContainer tab={BodyPartTabs.ElbowAndWrist} />,
  },
  {
    label: "Head & Hand",
    children: <BattingContainer tab={BodyPartTabs.HeadAndHand} />,
  },
];

const BodyPart = (props: any) => {
  const motionType = useSelector(selectSelectedMotionType);
  const isPitching = getIsPitching(motionType);
  const currentTabs = isPitching ? pitchTabs : battingTabs;

  return (
    <Tabs
      items={currentTabs.map(({ label, children }, index) => {
        const childrenWithProps = {
          ...children,
          props: { ...children.props, ...props },
        };

        return {
          label,
          key: `${label}-${index}`,
          children: <Scrollable>{childrenWithProps}</Scrollable>,
        };
      })}
    />
  );
};

export default BodyPart;
