import React from "react";
import DiscreteTrialEvent from "./ThrowEvents/DiscreteTrialEvent";
import DiscreteTrialEvents from "./DiscreteTrialEvents";

const SwingEvents = () => {
  const tabs = [
    {
      id: "stance",
      label: "Stance",
      children: <DiscreteTrialEvent event="SET" />,
    },
    {
      id: "maxHeelHeight",
      label: "Max Heel Height",
      children: <DiscreteTrialEvent event="MHH" />,
    },
    {
      id: "leadFootPlant",
      label: "Lead foot plant",
      children: <DiscreteTrialEvent event="LFP" />,
    },
    {
      id: "downSwing",
      label: "Down-Swing",
      children: <DiscreteTrialEvent event="DS" />,
    },
    {
      id: "flatBat",
      label: "Flat Bat",
      children: <DiscreteTrialEvent event="FB" />,
    },
    {
      id: "ballContact",
      label: "Ball Contact",
      children: <DiscreteTrialEvent event="BC" />,
    },
    {
      id: "followThrough",
      label: "Follow Through",
      children: <DiscreteTrialEvent event="FT" />,
    },
  ];
  return <DiscreteTrialEvents tabs={tabs} />;
};

export default SwingEvents;
