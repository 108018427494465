import { AbstractGfx } from "./guiGraphics3D";

let swingPlaneFolder = [];
let motionPathFolder = [];
let segmentLineFolder = [];
let syncFolder = [];
const syncFolderParams = {
  translateX: 0,
  translateY: 0,
  sync: "BC",
  reflect: false,
};

let toggleHeadLineController,
  toggleShoulderLineController,
  toggleSpineLineController,
  toggleHipLineController;

let toggleHeadMotionPathController,
  toggleHipMotionPathController,
  toggleKnobMotionPathController,
  toggleTipMotionPathController,
  toggleBallMotionPathController;

let toggleSwingPlaneController;
const swingPlaneParams = {
  toggleBatPath: false,
  togglePerfectPath: false,
};

const motionPathParams = {
  toggleHeadPath: false,
  toggleHipPath: false,
  toggleKnobPath: false,
  toggleTipPath: false,
  toggleBallPath: false,
};

const segmentLineParms = {
  toggleShoulderLine: false,
  toggleHipLine: false,
  toggleHeadLine: false,
  toggleSpineLine: false,
  toggleShoulderPlane: false,
  toggleHipPlane: false,
  toggleSpinePlane: false,
};

export class BattingGfx extends AbstractGfx {
  motionPathsToggles = {
    names: ["Head", "Hips", "Knob", "Top", "Center"],
    drawn: [false, false, false, false, false],
    visible: [false, false, false, false, false],
    paths: [[], [], [], [], []],
    colors: ["0xff5733", "0x33ff57", "0x3357ff", "0xff33a8", "0x33fff6"],
    uuids: [[], [], [], [], []],
  };
  segmentLineToggles = {
    names: ["Head", "Spine", "Hips", "Spine2"],
    positionNames: ["Head", "Spine", "Hips", "Spine2"],
    drawn: [false, false, false, false],
    visible: [false, false, false, false],
    paths: [[], [], [], []],
    colors: ["0xff5733", "0xa833ff", "0x33ff57", "0xffff33"],
    colors: ["0xff5733", "0xa833ff", "0x33ff57", "0xffff33"],
    uuids: [[], [], [], []],
  };
  swingPlaneToggles = {
    names: ["plane", "ope"],
    drawn: [false, false],
    visible: [false, false],
    uuidsPlane: [[], []],
    uuidsComponents: [[], []],
  };

  resetGui(world) {
    if (world.battingMode) {
      toggleHeadLineController.object.toggleHeadLine = false;
      toggleShoulderLineController.object.toggleShoulderLine = false;
      toggleSpineLineController.object.toggleSpineLine = false;
      toggleHipLineController.object.toggleHipLine = false;

      this.toggleLinePaths("Head", 0, world, false);
      this.toggleLinePaths("Spine", 0, world, false);
      this.toggleLinePaths("Hips", 0, world, false);
      this.toggleLinePaths("Spine2", 90, world, false);
      toggleHeadLineController.updateDisplay();
      toggleShoulderLineController.updateDisplay();
      toggleSpineLineController.updateDisplay();
      toggleHipLineController.updateDisplay();

      toggleHeadMotionPathController.object.toggleHeadPath = false;
      toggleHipMotionPathController.object.toggleHipPath = false;
      toggleTipMotionPathController.object.toggleTipPath = false;
      toggleKnobMotionPathController.object.toggleKnobPath = false;
      toggleBallMotionPathController.object.toggleBallPath = false;

      this.toggleMotionPaths("Head", world, false);
      this.toggleMotionPaths("Hips", world, false);
      this.toggleMotionPaths("Knob", world, false);
      this.toggleMotionPaths("Top", world, false);
      this.toggleMotionPaths("Center", world, false);
      toggleHeadMotionPathController.updateDisplay();
      toggleHipMotionPathController.updateDisplay();
      toggleTipMotionPathController.updateDisplay();
      toggleKnobMotionPathController.updateDisplay();
      toggleBallMotionPathController.updateDisplay();

      toggleSwingPlaneController.object.toggleBatPath = false;
      this.togglePlanePathVisibility(world, false);
      toggleSwingPlaneController.updateDisplay();

      // reset paths
      toggleHeadMotionPathController.object.toggleHeadPath = false;
      toggleHipMotionPathController.object.toggleHipPath = false;
      toggleTipMotionPathController.object.toggleTipPath = false;
      toggleKnobMotionPathController.object.toggleKnobPath = false;
      toggleBallMotionPathController.object.toggleBallPath = false;

      this.toggleMotionPaths("Head", world, false);
      this.toggleMotionPaths("Hips", world, false);
      this.toggleMotionPaths("Knob", world, false);
      this.toggleMotionPaths("Top", world, false);
      this.toggleMotionPaths("Center", world, false);
      toggleHeadMotionPathController.updateDisplay();
      toggleHipMotionPathController.updateDisplay();
      toggleTipMotionPathController.updateDisplay();
      toggleKnobMotionPathController.updateDisplay();
      toggleBallMotionPathController.updateDisplay();

      toggleSwingPlaneController.object.toggleBatPath = false;
      this.togglePlanePathVisibility(world, false);
      toggleSwingPlaneController.updateDisplay();

      // reset paths
      toggleHeadMotionPathController.object.toggleHeadPath = false;
      toggleHipMotionPathController.object.toggleHipPath = false;
      toggleTipMotionPathController.object.toggleTipPath = false;
      toggleKnobMotionPathController.object.toggleKnobPath = false;
      toggleBallMotionPathController.object.toggleBallPath = false;

      this.toggleMotionPaths("Head", world, false);
      this.toggleMotionPaths("Hips", world, false);
      this.toggleMotionPaths("Knob", world, false);
      this.toggleMotionPaths("Top", world, false);
      this.toggleMotionPaths("Center", world, false);
      toggleHeadMotionPathController.updateDisplay();
      toggleHipMotionPathController.updateDisplay();
      toggleTipMotionPathController.updateDisplay();
      toggleKnobMotionPathController.updateDisplay();
      toggleBallMotionPathController.updateDisplay();

      toggleSwingPlaneController.object.toggleBatPath = false;
      this.togglePlanePathVisibility(world, false);
      toggleSwingPlaneController.updateDisplay();
    }
  }

  initGui(gui, world) {
    swingPlaneFolder = gui.addFolder("Swing Plane Graphics");
    motionPathFolder = gui.addFolder("Motion Paths");
    segmentLineFolder = gui.addFolder("Segment Line Helpers");
    syncFolder = gui.addFolder("Sync & Positioning");

    this.initStyles(gui, world);

    syncFolder
      .add(syncFolderParams, "translateX", -1.0, 1.0)
      .name("Translate X")
      .onChange((value) => {
        this.translatePerson(value, world, "X");
      });
    syncFolder
      .add(syncFolderParams, "translateY", -1.0, 1.0)
      .name("Translate Y")
      .onChange((value) => {
        this.translatePerson(value, world, "Y");
      });
    syncFolder
      .add(syncFolderParams, "sync", ["SET", "MHH", "DS", "LFP", "PFP", "BC"])
      .name("Sync")
      .onChange((syncReferenceKeyFrame) => {
        this.changeSyncReferenceKeyFrame(world, syncReferenceKeyFrame);
      });
    syncFolder
      .add(syncFolderParams, "reflect")
      .name("3D Reflection")
      .onChange(() => {
        this.toggleReflection(world);
      });

    toggleHeadLineController = segmentLineFolder
      .add(segmentLineParms, "toggleHeadLine")
      .name("Head Line")
      .onChange((value) => {
        this.toggleLinePaths("Head", 0, world, value);
      });

    toggleShoulderLineController = segmentLineFolder
      .add(segmentLineParms, "toggleShoulderLine")
      .name("Shoulder Line")
      .onChange((value) => {
        this.toggleLinePaths("Spine", 0, world, value);
      });
    toggleHipLineController = segmentLineFolder
      .add(segmentLineParms, "toggleHipLine")
      .name("Hip Line")
      .onChange((value) => {
        this.toggleLinePaths("Hips", 0, world, value);
      });
    toggleSpineLineController = segmentLineFolder
      .add(segmentLineParms, "toggleSpineLine")
      .name("Spine Line")
      .onChange((value) => {
        this.toggleLinePaths("Spine2", 90, world, value);
      });
    toggleHeadMotionPathController = motionPathFolder
      .add(motionPathParams, "toggleHeadPath")
      .name("Head Path")
      .onChange((value) => {
        this.toggleMotionPaths("Head", world, value);
      });

    toggleHipMotionPathController = motionPathFolder
      .add(motionPathParams, "toggleHipPath")
      .name("Hip Path")
      .onChange((value) => {
        this.toggleMotionPaths("Hips", world, value);
      });

    toggleKnobMotionPathController = motionPathFolder
      .add(motionPathParams, "toggleKnobPath")
      .name("Knob Path")
      .onChange((value) => {
        this.toggleMotionPaths("Knob", world, value);
      });
    toggleTipMotionPathController = motionPathFolder
      .add(motionPathParams, "toggleTipPath")
      .name("Bat Tip Path")
      .onChange((value) => {
        this.toggleMotionPaths("Top", world, value);
      });
    toggleBallMotionPathController = motionPathFolder
      .add(motionPathParams, "toggleBallPath")
      .name("Ball Path")
      .onChange((value) => {
        this.toggleMotionPaths("Center", world, value);
      });

    toggleSwingPlaneController = swingPlaneFolder
      .add(swingPlaneParams, "toggleBatPath")
      .name("Swing Plane")
      .onChange((value) => {
        this.togglePlanePathVisibility(world, value);
      });
    // TODO - I need some help styling this lil-gui element. Been chasing my tail for a bit on it and am going to just keep it to default for now.
    swingPlaneFolder.open();
    segmentLineFolder.open();
    motionPathFolder.open();
    syncFolder.open();
    gui.close();
  }
  //eslint-disable-next-line no-unused-vars
  getNameAndDistalNameForPlaneToggle(_world, _key) {
    return { name: "Knob", distalName: "Top" };
  }
}
export const battingGfx = new BattingGfx();
