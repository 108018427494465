import { DataPoint, Result } from "regression";
import { isNil } from "lodash";
import { RegressionCalculator } from "../../services/regressionCalculator";
import { Range } from "../../services/performanceApi.service";

export interface SplitRegression {
  result?: Result;
  range?: Range;
}

export function defaultSplitRanges(points: DataPoint[] = []): [Range, Range] {
  const half = Math.floor(points.length / 2);
  return [
    { from: 1, to: half },
    { from: half + 1, to: points.length },
  ];
}

export function computeSplitRegression(
  data?: DataPoint[],
  range?: Range
): SplitRegression | undefined {
  if (isNil(data) || isNil(range)) {
    return undefined;
  }

  return {
    range,
    result: new RegressionCalculator().linearFromDataPoints(
      data.slice(range.from - 1, range.to)
    ),
  };
}
