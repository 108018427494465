import React, { useEffect } from "react";
import { Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectDataIndexingValue } from "../../redux/selectors";
import { setDataIndexingValue } from "../../redux/movementSlice";
import { ExpandedMetricsDataIndexing } from "./ExpandedMetricsDataIndexing";

interface DataIndexSelectorProps {
  options: ExpandedMetricsDataIndexing[];
  disabledOptions?: ExpandedMetricsDataIndexing[];
}

const DataIndexSelector = ({
  options,
  disabledOptions = [],
}: DataIndexSelectorProps) => {
  const dispatch = useDispatch();
  const dataIndexing = useSelector(selectDataIndexingValue);

  useEffect(() => {
    if (!options.includes(dataIndexing)) {
      dispatch(setDataIndexingValue(options[0]));
    }
  }, [dataIndexing]);

  const handleDataIndexChange = (
    _: React.MouseEvent<HTMLElement>,
    newDataIndexing: ExpandedMetricsDataIndexing
  ) => {
    if (newDataIndexing) {
      dispatch(setDataIndexingValue(newDataIndexing));
    }
  };

  return (
    <Grid container>
      <ToggleButtonGroup
        className="ptd-toggle-container"
        value={dataIndexing}
        exclusive
        size="small"
        onChange={handleDataIndexChange}
      >
        {options.map((it) => (
          <ToggleButton
            className="ptd-expanded-metrics-values"
            key={it}
            value={it}
            disabled={disabledOptions.includes(it)}
          >
            {it}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Grid>
  );
};

export default DataIndexSelector;
