import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { isEmpty, isNil } from "lodash";
import { Player3D } from "./Player3D";
import { shouldLoadHalloweenEasterEgg } from "./shouldLoadHalloweenEasterEgg";
import { Gizmo3D } from "./gizmo3D";
import { Speed } from "../hooks/use3DAnimationVideoScrubber";
import { HalloweenEasterEgg3D } from "./HalloweenEasterEgg3D";
import { Stadium3D } from "./Stadium3D";
import { shouldLoadLiteMode } from "./shouldLoadLiteMode";
import { extraPeopleColors } from "./extraPeopleColors";
import { StandardLights3D } from "./StandardLights3D";
import { battingGfx } from "./guiGraphicsBatting";
import { pitchingGfx } from "./guiGraphicsPitching";

import GUI from "lil-gui";

const extraPersonKey = (i) => {
  return `extraPerson${i}`;
};
const PEOPLE_KEYS = [
  "person",
  "comparingPerson",
  ...[0, 1, 2, 3].map((i) => extraPersonKey(i)),
];
var stadiumLoaded = false;

export class World3D {
  constructor(mountingPointRef, onFrameChange, onPauseVideo, hideControlsGui) {
    this.scene = new THREE.Scene();
    this.mountingPointRef = mountingPointRef;
    this.onFrameChange = onFrameChange;
    this.onPauseVideo = onPauseVideo;
    this.currentFrame = 0;
    this.playAnimation = false;
    this.characterSpeed = Speed.NORMAL;
    this.animationFrameId = null;
    this.loopVideo = false;
    this.startFrame = null;
    this.endFrame = null;
    this.halloweenEasterEgg3D = new HalloweenEasterEgg3D(this.scene);
    this.stadium3D = new Stadium3D(this.scene);
    this.standardLights3D = new StandardLights3D(this.scene);
    this.liteMode = shouldLoadLiteMode;
    this.mainKeyFrames = [];
    this.syncReferenceKeyFrame = "";
    /* eslint-disable no-unused-vars */
    this.setSyncReferenceFrame = (_it) => {};
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    this.onlyShowPlayer = null;
    this.hideControlsGui = hideControlsGui;
  }

  async render(battingMode) {
    const { renderer, camera } = await this.initializeWorld(battingMode);
    // Create a render loop.
    let previousTime = 0;
    const maxFrameRate = 30;
    const minFrameDuration = 1000 / maxFrameRate;
    let previousFrame = null;
    let previousOnlyShowPlayer = null;

    const animate = (currentTime) => {
      this.animationFrameId = requestAnimationFrame(animate);

      const timeElapsed = currentTime - previousTime;

      // Check if enough time has passed to render a frame at the desired frame rate
      if (timeElapsed > minFrameDuration) {
        previousTime = currentTime;
        if (this.playAnimation) {
          this.calculateNextFrame();
          this.onFrameChange(this.displayFrame());
          const endFrame =
            this.endFrame || this.person?.jointCoordinates.length - 2;
          const startFrame = this.startFrame || 0;
          if (this.currentFrame > endFrame) {
            this.currentFrame = startFrame; // Reset scrubber value if it exceeds max
            this.onFrameChange(startFrame);
            if (!this.loopVideo) {
              this.onPauseVideo();
              this.playAnimation = false;
            }
          }
        }
        const displayFrame = this.displayFrame();
        const frameChanged = previousFrame !== displayFrame;
        const onlyShowPlayerChanged =
          previousOnlyShowPlayer !== this.onlyShowPlayer;
        if (frameChanged || onlyShowPlayerChanged) {
          previousFrame = displayFrame;
          previousOnlyShowPlayer = this.onlyShowPlayer;
          try {
            //Update the graphics
            this.currentGfx().updateLineVisibility(displayFrame, this);
          } catch (error) {}

          this.updatePerson(displayFrame);
          this.updateComparingPerson(displayFrame);
          PEOPLE_KEYS.forEach((personKey) => {
            if (!isEmpty(this[personKey]?.jointCoordinates)) {
              this[personKey]?.render(displayFrame);
            }
          });
        }

        if (this.scene) {
          try {
            this.orientationGizmo.update();
            this.halloweenEasterEgg3D.update();
          } catch (error) {}

          renderer.render(this.scene, camera);
        }
      }
    };

    animate();
  }

  updateComparingPerson(displayFrame) {
    if (!isEmpty(this.comparingPerson?.jointCoordinates)) {
      if (this.shouldShowComparingPerson()) {
        this.comparingPerson?.render(displayFrame);
      } else {
        this.comparingPerson?.remove();
      }
    }
  }

  shouldShowComparingPerson() {
    return !this.onlyShowPlayer || this.onlyShowPlayer === "secondary";
  }

  updatePerson(displayFrame) {
    if (!isEmpty(this.person?.jointCoordinates)) {
      if (this.shouldShowMainPerson()) {
        this.person?.render(displayFrame);
      } else {
        this.person?.remove();
      }
    }
  }

  shouldShowMainPerson() {
    return !this.onlyShowPlayer || this.onlyShowPlayer === "main";
  }

  initializeGizmo(camera) {
    if (!this.gizmoInitialized) {
      this.orientationGizmo = new Gizmo3D(
        camera,
        this.controls,
        this.hideControlsGui
      ).initialize();
      this.mountingPointRef.current?.appendChild(this.orientationGizmo);
      this.gizmoInitialized = true;
    }
  }
  removeExtraPerson(i) {
    this[extraPersonKey(i)]?.remove();
  }

  removeComparingPerson() {
    this.comparingPerson?.remove();
  }

  async setUpComparingPerson(
    comparingMovementJointCoordinates,
    handedness,
    comparingMovementType
  ) {
    const shouldRender = await this.initializePlayer(
      "comparingPerson",
      comparingMovementType,
      comparingMovementJointCoordinates,
      handedness,
      {
        texture: "3d/textures/orange.png",
        liteColors: {
          line: 0xee6644,
          sphere: 0xe82d02,
        },
        color: "#ff7528",
      }
    );
    this.currentGfx().resetGui(this);
    if (!shouldRender) {
      this.comparingPerson = null;
    }
  }
  async setUpExtraPerson(jointCoordinates, i, handedness) {
    const personKey = extraPersonKey(i);
    const color = extraPeopleColors[i] || "#87dab7";
    const shouldRender = await this.initializePlayer(
      personKey,
      "pitch",
      jointCoordinates,
      handedness,
      {
        color,
        opacity: 0.6,
        liteColors: {
          line: 0xee6644,
          sphere: 0xe82d02,
        },
      }
    );
    this[personKey].skeletonColor = color;

    this.currentGfx().resetGui(this.displayFrame(), this);
    if (!shouldRender) {
      this[personKey] = null;
    }
  }
  async setUpPerson(
    jointCoordinates,
    handedness,
    mainMovementType,
    shouldRenderComparingPerson
  ) {
    const shouldRenderComparingPersonChanged =
      this.shouldRenderComparingPerson != shouldRenderComparingPerson;
    this.shouldRenderComparingPerson = shouldRenderComparingPerson;
    if (
      shouldRenderComparingPersonChanged ||
      this.person?.jointCoordinates !== jointCoordinates
    ) {
      await this.initializePlayer(
        "person",
        mainMovementType,
        jointCoordinates,
        handedness,
        shouldRenderComparingPerson
          ? {
              texture: "3d/textures/blue.png",
              liteColors: {
                line: 0x004dbf,
                sphere: 0x2974d6,
              },
              color: "#95b8ff",
            }
          : {}
      );
      this.currentGfx().resetGui(this.displayFrame(), this);
    }
  }

  displayFrame() {
    return Math.floor(this.currentFrame);
  }

  updateFrame(frame) {
    if (!isNaN(frame)) {
      this.currentFrame = frame;
    }
  }

  calculateNextFrame() {
    this.currentFrame += this.characterSpeed;
    if (this.startFrame) {
      const cappedCurrentFrame = Math.max(this.currentFrame, this.startFrame);
      this.currentFrame = cappedCurrentFrame;
    }
    this.onFrameChange(this.currentFrame);
  }

  previousFrame() {
    if (this.currentFrame >= this.characterSpeed) {
      this.currentFrame -= this.characterSpeed;
      this.onFrameChange(this.currentFrame);
    }
  }

  togglePlayAnimation() {
    this.playAnimation = !this.playAnimation;
  }

  async initializeWorld(battingMode) {
    const renderer = this.initRenderer();
    const camera = this.initCamera(renderer, battingMode);
    this.initializeGizmo(camera, this.controls);
    this.battingMode = battingMode;
    await this.initBackground(battingMode);
    // Remove this if you want to ever get rid of lil-gui Graphics
    const gui = new GUI({ title: "3D Graphics" });
    this.gui = gui;
    this.currentGfx().initGui(gui, this);
    this.initLights();
    return { renderer, camera };
  }

  currentGfx() {
    return this.battingMode ? battingGfx : pitchingGfx;
  }

  createCheckerBoardMaterial(range, squareSize, opacityRadius) {
    const canvas = document.createElement("canvas");
    const count = range * 2; // total number of squares
    canvas.width = canvas.height = squareSize * count;
    const context = canvas.getContext("2d");

    for (let i = 0; i < count; i++) {
      for (let j = 0; j < count; j++) {
        const distanceFromCenter = Math.sqrt(
          Math.pow(i - range, 2) + Math.pow(j - range, 2)
        );
        const opacity = Math.max(0, 1 - distanceFromCenter / opacityRadius);

        context.fillStyle =
          (i + j) % 2 === 0
            ? `rgba(210, 224, 255, ${opacity})`
            : `rgba(250, 250, 255, ${opacity})`;
        context.fillRect(
          i * squareSize,
          j * squareSize,
          squareSize,
          squareSize
        );
      }
    }

    const texture = new THREE.CanvasTexture(canvas);
    texture.needsUpdate = true;
    texture.magFilter = THREE.NearestFilter;
    texture.minFilter = THREE.NearestFilter;

    const material = new THREE.MeshBasicMaterial({
      map: texture,
      side: THREE.DoubleSide,
      transparent: true,
    });

    return material;
  }
  createHomePlate(scene, yVar, battingMode) {
    const material = new THREE.MeshBasicMaterial({
      color: 0xd4dce8,
      side: THREE.DoubleSide,
    });
    const homePlateGeometry = new THREE.BufferGeometry();
    const halfDepth = 0.3748 / 2; // Half of 12 inches converted to meters
    const vertices = new Float32Array([
      0,
      yVar,
      0,
      halfDepth,
      yVar,
      -halfDepth,
      halfDepth,
      yVar,
      halfDepth,
      halfDepth * 2,
      yVar,
      -halfDepth,
      halfDepth * 2,
      yVar,
      halfDepth,
    ]);

    homePlateGeometry.setAttribute(
      "position",
      new THREE.BufferAttribute(vertices, 3)
    );
    homePlateGeometry.setIndex([0, 1, 2, 1, 3, 4, 1, 2, 4]);

    const homePlate = new THREE.Mesh(homePlateGeometry, material);
    homePlate.name = "homePlate";
    homePlate.visible = battingMode;
    scene.add(homePlate);
  }
  createBattersBoxes(scene, yVar, side, battingMode) {
    let zOffset = 1.0;
    if (side == "left") {
      zOffset = zOffset * -1.0;
    }

    const material = new THREE.MeshBasicMaterial({
      color: 0xd4dce8,
      side: THREE.DoubleSide,
    });
    const battersBoxGeometry = new THREE.BufferGeometry();

    // Home plate dimensions converted to meters
    const boxWidth = 1.2192; // 4 feet in meters
    const boxLength = 1.8588; // 6 feet in meters
    let xOffset = -boxLength / 2 + 0.22;

    const thickness = 0.03;
    const vertices = new Float32Array([
      0 + xOffset,
      yVar,
      0 + zOffset - boxWidth / 2, //outer
      boxLength + xOffset,
      yVar,
      0 + zOffset - boxWidth / 2,
      boxLength + xOffset,
      yVar,
      boxWidth + zOffset - boxWidth / 2,
      0 + xOffset,
      yVar,
      boxWidth + zOffset - boxWidth / 2,
      0 + xOffset + thickness,
      yVar,
      0 + zOffset + thickness - boxWidth / 2, //inner
      boxLength + xOffset - thickness,
      yVar,
      0 + zOffset + thickness - boxWidth / 2,
      boxLength + xOffset - thickness,
      yVar,
      boxWidth + zOffset - thickness - boxWidth / 2,
      0 + xOffset + thickness,
      yVar,
      boxWidth + zOffset - thickness - boxWidth / 2,
    ]);

    battersBoxGeometry.setAttribute(
      "position",
      new THREE.BufferAttribute(vertices, 3)
    );
    battersBoxGeometry.setIndex([
      0, 1, 4, 1, 5, 4, 1, 2, 5, 2, 5, 6, 2, 3, 6, 6, 7, 3, 7, 3, 4, 3, 0, 4,
    ]);

    const box = new THREE.Mesh(battersBoxGeometry, material);
    box.name = "battersBox" + side;
    box.visible = battingMode;
    scene.add(box);
  }
  createKZoneThin(scene, battingMode) {
    let yOffset = (0.46 + 0.65) / 2;

    const material = new THREE.MeshBasicMaterial({
      color: 0xd4dce8,
      side: THREE.DoubleSide,
      transparent: true,
      opacity: 0.8,
      depthWrite: false,
    });
    const battersBoxGeometry = new THREE.BufferGeometry();

    // Home plate dimensions converted to meters
    const boxWidth = 0.4; // 4 feet in meters
    const boxLength = 0.55; // 6 feet in meters
    let zOffset = 0;
    const thickness = 0.0101;
    const plateDist = 0.43;

    const vertices = new Float32Array([
      plateDist,
      0 + yOffset,
      0 + zOffset - boxWidth / 2, //outer
      plateDist,
      boxLength + yOffset,
      0 + zOffset - boxWidth / 2,
      plateDist,
      boxLength + yOffset,
      boxWidth + zOffset - boxWidth / 2,
      plateDist,
      0 + yOffset,
      boxWidth + zOffset - boxWidth / 2,
      plateDist,
      0 + yOffset + thickness,
      0 + zOffset + thickness - boxWidth / 2, //inner
      plateDist,
      boxLength + yOffset - thickness,
      0 + zOffset + thickness - boxWidth / 2,
      plateDist,
      boxLength + yOffset - thickness,
      boxWidth + zOffset - thickness - boxWidth / 2,
      plateDist,
      0 + yOffset + thickness,
      boxWidth + zOffset - thickness - boxWidth / 2,
    ]);

    battersBoxGeometry.setAttribute(
      "position",
      new THREE.BufferAttribute(vertices, 3)
    );
    battersBoxGeometry.setIndex([
      0, 1, 4, 1, 5, 4, 1, 2, 5, 2, 5, 6, 2, 3, 6, 6, 7, 3, 7, 3, 4, 3, 0, 4,
    ]);

    const box = new THREE.Mesh(battersBoxGeometry, material);
    box.name = "kZone";
    box.visible = battingMode;
    scene.add(box);
  }

  createCheckerBoard(range, squareSize, opacityRadius) {
    const geometry = new THREE.PlaneBufferGeometry(range * 2, range * 2);

    const mesh = new THREE.Mesh(
      geometry,
      this.createCheckerBoardMaterial(range, squareSize, opacityRadius)
    );

    mesh.rotation.x = -Math.PI / 2;

    return mesh;
  }

  createMound(radius, position, color, opacity) {
    const geometry = new THREE.SphereGeometry(
      radius,
      32,
      32,
      0,
      Math.PI * 2,
      0,
      Math.PI / 19.5
    );

    const material = new THREE.MeshStandardMaterial({
      color: color,
      transparent: true,
      opacity: opacity,
      side: THREE.DoubleSide,
    });

    const mound = new THREE.Mesh(geometry, material);
    mound.name = "pitchingMound";

    mound.position.copy(position);

    return mound;
  }
  createPitcherRubber(width, height, depth, position, color) {
    const geometry = new THREE.BoxGeometry(width, height, depth);
    const material = new THREE.MeshStandardMaterial({
      color: color,
    });
    const rubber = new THREE.Mesh(geometry, material);
    rubber.position.copy(position);
    rubber.rotation.y = Math.PI / 2;
    rubber.name = "pitchingRubber";
    return rubber;
  }

  async initBackground(battingMode) {
    if (!this.backgroundInitialized) {
      const range = 30;
      const squareSize = 1;
      const opacityRadius = 15;
      const checkerboard = this.createCheckerBoard(
        range,
        squareSize,
        opacityRadius
      );
      checkerboard.position.y -= 0.2;
      checkerboard.name = "checkerboardFloor";
      this.checkerboard = checkerboard;
      this.scene.add(checkerboard);

      const moundRadius = 15;
      const moundPosition = new THREE.Vector3(0, 0, 0);
      const moundColor = new THREE.Color(`rgb(33, 64, 154)`);
      const moundOpacity = 0.05;
      const mound = this.createMound(
        moundRadius,
        moundPosition,
        moundColor,
        moundOpacity
      );
      mound.position.y -= 15.0;
      mound.position.x -= 0.2;
      mound.renderOrder = 1;
      this.scene.add(mound);

      const rubberWidth = 0.6;
      const rubberHeight = 0.03;
      const rubberDepth = 0.1;
      const rubberPosition = new THREE.Vector3(0, rubberHeight / 2, 0);
      const rubberColor = new THREE.Color("white");
      const rubber = this.createPitcherRubber(
        rubberWidth,
        rubberHeight,
        rubberDepth,
        rubberPosition,
        rubberColor
      );

      this.scene.add(rubber);

      const axesHelper = new THREE.AxesHelper(0.5);
      axesHelper.rotation.y = Math.PI;
      axesHelper.rotation.x = Math.PI / 2;
      axesHelper.rotation.z = Math.PI;
      axesHelper.name = "originAxesHelper";

      this.scene.add(axesHelper);
      await this.halloweenEasterEgg3D.initialize();
      this.backgroundInitialized = true;

      let yVar = 0.021;
      this.createHomePlate(this.scene, yVar, battingMode);
      this.createBattersBoxes(this.scene, yVar, "left", battingMode);
      this.createBattersBoxes(this.scene, yVar, "right", battingMode);
      this.createKZoneThin(this.scene, battingMode);
      this.battingMode = battingMode;
    }
  }

  initLights() {
    this.standardLights3D.initialize();
  }

  initCamera(renderer, battingMode) {
    const { aspect } = this.worldDimensions();
    const camera = new THREE.PerspectiveCamera(40, aspect, 0.1, 1000);
    camera.position.x = -1.5;
    camera.position.y = 1.0;
    camera.position.z = -4.0;
    let target = new THREE.Vector3();
    camera.lookAt(target);
    if (battingMode) {
      target.set(0.0, 1.0, 1.0);
      camera.position.set(0.0, 1.0, -4.5);
      camera.lookAt(target);
    } else {
      target.set(-1.5, 1.0, 0.0);
      camera.position.set(-1.5, 1.0, -4.0);
      camera.lookAt(target);
    }
    this.initOrbitControls(camera, renderer, target);
    camera.name = "mainCamera";
    this.camera = camera;
    return camera;
  }

  initOrbitControls(camera, renderer, target) {
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.staticMoving = false;
    controls.dynamicDampingFactor = 0.5;
    controls.target = target;
    controls.zoomSpeed = 0.3;
    controls.update();
    controls.name = "orbitController";
    this.controls = controls;
  }

  resize() {
    const { width, height, aspect } = this.worldDimensions();
    this.camera.aspect = aspect;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(width, height);
  }

  initRenderer() {
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    this.renderer = renderer;
    renderer.setPixelRatio(window.devicePixelRatio);
    const { width, height } = this.worldDimensions();
    renderer.setSize(width, height);
    this.setClearColor();
    if (this.mountingPointRef && this.mountingPointRef.current) {
      this.mountingPointRef.current.appendChild(renderer.domElement);
    }
    return renderer;
  }

  setClearColor(color) {
    this.renderer.setClearColor(
      !isNil(color) ? color : shouldLoadHalloweenEasterEgg ? 0x02031a : 0xffffff
    );
  }

  worldDimensions() {
    const width = this.width;
    const height = this.height;
    const aspect = width / height;
    return { width, height, aspect };
  }

  newPlayer(movementType) {
    return movementType === "pitch"
      ? new Player3D(this.scene)
      : new Player3D(this.scene); //Right now Player3D contemplates the 3D model loading/rendering for both pitchers and batters. Might need to differentiate them again later.
  }

  async initializePlayer(
    personKey,
    movementType,
    jointCoordinates,
    handedness,
    options
  ) {
    this[personKey]?.remove();
    this[personKey] = this.newPlayer(movementType);
    const currentGfx = this.currentGfx();
    this[personKey].guiData.segmentLineToggles = JSON.parse(
      JSON.stringify(currentGfx.segmentLineToggles)
    );
    this[personKey].guiData.motionPathsToggles = JSON.parse(
      JSON.stringify(currentGfx.motionPathsToggles)
    );

    this[personKey].guiData.swingPlaneToggles = JSON.parse(
      JSON.stringify(currentGfx.swingPlaneToggles)
    );

    this[personKey].handedness = handedness;

    const shouldRenderPerson = !isEmpty(jointCoordinates);
    if (shouldRenderPerson) {
      await this[personKey].initialize(jointCoordinates, {
        ...options,
        handedness,
        liteMode: this.liteMode,
      });
      this[personKey].render(this.displayFrame());
      this[personKey].skeletonColor = options.color;
    }
    this[personKey].skeletonColor = options.color;
    return shouldRenderPerson;
  }
  //gfx calls this one
  changeSyncReferenceKeyFrame(syncReferenceKeyFrame) {
    if (this.setSyncReferenceFrame) {
      this.setSyncReferenceFrame(syncReferenceKeyFrame);
    }
  }

  setSyncReferenceFrameProps({ syncReferenceKeyFrame, setSyncReferenceFrame }) {
    this.syncReferenceKeyFrame = syncReferenceKeyFrame;
    this.setSyncReferenceFrame = setSyncReferenceFrame;
  }

  destroy() {
    cancelAnimationFrame(this.animationFrameId);
    this.person?.remove();
    this.comparingPerson?.remove();
    if (this.mountingPointRef && this.mountingPointRef.current) {
      this.mountingPointRef.current.removeChild(this.renderer.domElement);
    }
    stadiumLoaded = false;
    this.gui?.destroy();
  }
  async setShouldShowStadium(shouldShowStadium, battingMode) {
    if (shouldShowStadium) {
      if (this.scene.getObjectByName("checkerboardFloor")) {
        this.scene.getObjectByName("checkerboardFloor").visible = false;
      }
      if (battingMode) {
        this.scene.getObjectByName("pitchingRubber").visible = false;
        this.scene.getObjectByName("pitchingMound").visible = false;
        this.scene.getObjectByName("originAxesHelper").visible = false;
      }
      if (!stadiumLoaded) {
        await this.stadium3D.initialize(battingMode);
        stadiumLoaded = true;
      } else {
        if (this.stadium3D.model) {
          this.stadium3D.model.children[0].visible = true;
        }
      }
      if (this.scene.getObjectByName("kZone")) {
        if (battingMode) {
          this.scene.getObjectByName("kZone").visible = true;
          this.scene.getObjectByName("battersBoxleft").visible = true;
          this.scene.getObjectByName("battersBoxright").visible = true;
          this.scene.getObjectByName("homePlate").visible = true;
        } else {
          this.scene.getObjectByName("kZone").visible = false;
        }
        this.scene.getObjectByName("battersBoxleft").visible = false;
        this.scene.getObjectByName("battersBoxright").visible = false;
        this.scene.getObjectByName("homePlate").visible = false;
      }
      this.standardLights3D.toggleLightsOff();
      this.setClearColor(0x000000);
    } else {
      this.setClearColor();
      this.scene.getObjectByName("checkerboardFloor").visible = true;
      this.checkerboard.position.set(0.0, 0.0, 0.0);
      if (!battingMode) {
        this.scene.getObjectByName("pitchingRubber").visible = true;
        this.scene.getObjectByName("pitchingMound").visible = true;
        this.scene.getObjectByName("originAxesHelper").visible = true;
        if (this.scene.getObjectByName("kZone")) {
          this.scene.getObjectByName("kZone").visible = false;
          this.scene.getObjectByName("battersBoxleft").visible = false;
          this.scene.getObjectByName("battersBoxright").visible = false;
          this.scene.getObjectByName("homePlate").visible = false;
        }
        this.checkerboard.position.set(0.0, -0.2, 0.0);
      } else {
        this.scene.getObjectByName("pitchingRubber").visible = false;
        this.scene.getObjectByName("pitchingMound").visible = false;
        this.scene.getObjectByName("originAxesHelper").visible = false;
        if (this.scene.getObjectByName("kZone")) {
          this.scene.getObjectByName("kZone").visible = true;
          this.scene.getObjectByName("battersBoxleft").visible = true;
          this.scene.getObjectByName("battersBoxright").visible = true;
          this.scene.getObjectByName("homePlate").visible = true;
        }
      }
      this.standardLights3D.toggleLightsOn();
      if (stadiumLoaded && this.stadium3D.model) {
        this.stadium3D.model.children[0].visible = false;
      }
    }
  }

  setDimensions(width, height) {
    this.width = width;
    this.height = height;
    this.resize();
  }
}
