import React from "react";
import { Table } from "antd";
import { Pitch } from "../../../services/mockData";
import { last } from "lodash";
const { Column } = Table;

export function VideoDebugger({ trial }: { trial: Pitch }) {
  const rows = trial?.videos || [];

  return (
    <>
      <Table dataSource={rows} pagination={false}>
        <Column
          title="Name"
          dataIndex={"videoUrl"}
          className="ptd-column ptd-column-variable"
          render={(videoUrl) => (
            <span>{last((videoUrl || "").split("/"))}</span>
          )}
        />
        <Column
          title="Angle"
          dataIndex={"angle"}
          className="ptd-column ptd-column-variable"
        />
        <Column
          title="Overlay"
          className="ptd-column ptd-column-variable"
          dataIndex={"videoUrl"}
          render={(videoUrl) => {
            return (
              <>
                {videoUrl?.includes("skeleton") || videoUrl?.includes("filt")
                  ? "Yes"
                  : "No"}
              </>
            );
          }}
        />
        <Column
          title="URL"
          dataIndex={"videoUrl"}
          className="ptd-column ptd-column-variable"
          render={(videoUrl) => {
            return (
              <a
                href={videoUrl}
                target="_blank"
                rel="noreferrer noopener"
                download="video"
              >
                Link
              </a>
            );
          }}
        />
      </Table>
    </>
  );
}
