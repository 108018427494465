import React from "react";
import { Grid } from "@mui/material";

interface RegressionLegendItemsProps {
  label: string;
  width: string;
  iconProps: {
    height: string;
    width: string;
    backgroundColor?: string;
    borderWidth?: string;
    borderColor?: string;
    borderStyle?: string;
    borderRadius?: string;
  };
}

const RegressionLegendItem = ({
  label,
  width,
  iconProps,
}: RegressionLegendItemsProps) => (
  <Grid container flexDirection="row" alignItems="center" width={width}>
    <div
      style={{
        height: iconProps.height,
        width: iconProps.width,
        borderWidth: iconProps.borderWidth,
        borderColor: iconProps.borderColor,
        borderStyle: iconProps.borderStyle,
        borderRadius: iconProps.borderRadius,
        backgroundColor: iconProps?.backgroundColor
          ? iconProps.backgroundColor
          : "white",
        marginRight: "4px",
      }}
    />
    <div style={{ fontSize: "12px" }}>{label}</div>
  </Grid>
);

export default RegressionLegendItem;
