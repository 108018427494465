import React, { useMemo } from "react";
import { Grid } from "@mui/material";
import Highcharts from "highcharts";
import { HighchartsReact } from "highcharts-react-official";
import { makeRegressionSeries } from "../../utils/charts";
import { DataPoint } from "regression";

interface GradientLineChartProps {
  data?: DataPoint[];
  secondaryData?: { visible: boolean; data: any };
  regressionsData?: DataPoint[];
  width?: any;
  yAxis?: { title?: string };
  splitRegression1Data?: DataPoint[];
  splitRegression2Data?: DataPoint[];
  isDataCompare?: boolean;
}

const overColor = "#ff7272";
const betweenColor = "#929395";
const underColor = "#b0c2e4";

const GradientLineChart = ({
  data,
  secondaryData,
  regressionsData,
  width,
  yAxis,
  splitRegression1Data,
  splitRegression2Data,
  isDataCompare,
}: GradientLineChartProps) => {
  const mainSeries = useMemo(
    () => ({
      marker: {
        enabled: false,
      },
      lineWidth: 3,
      zIndex: 2,
      data,
      color: isDataCompare
        ? {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [
              [0, overColor],
              [0.5, betweenColor],
              [1, underColor],
            ],
          }
        : undefined,
    }),
    [data, isDataCompare]
  );

  const secondarySeries = useMemo(
    () => ({
      ...secondaryData,
      type: "arearange",
      lineWidth: 0,
      zIndex: 0,
      marker: { enabled: false },
      enableMouseTracking: false,
      color: "#faf0ee",
      fillOpacity: 0.6,
    }),
    [secondaryData]
  );

  const regressionSeries = useMemo(
    () => makeRegressionSeries(regressionsData),
    [regressionsData]
  );

  const splitRegression1 = useMemo(
    () => makeRegressionSeries(splitRegression1Data),
    [splitRegression1Data]
  );

  const splitRegression2 = useMemo(
    () => makeRegressionSeries(splitRegression2Data),
    [splitRegression2Data]
  );

  const series = useMemo(
    () => [
      mainSeries,
      secondarySeries,
      regressionSeries,
      splitRegression1,
      splitRegression2,
    ],
    [
      mainSeries,
      secondarySeries,
      regressionSeries,
      splitRegression1,
      splitRegression2,
    ]
  );

  const options = {
    chart: {
      type: "spline",
      width: width || "auto",
    },
    title: { text: "" },
    credits: false,
    tooltip: false,
    legend: false,
    plotOptions: {
      series: {
        animation: false,
      },
    },
    yAxis: {
      title: {
        text: yAxis?.title,
        style: {
          fontWeight: "bold",
        },
      },
    },
    series,
  };

  return (
    <Grid>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Grid>
  );
};

export default GradientLineChart;
