import { useEffect, useState } from "react";
import {
  useLazyFetchTrialVideosQuery,
  useLazySearchPlayerDetailsQuery,
} from "../services/performanceApi.service";
import { extraPeopleColors } from "../three-js/extraPeopleColors";

export function useFetchExtraVideosDetails(extraMovements: any[]) {
  const [extraVideosDetails, setExtraVideosDetails] = useState<any[]>([]);

  const [fetchVideos] = useLazyFetchTrialVideosQuery();
  const [fetchPlayerDetails] = useLazySearchPlayerDetailsQuery();
  useEffect(() => {
    Promise.all(
      extraMovements
        ?.map((it, i) => ({ ...it, color: extraPeopleColors[i] }))
        ?.filter((it: any) => it.trial?.id && it.player?.id)
        .map((it: any, i) => {
          return Promise.all([
            fetchVideos(it.trial).unwrap(),
            fetchPlayerDetails(it.player.id).unwrap(),
          ]).then(([videos, playerDetails]) => ({
            movement: it,
            color: it.color,
            movementIndex: i,
            videos,
            playerDetails,
          }));
        })
    ).then((it) => setExtraVideosDetails(it));
  }, [extraMovements]);
  return { extraVideosDetails };
}
