import React from "react";
import Icon, {
  ArrowRightOutlined,
  ColumnHeightOutlined,
  FallOutlined,
  RiseOutlined,
} from "@ant-design/icons";
import { get, isNil } from "lodash";

export interface TrendConfiguration {
  color: string;
  background: string;
  icon: JSX.Element;
  label: string;
}

export type TrendType = "down" | "up" | "stable" | "variable";

export const trendsConfiguration = (type?: string) =>
  isNil(type) ? {} : get(trendsConfigurations, mapTrend(type) ?? type);

const EmptySvg = () => (
  <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 1024 1024">
    <title>empty icon</title>
  </svg>
);

export function EmptyTrendsIcon() {
  return <Icon component={EmptySvg} style={{ fontSize: 14 }} />;
}

export const trendsConfigurationOrDefault = (
  type?: string,
  defaultType: TrendType = "stable"
) => trendsConfiguration(type ?? defaultType);

export const trendsConfigurations: Record<TrendType, TrendConfiguration> = {
  down: {
    color: "var(--ptd-trend-color-down)",
    background: "var(--ptd-trend-background-down)",
    icon: (
      <FallOutlined
        style={{ color: "var(--ptd-trend-color-down)", fontSize: 14 }}
      />
    ),
    label: "Trending",
  },
  up: {
    color: "var(--ptd-trend-color-up)",
    background: "var(--ptd-trend-background-up)",
    icon: (
      <RiseOutlined
        style={{ color: "var(--ptd-trend-color-up)", fontSize: 14 }}
      />
    ),
    label: "Trending",
  },
  stable: {
    color: "var(--ptd-trend-color-stable)",
    background: "var(--ptd-trend-background-stable)",
    icon: (
      <ArrowRightOutlined
        style={{ color: "var(--ptd-trend-color-stable)", fontSize: 14 }}
      />
    ),
    label: "Stable",
  },
  variable: {
    color: "var(--ptd-trend-color-variable)",
    background: "var(--ptd-trend-background-variable)",
    icon: (
      <ColumnHeightOutlined
        style={{ color: "var(--ptd-trend-color-variable)", fontSize: 14 }}
      />
    ),
    label: "Variable",
  },
};

export const trendsMap: any = {
  Stable: "stable",
  Upwards: "up",
  Downwards: "down",
  Variable: "variable",
};

export function mapTrend(apiTrendValue: string) {
  return trendsMap[apiTrendValue] || null;
}
