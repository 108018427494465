import React from "react";
import { FiberManualRecord as FiberManualRecordIcon } from "@mui/icons-material";

export type SymbolVariants = "solidLine" | "circle" | "dashedLine";

interface SymbolProps {
  color?: string;
  variant: SymbolVariants;
}

const Symbol = ({ variant, color }: SymbolProps) => {
  const isLine = variant.includes("Line");

  if (isLine) {
    const isDashed = variant.startsWith("dashed");

    return (
      <div
        style={{
          height: "2px",
          width: "16px",
          borderWidth: "1px",
          borderColor: `var(--${color})`,
          borderStyle: isDashed ? "dashed" : "solid",
        }}
      ></div>
    );
  }

  return (
    <FiberManualRecordIcon fontSize="small" sx={{ color: `var(--${color})` }} />
  );
};

export default Symbol;
