import React, { useEffect, useState } from "react";
import { Input, Table, Spin } from "antd";
import { useFetchDiscreteMetricsQuery } from "../../../services/performanceApi.service";
import { Pitch } from "../../../services/mockData";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { selectSelectedMotionType } from "../../../redux/selectors";
const { Column } = Table;
export function DiscreteMetricDebugger({ trial }: { trial: Pitch }) {
  const motionType = useSelector(selectSelectedMotionType);
  const { data: discreteMetrics, isLoading } = useFetchDiscreteMetricsQuery({
    trial,
    motionType,
  });
  const [selectedMetrics, setSelectedMetrics] = useState<
    { key: string; value: any }[]
  >([]);
  useEffect(() => {
    if (discreteMetrics) {
      setSelectedMetrics(discreteMetrics);
    }
  }, [discreteMetrics]);
  if (isLoading) {
    return <Spin className="spinner" />;
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <Input
          placeholder="Discrete metric"
          onChange={(e) => {
            const metricId = e.target.value || "";
            const criteria = metricId.split(",");
            const filteredMetrics =
              criteria.reduce(
                (acc, it) =>
                  acc?.filter((metric) =>
                    `${metric.key}`
                      .toLowerCase()
                      .includes(it.trim().toLowerCase())
                  ) || [],
                discreteMetrics
              ) || [];
            setSelectedMetrics(filteredMetrics);
            console.log("metricid", metricId);
          }}
        ></Input>
      </Grid>
      <Grid item xs={12}>
        <Table dataSource={selectedMetrics}>
          <Column
            title="Variable"
            dataIndex={"action"}
            className="ptd-column ptd-column-variable"
            render={(_, metric: { key: string; value: any }) => (
              <span style={{ whiteSpace: "nowrap" }}>{metric.key}</span>
            )}
          />
          <Column
            title="Value"
            dataIndex={"action"}
            className="ptd-column ptd-column-variable"
            render={(
              _,
              metric: { name: string; value: any; onClick?: any }
            ) => {
              if (!metric.onClick) {
                return (
                  <span
                    style={{ whiteSpace: "nowrap" }}
                  >{`${metric.value}`}</span>
                );
              }
              return <a onClick={metric.onClick}>{`${metric.value}`}</a>;
            }}
          />
        </Table>
      </Grid>
    </Grid>
  );
}
