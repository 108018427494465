import React from "react";
import Autocomplete, {
  AutocompleteRenderOptionState,
} from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { DownOutlined, CalendarOutlined } from "@ant-design/icons";
import { DatePicker, Spin } from "antd";
import "./Selector.css";
import { getColorBySource } from "../../utils/colors";
import { uniqBy } from "lodash";
import dayjs from "dayjs";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { SourceDot } from "./SourceDot";

interface SelectorProps {
  label: string;
  options?: any[];
  value: any;
  onChange: (param?: any) => void;
  width?: string | number;
  height?: string | number;
  isDate?: boolean;
  getOptionLabel?: (option: any) => string;
  isOptionEqualToValue?: (option: any, value: any) => boolean;
  color?: string;
  dates?: any[];
  className?: string;
  isMetricSelector?: boolean;
  isLoading?: boolean;
  renderOption?:
    | ((
        props: React.HTMLAttributes<HTMLLIElement>,
        option: any,
        state: AutocompleteRenderOptionState
      ) => React.ReactNode)
    | undefined;
  center?: boolean;
  open?: boolean;
  getOptionDisabled?: (option: any) => boolean;
  disableClearable?: boolean;
  marginLeft?: React.CSSProperties["marginLeft"];
  marginRight?: React.CSSProperties["marginRight"];
}

const ICON_STYLE = { fontSize: "14px", color: "var(--greyIcon)" };

const Selector = ({
  label,
  value,
  onChange,
  options = [],
  width = "250px",
  height = "auto",
  isDate = false,
  getOptionLabel,
  isOptionEqualToValue,
  color = "var(--geekBlue)",
  dates = [],
  className,
  isMetricSelector,
  isLoading,
  renderOption,
  center = false,
  open,
  getOptionDisabled,
  disableClearable = true,
  marginLeft,
  marginRight,
}: SelectorProps) => {
  const Icon = isDate ? CalendarOutlined : DownOutlined;

  if (isDate) {
    return (
      <DatePicker
        open={open}
        showToday={false}
        style={{
          height: "30px",
          width,
          border: `1px solid ${color}`,
          borderRadius: "6px",
          marginLeft,
          marginRight,
        }}
        data-testid="single-date-picker-test"
        value={dayjs(value)}
        onPanelChange={(event) => {
          if (event !== null) {
            onChange(event);
          }
        }}
        onChange={(event) => {
          if (event !== null) {
            onChange(event);
          }
        }}
        cellRender={(current, info) => {
          if (info.type !== "date") {
            //Only customize date selection, not month/year/decade
            return info.originNode;
          }
          const daysWithTrialSources = uniqBy(
            dates,
            (date) => date.date.split("T")[0] + date.source
          ).map((date) => {
            const matchDate =
              date.date.split("T")[0] === dayjs(current).format("YYYY-MM-DD");

            return (
              <SourceDot
                key={"dateRender-" + date.id}
                source={date.source}
                visible={matchDate}
              />
            );
          });

          const date = dayjs(current).date();
          return (
            <div
              className="ant-calendar-date"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {date}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {daysWithTrialSources}
              </div>
            </div>
          );
        }}
        panelRender={(originalPanel) => {
          if (isLoading) {
            return (
              <Stack>
                {originalPanel}
                <Spin
                  className="spinner"
                  style={{
                    position: "absolute",
                    height: "85%",
                    width: "100%",
                    margin: 0,
                    bottom: 0,
                    background: "rgba(0, 0, 0, 0.1)",
                  }}
                />
              </Stack>
            );
          }

          const daysWithTrialSources = uniqBy(
            dates,
            (date) => date.date.split("T")[0] + date.source
          );

          return (
            <Grid width="280px" height="auto">
              <Grid container>{originalPanel}</Grid>

              <Grid
                container
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                {uniqBy(daysWithTrialSources, (date) => date.source).map(
                  (date) => (
                    <Grid
                      container
                      flexDirection="row"
                      justifyContent="center"
                      alignItems="center"
                      key={"panel-render-" + date.id}
                      width="100px"
                    >
                      <Grid item>
                        <Box
                          width="4px"
                          height="4px"
                          borderRadius="100%"
                          bgcolor={getColorBySource(date.source)}
                          margin="8px"
                        />
                      </Grid>

                      <Grid item>
                        <Typography fontSize={12}>{date.name}</Typography>
                      </Grid>
                    </Grid>
                  )
                )}
              </Grid>
            </Grid>
          );
        }}
      />
    );
  }

  return (
    <Autocomplete
      value={value}
      getOptionDisabled={getOptionDisabled}
      onChange={(_event, value) => onChange(value)}
      size="small"
      disableClearable={disableClearable}
      options={options}
      popupIcon={<Icon style={ICON_STYLE} />}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      className={`ptd-selector ${className}`}
      renderOption={renderOption}
      open={open}
      loading={isLoading}
      sx={{
        height,
        width,
        marginLeft,
        marginRight,
        "&.MuiAutocomplete-root": {
          ".MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${!isMetricSelector && color}`,
          },
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          placeholder={label}
          sx={{
            width,
            backgroundColor: "white",

            input: {
              height: 13,
              padding: 0,
              borderRadius: 6,
              fontSize: "14px",
              color: "black",
              fontFamily: "Lato",
              "&::placeholder": {
                opacity: 1,
              },
              textAlign: center ? "center" : "auto",
            },
          }}
          InputProps={
            isMetricSelector
              ? {
                  ...params.InputProps,
                  startAdornment: (
                    <span
                      style={{
                        height: "4px",
                        width: "4px",
                        borderRadius: "100%",
                        backgroundColor: color,
                      }}
                    />
                  ),
                }
              : { ...params.InputProps }
          }
          InputLabelProps={{}}
        />
      )}
    />
  );
};

export default Selector;
