import React from "react";
import { Grid, Typography } from "@mui/material";
import { Video } from "./Video";
import { trialDescriptionText, trialNumberText } from "../../utils/metrics";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { selectSelectedMotionType } from "../../redux/selectors";

interface NoOverlayVideoProps {
  trial: any;
  videoRef: any;
  videoUrl: any;
  isComparingMovement: any;
  mirrorMainTrialVideo?: boolean;
  comparingVideoRef: any;
  comparingVideoUrl: any;
  comparingMovement: any;
  extraVideos?: any[];
}

export default function NoOverlayVideo({
  trial,
  videoRef,
  videoUrl,
  isComparingMovement,
  mirrorMainTrialVideo,
  comparingVideoRef,
  comparingVideoUrl,
  comparingMovement,
  extraVideos,
}: NoOverlayVideoProps) {
  const mainMovementVideo = (
    <Video
      mirror={mirrorMainTrialVideo}
      width={"100%"}
      height={"100%"}
      videoRef={videoRef}
      videoUrl={videoUrl}
    />
  );
  if (!isComparingMovement) {
    return (
      <>
        <TrialName trial={trial} fullName />
        <Grid item xs={12} lg={6}>
          {mainMovementVideo}
        </Grid>
      </>
    );
  }
  const hasExtraVideos = !isEmpty(extraVideos);
  return (
    <Grid
      container
      spacing={hasExtraVideos ? 2 : 1}
      alignContent={"center"}
      justifyContent={"center"}
    >
      <Grid item md={6} lg={hasExtraVideos ? 4 : 6}>
        <TrialName trial={trial} />
        <Grid item>{mainMovementVideo}</Grid>
      </Grid>
      {isComparingMovement && (
        <Grid item md={6} lg={hasExtraVideos ? 4 : 6}>
          <TrialName trial={comparingMovement?.trial} />
          <Grid item>
            <Video
              width="100%"
              height="100%"
              videoRef={comparingVideoRef}
              videoUrl={comparingVideoUrl}
              comparative
            />
          </Grid>
        </Grid>
      )}
      {extraVideos?.map((it, i) => (
        <Grid item key={i} md={6} lg={4}>
          <TrialName trial={it.movement.trial} />
          <Grid item>
            <Video
              width="100%"
              height="100%"
              videoRef={it.videoRef}
              videoUrl={it.videoUrl}
              borderColor={it.color}
              comparative
            />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

const TrialName = ({ trial, fullName }: any) => {
  const motionType = useSelector(selectSelectedMotionType);
  return (
    <Grid container item xs={12} alignItems={"center"}>
      <div className="ptd-ellipse" />
      <Typography fontSize={14}>
        {fullName
          ? trialDescriptionText(trial, motionType)
          : trialNumberText(trial, motionType)}
      </Typography>
    </Grid>
  );
};
