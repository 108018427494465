import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { formatPolarSeriesData } from "../../utils/charts";

interface SpiderChartProps {
  data: number[];
  min?: number;
  max?: number;
  isPitcher: boolean;
}

export const categories = ["ROM", "Power", "Vision", "Body Comp", "MSK"];
const SpiderChart = ({
  data,
  min = 20,
  max = 80,
  isPitcher,
}: SpiderChartProps) => {
  const filteredCategories = categories.filter((label) => {
    if (!isPitcher) return true;
    return !["Vision"].includes(label);
  });
  const bounds = formatPolarSeriesData({
    data: filteredCategories.map(() => max),
    symbol: "square",
    pointPlacement: "on",
    categories: filteredCategories,
  });

  const formattedData = formatPolarSeriesData({
    data,
    isArea: true,
    pointPlacement: "on",
    categories: filteredCategories,
  });

  const chartOptions = {
    chart: { polar: true },
    credits: { enabled: false },
    legend: { enabled: false },
    title: { text: "" },
    pane: { size: "80%" },
    xAxis: {
      categories: filteredCategories,
      tickmarkPlacement: "on",
      lineWidth: 0,
      labels: { style: { color: "gray" } },
      min: 0,
      max: filteredCategories.length,
    },
    yAxis: {
      gridLineInterpolation: "polygon",
      lineWidth: 0,
      tickmarkPlacement: "on",
      labels: {
        style: { color: "transparent" },
      },
      categories: filteredCategories,
      max,
      min,
    },
    tooltip: {
      shared: true,
      pointFormat:
        '<span style="color:{series.color}"><b>{point.y:,.0f}</b><br/>',
    },
    plotOptions: {
      series: {
        color: "grey",
      },
      area: {
        fillOpacity: 0.25,
        lineWidth: 2,
      },
    },
    series: [bounds, formattedData],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 450,
          },
          chartOptions: {
            xAxis: {
              labels: {
                style: {
                  fontSize: "11px",
                },
              },
            },
            pane: {
              size: "60%",
            },
          },
        },
      ],
    },
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
      containerProps={{ style: { height: "300px" } }}
    />
  );
};

export default SpiderChart;
