import React, { Fragment, memo } from "react";
import { Grid, Typography } from "@mui/material";
import { isNil, round } from "lodash";
import BulletChart from "../../../charts/BulletChart";
import { useSelector } from "react-redux";
import {
  selectIsSelfCompare,
  selectSelectedMotionType,
} from "../../../../redux/selectors";
import { getIsPitching } from "../../../../utils/motionType";

interface SequenceNormalRangesProps {
  rangeValues: any[][];
}

function SequencePeakValues({ rangeValues }: SequenceNormalRangesProps) {
  const isSelfCompare = useSelector(selectIsSelfCompare);
  const motionType = useSelector(selectSelectedMotionType);
  const isPitching = getIsPitching(motionType);
  return (
    <Grid container textAlign={"end"}>
      <Grid container textAlign={"center"}>
        <Grid item xs={6}>
          <BoldText label="Rotation Velocity (°/s)" />
        </Grid>
        <Grid item xs={6} mb={3}>
          <BoldText
            label={`Peak Timing (ms before ${
              isPitching ? "release" : "contact"
            })`}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" textAlign={"start"}>
        {rangeValues.map((metricList) =>
          metricList.map(
            (
              { id, value, description, averageRange, label, secondaryValue },
              index
            ) => (
              <Fragment key={id + index}>
                {label && (
                  <Grid item xs={12}>
                    <BoldText label={`${label} ${description}`} />
                  </Grid>
                )}
                <Grid item xs={6}>
                  <BulletChart
                    value={value}
                    averageRange={{
                      to: round(averageRange?.to),
                      from: round(averageRange?.from),
                    }}
                    title={""}
                    unit={""}
                    isSelf={isSelfCompare}
                    secondaryValue={secondaryValue}
                    noValue={isNil(value) || value === "-"}
                  />
                </Grid>
              </Fragment>
            )
          )
        )}
      </Grid>
    </Grid>
  );
}

const BoldText = ({ label }: { label: string }) => (
  <Typography fontWeight={600} fontSize={13} pr={1}>
    {label}
  </Typography>
);

export default memo(SequencePeakValues);
