import { startsWith } from "lodash";
import { getApiLocation } from "../../Components/api";

export interface Cache {
  get(key: string): any;
  set(key: string, value: any): any;
  remove(key: string): any;
  clear(): void;
}

export class MemoryCache implements Cache {
  cache: any;
  constructor() {
    this.clear();
  }

  async withCache(key: string, operation: () => Promise<any>) {
    const cachedResult = this.get(key);
    if (cachedResult) return cachedResult;
    const result = await operation().catch(() => operation());

    this.set(key, result);
    return result;
  }

  get(key: string) {
    return this.cache[key];
  }

  set(key: string, value: any) {
    this.cache[key] = value;
  }

  remove(key: string) {
    delete this.cache[key];
  }

  clear() {
    this.cache = {};
  }
}
const MINUTES = 60 * 1000;
const DEFAULT_LOCAL_STORAGE_CACHE_TTL =
  Number(process.env.REACT_APP_DEFAULT_LOCAL_STORAGE_CACHE_TTL) || 60;
const defaultLocalStorageCacheTTL = DEFAULT_LOCAL_STORAGE_CACHE_TTL * MINUTES;
const PERSISTENT_CACHE_VERSION = "v6";
const BASE_PERSISTENT_CACHE_KEY = `persistentCache-${getApiLocation()}-`;
// Setting to true to disable while persistent cache being reconsidered.
// Was previously set to:
//   process.env.REACT_APP_PTD_PERSISTENT_CACHE_DISABLED === "true";
const PERSISTENT_CACHE_DISABLED = true;

export class LocalStorageCache extends MemoryCache {
  isEnabled = false;
  constructor(forceEnabled = false) {
    super();
    this.isEnabled = forceEnabled || !PERSISTENT_CACHE_DISABLED;
    if (this.isEnabled) {
      this._clearIfNeeded();
      setInterval(() => this._clearIfNeeded(), defaultLocalStorageCacheTTL);
    }
  }

  remove(key: string) {
    if (!this.isEnabled) return;
    try {
      localStorage.removeItem(this.buildKey(key));
    } catch (e) {}
  }

  set(key: string, value: any) {
    if (!this.isEnabled) return;
    this.setWithExpiry(key, value, defaultLocalStorageCacheTTL);
  }

  setWithExpiry(key: string, value: any, ttl: number) {
    if (!this.isEnabled) return;
    ttl = ttl || defaultLocalStorageCacheTTL;
    try {
      const now = new Date();
      const item = {
        value: value,
        expiry: now.getTime() + ttl * MINUTES,
      };
      localStorage.setItem(this.buildKey(key), JSON.stringify(item));
    } catch (e) {
      //      console.log("Error caching in localStorage", key, value, e)
    }
  }

  get(key: string, fullKey = false) {
    if (!this.isEnabled) return null;
    try {
      const itemStr = localStorage.getItem(fullKey ? key : this.buildKey(key));
      // if the item doesn't exist, return null
      if (!itemStr) {
        return null;
      }

      const item = JSON.parse(itemStr);
      const now = new Date();
      // compare the expiry time of the item with the current time
      if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        this.remove(key);
        return null;
      }
      return item.value;
    } catch (e) {
      return null;
    }
  }

  _clearIfNeeded() {
    try {
      Object.keys(localStorage)
        .filter((it) => startsWith(it, BASE_PERSISTENT_CACHE_KEY))
        .forEach((key) => this.get(key));
    } catch (e) {
      return null;
    }
  }

  buildKey(key: string): string {
    return (
      BASE_PERSISTENT_CACHE_KEY + "/" + PERSISTENT_CACHE_VERSION + "/" + key
    );
  }
}
