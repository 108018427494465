import React from "react";

const PitchIcon = () => (
  <svg
    width="40"
    height="13"
    viewBox="0 0 51 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 11.5H49.5V6.5L25.5 1.5L1.5 6.5V11.5Z"
      stroke="#C3C5C6"
      strokeWidth="2"
    />
  </svg>
);

export default PitchIcon;
