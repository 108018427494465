import React, { useMemo } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highcharts.js";
import { alpha } from "@mui/material/styles";
import { Grid } from "@mui/material";
import ValueBox from "../common/ValueBox";
import { getSliderColorByValue } from "../../utils/colors";
import {
  fixOffsetValue,
  getVarValue,
  markerSVG,
  getTickPositionsFromAverage,
} from "../../utils/charts";
import { isNil, round, isEmpty } from "lodash";
import { selectIsDataCompare } from "../../redux/selectors";
import { useSelector } from "react-redux";
import { MetricTooltip } from "../common/MetricTooltip";
import { getBounds } from "../../utils/metrics";
import { ClickableText } from "../common/AntdExtensions";
import { toLocaleString } from "../../utils/strings";
import { Unless } from "react-if";

type Series = {
  x: number;
  y: number;
  color: string;
  marker: {
    enabled?: boolean;
    symbol?: string;
    lineWidth?: number;
  };
  dataLabels: {
    enabled?: boolean;
    useHTML?: boolean;
    verticalAlign: string;
    y: number;
    formatter?: (color?: string) => string | void;
  };
};

interface BulletChartProps {
  title?: string;
  mean?: number;
  height?: number;
  width?: number;
  marginTop?: number;
  marginBottom?: number;
  averageRange: { from: number; to: number };
  value: number;
  noValue?: boolean;
  unit: string;
  tickPositions?: number[];
  secondaryValue?: number;
  extraValues?: { color: string; value: number }[];
  isSelf?: boolean;
  dontShowValueBox?: boolean;
  dontShowLabel?: boolean;
  dontShowTickPositions?: boolean;
  extraLabel?: string;
  TooltipModal?: any;
  decimals?: number;
  formatSvgIcon?: (color?: string, index?: number) => string;
}

const BulletChart = ({
  value,
  secondaryValue,
  extraValues,
  averageRange,
  noValue,
  height = 78,
  width,
  marginTop = 10,
  marginBottom,
  title,
  unit,
  isSelf,
  extraLabel,
  dontShowValueBox = false,
  dontShowLabel = false,
  dontShowTickPositions = false,
  TooltipModal = false,
  decimals,
  formatSvgIcon,
}: BulletChartProps) => {
  const { from, to } = averageRange || { from: 0, to: 0 };
  const fixDataCompValue = (value: number) =>
    value >= 1000 ? round(value) : value;
  const isDataCompare = useSelector(selectIsDataCompare);
  const accentColor = isDataCompare
    ? getSliderColorByValue(value, { from, to })
    : "var(--hoveredGrey)";

  const { meanTick, start, end } = getTickPositionsFromAverage({ from, to });

  const fixedTickPositions = [start, from, meanTick, to, end];
  const { min, max } = getBounds(fixedTickPositions);

  // TODO: some usages of this component include the unit in the title but also
  // in the unit attribute. We should get rid of those and let the component handle
  // the actual title.
  const titleWithUnit = isNil(title)
    ? undefined
    : `${title} ${unit && !title.includes(`(${unit})`) ? `(${unit})` : ""}`;

  let series: Series[] = [
    {
      x: 0,
      y: fixOffsetValue(value, { min, max }),
      marker: {
        enabled: isNil(formatSvgIcon),
        symbol: markerSVG(
          getVarValue(secondaryValue ? "var(--main-accent-color)" : accentColor)
        ),
        lineWidth: 1,
      },
      dataLabels: {
        enabled: !isNil(formatSvgIcon),
        useHTML: true,
        verticalAlign: "middle",
        y: 0,
        formatter: function () {
          if (formatSvgIcon) {
            return formatSvgIcon(
              getVarValue(
                secondaryValue ? "var(--main-accent-color)" : accentColor
              ),
              !isNil(secondaryValue) ? 1 : undefined
            );
          }
        },
      },
      color: secondaryValue ? "var(--main-accent-color)" : accentColor,
    },
  ];
  const appendValueSeries = (
    value: number,
    color: string,
    isSecondary?: boolean
  ) => {
    const newSeries: Series = {
      x: 0,
      y: fixOffsetValue(value, { min, max }),
      marker: {
        enabled: isNil(formatSvgIcon),
        symbol: markerSVG(getVarValue(color)),
        lineWidth: 1,
      },
      dataLabels: {
        enabled: !isNil(formatSvgIcon),
        useHTML: true,
        verticalAlign: "middle",
        y: 0,
        formatter: function () {
          if (formatSvgIcon) {
            return formatSvgIcon(getVarValue(color), !isSecondary ? 1 : 2);
          }
        },
      },
      color,
    };

    series = series.concat(newSeries);
  };
  if (secondaryValue) {
    appendValueSeries(
      secondaryValue,
      `var(--${isSelf ? "self" : "secondary"}-accent-color)`,
      true
    );
  }
  if (!isEmpty(extraValues)) {
    extraValues?.forEach((extraValue) =>
      appendValueSeries(extraValue.value, extraValue.color, true)
    );
  }

  series = noValue ? [] : series;

  const options = {
    chart: {
      marginTop,
      marginBottom,
      height,
      width: width,
      backgroundColor: "transparent",
      borderColor: undefined,
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
    },
    title: { text: "" },
    credits: { enabled: false },
    legend: { enabled: false },
    plotOptions: {
      series: {
        enableMouseTracking: false,
        animation: false,
        pointWidth: 3,
      },
    },
    xAxis: [{ visible: false }, { visible: false }],
    yAxis: [
      {
        min: 0,
        max: 0.1,
        maxPadding: 0,
        visible: false,
        zIndex: 3,
      },
      {
        min: fixDataCompValue(min),
        max: fixDataCompValue(max),
        gridLineWidth: 1,
        enabled: false,
        tickPositions: fixedTickPositions,
        tickColor: !dontShowTickPositions ? "#B1CAE9" : "transparent",
        tickWidth: 3,
        tickLength: 6,
        gridLineColor: "transparent",
        zIndex: 1,
        plotBands: isDataCompare
          ? [
              {
                from: fixDataCompValue(from),
                to: fixDataCompValue(to),
                zIndex: 5,
                color: alpha("#BFD7EE", 0.3),
                borderColor: "transparent",
              },
            ]
          : [],
        title: { text: "" },
        labels: {
          formatter: (thisValue: any) =>
            toLocaleString(thisValue?.value, { decimals }),
          style: { fontSize: "11px" },
        },
      },
      {
        opposite: true,
        min: 0,
        max,
        gridLineWidth: 0,
        enabled: true,
        title: {
          text: "",
        },
        labels: {
          formatter: function (): string {
            const thisPoint: any = this;
            const formattedValue = thisPoint.value?.toLocaleString
              ? thisPoint.value.toLocaleString()
              : thisPoint;
            return `<div style="color: transparent;">${formattedValue}${unit}</div>`;
          },
        },
      },
    ],
    series: [
      {
        type: "bar",
        linkedTo: "main",
        pointStart: 1,
        showInLegend: false,
        xAxis: 0,
        yAxis: 0,
        data: [1],
        borderRadius: 8,
        color: "#f0f0f0",
        borderColor: "#f0f0f0",
      },
      {
        type: "scatter",
        id: "main",
        xAxis: 1,
        yAxis: 1,
        marker: {
          symbol: "line",
          lineColor: null,
          lineWidth: 1,
        },
        data: series,
      },
    ],
  };

  let cachedHighcharts = useMemo(
    () => <HighchartsReact highcharts={Highcharts} options={options} />,
    [JSON.stringify(options), isDataCompare, isSelf]
  );

  const TooltipContainer = (props: any) =>
    TooltipModal ? (
      {
        ...TooltipModal,
        props: {
          ...TooltipModal.props,
          children: <ClickableText>{titleWithUnit}</ClickableText>,
        },
      }
    ) : (
      <MetricTooltip {...props} />
    );

  return (
    <Grid container item xs={12} justifyContent="flex-start" pr={1}>
      <Unless condition={dontShowLabel}>
        <Grid item xs={"auto"}>
          <TooltipContainer
            max={max}
            min={min}
            averageRange={{ from, to }}
            value={value}
            title={titleWithUnit || ""}
            unit={unit}
          >
            <ClickableText>{titleWithUnit}</ClickableText>
          </TooltipContainer>

          {extraLabel && (
            <Grid item sx={{ color: "rgba(0, 0, 0, 0.45)" }}>
              {extraLabel}
            </Grid>
          )}
        </Grid>
      </Unless>

      <Grid
        container
        item
        xs={12}
        wrap="nowrap"
        justifyContent={dontShowValueBox ? "center" : "space-between"}
        sx={noValue ? { opacity: 0.4 } : {}}
      >
        {!dontShowValueBox && (
          <Grid
            container
            item
            xs={secondaryValue != null ? 4 : 2}
            height="100%"
          >
            <ValueBox
              value={value}
              noValue={noValue}
              range={{ from, to }}
              accentColor={
                !isNil(secondaryValue) ? "var(--main-accent-color)" : undefined
              }
              decimals={decimals}
            />
            {secondaryValue != null && (
              <ValueBox
                value={secondaryValue}
                noValue={noValue}
                range={{ from, to }}
                accentColor={`var(--${
                  isSelf ? "self" : "secondary"
                }-accent-color)`}
                isSecondary
                decimals={decimals}
              />
            )}
          </Grid>
        )}
        <Grid
          item
          xs={dontShowValueBox ? "auto" : secondaryValue != null ? 8 : 10}
        >
          {cachedHighcharts}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BulletChart;
