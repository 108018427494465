import React, { memo } from "react";
import BodyPartTable from "./BodyPartTable";
import useBodyPartData from "../../../../hooks/useBodyPartData";
import { BodyPartTabs } from "../../../../services/mockData";
import { Grid } from "@mui/material";
import BodyPartLineChart from "./BodyPartLineChart";

interface BodyPartsLayoutProps {
  bodyPartName: BodyPartTabs;
  container?: any;
}

export interface BodyPartsLayoutChildren {
  container?: any;
}

const BodyPartsLayout = ({ bodyPartName, container }: BodyPartsLayoutProps) => {
  const {
    data,
    comparingData,
    isSelfCompare,
    metrics,
    handleMetric,
    secondaryData,
    isDataCompare,
    keyframesData,
    discreteSelfData,
  } = useBodyPartData({
    bodyPartName,
  });

  return (
    <Grid container justifyContent="center">
      {data && (
        <BodyPartTable
          data={data}
          comparingData={isSelfCompare ? discreteSelfData : comparingData || []}
          isSelfCompare={isSelfCompare}
          handleMetric={handleMetric}
        />
      )}

      <BodyPartLineChart
        metrics={metrics}
        secondaryData={secondaryData}
        isSelfCompare={isSelfCompare}
        isDataCompare={isDataCompare}
        keyframesData={keyframesData}
        container={container}
      />
    </Grid>
  );
};

export default memo(BodyPartsLayout);
