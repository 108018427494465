import React, { useMemo, useState } from "react";
import { Button, Divider, Typography, Radio } from "antd";
import { Chip, Grid, Popover, Stack } from "@mui/material";
import { QUALIFIERS } from "./mockFilters";
import { Unless } from "react-if";
import { findIndex, isNil, sortBy } from "lodash";
import { useSelector } from "react-redux";
import { selectMovement, selectSelfCompOptions } from "../../redux/selectors";
import { useFetchSelfCompPitchTypesQuery } from "../../services/performanceApi.service";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { usePerformanceAppDispatch } from "../../redux/hooks";
import { setSelfCompOptions } from "../../redux/movementSlice";
import { pitchTypesByPriority } from "../../utils/metrics";
import { Player } from "../../services/mockData";

const { Text, Title } = Typography;

export type PitchTypesSelectorValues = {
  pitchType?: string;
  sup75Perc?: boolean;
};
interface PitchTypesSelectorProps {
  player?: Player;
  values?: PitchTypesSelectorValues;
  onChange?: (key: string, newValue: string | boolean) => void;
}
const PitchTypesSelector = ({
  player: playerProp,
  values,
  onChange,
}: PitchTypesSelectorProps) => {
  const dispatch = usePerformanceAppDispatch();
  const { player: storePlayer } = useSelector(selectMovement);
  const player = !isNil(playerProp?.id) ? playerProp : storePlayer;
  const { data: allPitchTypes } = useFetchSelfCompPitchTypesQuery(
    isNil(player) ? skipToken : player?.id
  );
  const { payload } = useSelector(selectSelfCompOptions);
  const { pitchType, sup75Perc } = !isNil(values) ? values : payload;
  const [anchorEl, setAnchorEl] = useState(null);
  const mappedQualifier = sup75Perc ? "sup75Perc" : "all";
  const open = anchorEl !== null;

  const pitchTypes = useMemo(() => {
    const availablePitchTypes = sortBy(allPitchTypes, (it) => {
      const index = findIndex(
        pitchTypesByPriority,
        (element) => element == it.pitch_type
      );
      return index === -1 ? 99 : index;
    });

    return availablePitchTypes.map((it) => ({
      label: it.pitch_type,
      value: it.pitch_type,
      checked: it.pitch_type === pitchType,
    }));
  }, [allPitchTypes, pitchTypesByPriority, pitchType, sup75Perc]);

  const qualifiers = QUALIFIERS.map((it) => ({
    ...it,
    checked: mappedQualifier === it.value,
  }));

  const handleCancel = () => {
    setAnchorEl(null);
  };

  const updateSelfCompOptions = (newValue: {
    [key: string]: string | boolean;
  }) => {
    if (!isNil(onChange)) {
      const keys = Object.keys(newValue);

      keys.forEach((key: any) => {
        onChange(key, newValue[key]);
      });
    } else {
      dispatch(
        setSelfCompOptions({
          type: "Session",
          payload: { ...payload, ...newValue },
        })
      );
    }
  };

  const updatePitchType = (pitchType: string) => {
    updateSelfCompOptions({ pitchType });
  };

  const updateQualifiers = (sup75Perc: boolean) => {
    updateSelfCompOptions({ sup75Perc });
  };

  const clearAll = () => {
    updateSelfCompOptions({ pitchType: pitchTypes[0].value, sup75Perc: false });
  };

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="center"
      item
      xs={12}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Button onClick={(event: any) => setAnchorEl(event.currentTarget)}>
          Pitch Type
        </Button>

        <Unless condition={isNil(pitchType)}>
          <Chip variant="outlined" size="small" label={pitchType} />
        </Unless>

        <Unless condition={!sup75Perc}>
          <Chip
            size="small"
            label={!sup75Perc ? "All" : ">75%"}
            onDelete={() => updateQualifiers(false)}
          />
        </Unless>
      </Stack>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleCancel}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        style={{ zIndex: 1000 }}
      >
        <Stack spacing={1} m={2} px={2}>
          <Title level={4}>Filters</Title>
          <Divider style={{ marginTop: 0 }} />

          <Text>Pitch Types</Text>
          {pitchTypes.map((pitchType: any) => (
            <Radio
              key={pitchType.value}
              value={pitchType.value}
              onChange={(event) => {
                updatePitchType(event.target.value);
              }}
              checked={pitchType.checked}
            >
              {pitchType.label}
            </Radio>
          ))}

          <Text>Qualifiers</Text>
          {qualifiers.map((qualifier: any) => (
            <Radio
              key={qualifier.value}
              value={qualifier.value}
              onChange={(event) => {
                const mappedValue = event.target.value === "sup75Perc";
                updateQualifiers(mappedValue);
              }}
              checked={qualifier.checked}
            >
              {qualifier.label}
            </Radio>
          ))}

          <Button type="link" onClick={clearAll}>
            Clear all filters
          </Button>
        </Stack>
      </Popover>
    </Grid>
  );
};

export default PitchTypesSelector;
