import React, { memo } from "react";
import { Grid } from "@mui/material";
import MetricSelector from "../../../common/MetricSelector";
import MetricsAddOrRemove from "../../../common/MetricsAddOrRemove";
import LineChart from "../../../charts/LineChart";
import { useSelector } from "react-redux";
import { selectSelectedMotionType } from "../../../../redux/selectors";
import { MotionType } from "../../../common/MotionType";

interface SequenceGraphProps {
  hasLineChartData: boolean;
  lineChartData: any;
  alternativeData: any[];
  fixedKeyFrames: any;
  metrics: any[];
  data: any[];
  updateMetric: (value: any, index: number) => void;
  addMetric: () => void;
  removeMetric: () => void;
  canAddMetric: boolean;
  parentHeight?: number;
}

function SequenceGraph({
  hasLineChartData,
  lineChartData,
  alternativeData,
  fixedKeyFrames,
  metrics,
  data,
  updateMetric,
  addMetric,
  removeMetric,
  canAddMetric,
  parentHeight,
}: SequenceGraphProps) {
  const motionType = useSelector(selectSelectedMotionType);
  return (
    <Grid container>
      <Grid item xs={12}>
        {hasLineChartData && (
          <LineChart
            data={lineChartData}
            secondaryData={alternativeData}
            height={`${parentHeight ? parentHeight - 310 : 280}px`}
            plotLines={fixedKeyFrames}
            marginTop={10}
            showTooltip
            setInitialZoom={motionType === MotionType.Pitching}
            stickyTracking
            tooltipDirection="column"
          />
        )}
      </Grid>

      <Grid container item>
        <Grid container item xs>
          {metrics.map((metric, index) => (
            <Grid item xs={6} key={"metric-selector-" + index}>
              <MetricSelector
                value={metric}
                onChange={(value) => {
                  updateMetric(value, index);
                }}
                options={data}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                checked={metric.visible}
                setChecked={(value: boolean) => {
                  updateMetric({ ...metric, visible: value }, index);
                }}
                color={metric.color}
                width="100%"
              />
            </Grid>
          ))}
        </Grid>

        <MetricsAddOrRemove
          canAddMetric={canAddMetric}
          metrics={metrics}
          onAdd={addMetric}
          onRemove={removeMetric}
        />
      </Grid>
    </Grid>
  );
}

export default memo(SequenceGraph);
