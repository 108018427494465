import dayjs from "dayjs";

const GENERIC_DATA_VALUE = [
  121.272374, 121.074741, 120.840607, 120.564905, 120.247386, 119.891955,
  119.506027, 119.099837, 118.685482, 118.275617, 117.881993, 117.514177,
  117.178716, 116.878861, 116.614799, 116.384239, 116.183135, 116.006369,
  115.84823, 115.702643, 115.563184, 115.42303, 115.275021, 115.111971,
  114.927261, 114.715647, 114.474054, 114.202164, 113.902598, 113.580661,
  113.243701, 112.900255, 112.559164, 112.228795, 111.916422, 111.627797,
  111.366873, 111.135665, 110.934164, 110.760273, 110.609742, 110.476176,
  110.351194, 110.224809, 110.086082, 109.924017, 109.728626, 109.491958,
  109.208887, 108.877525, 108.499246, 108.078422, 107.622015, 107.139124,
  106.640592, 106.138655, 105.646497, 105.177525, 104.744261, 104.356984,
  104.022434, 103.742944, 103.516243, 103.335987, 103.192934, 103.07652,
  102.976542, 102.884597, 102.794985, 102.704862, 102.613661, 102.522018,
  102.430592, 102.339117, 102.245861, 102.147546, 102.039677, 101.917188,
  101.775292, 101.610423, 101.421161, 101.208943, 100.97827, 100.736157,
  100.490806, 100.249895, 100.019045, 99.801041, 99.595996, 99.402295,
  99.217809, 99.040866, 98.870649, 98.707043, 98.550119, 98.399488, 98.253715,
  98.109952, 97.963974, 97.810732, 97.645416, 97.464777, 97.268301, 97.058809,
  96.842227, 96.626509, 96.419983, 96.229538, 96.059114, 95.908845, 95.775046,
  95.651035, 95.528638, 95.400021, 95.259418, 95.104317, 94.935874, 94.758583,
  94.579395, 94.40652, 94.248127, 94.111111, 94.00009, 93.916816, 93.860173,
  93.82683, 93.812354, 93.812395, 93.82354, 93.843592, 93.871302, 93.905754,
  93.945624, 93.988519, 94.030538, 94.066257, 94.089279, 94.093335, 94.073705,
  94.028545, 93.959691, 93.872587, 93.775315, 93.676967, 93.585838, 93.507906,
  93.445946, 93.399365, 93.364639, 93.336177, 93.307428, 93.272091, 93.225269,
  93.164365, 93.089519, 93.003457, 92.910735, 92.816572, 92.725586, 92.640778,
  92.563007, 92.491012, 92.421932, 92.352125, 92.278059, 92.197048, 92.107671,
  92.009871, 91.904858, 91.794939, 91.683318, 91.573845, 91.470664, 91.377781,
  91.2986, 91.235507, 91.189548, 91.160244, 91.145556, 91.142067, 91.14537,
  91.150619, 91.153137, 91.14899, 91.13544, 91.111248, 91.076764, 91.033796,
  90.985265, 90.9347, 90.885663, 90.841212, 90.803528, 90.77375, 90.752057,
  90.738016, 90.731129, 90.731376, 90.73951, 90.756923, 90.785132, 90.82509,
  90.876667, 90.938595, 91.008947, 91.085989, 91.168988, 91.2586, 91.356571,
  91.464832, 91.584294, 91.713795, 91.849548, 91.98529, 92.113025, 92.224159,
  92.310739, 92.36657, 92.388061, 92.37469, 92.329069, 92.256589, 92.16475,
  92.062253, 91.95798, 91.85993, 91.774261, 91.704673, 91.652318, 91.616305,
  91.594563, 91.584762, 91.58495, 91.593811, 91.610578, 91.634751, 91.665794,
  91.702899, 91.744909, 91.790391, 91.837762, 91.885319, 91.931105, 91.972665,
  92.006867, 92.029939, 92.037836, 92.026925, 91.994816, 91.941132, 91.867992,
  91.780076, 91.684205, 91.588496, 91.501244, 91.429744, 91.379233, 91.352174,
  91.348017, 91.363521, 91.393552, 91.432147, 91.473573, 91.513147, 91.547665,
  91.575443, 91.596087, 91.61016, 91.61882, 91.623438, 91.625172, 91.624589,
  91.621488, 91.615036, 91.604185, 91.588195, 91.567043, 91.541585, 91.513436,
  91.484673, 91.457473, 91.433835, 91.415417, 91.403482, 91.398917, 91.402258,
  91.413751, 91.433416, 91.461172, 91.497014, 91.541212, 91.594378, 91.657329,
  91.730737, 91.814727, 91.908588, 92.010623, 92.118163, 92.227708, 92.335223,
  92.436545, 92.527822, 92.605938, 92.668828, 92.715699, 92.747074, 92.764688,
  92.771259, 92.770216, 92.765424, 92.760871, 92.760276, 92.766645, 92.781891,
  92.806634, 92.84026, 92.881223, 92.927527, 92.977257, 93.029015, 93.082122,
  93.136506, 93.192321, 93.249474, 93.307273, 93.364334, 93.418803, 93.468811,
  93.512973, 93.550746, 93.58259, 93.609906, 93.634832, 93.659938, 93.687927,
  93.721379, 93.762595, 93.813493, 93.875578, 93.949936, 94.037217, 94.137486,
  94.249968, 94.372801, 94.502991, 94.636689, 94.769686, 94.89792, 95.017759,
  95.126034, 95.219963, 95.297199, 95.356176, 95.396685, 95.420405, 95.431014,
  95.433663, 95.43383, 95.435904, 95.442002, 95.451519, 95.461611, 95.46839,
  95.468315, 95.459266, 95.440995, 95.414884, 95.383211, 95.348246, 95.311524,
  95.273503, 95.233649, 95.190785, 95.143471, 95.090239, 95.029622, 94.960074,
  94.879898, 94.787248, 94.680241, 94.557236, 94.417269, 94.2606, 94.089234,
  93.907255, 93.720846, 93.537934, 93.367446, 93.218253, 93.097947, 93.011673,
  92.96127, 92.944888, 92.957167, 92.990036, 93.034066, 93.080229, 93.121658,
  93.154965, 93.180782, 93.203377, 93.229482, 93.266593, 93.321157, 93.397003,
  93.494347, 93.609564, 93.735748, 93.863946, 93.98471, 94.089569, 94.172129,
  94.228688, 94.258341, 94.262589, 94.244516, 94.207765, 94.15562, 94.090436,
  94.013505, 93.925297, 93.825976, 93.716095, 93.597315, 93.472959, 93.348161,
  93.22948, 93.124007, 93.038178, 92.976597, 92.941224, 92.931167, 92.943127,
  92.972272, 93.013197, 93.060666, 93.110008, 93.157273, 93.199277, 93.23357,
  93.258251, 93.271673, 93.272176, 93.258058, 93.227866, 93.180909, 93.117862,
  93.041248, 92.955577, 92.86694, 92.782036, 92.706835, 92.645299, 92.598593,
  92.565027, 92.540702, 92.520627, 92.499964, 92.475104, 92.444345, 92.408084,
  92.368533, 92.329123, 92.293773, 92.266225, 92.249559, 92.245943, 92.256575,
  92.281751, 92.320978, 92.373082, 92.436287, 92.508311, 92.586471, 92.667809,
  92.749183, 92.82732, 92.898825, 92.960174, 93.007775, 93.038149, 93.048295,
  93.036205, 93.00143, 92.945519, 92.872177, 92.78702, 92.696937, 92.609161,
  92.53023, 92.464985, 92.415792, 92.382126, 92.360648, 92.345778, 92.330666,
  92.308372, 92.273036, 92.220865, 92.150743, 92.064379, 91.96602, 91.861865,
  91.759343, 91.666332, 91.590397, 91.538168, 91.514939, 91.524492, 91.569005,
  91.648899, 91.762602, 91.906333, 92.074141, 92.25837, 92.450569, 92.642638,
  92.827865, 93.00153, 93.160872, 93.304481, 93.431415, 93.540391, 93.62932,
  93.695263, 93.734756, 93.74447, 93.722061, 93.667003, 93.581154, 93.468912,
  93.337007, 93.193999, 93.049576, 92.913684, 92.795599, 92.703018, 92.641237,
  92.612502, 92.615652, 92.646253, 92.697276, 92.760221, 92.826395, 92.88804,
  92.939115, 92.975677, 92.995936, 93.000012, 92.989472, 92.966706, 92.934286,
  92.894486, 92.849102, 92.799566, 92.747245, 92.693686, 92.640634, 92.589777,
  92.542326, 92.498665, 92.458217, 92.419593, 92.380913, 92.340215, 92.295819,
  92.246571, 92.191908, 92.131774, 92.066493, 91.996762, 91.923825, 91.84975,
  91.777621, 91.71142, 91.655531, 91.613919, 91.58925, 91.582249, 91.591442,
  91.613257, 91.642377, 91.672206, 91.695411, 91.704565, 91.692935, 91.655407,
  91.589471, 91.496035, 91.379835, 91.24921, 91.11513, 90.989602, 90.88378,
  90.806291, 90.762171, 90.752608, 90.775404, 90.825938, 90.898276, 90.986115,
  91.083331, 91.184163, 91.28326, 91.375781, 91.457656, 91.525912, 91.578877,
  91.616183, 91.638563, 91.647607, 91.645614, 91.635515, 91.620703, 91.60457,
  91.589801, 91.577687, 91.567879, 91.558802, 91.548621, 91.536371, 91.522745,
  91.510179, 91.502145, 91.501856, 91.51082, 91.527799, 91.548586, 91.566767,
  91.575243, 91.568026, 91.541709, 91.496221, 91.43479, 91.363314, 91.289407,
  91.221328, 91.166918, 91.13262, 91.122673, 91.1386, 91.179171, 91.240854,
  91.318602, 91.406687, 91.499388, 91.591455, 91.678428, 91.756836, 91.824322,
  91.879721, 91.923092, 91.955689, 91.979816, 91.998552, 92.015374, 92.033742,
  92.056701, 92.086556, 92.12465, 92.171281, 92.225758, 92.286583, 92.351692,
  92.418692, 92.485071, 92.548402, 92.606552, 92.657896, 92.701508, 92.737373,
  92.766624, 92.791721, 92.816356, 92.844951, 92.881823, 92.930263, 92.991795,
  93.06578, 93.149391, 93.237949, 93.325519, 93.405656, 93.472209, 93.520061,
  93.545777, 93.548054, 93.527929, 93.488674, 93.435338, 93.373981, 93.310715,
  93.25082, 93.198163, 93.155069, 93.122597, 93.100971, 93.089922, 93.088775,
  93.096322, 93.110682, 93.129317, 93.149313, 93.167884, 93.18298, 93.19381,
  93.201131, 93.20722, 93.21552, 93.230064, 93.254773, 93.292734, 93.345498,
  93.412473, 93.490532, 93.574007, 93.655175, 93.725247, 93.775654, 93.799387,
  93.792091, 93.752707, 93.683576, 93.589987, 93.479261, 93.359544, 93.238578,
  93.122689, 93.016233, 92.921581, 92.83961, 92.770479, 92.714405, 92.672157,
  92.645134, 92.635067, 92.64348, 92.671064, 92.717122, 92.779241, 92.853301,
  92.933817, 93.014588, 93.08953, 93.153545, 93.203217, 93.237105, 93.255565,
  93.260213, 93.253312, 93.237352, 93.214982, 93.189254, 93.163986, 93.14398,
  93.134932, 93.142996, 93.174072, 93.232886, 93.322016, 93.441109, 93.58652,
  93.751541, 93.927172, 94.103274, 94.269881, 94.418452, 94.542857, 94.639946,
  94.7096, 94.754306, 94.778343, 94.786819, 94.784804, 94.776852, 94.766988,
  94.759015, 94.756803, 94.764246, 94.784797, 94.820695, 94.872174, 94.936942,
  95.010205, 95.085302, 95.154831, 95.211984, 95.251774, 95.271902, 95.273179,
  95.259486, 95.237384, 95.215467, 95.203486, 95.211245, 95.24726, 95.31731,
  95.423138, 95.5617, 95.725296, 95.902676, 96.080902, 96.247489, 96.392277,
  96.508616, 96.593706, 96.648175, 96.675185, 96.679396, 96.665993, 96.639877,
  96.605032, 96.564115, 96.518367, 96.467895, 96.412305, 96.351537, 96.286683,
  96.220597, 96.158169, 96.106158, 96.072542, 96.065378, 96.091273, 96.153775,
  96.252079, 96.380536, 96.529179, 96.685167, 96.834678, 96.964777, 97.064881,
  97.127655, 97.149368, 97.129788, 97.071818, 96.980965, 96.864728, 96.731907,
  96.591884, 96.453885, 96.326327, 96.216287, 96.129117, 96.068183, 96.034704,
  96.027751, 96.044412, 96.080161, 96.129392, 96.186046, 96.244237, 96.298733,
  96.345215, 96.380293, 96.401388, 96.406618, 96.394794, 96.36552, 96.319305,
  96.257578, 96.182608, 96.097351, 96.005255, 95.910052, 95.815512, 95.725223,
  95.642407, 95.569783, 95.509445, 95.462731, 95.430142, 95.411359, 95.405431,
  95.411139, 95.427468, 95.45405, 95.491447, 95.54116, 95.605317, 95.686027,
  95.784502, 95.900108, 96.02962, 96.166924, 96.303342, 96.428569, 96.532066,
  96.604574, 96.639429, 96.633441, 96.587239, 96.505126, 96.394523, 96.265097,
  96.127654, 95.992891, 95.870172, 95.766503, 95.685892, 95.62918, 95.594378,
  95.57745, 95.573392, 95.577441, 95.586146, 95.598102, 95.614216, 95.637541,
  95.672825, 95.725888, 95.802907, 95.909574, 96.050127, 96.22637, 96.436883,
  96.676664, 96.937386, 97.20835, 97.478074, 97.736228, 97.975454, 98.19256,
  98.38873, 98.56876, 98.739634, 98.908958, 99.083675, 99.269256, 99.469331,
  99.685691, 99.918597, 100.167298, 100.430637, 100.707528, 100.99713,
  101.298585, 101.610416, 101.929796, 102.252027, 102.570458, 102.876957,
  103.162895, 103.420394, 103.643495, 103.828892, 103.975977, 104.086153,
  104.161572, 104.203661, 104.211964, 104.183821, 104.115156, 104.002289,
  103.844339, 103.645615, 103.417303, 103.177874, 102.952026, 102.768479,
  102.657256, 102.646962, 102.76229, 103.021736, 103.435483, 104.00361,
  104.714863, 105.546284, 106.464046, 107.425865, 108.385215, 109.296933,
  110.123153, 110.838125, 111.430781, 111.904545, 112.27462, 112.563548,
  112.796263, 112.995823, 113.180554, 113.362768, 113.548897, 113.740734,
  113.937294, 114.136645, 114.337189, 114.538143, 114.739287, 114.940263,
  115.139839, 115.33558, 115.524143, 115.702012, 115.866187, 116.014467,
  116.145329, 116.257636, 116.35039, 116.422609, 116.473333, 116.501729,
  116.507318, 116.490251, 116.451621, 116.393767, 116.32048, 116.236975,
  116.149528, 116.064795, 115.989026, 115.927333, 115.883135, 115.857796,
  115.850511, 115.858467, 115.877264, 115.901528, 115.925637, 115.944416,
  115.953696, 115.95063, 115.933763, 115.90288, 115.858725, 115.80264,
  115.736213, 115.661034, 115.578647, 115.490731, 115.399429, 115.307637,
  115.219091, 115.138202, 115.069748, 115.01851, 114.988874, 114.984306,
  115.006693, 115.055659, 115.128138, 115.218454, 115.318979, 115.421208,
  115.516976, 115.599596, 115.664724, 115.710872, 115.739494, 115.754632,
  115.762155, 115.768702, 115.780556, 115.802685, 115.838104, 115.887583,
  115.949651, 116.020881, 116.09645, 116.170917, 116.239063, 116.296624,
  116.340729, 116.370042, 116.384607, 116.385537, 116.374652, 116.354195,
  116.326642, 116.29457, 116.260538, 116.227034, 116.196487, 116.171272,
  116.153637, 116.145492, 116.148154, 116.162194, 116.187478, 116.223402,
  116.269169, 116.323968, 116.387021, 116.457544, 116.534708, 116.617584,
  116.705074, 116.795792, 116.887913, 116.979022, 117.065961, 117.144753,
  117.210706, 117.258778, 117.284224, 117.283438, 117.254877, 117.199823,
  117.122719, 117.030842, 116.933295, 116.839507, 116.757578, 116.692848,
  116.647023, 116.618049, 116.600663, 116.587492, 116.570414, 116.541954,
  116.496447, 116.430775, 116.344636, 116.240421, 116.122813, 115.998238,
  115.874232, 115.758734, 115.659306, 115.5823, 115.532072, 115.510394,
  115.516202, 115.545722, 115.592959, 115.650453, 115.710224, 115.764821,
  115.808273, 115.83676, 115.848785, 115.844813, 115.826462, 115.795509,
  115.753014, 115.698849, 115.631733, 115.549692, 115.450731, 115.33351,
  115.197881, 115.045262, 114.878794, 114.70325, 114.524634, 114.349458,
  114.183858, 114.032747, 113.899285, 113.784784, 113.689037, 113.610939,
  113.549193, 113.502917, 113.472037, 113.45741, 113.460683, 113.483902,
  113.528936, 113.596774, 113.686807, 113.796239, 113.919829, 114.050149,
  114.178433, 114.295888, 114.395122, 114.471323, 114.522887, 114.551391,
  114.560924, 114.556985, 114.545245, 114.530489, 114.515951, 114.503093,
  114.491745, 114.480442, 114.466854, 114.448288, 114.422234, 114.386878,
  114.34145, 114.286241, 114.222284, 114.150824, 114.072816, 113.988709,
  113.898595, 113.80266, 113.701715, 113.597581, 113.493123, 113.391909,
  113.297574, 113.213051, 113.139852, 113.077578, 113.023754, 112.974044,
  112.922807, 112.863859, 112.791225, 112.699748, 112.585522, 112.446216,
  112.281349, 112.092556, 111.883764, 111.661181, 111.432958, 111.208434,
  110.997017, 110.806934, 110.644143, 110.511644, 110.409331, 110.334334,
  110.281741, 110.245554, 110.219717, 110.199036, 110.179782, 110.159798,
  110.138147, 110.114488, 110.088495, 110.059564, 110.026877, 109.989683,
  109.947571, 109.900578, 109.849088, 109.793609, 109.734569, 109.672229,
  109.606787, 109.538629, 109.468638, 109.398436, 109.330458, 109.267868,
  109.214334, 109.173712, 109.149636, 109.145024, 109.161589, 109.199498,
  109.25729, 109.332066, 109.419853, 109.516, 109.615476, 109.713035,
  109.803311, 109.881021, 109.941329, 109.98032, 109.9954, 109.985518,
  109.951195, 109.894386, 109.818192, 109.726432, 109.623161, 109.51221,
  109.396842, 109.279541, 109.161935, 109.044886, 108.928797, 108.814093,
  108.701773, 108.59385, 108.493574, 108.405339, 108.334246, 108.285367,
  108.262843, 108.269022, 108.303841, 108.364575, 108.446008, 108.540958,
  108.641118, 108.738072, 108.824366, 108.894472, 108.945469, 108.977325,
  108.992704, 108.996283, 108.993691, 108.990269, 108.989954, 108.994574,
  109.003754, 109.015417, 109.026722, 109.035167, 109.039543, 109.040509,
  109.040622, 109.04382, 109.054467, 109.076258, 109.111361, 109.160133,
  109.221519, 109.293897, 109.375869, 109.466559, 109.565254, 109.670589,
  109.779659, 109.887437, 109.986707, 110.068501, 110.122818, 110.139445,
  110.108766, 110.022555, 109.874677, 109.661544, 109.382269, 109.038538,
  108.634392, 108.176097, 107.672301, 107.134547, 106.578047, 106.022285,
  105.490822, 105.009747, 104.604595, 104.296188, 104.096367, 104.00488,
  104.008514, 104.082895, 104.196402, 104.314977, 104.406578, 104.444533,
  104.409577, 104.290684, 104.084939, 103.796631, 103.435784, 103.016264,
  102.553679, 102.063333, 101.55853, 101.04938, 100.542113, 100.038886, 99.5381,
  99.035185, 98.523658, 97.996148, 97.445226, 96.864009, 96.246579, 95.58827,
  94.885832, 94.137441, 93.34259, 92.501981, 91.61759, 90.692948, 89.733597,
  88.747557, 87.745454, 86.739894, 85.743878, 84.768649, 83.821768, 82.906229,
  82.020906, 81.161961, 80.324434, 79.503318, 78.693958, 77.892048, 77.093716,
  76.295838, 75.496404, 74.694618, 73.890624, 73.085081, 72.27887, 71.472992,
  70.668502, 69.866274, 69.066572, 68.26856, 67.469897, 66.666521, 65.85265,
  65.021054, 64.163645, 63.272376, 62.34026, 61.362214, 60.335546, 59.260182,
  58.138893, 56.977684, 55.786138, 54.577278, 53.366538, 52.169808, 51.000927,
  49.869366, 48.778826, 47.727193, 46.707807, 45.711593, 44.729316, 43.753229,
  42.777602, 41.798068, 40.810266, 39.8086, 38.785909, 37.7344, 36.647539,
  35.522131, 34.359711, 33.166579, 31.952394, 30.727781, 29.501888, 28.280824,
  27.067698, 25.864384, 24.674472, 23.506157, 22.373683, 21.296422, 20.295561,
  19.38927, 18.587817, 17.890196, 17.283361, 16.744343, 16.244722, 15.756332,
  15.256886, 14.734328, 14.189195, 13.634704, 13.094733, 12.600085, 12.183681,
  11.875494, 11.698068, 11.663239, 11.770401, 12.006559, 12.34827, 12.765398,
  13.22612, 13.702273, 14.174059, 14.633294, 15.084737, 15.545417, 16.042216,
  16.608233, 17.278533, 18.085995, 19.057917, 20.21372, 21.563612, 23.10784,
  24.836462, 26.729945, 28.761016, 30.897849, 33.108138, 35.363143, 37.640811,
  39.927515, 42.218512, 44.517305, 46.833985, 49.182584, 51.577805, 54.031803,
  56.551704, 59.138318, 61.78615, 64.4846, 67.220062, 69.978402, 72.747137,
  75.516592, 78.279718, 81.030759, 83.763516, 86.469975, 89.139879, 91.761255,
  94.321507, 96.808536, 99.211538, 101.521425, 103.730964, 105.834747,
  107.829038, 109.711535, 111.481108, 113.137598, 114.681659, 116.114643,
  117.43848, 118.655599, 119.768941, 120.782161, 121.700016, 122.528778,
  123.276362, 123.951843, 124.564213, 125.120551, 125.624055, 126.072617,
  126.458568, 126.769958, 126.993256, 127.116818, 127.134182, 127.046284,
  126.862065, 126.597483, 126.273349, 125.912694, 125.538328, 125.170957,
  124.827941, 124.522626, 124.264126, 124.057473, 123.904182, 123.80337,
  123.753499, 123.754463, 123.809217, 123.923914, 124.105881, 124.359536,
  124.681264, 125.054774, 125.448479, 125.815777, 126.097926, 126.228149,
  126.135424, 125.747082, 124.990309, 123.793287, 122.086659, 119.805735,
  116.893606, 113.305268, 109.012875, 104.011976, 98.328197, 92.02317,
  85.198098, 77.993345, 70.583303, 63.166985, 55.955641, 49.158642, 42.96832,
  37.544071, 32.996621, 29.374921, 26.659517, 24.765645, 23.55627, 22.861903,
  22.502482, 22.307071, 22.128699, 21.853544, 21.405269, 20.745846, 19.873751,
  18.819594, 17.639155, 16.404259, 15.192719, 14.078924, 13.126351, 12.382532,
  11.876348, 11.617271, 11.596154, 11.787294, 12.151668, 12.6413, 13.204515,
  13.791447, 14.358732, 14.872306, 15.307817, 15.649206, 15.886794, 16.016025,
  16.037206, 15.955829, 15.782807, 15.534056, 15.22907, 14.888466, 14.530933,
  14.170391, 13.814255, 13.463312, 13.113188, 12.756793, 12.386912, 11.998167,
  11.587881, 11.155824, 10.703144, 10.23102, 9.739667, 9.228273, 8.696063,
  8.144059, 7.57659, 7.001544, 6.42892, 5.868115, 5.324902, 4.799221, 4.284617,
  3.769737, 3.24178, 2.691263, 2.116989, 1.529941, 0.955223, 0.431947, 0.011643,
  -0.244016, -0.265606, 0.021824, 0.696638, 1.838158, 3.523383, 5.822524,
  8.792629, 12.468005, 16.846439, 21.8722, 27.419935, 33.285936, 39.192653,
  44.808512, 49.780374, 53.7726, 56.505671, 57.788185, 57.537941, 55.789497,
  52.687131, 48.464095, 43.412065, 37.847371, 32.080609, 26.393267, 21.021512,
  16.145761, 11.885445, 8.299442, 5.392423, 3.126025, 1.432498, 0.228368,
  -0.573827, -1.056775, -1.292794, -1.340969, -1.246266, -1.039799, -0.73979,
  -0.35327, 0.121186, 0.689817, 1.359743, 2.135578, 3.017036, 3.997553,
  5.063681, 6.19501, 7.364505, 8.539462, 9.683421, 10.759223, 11.733004,
  12.578467, 13.280432, 13.836661, 14.257435, 14.56295, 14.779175, 14.933069,
  15.048152, 15.141226, 15.220822, 15.287607, 15.336603, 15.360535, 15.353326,
  15.312756, 15.241713, 15.147983, 15.043031, 14.940322, 14.853599, 14.795254,
  14.774879, 14.798154, 14.86633, 14.976569, 15.123161, 15.299314, 15.498895,
  15.717595, 15.953272, 16.205618, 16.475481, 16.764069, 17.07213, 17.399047,
  17.741947, 18.095125, 18.450202, 18.79715, 19.125893, 19.4279, 19.697355,
  19.931713, 20.131551, 20.299551, 20.438649, 20.549878, 20.630876, 20.675955,
  20.678003, 20.631544, 20.535727, 20.396044, 20.22421, 20.036428, 19.850696,
  19.683932, 19.549562, 19.455965, 19.405975, 19.397377, 19.424204, 19.478522,
  19.552365, 19.639421, 19.736106, 19.84178, 19.958109, 20.087838, 20.233381,
  20.395669, 20.573518, 20.763577, 20.960771, 21.159024, 21.352053, 21.534027,
  21.69997, 21.845875, 21.968639, 22.066038, 22.136875, 22.181334, 22.201343,
  22.200749, 22.185219, 22.161877, 22.138767, 22.124204, 22.126037, 22.150856,
  22.203204, 22.284967, 22.395195, 22.530644, 22.687162, 22.861707, 23.054447,
  23.270217, 23.518761, 23.813483, 24.168936, 24.597622, 25.106887, 25.696658,
  26.358444, 27.075592, 27.824422, 28.575823, 29.29725, 29.95526, 30.518534,
  30.960928, 31.263867, 31.417621, 31.421378, 31.282413, 31.014813, 30.638205,
  30.176706, 29.658036, 29.112538, 28.571898, 28.067547, 27.628876, 27.281442,
  27.045281, 26.933414, 26.950704, 27.093344, 27.349255, 27.699471, 28.120244,
  28.585412, 29.068668, 29.545637, 29.995729, 30.4037, 30.760684, 31.064572,
  31.319714, 31.536081, 31.72801, 31.912614, 32.107914, 32.330762, 32.594706,
  32.908044, 33.272397, 33.682196, 34.125329, 34.584928, 35.041992, 35.478311,
  35.87906, 36.234475, 36.540195, 36.796262, 37.005162, 37.169649, 37.291059,
  37.368578, 37.399519, 37.380387, 37.308301, 37.182319, 37.004254, 36.778736,
  36.512458, 36.212885, 35.886979, 35.540522, 35.178261, 34.804628, 34.424596,
  34.044314, 33.67142, 33.314981, 32.985005, 32.691429, 32.442647, 32.243918,
  32.096268, 31.996337, 31.937198, 31.909707, 31.903857, 31.909706, 31.917682,
  31.918331, 31.901845, 31.857856, 31.775814, 31.645917, 31.460298, 31.214133,
  30.906411, 30.540269, 30.122841, 29.664688, 29.179018, 28.68093, 28.186848,
  27.71403, 27.279896, 26.900979, 26.591604, 26.362592, 26.220384, 26.166797,
  26.199434, 26.312546, 26.49803, 26.746187, 27.04597, 27.384696, 27.74756,
  28.11755, 28.476418, 28.807002, 29.096442, 29.339157, 29.538246, 29.704554,
  29.853611, 30.001492, 30.160971, 30.339041, 30.536209, 30.747321, 30.963232,
  31.172623, 31.363502, 31.524292, 31.644649, 31.716131, 31.732694, 31.690902,
  31.589738, 31.430064, 31.213879, 30.943669, 30.622068, 30.251919, 29.83663,
  29.380655, 28.889906, 28.371899, 27.835595, 27.291052, 26.749162, 26.22159,
  25.720874, 25.260521, 24.854904, 24.518729, 24.265909, 24.107848, 24.051398,
  24.096955, 24.237271, 24.457487, 24.736603, 25.050139, 25.373399, 25.684529,
  25.966658, 26.208769, 26.405386, 26.5555, 26.661326, 26.727255, 26.759079,
  26.763384, 26.74697, 26.716292, 26.676926, 26.633065, 26.587105, 26.539463,
  26.488764, 26.432484, 26.367943, 26.293418, 26.2091, 26.117713, 26.024737,
  25.938246, 25.868433, 25.826827, 25.825212, 25.874324, 25.982454, 26.154201,
  26.389612, 26.68391, 27.027872, 27.408832, 27.812118, 28.222677, 28.626585,
  29.012145, 29.370368, 29.694771, 29.98061, 30.22387, 30.420459, 30.566067,
  30.656945, 30.691497, 30.672179, 30.60697, 30.509688, 30.39874, 30.294411,
  30.215382, 30.175401, 30.180932, 30.23024, 30.313934, 30.416664, 30.519483,
  30.602352, 30.646466, 30.636267, 30.561132, 30.416679, 30.205465, 29.936814,
  29.625679, 29.290648, 28.951437, 28.626342, 28.330161, 28.072975, 27.859928,
  27.691856, 27.566431, 27.47946, 27.426013, 27.401202, 27.400506, 27.419618,
  27.453912, 27.497756, 27.544003, 27.583986, 27.608131, 27.607065, 27.572845,
  27.499963, 27.385851, 27.230806, 27.037461, 26.810033, 26.553635, 26.273873,
  25.976733, 25.668655, 25.356579, 25.047835, 24.749867, 24.469873, 24.214508,
  23.989733, 23.800832, 23.65249, 23.548796, 23.493048, 23.487373, 23.532244,
  23.626058, 23.764879, 23.942464, 24.150613, 24.379905, 24.620739, 24.864514,
  25.104699, 25.337527, 25.562204, 25.780624, 25.996688, 26.215353, 26.44153,
  26.678975, 26.929297, 27.191226, 27.460253, 27.728861, 27.987523, 28.226619,
  28.439198, 28.624369, 28.790911, 28.960641, 29.171128, 29.477573, 29.954288,
];

const generateData = (fix: number) => {
  return GENERIC_DATA_VALUE.map((value) => value + fix);
};

export const TRENDS_TIME_SERIES_METRIC = [
  {
    visible: true,
    id: "KM_WB_Pelvis_Angle_ROT_",
    pitchId: "f79c2356-d1f8-465b-ae8f-653e0dbe5fb1",
    label: "Pelvis Rotation",
    date: dayjs("01/15/2023").toString(),
    data: generateData(0),
    index: 0,
  },
  {
    visible: true,
    id: "KM_WB_Pelvis_Angle_ROT_",
    pitchId: "f79c2356-d1f8-465b-ae8f-653e0dbe5fb2",
    label: "Pelvis Rotation",
    date: dayjs("03/24/2023").toString(),
    data: generateData(10),
    index: 1,
  },
  {
    visible: true,
    id: "KM_WB_Pelvis_Angle_ROT_",
    pitchId: "f79c2356-d1f8-465b-ae8f-653e0dbe5fb3",
    label: "Pelvis Rotation",
    date: dayjs("05/02/2022").toString(),
    data: generateData(-20),
    index: 2,
  },
  {
    visible: true,
    id: "KM_WB_Spine_Angle_GloveLean_",
    pitchId: "f79c2356-d1f8-465b-ae8f-653e0dbe5fb4",
    label: "Spine Flexion",
    data: generateData(-20),
    date: dayjs("11/24/2023").toString(),
    index: 3,
  },
  {
    visible: true,
    id: "KM_WB_Spine_Angle_GloveLean_",
    pitchId: "f79c2356-d1f8-465b-ae8f-653e0dbe5fb5",
    label: "Spine Flexion",
    data: generateData(10),
    date: dayjs("08/10/2023").toString(),
    index: 4,
  },
  {
    visible: true,
    id: "KM_WB_Spine_Angle_ROT_",
    pitchId: "f79c2356-d1f8-465b-ae8f-653e0dbe5fb06",
    label: "Spine Rotation",
    data: generateData(10),
    date: dayjs("11/28/2023").toString(),
    index: 5,
  },
  {
    visible: true,
    id: "KM_WB_Spine_Angle_ROT_",
    pitchId: "f79c2356-d1f8-465b-ae8f-653e0dbe5fb07",
    label: "Spine Rotation",
    data: generateData(-10),
    date: dayjs("03/10/2023").toString(),
    index: 6,
  },
];

export const TRENDS_DISCRETE_METRIC = [
  {
    id: "KM_WB_Stride_Length_ht",
    pitchId: "f79c2356-d1f8-465b-ae8f-653e0dbe5fb0",
    label: "Stride Length",
    date: "2023-12-14",
    value: 189,
    inning: 1,
    numPitches: 19,
  },
  {
    id: "KM_WB_Stride_Length_ht",
    pitchId: "f79c2356-d1f8-465b-ae8f-653e0dbe5fb1",
    label: "Stride Length",
    date: "2023-12-14",
    value: 197,
    inning: 1,
    numPitches: 20,
  },
  {
    id: "KM_WB_Stride_Length_ht",
    pitchId: "f79c2356-d1f8-465b-ae8f-653e0dbe5fb2",
    label: "Stride Length",
    date: "2023-12-15",
    value: 188,
    inning: 1,
    numPitches: 19,
  },
  {
    id: "KM_WB_Stride_Length_ht",
    pitchId: "f79c2356-d1f8-465b-ae8f-653e0dbe5fb3",
    label: "Stride Length",
    date: "2023-12-15",
    value: 101,
    inning: 2,
    numPitches: 11,
  },
  {
    id: "KM_WB_Stride_Length_ht",
    pitchId: "f79c2356-d1f8-465b-ae8f-653e0dbe5fb4",
    label: "Stride Length",
    date: "2023-12-15",
    value: 103,
    inning: 2,
    numPitches: 11,
  },
  {
    id: "KM_WB_Stride_Length_ht",
    pitchId: "f79c2356-d1f8-465b-ae8f-653e0dbe5fb5",
    label: "Stride Length",
    date: "2023-12-16",
    value: 100,
    inning: 3,
    numPitches: 10,
  },
  {
    id: "KM_WB_Stride_Length_ht",
    pitchId: "f79c2356-d1f8-465b-ae8f-653e0dbe5fb6",
    label: "Stride Length",
    date: "2023-12-17",
    value: 103,
    inning: 4,
    numPitches: 11,
  },
  {
    id: "KM_WB_Stride_Length_ht",
    pitchId: "f79c2356-d1f8-465b-ae8f-653e0dbe5fb7",
    label: "Stride Length",
    date: "2023-12-17",
    value: 102,
    inning: 4,
    numPitches: 11,
  },
  {
    id: "KM_WB_Stride_Length_ht",
    pitchId: "f79c2356-d1f8-465b-ae8f-653e0dbe5fb8",
    label: "Stride Length",
    date: "2023-12-18",
    value: 105,
    inning: 5,
    numPitches: 11,
  },
  {
    id: "KM_WB_Stride_Length_ht",
    pitchId: "f79c2356-d1f8-465b-ae8f-653e0dbe5fb9",
    label: "Stride Length",
    date: "2023-12-18",
    value: 101,
    inning: 5,
    numPitches: 11,
  },
  {
    id: "KM_WB_Stride_Length_ht",
    pitchId: "f79c2356-d1f8-465b-ae8f-653e0dbe5fb10",
    label: "Stride Length",
    date: "2023-12-19",
    value: 102,
    inning: 5,
    numPitches: 11,
  },
  {
    id: "KM_WB_Stride_Length_ht",
    pitchId: "f79c2356-d1f8-465b-ae8f-653e0dbe5fb11",
    label: "Stride Length",
    date: "2023-12-19",
    value: 97,
    inning: 6,
    numPitches: 10,
  },
  {
    id: "KM_WB_Stride_Length_ht",
    pitchId: "f79c2356-d1f8-465b-ae8f-653e0dbe5fb12",
    label: "Stride Length",
    date: "2023-12-20",
    value: 80,
    inning: 7,
    numPitches: 8,
  },
  {
    id: "KM_WB_Stride_Length_ht",
    pitchId: "f79c2356-d1f8-465b-ae8f-653e0dbe5fb13",
    label: "Stride Length",
    date: "2023-12-21",
    value: 101,
    inning: 8,
    numPitches: 11,
  },
  {
    id: "KM_WB_Stride_Length_ht",
    pitchId: "f79c2356-d1f8-465b-ae8f-653e0dbe5fb14",
    label: "Stride Length",
    date: "2023-12-22",
    value: 105,
    inning: 8,
    numPitches: 11,
  },
  {
    id: "KM_WB_Stride_Length_ht",
    pitchId: "f79c2356-d1f8-465b-ae8f-653e0dbe5fb15",
    label: "Stride Length",
    date: "2023-12-22",
    value: 108,
    inning: 9,
    numPitches: 11,
  },
  {
    id: "KM_WB_Stride_Length_ht",
    pitchId: "f79c2356-d1f8-465b-ae8f-653e0dbe5fb16",
    label: "Stride Length",
    date: "2023-12-22",
    value: 110,
    inning: 9,
    numPitches: 11,
  },
  {
    id: "KM_WB_Stride_Length_ht",
    pitchId: "f79c2356-d1f8-465b-ae8f-653e0dbe5fb17",
    label: "Stride Length",
    date: "2023-12-22",
    value: 120,
    inning: 9,
    numPitches: 12,
  },
];

export const CORRELATION_DATACOMP = [
  {
    x: 1,
    low: 60,
    high: 75,
  },
  {
    x: 2,
    low: 60,
    high: 75,
  },
  {
    x: 3,
    low: 60,
    high: 75,
  },
  {
    x: 4,
    low: 60,
    high: 75,
  },
  {
    x: 5,
    low: 60,
    high: 75,
  },
  {
    x: 6,
    low: 60,
    high: 75,
  },
  {
    x: 7,
    low: 60,
    high: 75,
  },
  {
    x: 8,
    low: 60,
    high: 75,
  },
  {
    x: 9,
    low: 60,
    high: 75,
  },
  {
    x: 10,
    low: 60,
    high: 75,
  },
  {
    x: 11,
    low: 60,
    high: 75,
  },
  {
    x: 12,
    low: 60,
    high: 75,
  },
  {
    x: 13,
    low: 60,
    high: 75,
  },
  {
    x: 14,
    low: 60,
    high: 75,
  },
  {
    x: 15,
    low: 60,
    high: 75,
  },
  {
    x: 16,
    low: 60,
    high: 75,
  },
  {
    x: 17,
    low: 60,
    high: 75,
  },
  {
    x: 18,
    low: 60,
    high: 75,
  },
];

export const CORRELATION_DATACOMP2 = [
  {
    x: 8,
    low: 0,
    high: 80,
  },
  {
    x: 9,
    low: 0,
    high: 80,
  },
  {
    x: 10,
    low: 0,
    high: 80,
  },
  {
    x: 11,
    low: 0,
    high: 80,
  },
  {
    x: 12,
    low: 0,
    high: 80,
  },
];

export const TRENDS_CORRELATION_STATS = {
  mean: 2,
  stdDev: 1,
  low: 10,
  high: 15,
};

export const TRENDS_CORRELATION = {
  KM_WB_Pelvis_Angle_ROT_TS_MKH: [
    {
      date: "2023-05-17",
      mean: -36.77366393023256,
      stdDev: 3.0386199819656983,
      numPitches: 43,
    },
    {
      date: "2023-05-05",
      mean: -40.55849866666667,
      stdDev: 11.13970155075734,
      numPitches: 42,
    },
    {
      date: "2023-06-28",
      mean: -31.57327242105263,
      stdDev: 4.640916507626638,
      numPitches: 38,
    },
    {
      date: "2023-07-27",
      mean: -31.76880566666667,
      stdDev: 6.674492458144855,
      numPitches: 21,
    },
    {
      date: "2023-07-05",
      mean: -31.3025603125,
      stdDev: 5.9789444873489686,
      numPitches: 32,
    },
    {
      date: "2023-08-07",
      mean: -33.3006894,
      stdDev: 3.1113203034414805,
      numPitches: 5,
    },
    {
      date: "2023-07-21",
      mean: -31.27986672727273,
      stdDev: 5.64414371945868,
      numPitches: 11,
    },
    {
      date: "2023-05-11",
      mean: -42.03740339130435,
      stdDev: 11.650471450460843,
      numPitches: 23,
    },
    {
      date: "2023-07-15",
      mean: -29.49456782758621,
      stdDev: 6.696831881768895,
      numPitches: 29,
    },
  ],
  KM_WB_Stride_Length_ht: [
    {
      date: "2023-07-05",
      mean: 0.889929625,
      stdDev: 0.0335357487283971,
      numPitches: 32,
    },
    {
      date: "2023-06-28",
      mean: 0.8644200526315788,
      stdDev: 0.0276267326821562,
      numPitches: 38,
    },
    {
      date: "2023-07-21",
      mean: 0.8907571818181818,
      stdDev: 0.0142367683641529,
      numPitches: 11,
    },
    {
      date: "2023-07-27",
      mean: 0.8762293333333333,
      stdDev: 0.0348908912029104,
      numPitches: 21,
    },
    {
      date: "2023-08-07",
      mean: 0.8634494,
      stdDev: 0.0335814810155835,
      numPitches: 5,
    },
    {
      date: "2023-05-17",
      mean: 0.8775063488372093,
      stdDev: 0.0244593390436872,
      numPitches: 43,
    },
    {
      date: "2023-05-05",
      mean: 0.8680124761904762,
      stdDev: 0.0202961527880945,
      numPitches: 42,
    },
    {
      date: "2023-07-15",
      mean: 0.8829243448275863,
      stdDev: 0.0260172393466654,
      numPitches: 29,
    },
    {
      date: "2023-05-11",
      mean: 0.8720983043478262,
      stdDev: 0.0149022306498027,
      numPitches: 23,
    },
  ],
};
