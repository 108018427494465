import React, { useState } from "react";
import { LineChart } from "../charts";
import TrendsChartLayout from "./TrendsChartLayout";
import { TabNames } from "./TrendsPanelModal";
import { Grid, Stack } from "@mui/material";
import { useTimeSeriesVariableData } from "../../hooks/useTimeSeriesVariableData";
import MetricSelector from "./MetricSelector";
import SelfHorizontalBarIndicator from "./SelfHorizontalBarIndicator";
import { Spin } from "antd";

export interface TrendFetchDataProps {
  metricId: string;
  playerId: string;
  startDate: string;
  endDate: string;
}

function TimeSeriesVariable() {
  const {
    metricSelected,
    metricsData,
    dataComp,
    selfData,
    onChangeMetric,
    isLoading,
    selfComp,
    setSelfComp,
    setShowDataComp,
  } = useTimeSeriesVariableData();
  const [hoveredColor, setHoveredColor] = useState<string | undefined>();

  return isLoading ? (
    <Stack justifyContent="center" alignItems="center" height="100%">
      <Spin />
    </Stack>
  ) : (
    <TrendsChartLayout
      tabName={TabNames.TIME_SERIES}
      setDataComp={setShowDataComp}
      selfComp={selfComp}
      setSelfComp={setSelfComp}
    >
      <Grid item xs={12}>
        <MetricSelector
          metric={metricSelected}
          onChange={onChangeMetric}
          items={metricsData}
        />
      </Grid>

      <Grid item xs={12} position="relative">
        {selfData && (
          <LineChart
            trendsTimeSeries
            showTooltip
            noShared
            hoveredColor={hoveredColor}
            setHoveredColor={setHoveredColor}
            data={selfData.map((it, index) => ({ ...it, index }))}
            secondaryData={dataComp || []}
            height="350px"
            includeSelf
            showReversed
          />
        )}
      </Grid>
      {selfData && (
        <Grid item xs={12} p={2} mt={2}>
          <SelfHorizontalBarIndicator
            data={selfData}
            hoveredColor={hoveredColor}
          />
        </Grid>
      )}
    </TrendsChartLayout>
  );
}

export default TimeSeriesVariable;
