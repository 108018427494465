import { random } from "lodash";

interface MetricContainer {
  id: string;
  elements: Metric[];
}

export interface Metric {
  id: string;
  systematicName?: string;
  label: string;
  section: Section;
  playerId: number | string;
  value: number;
  averageRange: {
    from: number;
    to: number;
  };
  metricName: string;
  description: string;
  min: number;
  max: number;
  unit: string;
  decimals?: number;
}

export enum Section {
  Angles = "Angles",
  AngularVelocities = "Angular Velocities",
  LinearVelocities = "Linear Velocities",
  LinearAccelerations = "Linear Accelerations",
  Kinetics = "Kinetics",
  TimeBasedMisc = "Time Based/Misc",
  MiscMax = "Misc/Max",
}

const {
  Angles,
  AngularVelocities,
  LinearVelocities,
  LinearAccelerations,
  Kinetics,
  TimeBasedMisc,
  MiscMax,
} = Section;

const defaultMetricValue = () => {
  const randomValue = random(60, 80);
  return {
    playerId: 1,
    value: randomValue,
    averageRange: { from: 60, to: 80 },
    metricName: "SelfRubberPosition",
    description: "“ to the left",
    min: 50,
    max: 90,
    unit: "“",
  };
};
export const defaultMetric = {
  id: "pelvisRotation",
  label: "Pelvis Rotation",
  section: AngularVelocities,
  ...defaultMetricValue(),
};

export const defaultThrowEventMetric = {
  averageRange: { from: 60, to: 80 },
  description: "",
  id: "default_metric",
  label: "",
  max: 90,
  metricName: "",
  min: 50,
  pitchId: 1,
  systematicName: "",
  unit: "",
  value: "-",
};

export const defaultCompareMetric = {
  id: "pelvisRotation",
  label: "Pelvis Rotation",
  section: AngularVelocities,
  ...defaultMetricValue(),
};

const baseData = () => {
  return [
    {
      id: "pelvisRotation",
      label: "Pelvis Rotation",
      section: AngularVelocities,
      ...defaultMetricValue(),
    },
    {
      id: "pelvisLateralTilt",
      label: "Pelvis Lateral Tilt",
      section: Angles,
      ...defaultMetricValue(),
    },
    {
      id: "pelvisFlexion",
      label: "Pelvis Flexion",
      section: AngularVelocities,
      ...defaultMetricValue(),
    },
    {
      id: "trunkRotation",
      label: "Trunk Rotation",
      section: Angles,
      ...defaultMetricValue(),
    },
    {
      id: "trunkLateral Tilt",
      label: "Trunk Lateral Tilt",
      section: Angles,
      ...defaultMetricValue(),
    },
    {
      id: "trunkFlexion",
      label: "Trunk Flexion",
      section: Angles,
      ...defaultMetricValue(),
    },
    {
      id: "spineRotation",
      label: "Spine Rotation",
      section: Angles,
      ...defaultMetricValue(),
    },
    {
      id: "spineLateral Tilt",
      label: "Spine Lateral Tilt",
      section: Angles,
      ...defaultMetricValue(),
    },
    {
      id: "spineFlexion",
      label: "Spine Flexion",
      section: Angles,
      ...defaultMetricValue(),
    },
    {
      id: "headRotation",
      label: "Head Rotation",
      section: Angles,
      ...defaultMetricValue(),
    },
    {
      id: "headLateralTilt",
      label: "Head Lateral Tilt",
      section: Angles,
      ...defaultMetricValue(),
    },
    {
      id: "headFlexion",
      label: "Head Flexion",
      section: Angles,
      ...defaultMetricValue(),
    },
    {
      id: "neckRotation",
      label: "Neck Rotation",
      section: Angles,
      ...defaultMetricValue(),
    },
    {
      id: "neckLateralTilt",
      label: "Neck Lateral Tilt",
      section: Angles,
      ...defaultMetricValue(),
    },
    {
      id: "neckFlexion",
      label: "Neck Flexion",
      section: Angles,
      ...defaultMetricValue(),
    },
    {
      id: "throwingShoulderRotation",
      label: "Throwing Shoulder Rotation",
      section: Angles,
      ...defaultMetricValue(),
    },
    {
      id: "throwingShoulderAbduction",
      label: "Throwing Shoulder Abduction",
      section: Angles,
      ...defaultMetricValue(),
    },
    {
      id: "throwingShoulderHorizontalAbduction",
      label: "Throwing Shoulder Horizontal Abduction",
      section: Angles,
      ...defaultMetricValue(),
    },
    {
      id: "leadShoulderAbduction",
      label: "Lead Shoulder Abduction",
      section: Angles,
      ...defaultMetricValue(),
    },
    {
      id: "leadShoulderHorizontal Abduction",
      label: "Lead Shoulder Horizontal Abduction",
      section: Angles,
      ...defaultMetricValue(),
    },
    {
      id: "throwingElbowFlexion",
      label: "Throwing Elbow Flexion",
      section: Angles,
      ...defaultMetricValue(),
    },
    {
      id: "throwingWristFlexion",
      label: "Throwing Wrist Flexion",
      section: Angles,
      ...defaultMetricValue(),
    },
    {
      id: "throwingWristUlnar Deviation",
      label: "Throwing Wrist Ulnar Deviation",
      section: Angles,
      ...defaultMetricValue(),
    },
    {
      id: "leadElbowFlexion",
      label: "Lead Elbow Flexion",
      section: Angles,
      ...defaultMetricValue(),
    },
    {
      id: "backHipRotation",
      label: "Back Hip Rotation",
      section: LinearVelocities,
      ...defaultMetricValue(),
    },
    {
      id: "backHipAbduction",
      label: "Back Hip Abduction",
      section: LinearVelocities,
      ...defaultMetricValue(),
    },
    {
      id: "backHipFlexion",
      label: "Back Hip Flexion",
      section: Angles,
      ...defaultMetricValue(),
    },
    {
      id: "leadHipRotation",
      label: "Lead Hip Rotation",
      section: Kinetics,
      ...defaultMetricValue(),
    },
    {
      id: "leadHipAbduction",
      label: "Lead Hip Abduction",
      section: Kinetics,
      ...defaultMetricValue(),
    },
    {
      id: "leadHipFlexion",
      label: "Lead Hip Flexion",
      section: Kinetics,
      ...defaultMetricValue(),
    },
    {
      id: "backKneeValgus",
      label: "Back Knee Valgus",
      section: Angles,
      ...defaultMetricValue(),
    },
    {
      id: "leadKneeFlexion",
      label: "Lead Knee Flexion",
      section: Angles,
      ...defaultMetricValue(),
    },
    {
      id: "backAnkleFlexion",
      label: "Back Ankle Flexion",
      section: Angles,
      ...defaultMetricValue(),
    },
    {
      id: "backFootRotation Angle",
      label: "Back Foot Rotation Angle",
      section: Angles,
      ...defaultMetricValue(),
    },
    {
      id: "leadAnkleFlexion",
      label: "Lead Ankle Flexion",
      section: LinearAccelerations,
      ...defaultMetricValue(),
    },
    {
      id: "leadFootRotation",
      label: "Lead Foot Rotation",
      section: LinearAccelerations,
      ...defaultMetricValue(),
    },
    {
      id: "backShinVerticalAngle (Sagittal)",
      label: "Back Shin Vertical Angle (Sagittal)",
      section: Angles,
      ...defaultMetricValue(),
    },
    {
      id: "backShinVerticalAngle (Frontal)",
      label: "Back Shin Vertical Angle (Frontal)",
      section: Angles,
      ...defaultMetricValue(),
    },
    {
      id: "throwingForearmElevation",
      label: "Throwing Forearm Elevation",
      section: Angles,
      ...defaultMetricValue(),
    },
    {
      id: "headDisplacementX",
      label: "Head Displacement (x)",
      section: Angles,
      ...defaultMetricValue(),
    },
    {
      id: "headDisplacementY",
      label: "Head Displacement (y)",
      section: Angles,
      ...defaultMetricValue(),
    },
    {
      id: "headDisplacementZ",
      label: "Head Displacement (z)",
      section: Angles,
      ...defaultMetricValue(),
    },
    {
      id: "cOMDisplacementX",
      label: "COM Displacement (x)",
      section: Angles,
      ...defaultMetricValue(),
    },
    {
      id: "cOMDisplacementY",
      label: "COM Displacement (y)",
      section: Angles,
      ...defaultMetricValue(),
    },
    {
      id: "cOMDisplacementZ",
      label: "COM Displacement (z)",
      section: Angles,
      ...defaultMetricValue(),
    },
    {
      id: "cOMLinearVelocityX",
      label: "COM Linear Velocity (x)",
      section: TimeBasedMisc,
      ...defaultMetricValue(),
    },
    {
      id: "cOMLinearVelocityY",
      label: "COM Linear Velocity (Y)",
      section: TimeBasedMisc,
      ...defaultMetricValue(),
    },
    {
      id: "cOMLinearVelocityZ",
      label: "COM Linear Velocity (Z)",
      section: TimeBasedMisc,
      ...defaultMetricValue(),
    },
    {
      id: "cOMTotalLinearVelocity",
      label: "COM Total Linear Velocity",
      section: Angles,
      ...defaultMetricValue(),
    },
  ];
};
const miscMax: Metric[] = [
  {
    ...defaultMetricValue(),
    id: "pitchTime",
    label: "Pitch Time (MKH - BR)",
    section: MiscMax,
  },
  {
    ...defaultMetricValue(),
    id: "strideLengthIn",
    label: "Stride Length (in)",
    section: MiscMax,
  },
  {
    ...defaultMetricValue(),
    id: "strideLengthHt",
    label: "Stride Length (%ht)",
    section: MiscMax,
  },
  {
    ...defaultMetricValue(),
    id: "strideLengthLegLength",
    label: "Stride Length (%leg length)",
    section: MiscMax,
  },
  {
    ...defaultMetricValue(),
    id: "strideWidth",
    label: "Stride Width",
    section: MiscMax,
  },
  {
    ...defaultMetricValue(),
    id: "maxSpineRotation",
    label: "Max Spine Rotation",
    section: MiscMax,
  },
  {
    ...defaultMetricValue(),
    id: "maxSpineExtension",
    label: "Max Spine Extension",
    section: MiscMax,
  },
  {
    ...defaultMetricValue(),
    id: "maxShoulderRotation",
    label: "Max Shoulder Rotation",
    section: MiscMax,
  },
  {
    ...defaultMetricValue(),
    id: "maxShoulderHorizontalAbduction",
    label: "Max Shoulder Horizontal Abduction",
    section: MiscMax,
  },
  {
    ...defaultMetricValue(),
    id: "maxElbowFlexion",
    label: "Max Elbow Flexion",
    section: MiscMax,
  },
  {
    ...defaultMetricValue(),
    id: "maxElbowExtension",
    label: "Max Elbow Extension",
    section: MiscMax,
  },
  {
    ...defaultMetricValue(),
    id: "maxBackHipFlexion",
    label: "Max Back Hip Flexion",
    section: MiscMax,
  },
  {
    ...defaultMetricValue(),
    id: "maxBackHipExtension",
    label: "Max Back Hip Extension",
    section: MiscMax,
  },
  {
    ...defaultMetricValue(),
    id: "maxBackHipInternalRotation",
    label: "Max Back Hip Internal Rotation",
    section: MiscMax,
  },
  {
    ...defaultMetricValue(),
    id: "maxBackHipExternalRotation",
    label: "Max Back Hip External Rotation",
    section: MiscMax,
  },
  {
    ...defaultMetricValue(),
    id: "maxLeadHipFlexion",
    label: "Max Lead Hip Flexion",
    section: MiscMax,
  },
  {
    ...defaultMetricValue(),
    id: "maxLeadHipInternalRotation",
    label: "Max Lead Hip Internal Rotation",
    section: MiscMax,
  },
  {
    ...defaultMetricValue(),
    id: "maxLeadHipExternalRotation",
    label: "Max Lead Hip External Rotation",
    section: MiscMax,
  },
  {
    ...defaultMetricValue(),
    id: "maxPelvisRotationVelocity",
    label: "Max Pelvis Rotation Velocity",
    section: MiscMax,
  },
  {
    ...defaultMetricValue(),
    id: "maxPelvisRotationEnergy",
    label: "Max Pelvis Rotation Energy",
    section: MiscMax,
  },
  {
    ...defaultMetricValue(),
    id: "maxTrunkRotationVelocity",
    label: "Max Trunk Rotation Velocity",
    section: MiscMax,
  },
  {
    ...defaultMetricValue(),
    id: "maxTrunkRotationEnergy",
    label: "Max Trunk Rotation Energy",
    section: MiscMax,
  },
  {
    ...defaultMetricValue(),
    id: "maxTrunkFlexionVelocity",
    label: "Max Trunk Flexion Velocity",
    section: MiscMax,
  },
  {
    ...defaultMetricValue(),
    id: "maxTrunkLateralTiltVelocity",
    label: "Max Trunk Lateral Tilt Velocity",
    section: MiscMax,
  },
  {
    ...defaultMetricValue(),
    id: "maxSpineRotationVelocity",
    label: "Max Spine Rotation Velocity",
    section: MiscMax,
  },
  {
    ...defaultMetricValue(),
    id: "maxSpineFlexionVelocity",
    label: "Max Spine Flexion Velocity",
    section: MiscMax,
  },
  {
    ...defaultMetricValue(),
    id: "maxSpineLateralTiltVelocity",
    label: "Max Spine Lateral Tilt Velocity",
    section: MiscMax,
  },
  {
    ...defaultMetricValue(),
    id: "maxLeadKneeExtensionVelocity",
    label: "Max Lead Knee Extension Velocity",
    section: MiscMax,
  },
  {
    ...defaultMetricValue(),
    id: "maxShoulderRotationVelocoity",
    label: "Max Shoulder Rotation Velocoity",
    section: MiscMax,
  },
  {
    ...defaultMetricValue(),
    id: "maxElbowExtensionVelocity",
    label: "Max Elbow Extension Velocity",
    section: MiscMax,
  },
  {
    ...defaultMetricValue(),
    id: "maxWristExtensionVelocity",
    label: "Max Wrist Extension Velocity",
    section: MiscMax,
  },
  {
    ...defaultMetricValue(),
    id: "maxTrunkAngularMomentum",
    label: "Max Trunk Angular Momentum",
    section: MiscMax,
  },
  {
    ...defaultMetricValue(),
    id: "maxBicepAngularMomentum",
    label: "Max Bicep Angular Momentum",
    section: MiscMax,
  },
  {
    ...defaultMetricValue(),
    id: "maxForearmAngularMomentum",
    label: "Max Forearm Angular Momentum",
    section: MiscMax,
  },
  {
    ...defaultMetricValue(),
    id: "maxLeadLegAngularMomentum",
    label: "Max Lead Leg Angular Momentum",
    section: MiscMax,
  },
  {
    ...defaultMetricValue(),
    id: "maxHandLinearMomentum",
    label: "Max Hand Linear Momentum",
    section: MiscMax,
  },
  {
    ...defaultMetricValue(),
    id: "maxGloveLinearMomentum",
    label: "Max Glove Linear Momentum",
    section: MiscMax,
  },
  {
    ...defaultMetricValue(),
    id: "maxCOMLinearVelocity",
    label: "Max COM Linear Velocity",
    section: MiscMax,
  },
];

const metrics: MetricContainer[] = [
  {
    id: "MKH",
    elements: [
      {
        id: "timeOfMKH",
        label: "Time of MKH",
        section: TimeBasedMisc,
        ...defaultMetricValue(),
      },
      ...baseData(),
    ],
  },
  {
    id: "HS",
    elements: [
      {
        id: "timeOfHS",
        label: "Time of HS",
        section: TimeBasedMisc,
        ...defaultMetricValue(),
      },
      ...baseData(),
    ],
  },
  {
    id: "IFC",
    elements: [
      {
        id: "timeOfIFC",
        label: "Time of IFC",
        section: TimeBasedMisc,
        ...defaultMetricValue(),
      },
      ...baseData(),
    ],
  },
  {
    id: "FFC",
    elements: [
      {
        id: "timeOfFFC",
        label: "Time of FFC",
        section: TimeBasedMisc,
        ...defaultMetricValue(),
      },
      ...baseData(),
    ],
  },
  {
    id: "MER",
    elements: [
      {
        id: "timeOfMER",
        label: "Time of MER",
        section: TimeBasedMisc,
        ...defaultMetricValue(),
      },
      ...baseData(),
    ],
  },
  {
    id: "BR",
    elements: [
      {
        id: "timeOfBR",
        label: "Time of BR",
        section: LinearAccelerations,
        ...defaultMetricValue(),
      },
      ...baseData(),
    ],
  },
  {
    id: "FT",
    elements: [
      {
        id: "timeOfFT",
        label: "Time of FT",
        section: LinearAccelerations,
        ...defaultMetricValue(),
      },
      ...baseData(),
    ],
  },
  {
    id: "metric",
    elements: baseData(),
  },
  {
    id: "compareMetric",
    elements: baseData(),
  },
  {
    id: "miscMax",
    elements: miscMax,
  },
];

const SELF_METRIC = [
  {
    id: "selfMetric",
    elements: baseData(),
  },
];

export const selfMetricData = (metricId: string) =>
  SELF_METRIC.filter((metric) => metric.id === metricId)[0].elements;

export const metricsById = (id: string): Metric[] =>
  metrics.filter((metric) => metric.id === id)[0].elements;

export interface TimeSeriesDataComp {
  id: string;
  category: string;
  data: { x: number; y: number[] }[];
}

export const PLAYER_METRICS_ORDER: any = {
  msk: [
    "msk_shoulder_ir_d",
    "msk_shoulder_er_d",
    "msk_shoulder_ir_sup_d",
    "msk_core_rot_d",
    "msk_hip_add_nd",
    "msk_hip_abd_nd",
    "msk_hip_ir_nd",
    "msk_hip_er_nd",
    "msk_knee_flex_d",
    "msk_grip_0_d",
    "msk_grip_90_d",
    "msk_grip_fds_ext_d",
    "msk_grip_fds_ext",
  ],
  power: [
    "pwr_iso_mtp",
    "pwr_cmj_conc_impulse",
    "pwr_cmj_rsi_modified",
    "pwr_cmj_height",
    "pwr_cmj_conc_mean_power_bm",
    "pwr_cmj_contract_time",
    "pwr_cmj_ecc_braking_impulse",
    "pwr_sj_conc_impulse",
    "pwr_sj_jump_height",
    "pwr_sj_conc_mean_power_bm",
    "pwr_sj_contract_time",
    "pwr_iso_hamstring",
    "pwr_iso_hamstring_asym",
    "pwr_row_test",
    "pwr_iso_squat",
  ],
  rom: [
    "rom_shoulder_er_d",
    "rom_shoulder_ir_d",
    "rom_shoulder_trot_d",
    "rom_shoulder_flex_p",
    "rom_shoulder_flex_u",
    "rom_shoulder_add_d",
    "rom_elbow_ext_d",
    "rom_elbow_flex_d",
    "rom_hip_er_d",
    "rom_hip_ir_d",
    "rom_hip_trot_d",
    "rom_hip_flex_slr",
    "rom_hamstring_90",
    "rom_ankle_flex",
    "rom_trunk_cerv",
    "rom_trunk_thor_pass",
    "rom_trunk_lumb",
    "rom_trunk_thor_d",
  ],
  bodyComp: [
    "bcmp_weight",
    "bcmp_goal_weight_lower",
    "bcmp_goal_weight_upper",
    "bcmp_hydration_color",
    "bcmp_bodyfat",
    "bcmp_body_fat_percent",
    "bcmp_skinfolds",
    "bcmp_age",
    "bcmp_height",
    "bcmp_wingspan",
    "bcmp_handsize",
  ],
};
