import React from "react";
import VideoAnimationViewer from "./VideoAnimationViewer";
import { useLoadCSVCoordinatesFromFile } from "./hooks/useLoadCSVCoordinatesFromFile";

export function StandalonePerformanceDashboard() {
  const { jointCoordinates, setMovementFile } = useLoadCSVCoordinatesFromFile();
  const {
    jointCoordinates: comparingJointCoordinates,
    setMovementFile: setComparingMovementFile,
  } = useLoadCSVCoordinatesFromFile();

  return (
    <>
      <input
        type="file"
        accept=".csv"
        onChange={(e) => setMovementFile(e.target.files)}
      />
      <input
        type="file"
        accept=".csv"
        onChange={(e) => setComparingMovementFile(e.target.files)}
      />
      <VideoAnimationViewer
        videos={[]}
        comparingVideos={[]}
        jointCoordinates={jointCoordinates || []}
        comparingJointCoordinates={comparingJointCoordinates || []}
        standaloneViewer
      />
    </>
  );
}
