import { SourcePitch } from "../services/mockData";
import { inRange, isNil, mean } from "lodash";

export const getColorBySource = (source: SourcePitch) => {
  const colors = {
    "Hawkeye(g)": "var(--hoveredDigitalRed)",
    "Kinatrax(g)": "var(--hoveredDigitalGreen)",
    Kinatrax: "var(--hoveredDigitalPink)",
    "Lab Mocap": "var(--hoveredDigitalBlue)",
    "Lab Kinatrax": "#ED8139",
    "Kinatrax(ng)": "var(--hoveredDigitalPink)",
  };
  return colors[source] || "var(--hoveredDigitalRed)";
};

export const getSliderColorByValue = (
  value: number,
  range: { from: number; to: number }
) => {
  return getDiscreteMetricColor(value, range, "var(--hoveredGrey)");
};

export const getTableCellColorByValue = (
  value: number,
  range: { from: number; to: number }
) => {
  return getDiscreteMetricColor(value, range, "var(--white)");
};

export const getDiscreteMetricColor = (
  value: number | undefined,
  range: { from: number; to: number } | undefined,
  defaultColor: string
) => {
  const kind = dataCompKind(value, range);

  switch (kind) {
    case DataCompKind.InRange:
      return defaultColor;
    case DataCompKind.AboveRange:
      return "var(--ptd-comp-data-light-red)";
    case DataCompKind.WayAboveRange:
      return "var(--ptd-comp-data-red)";
    case DataCompKind.BelowRange:
      return "var(--ptd-comp-data-light-blue)";
    case DataCompKind.WayBelowRange:
      return "var(--ptd-comp-data-blue)";
  }
};

export enum DataCompKind {
  InRange,
  AboveRange,
  WayAboveRange,
  BelowRange,
  WayBelowRange,
}

export const dataCompKind = (
  value: number | undefined,
  range: { from: number; to: number } | undefined
): DataCompKind => {
  if (isNil(value) || isNil(range)) {
    return DataCompKind.InRange;
  }

  const { from, to } = range;

  const meanValue = mean([to, from]);
  const deviation = to - meanValue;
  if (inRange(value, meanValue + deviation, meanValue - deviation)) {
    return DataCompKind.InRange;
  }

  if (value <= meanValue - deviation * 2) {
    return DataCompKind.WayBelowRange;
  }

  if (value <= meanValue - deviation) {
    return DataCompKind.BelowRange;
  }

  if (value >= meanValue + deviation * 2) {
    return DataCompKind.WayAboveRange;
  }

  if (value >= meanValue + deviation) {
    return DataCompKind.AboveRange;
  }

  return DataCompKind.InRange;
};
