export interface Filter {
  label: string;
  value: string;
  checked: boolean;
}

export interface Collapsible {
  key: string;
  label: string;
  children: Filter[];
}

export const PITCH_TYPES: Filter[] = [
  {
    label: "All Pitches",
    value: "allPitches",
    checked: false,
  },
  { label: "Fastballs", value: "fastballs", checked: false },
  { label: "Off Speed", value: "offSpeed", checked: false },
  { label: "Breaking Balls", value: "breakingBalls", checked: false },
];

export const QUALIFIERS: Filter[] = [
  { label: "All", value: "all", checked: true },
  { label: ">75%ile Velocity (mph)", value: "sup75Perc", checked: false },
];
