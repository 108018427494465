import React, { useState, useEffect } from "react";
import "./Resizable.css";

interface ChildrenProps {
  width: number;
  height: number;
}

interface ResizableProps {
  children: (childrenProps: ChildrenProps) => JSX.Element;
  minWidth?: number;
  minHeight?: number;
  initialWidth?: number;
  initialHeight?: number;
}

const Resizable = ({
  children,
  minWidth = 590,
  minHeight = 65,
  initialWidth,
  initialHeight,
}: ResizableProps) => {
  const initialHeightInNumber = vhToPixels(initialHeight ?? minHeight);
  const heightInNumber = vhToPixels(minHeight);
  const [width, setWidth] = useState<number>(initialWidth || minWidth);
  const [height, setHeight] = useState<number>(initialHeightInNumber);
  const [isResizing, setIsResizing] = useState<boolean>(false);
  const [resizeDirection, setResizeDirection] = useState<string | null>(null);
  const [initialX, setInitialX] = useState<number>(0);
  const [initialY, setInitialY] = useState<number>(0);

  const handleMouseDown = (
    direction: string,
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    setIsResizing(true);
    setResizeDirection(direction);
    setInitialX(e.clientX);
    setInitialY(e.clientY);
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!isResizing) return;
    const deltaX = e.clientX - initialX;
    const deltaY = e.clientY - initialY;
    let newWidth = width;
    let newHeight = height;

    if (resizeDirection?.includes("right")) {
      newWidth = Math.max(minWidth, width + deltaX);
    }
    if (resizeDirection?.includes("left")) {
      newWidth = Math.max(minWidth, width - deltaX);
    }
    if (resizeDirection?.includes("bottom")) {
      const resizableDiv = document.getElementById("resizable-div");
      const distanceToTop = resizableDiv?.getBoundingClientRect().top;
      const screenHeight = window.innerHeight;
      newHeight = Math.min(
        Math.max(heightInNumber, height + deltaY),
        screenHeight - Number(distanceToTop)
      );
    }

    setWidth(newWidth);
    setHeight(newHeight);
    setInitialX(e.clientX);
    setInitialY(e.clientY);
  };

  useEffect(() => {
    if (initialWidth) setWidth(initialWidth);
  }, [initialWidth]);

  const handleMouseUp = () => {
    setIsResizing(false);
    setResizeDirection(null);
  };

  useEffect(() => {
    if (heightInNumber > height) {
      setHeight(heightInNumber);
    }
  }, [heightInNumber]);
  useEffect(() => {
    if (isResizing) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    } else {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isResizing]);

  return (
    <div
      className={`resizable-div ${isResizing ? "resizing" : ""}`}
      style={{ width: `${width}px`, height: `${height}px` }}
      id="resizable-div"
    >
      <div
        className="resize-handle resize-left"
        onMouseDown={(e) => handleMouseDown("left", e)}
      ></div>
      <div
        className="resize-handle resize-right"
        onMouseDown={(e) => handleMouseDown("right", e)}
      ></div>
      <div
        className="resize-handle resize-bottom-left"
        onMouseDown={(e) => handleMouseDown("bottom-left", e)}
      ></div>
      <div
        className="resize-handle resize-bottom"
        onMouseDown={(e) => handleMouseDown("bottom", e)}
      ></div>
      <div
        className="resize-handle resize-bottom-right"
        onMouseDown={(e) => handleMouseDown("bottom-right", e)}
      ></div>
      {children({ height, width })}
    </div>
  );
};

const vhToPixels = (vh: number): number => {
  const windowHeight = window.innerHeight;
  return (vh * windowHeight) / 100;
};

export default Resizable;
