import React, { ForwardRefExoticComponent, Fragment } from "react";
import { PauseCircleOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { bigButtonStyle } from "../VideoControlPanel/VideoControlPanel";

interface PlayButtonProps {
  onClick: () => void;
  isPlaying: boolean;
}

interface IconComponentProps {
  icon: ForwardRefExoticComponent<any>;
  onClick: () => void;
}

function PlayButton({ onClick, isPlaying }: PlayButtonProps) {
  return (
    <Fragment>
      <IconComponent
        icon={isPlaying ? PauseCircleOutlined : PlayCircleOutlined}
        onClick={onClick}
      />
    </Fragment>
  );
}

const IconComponent = ({ icon: IconFeather, onClick }: IconComponentProps) => (
  <IconFeather onClick={onClick} style={bigButtonStyle} />
);

export default PlayButton;
