import React, { useState } from "react";
import "../PlayerDashboard/PlayerMetricsModalSections/PlayerMetricsModalSections.css";
import { Button, DatePicker, Divider } from "antd";
import { Grid, Popover, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { CloseOutlined } from "@ant-design/icons";
import { Period } from "./PeriodSelector";
import { isNil } from "lodash";

interface DateRangePopoverProps {
  open: boolean;
  setOpen: (param: boolean) => void;
  onSuccess: (param: any) => void;
  anchorEl: any;
  initialPeriod?: Period;
  format?: string;
}

const DateRangePopover = ({
  open,
  setOpen,
  onSuccess,
  anchorEl,
  initialPeriod,
  format,
}: DateRangePopoverProps) => {
  const { RangePicker } = DatePicker;
  const [currentRange, setCurrentRange] = useState<{
    startDate: string | null;
    endDate: string | null;
  }>(
    initialPeriod ?? {
      startDate: null,
      endDate: null,
    }
  );

  const handleCancel = () => {
    setOpen(false);
  };

  const handleApply = () => {
    if (!!currentRange.startDate && !!currentRange.endDate) {
      onSuccess({
        label: "Custom",
        startDate: currentRange.startDate,
        endDate: currentRange.endDate,
      });
    }

    setOpen(false);
  };

  const toFormat = (date: Dayjs) => {
    if (!isNil(format)) {
      return date.format(format);
    }

    return date.toISOString();
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleCancel}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      style={{ zIndex: 1000 }}
    >
      <Grid container m={2} height="225px" width="300px">
        <Grid container flexDirection="column">
          <Grid container flexDirection="row">
            <Grid item xs={10}>
              <Typography variant="h6">
                <strong>Custom Date Range</strong>
              </Typography>
            </Grid>

            <Grid
              item
              xs={2}
              alignItems="center"
              display="flex"
              justifyContent="flex-end"
            >
              <CloseOutlined
                style={{ fontSize: "24px", color: "grey" }}
                onClick={handleCancel}
              />
            </Grid>
          </Grid>

          <Divider />

          <Grid mb={2}>Select Desired date range.</Grid>

          <RangePicker
            value={
              currentRange.startDate
                ? [dayjs(currentRange.startDate), dayjs(currentRange.endDate)]
                : undefined
            }
            popupStyle={{ zIndex: 1001 }}
            style={{ zIndex: 1001 }}
            onChange={(newValue) =>
              setCurrentRange({
                startDate: toFormat(newValue?.[0] || dayjs()),
                endDate: toFormat(newValue?.[1] || dayjs()),
              })
            }
          />
        </Grid>

        <Divider />

        <Grid container justifyContent="flex-end">
          <Button
            key="cancel"
            onClick={handleCancel}
            style={{ marginRight: "12px" }}
          >
            Cancel
          </Button>

          <Button
            key="apply"
            onClick={handleApply}
            disabled={!currentRange.startDate || !currentRange.endDate}
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    </Popover>
  );
};

export default DateRangePopover;
