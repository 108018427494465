import React from "react";
import { Stack } from "@mui/material";
import LabeledSwitch from "../common/LabeledSwitch";

export interface VisibleChartSeries {
  linePlot: boolean;
  errorBar: boolean;
  fullRegression: boolean;
  splitRegression1: boolean;
  splitRegression2: boolean;
}

export type VisibilityOfSeriesKeys =
  | "linePlot"
  | "errorBar"
  | "fullRegression"
  | "splitRegression1"
  | "splitRegression2";

export const initialVisibleSeries: VisibleChartSeries = {
  linePlot: false,
  errorBar: true,
  fullRegression: true,
  splitRegression1: true,
  splitRegression2: true,
};

export interface VisibilityOfSeries {
  visibleChartSeries?: VisibleChartSeries;
  onChangeVisibleChartSeries?: (series: VisibilityOfSeriesKeys) => void;
}

interface VisibleChartSeriesTogglesProps extends VisibilityOfSeries {
  hideLinePlot?: boolean;
  hideErrorBar?: boolean;
}

const VisibleChartSeriesToggles = ({
  visibleChartSeries,
  onChangeVisibleChartSeries,
  hideLinePlot = false,
  hideErrorBar = false,
}: VisibleChartSeriesTogglesProps) => (
  <Stack flexDirection="row" justifyContent="center" height="18px">
    {!hideLinePlot && (
      <LabeledSwitch
        label="Line Plot"
        checked={visibleChartSeries?.linePlot || false}
        onClick={() => {
          if (onChangeVisibleChartSeries) {
            onChangeVisibleChartSeries("linePlot");
          }
        }}
        noMenu
        textTransform="none"
        textColor="black"
      />
    )}

    {!hideErrorBar && (
      <LabeledSwitch
        label="Error Bars"
        checked={visibleChartSeries?.errorBar || false}
        onClick={() => {
          if (onChangeVisibleChartSeries) {
            onChangeVisibleChartSeries("errorBar");
          }
        }}
        noMenu
        textTransform="none"
        textColor="black"
      />
    )}
  </Stack>
);

export default VisibleChartSeriesToggles;
