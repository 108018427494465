import React, { useMemo } from "react";
import { useFetchTrendsDiscreteInningSplitQuery } from "../../services/performanceApi.service";
import { RegressionPillChart } from "../charts";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Stack } from "@mui/material";
import TrendsInfoTable from "./TrendsInfoTable";
import { TrendsDiscreteMetricChartProps } from "./DiscreteMetric";
import useSplitRegressions from "./useSplitRegressions";

export default function InningSplitChart({
  metric,
  timePeriod,
  dataComp,
}: TrendsDiscreteMetricChartProps) {
  const { data } = useFetchTrendsDiscreteInningSplitQuery(
    metric?.id
      ? {
          metricId: metric?.id,
          timePeriod,
        }
      : skipToken
  );

  const { splitRegression1, splitRegression2, onApply, onReset } =
    useSplitRegressions(data);

  const filteredDataComp = useMemo(
    () =>
      data?.dataComp?.filter((it: any) =>
        data?.values?.find((value: any) => value.inning === it.x)
      ),
    [data]
  );

  return (
    <Stack>
      <RegressionPillChart
        data={data?.values || []}
        // Regressions returns readonly DataPoint[] and `readonly` keyword throws syntax error
        // @ts-expect-error
        regressionsData={data?.regression?.points || []}
        dataComp={filteredDataComp || []}
        isDataCompActive={dataComp}
        // Regressions returns readonly DataPoint[] and `readonly` keyword throws syntax error
        // @ts-expect-error
        splitRegression1Data={splitRegression1?.result?.points}
        // Regressions returns readonly DataPoint[] and `readonly` keyword throws syntax error
        // @ts-expect-error
        splitRegression2Data={splitRegression2?.result?.points}
      />
      <TrendsInfoTable
        data={data}
        countLabel="Days"
        onApply={onApply}
        onReset={onReset}
        splitRegression1={splitRegression1}
        splitRegression2={splitRegression2}
      />
    </Stack>
  );
}
