import React from "react";
import PitchIcon from "../common/PitchIcon";
import { Grid, styled } from "@mui/material";
import { useStrikeDots } from "../../hooks/useStrikeDots";
import { Pitch } from "../../services/mockData";
import { clamp } from "./PitchChart";

const Wrapper = styled("div")({
  height: "20px",
  width: "15.6px",
  margin: "0 auto",
  zoom: "1.9",
});

const StrikeZone = styled("div")({
  height: "100%",
  width: "100%",
  position: "relative",
  background: "rgba(196, 196, 196, 0.25)",
  border: "0.05px solid rgba(0, 0, 0, 0.5)",
});

interface PitchIndicatorProps {
  x: number;
  y: number;
}

const PitchIndicator = styled("div")<PitchIndicatorProps>(({ x, y }) => ({
  top: clamp(-12, 25, y),
  left: clamp(-53, 53, x),
  zIndex: 5,
  height: "3.2px",
  width: "3.2px",
  lineHeight: "32px",
  textAlign: "center",
  borderRadius: "50%",
  cursor: "pointer",
  backgroundColor: "#cf1322",
  position: "absolute",
  color: "white",
  WebkitTextStrokeWidth: "1px",
  WebkitTextStrokeColor: "black",
}));

export function PitchZone({ pitch }: { pitch: Pitch }) {
  const { finalX, finalY } = useStrikeDots({
    x: pitch.strikeZonePosition?.x * -1,
    y: pitch.strikeZonePosition?.y,
  });

  return (
    <Grid item p={1} direction="column" className="ptd-pitch-chart-left">
      <Wrapper>
        <StrikeZone>
          <PitchIndicator x={finalX} y={finalY} />
        </StrikeZone>
      </Wrapper>

      <PitchIcon />
    </Grid>
  );
}
