import { createListenerMiddleware } from "@reduxjs/toolkit";
import type { TypedStartListening } from "@reduxjs/toolkit";
import type {
  PerformanceAppDispatch,
  PerformanceRootState,
} from "./performanceReduxModule";

export const listenerMiddleware = createListenerMiddleware();

export type AppStartListening = TypedStartListening<
  PerformanceRootState,
  PerformanceAppDispatch
>;

// @ts-ignore
export const startAppListening: AppStartListening =
  listenerMiddleware.startListening;
