import React from "react";
import { BodyPartTabs } from "../../../../services/mockData";
import BodyPartsLayout, { BodyPartsLayoutChildren } from "./BodyPartsLayout";

function ThrowingArm({ container }: BodyPartsLayoutChildren) {
  return (
    <BodyPartsLayout
      container={container}
      bodyPartName={BodyPartTabs.ThrowingArm}
    />
  );
}

export default ThrowingArm;
