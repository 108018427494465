import { isNil, isEqual } from "lodash";
import { PlayerDashPositionSettings } from "./movementSlice";
import { startAppListening } from "./listenerMiddleware";
import { PerformanceRootState } from "./performanceReduxModule";

export function isPlayerSectionEnabled(
  sectionKey: string | undefined,
  playerDashSettings: PlayerDashPositionSettings
) {
  return (
    (!isNil(sectionKey) &&
      playerDashSettings.find((it) => it.key === sectionKey)?.enabled) ??
    false
  );
}

export function playerSectionOrder(
  sectionKey: string | undefined,
  playerDashSettings: PlayerDashPositionSettings
) {
  return playerDashSettings.findIndex((it) => it.key === sectionKey);
}

export function localStorageRead(key: string) {
  const item = localStorage.getItem(key);
  return isNil(item) ? undefined : JSON.parse(item);
}

export function localStorageWrite(key: string, payload: any) {
  localStorage.setItem(key, JSON.stringify(payload));
}

export function persistOnLocalStorage(
  key: string,
  selector: (state: PerformanceRootState) => any
) {
  startAppListening({
    predicate: (_action, currentState, prevState) =>
      !isEqual(selector(currentState), selector(prevState)),
    effect: (_action, { getState }) => {
      localStorageWrite(key, selector(getState()));
    },
  });
}
