export const LOAD_STATUS = {
  UNINITIALIZED: "UNINITIALIZED",
  LOADING: "LOADING",
  READY: "READY",
  ERROR: "ERROR",
};

export const SEARCH_RESULT_TYPES = {
  PLAYERS: "players",
  TEAMS: "teams",
};

export const SEARCH_MODES = {
  single: "single",
  multiple: "multiple",
};

export const PLAYER_TYPE = {
  PITCHER: "Pitcher",
  POSITION_PLAYER: "Position Player",
};
