import { useEffect, useState } from "react";
import { useLazyFetchMetricsQuery } from "../services/performanceApi.service";
import { extraPeopleColors } from "../three-js/extraPeopleColors";
import { useSelector } from "react-redux";
import { selectSelectedMotionType } from "../redux/selectors";
export const METRIC = "metric";
export const SELF = "self";

export function useFetchExtraMovementsMetrics(extraMovements: any[]) {
  const [extraMovementsMetrics, setExtraMovementsMetrics] = useState<any[]>([]);
  const motionType = useSelector(selectSelectedMotionType);
  const [fetchMetrics] = useLazyFetchMetricsQuery();
  useEffect(() => {
    Promise.all(
      extraMovements
        ?.map((it, i) => ({ ...it, color: extraPeopleColors[i] }))
        ?.filter((it: any) => it.trial?.id && it.player?.id)
        .map((it: any, i) => {
          return fetchMetrics({ pitch: it.trial, metricId: METRIC, motionType })
            .unwrap()
            .then((metrics) => ({
              movement: it,
              color: it.color,
              movementIndex: i,
              metrics,
            }));
        })
    ).then((it) => setExtraMovementsMetrics(it));
  }, [extraMovements]);
  return { extraMovementsMetrics };
}
