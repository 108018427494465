import React, { CSSProperties, useMemo } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highcharts.js";
import AnnotationsModule from "highcharts/modules/annotations";

AnnotationsModule(Highcharts);

interface MemoizedHighchartsProps {
  options: any;
  style?: CSSProperties;
}

// Tried wrapping the component with memo() but didn't work
export default function MemoizedHighcharts({
  options,
  style,
}: MemoizedHighchartsProps) {
  const Chart = useMemo(
    () => (
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        containerProps={{ style }}
      />
    ),
    [JSON.stringify(options)]
  );
  return Chart;
}
