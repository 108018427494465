import React from "react";
import BodyPartsLayout, { BodyPartsLayoutChildren } from "./BodyPartsLayout";
import { BodyPartTabs } from "../../../../services/mockData";

interface BattingContainerProps extends BodyPartsLayoutChildren {
  tab: BodyPartTabs;
}

function BattingContainer({ container, tab }: BattingContainerProps) {
  return <BodyPartsLayout container={container} bodyPartName={tab} />;
}

export default BattingContainer;
