import { isEmpty } from "lodash";
import * as THREE from "three";
const scale = 10;

export class MiddleJoint3D {
  constructor(
    model,
    {
      jointCoordinates,
      name,
      scaleSeg,
      rotationExtra,
      distalName,
      positionWeight,
      skipScaling = false,
      extraX = 0,
    }
  ) {
    // Filtering possible empty joint coordinates to avoid app crashes.
    const filteredJointCoordinates = jointCoordinates.filter(
      (it) => !isEmpty(it)
    );
    this.segment = model;
    //const axesHelper = new THREE.AxesHelper(1);
    //this.segment.add(axesHelper);
    Object.assign(this, {
      jointCoordinates: filteredJointCoordinates,
      name,
      scaleSeg,
      rotationExtra,
      distalName,
      positionWeight,
      skipScaling,
      extraX,
    });
    this.rotationCache = {};
    this.positionCache = {};
  }

  render(currentFrame) {
    if (this.segment) {
      try {
        const drawableFrame = Math.min(
          this.jointCoordinates.length - 2,
          currentFrame
        );
        var { euler, dcm } = this.segmentRotation(drawableFrame);
        //euler.setFromRotationMatrix(mat);
        this.segment.rotation.copy(euler);
        // draw helper axes on this.segment

        // get distal this.segment position
        let { position, positionMiddle, positionDistal } = this.segmentPosition(
          drawableFrame,
          dcm
        );
        this.segment.position.copy(positionMiddle);
        this.scaleSegment(position, positionDistal);
      } catch (e) {
        //Offsets for trial sync might create an out of bounds array access. Ignore it.
      }
    }
  }

  scaleSegment(position, positionDistal) {
    if (!this.skipScaling) {
      var lengthX_pos = Math.pow(positionDistal.x - position.x, 2);
      var lengthY_pos = Math.pow(positionDistal.y - position.y, 2);
      var lengthZ_pos = Math.pow(positionDistal.z - position.z, 2);
      let scaleVal = Math.sqrt(lengthX_pos + lengthY_pos + lengthZ_pos);
      let blenderScale = this.scaleSeg * 2.0;
      let scaleSegNew = scaleVal / blenderScale;
      this.segment.scale.set(scaleSegNew, scaleSegNew, scaleSegNew);
    } else {
      this.segment.scale.set(this.scaleSeg, this.scaleSeg, this.scaleSeg);
    }
  }

  segmentPosition(drawableFrame, dcm) {
    /*if (this.positionCache[drawableFrame]) {
      return this.positionCache[drawableFrame];
    }*/
    let dcmDistal =
      this.jointCoordinates[drawableFrame][this.distalName].elements;

    let positionDistal = new THREE.Vector3();
    var position = new THREE.Vector3();

    position.set(dcm[3] * scale, dcm[7] * scale, dcm[11] * scale);
    positionDistal.set(
      dcmDistal[3] * scale,
      dcmDistal[7] * scale,
      dcmDistal[11] * scale
    );

    let positionMiddle = new THREE.Vector3();
    let pw1 = this.positionWeight;
    let pw2 = 1 - pw1;
    positionMiddle.set(
      position.x * pw1 + positionDistal.x * pw2,
      position.y * pw1 + positionDistal.y * pw2,
      position.z * pw1 + positionDistal.z * pw2
    );
    const positions = { position, positionMiddle, positionDistal };
    this.positionCache[drawableFrame] = positions;

    return positions;
  }

  segmentRotation(drawableFrame) {
    /*if (this.rotationCache[drawableFrame]) {
      return this.rotationCache[drawableFrame];
    }*/
    var euler = new THREE.Euler();
    var dcm = null;
    try {
      dcm = this.jointCoordinates[drawableFrame][this.name].elements;
    } catch (_e) {
      //If drawableFrame is unavailable, use last frame
      dcm =
        this.jointCoordinates[this.jointCoordinates.length - 1][this.name]
          .elements;
    }
    var mat = new THREE.Matrix4();
    mat.set(
      dcm[0],
      dcm[1],
      dcm[2],
      dcm[3],
      dcm[4],
      dcm[5],
      dcm[6],
      dcm[7],
      dcm[8],
      dcm[9],
      dcm[10],
      dcm[11],
      0,
      0,
      0,
      1
    );
    euler.setFromRotationMatrix(mat);

    const segmentRotation = { euler, dcm };
    this.rotationCache[drawableFrame] = segmentRotation;
    return segmentRotation;
  }
}
