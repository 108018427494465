import { useParams } from "react-router-dom";
import {
  useFetchPlayersQuery,
  useFetchTeamsQuery,
  useFetchTeamNormativeRangesQuery,
  useFetchAvailableLevelsQuery,
  useFetchPlayersFilesSummaryQuery,
  useFetchAdHocRostersQuery,
  useFetchPlayersInsightsSummaryQuery,
} from "../services/performanceApi.service";
import { useUpdateMetric } from "./useUpdateMetric";
import { useMemo, useState } from "react";
import { Level, METRICS_PLAYER, Team } from "../services/mockData";
import {
  filter,
  flatMap,
  groupBy,
  includes,
  isEmpty,
  partition,
  some,
  uniqBy,
} from "lodash";
import { PITCHER_TYPES, mapPlayerPosition } from "./pitcherTypes";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { TeamPlayerPosition } from "../components/TeamDashboard/TeamDataSelector/PositionSelector";
import { usePerformanceAppSelector } from "../redux/hooks";
import { useSelector } from "react-redux";
import { selectPeriodSelectorValue } from "../redux/selectors";
import { dateToISO } from "../services/performanceApi.adapter";
import dayjs from "dayjs";
import { getOppositeMetric, sidelessMetric } from "../utils/metrics";

export const DR_ORANGE_SAMPLE_ID = 1000001;
export const DR_BLUE_SAMPLE_ID = 1000002;

export interface TeamPlayersData {
  data: any[] | undefined;
  allPlayers: any[] | undefined;
  hiddenList: any[];
  visible: boolean;
  handleHidden: () => void;
  onHandleHidden: (id: number | string) => void;
  items: (any | null)[];
  metrics: any[];
  updateMetric: (current: any, newMetricKey: string) => void;
  isLoading: boolean;
  metricsAreLoading: boolean;
  updateAllMetrics: (newMetrics: any) => void;
  metsLevels: Level[];
  filesSummaryIsLoading: boolean;
  filesSummary: any;
  insightsSummary: any;
  levelTeams: Team[];
}

export const TEAM_NAMES_BY_LEVEL: any = {
  "Major League Baseball": "New York Mets",
  "Triple-A": "Syracuse Mets",
  "Double-A": "Binghamton Rumble Ponies",
  "High-A": "Brooklyn Cyclones",
  "Single-A": "St. Lucie Mets",
  "DR - DSL Orange": "DSL Mets Orange",
  "DR - DSL Blue": "DSL Mets Blue",
  "Florida & Arizona Complex Leagues and other rookie complexes": "FCL Mets",
};

export const LEVEL_NAME_BY_SHORT_NAME: any = {
  "Double-A": "AA",
  "DSL Mets Orange": "DSL",
  "DSL Mets Blue": "DSL",
  "Dominican Summer League and other rookie academies": "DSL",
  "Single-A": "Low A",
  "High-A": "High A",
  "Major League Baseball": "MLB",
  "Triple-A": "AAA",
  FCL: "FCL",
};

const FCL_LONG_NAME =
  "Florida & Arizona Complex Leagues and other rookie complexes";
const FCL_SHORT_NAME = "FCL";
function swapLevelName(levels: Level[], fromName: string, toName: string) {
  return levels.map((level) =>
    level.name === fromName
      ? {
          ...level,
          name: toName,
        }
      : level
  );
}
export function getLevelName(shortName: string) {
  return LEVEL_NAME_BY_SHORT_NAME[shortName];
}

export const NYM_LEVELS = Object.keys(TEAM_NAMES_BY_LEVEL);
export const ALL_LEVEL_ID = 0;
export function useTeamPlayers({
  selectedPlayerIds,
  selectedPlayerLevels: rawSelectedPlayerLevels,
  selectedPlayerPositions,
  selectedColumns,
}: {
  selectedPlayerIds: string[];
  selectedPlayerPositions: string[] | null;
  selectedPlayerLevels: Level[];
  selectedColumns: any;
}): TeamPlayersData {
  const selectedPlayerLevels = swapLevelName(
    rawSelectedPlayerLevels,
    FCL_SHORT_NAME,
    FCL_LONG_NAME
  );
  const allLevelsOptionIsSelected = some(
    selectedPlayerLevels,
    (level) => level.id == ALL_LEVEL_ID
  );

  const { data: adHocRosters = [] } = useFetchAdHocRostersQuery();
  const { data: rawLevels, isLoading: levelsAreLoading } =
    useFetchAvailableLevelsQuery();
  const levels = rawLevels || [];
  //DR - DSL Orange/Blue are not actual levels but we want to show them as such
  const rawMetsLevels = levels
    .filter(
      (it) => it.name !== "Dominican Summer League and other rookie academies"
    )
    .concat([
      { id: DR_ORANGE_SAMPLE_ID, name: "DR - DSL Orange" },
      { id: DR_BLUE_SAMPLE_ID, name: "DR - DSL Blue" },
    ])
    .filter((it) => NYM_LEVELS.includes(it.name))
    //Make ad-hoc rosters appear on the dropdown
    .concat(adHocRosters);

  const metsLevels = swapLevelName(
    rawMetsLevels,
    FCL_LONG_NAME,
    FCL_SHORT_NAME
  );
  const position = usePerformanceAppSelector(
    (state: any) => state.movement.teamPlayerPosition
  );

  const selectedLevels = allLevelsOptionIsSelected
    ? metsLevels || []
    : selectedPlayerLevels;
  const levelIds = (selectedLevels || [])
    .map((playerLevel) => {
      //Map DSL Orange/Blue to actual DR level
      const levelId = [DR_BLUE_SAMPLE_ID, DR_ORANGE_SAMPLE_ID].includes(
        playerLevel?.id || 0
      )
        ? levels?.find(
            (aLevel) =>
              aLevel.name ===
              "Dominican Summer League and other rookie academies"
          )?.id
        : playerLevel?.id;
      return levelId;
    })
    //Do not try to fetch ad hoc roster levels teams
    .filter((levelId) => !some(adHocRosters, (it) => it.id === levelId));
  const { data: rawLevelTeams, isLoading: teamsAreLoading } =
    useFetchTeamsQuery(!isEmpty(levelIds) ? levelIds.join(",") : skipToken);

  const {
    data: teamNormativeRangesData /*, isLoading: normativeRangesAreLoding*/,
  } = useFetchTeamNormativeRangesQuery();
  const grouppedData = groupBy(teamNormativeRangesData, "metricName");

  const levelTeams = (rawLevelTeams || []).concat(adHocRosters);
  const teamIdByLevels = () => {
    const teamIds = selectedLevels
      .map((playerLevel) => {
        if (!playerLevel) return null;
        const team = levelTeams.find(
          (it) => it.name === TEAM_NAMES_BY_LEVEL[playerLevel.name]
        );
        return team?.id;
      })
      .filter((it) => it);
    return teamIds;
  };
  const { teamId: pathTeamId } = useParams();
  const teamIds = (pathTeamId ? [pathTeamId] : teamIdByLevels()).map(
    (it) => it?.toString() || ""
  );
  const [visible, setVisible] = useState(true);
  const [hiddenList, setHiddenList] = useState<any[]>([]);
  const { allItems, updateMetric, metrics, setMetrics } = useUpdateMetric();
  const timePeriod = useSelector(selectPeriodSelectorValue);
  const endDate = timePeriod.endDate;
  const dashboardMode =
    flatMap(selectedColumns[position], (metric: string) =>
      sidelessMetric(metric) === metric
        ? [metric]
        : [metric, getOppositeMetric(metric)]
    ) || true;
  const [adHocLevels, regularLevels] = partition(selectedLevels, (it) =>
    some(adHocRosters, (x) => x.id === it.id.toString())
  );
  //Fetch team players
  const { data: rawTeamPlayersData = [], isFetching: isLoading } =
    useFetchPlayersQuery(
      isEmpty(teamIds) || !timePeriod?.endDate || !timePeriod?.startDate
        ? skipToken
        : {
            onlyPitchers: false,
            position,
            teams: teamIds,
            timePeriod,
            dashboardMode,
          }
    );
  const teamPlayersData = !isEmpty(regularLevels) ? rawTeamPlayersData : [];
  const retrievableAdHocLevels = allLevelsOptionIsSelected
    ? []
    : adHocLevels?.map(
        //Levels are locally cached, use up to date roster definition for fetching players. Fallback to cached level if roster is not present.
        (adHocLevel) =>
          adHocRosters?.find(
            (adHocRoster: any) => adHocLevel.id === adHocRoster.id
          ) || adHocLevel
      ) || [];
  //Fetch ad-hoc roster players
  const {
    data: rawAdHocPlayersData = [],
    isFetching: adHocPlayersDataLoading,
  } = useFetchPlayersQuery(
    !isEmpty(retrievableAdHocLevels)
      ? {
          onlyPitchers: false,
          playerIds: flatMap(
            retrievableAdHocLevels,
            (it: any) => it.players || []
          ),
          position,
          timePeriod,
          dashboardMode,
          includeFreeAgents: true,
        }
      : skipToken
  );
  const adHocPlayersData = !isEmpty(adHocLevels) ? rawAdHocPlayersData : [];
  //Avoid duplicates with team players and ad-hoc players
  const playersData = uniqBy(
    teamPlayersData.concat(adHocPlayersData),
    (it) => it.id
  );
  const playerIds = useMemo(() => {
    return playersData?.map((player) => player.id?.toString()) || [];
  }, [playersData]);
  const { data: filesSummary = {}, isFetching: filesSummaryIsLoading } =
    useFetchPlayersFilesSummaryQuery(
      !isEmpty(playerIds) ? playerIds : skipToken
    );
  const { data: insightsSummary = [], isFetching: insightsSummaryIsLoading } =
    useFetchPlayersInsightsSummaryQuery(
      !isEmpty(playerIds) ? { playerIds, timePeriod } : skipToken
    );

  const data = useMemo(() => {
    return playersData
      ?.filter(
        (it) =>
          position === TeamPlayerPosition.All ||
          (position === TeamPlayerPosition.Pitcher) ===
            PITCHER_TYPES.includes(it.position || "")
      )
      .filter(
        (it) =>
          isEmpty(selectedPlayerIds) ||
          selectedPlayerIds?.includes(it.id.toString() || "")
      )
      .filter(
        (it) =>
          isEmpty(selectedPlayerPositions) ||
          includes(selectedPlayerPositions, "All") ||
          selectedPlayerPositions?.includes(mapPlayerPosition(it) || "")
      )
      .map((player) => {
        const metricsInPeriod = !endDate
          ? player.metrics
          : player.metrics?.filter((metric: any) => {
              return dayjs(dateToISO(metric.date))
                .startOf("day")
                .isBefore(dayjs(endDate).endOf("day"));
            }) || [];
        return { ...player, metrics: metricsInPeriod };
      });
  }, [
    playersData,
    selectedPlayerIds,
    selectedPlayerPositions,
    selectedPlayerLevels,
    adHocRosters,
    position,
    endDate,
  ]);

  const handleHidden = () => {
    if (visible) {
      setHiddenList(playerIds);
    } else {
      setHiddenList([]);
    }
    setVisible((prevState) => !prevState);
  };

  const onHandleHidden = (id: number | string) => {
    const isHidden = hiddenList.some((hidden: any) => hidden === id);
    if (!isHidden) {
      setHiddenList((prevState: any) => [...prevState, id]);
    } else {
      setHiddenList((prevState) =>
        filter(prevState, (hidden: any) => hidden !== id)
      );
    }
  };

  const updateAllMetrics = (newMetrics: any) => {
    setMetrics(newMetrics);
  };

  const mappedMetrics = metrics.map((it) => ({
    ...it,
    normativeRanges: grouppedData[it.key],
  }));
  return {
    data,
    allPlayers: playersData,
    hiddenList,
    visible,
    handleHidden,
    onHandleHidden,
    items: allItems,
    metrics: mappedMetrics,
    updateMetric,
    isLoading:
      isLoading ||
      levelsAreLoading ||
      teamsAreLoading ||
      adHocPlayersDataLoading,
    metricsAreLoading: isLoading,
    updateAllMetrics,
    metsLevels,
    filesSummaryIsLoading: filesSummaryIsLoading || insightsSummaryIsLoading,
    filesSummary,
    insightsSummary,
    levelTeams: levelTeams.filter((it) => teamIds.includes(it.id.toString())),
  };
}

export const METRIC_CATEGORIES = [
  {
    label: "MSK",
    key: "msk_index_grade",
    category: "MSK",
    index: METRICS_PLAYER.Msk,
    group: "MSK",
  },
  {
    label: "ROM",
    key: "rom_index_grade",
    category: "ROM",
    index: METRICS_PLAYER.Rom,
    group: "ROM",
  },
  {
    label: "Power",
    key: "pwr_index_grade",
    category: "Power",
    index: METRICS_PLAYER.Power,
  },
  {
    label: "Speed",
    key: "spd_index_grade",
    category: "Speed",
    index: METRICS_PLAYER.Speed,
  },
  {
    label: "Body Comp",
    key: "bcmp_index_grade",
    category: "BodyComp",
    index: METRICS_PLAYER.BodyComp,
  },
  {
    label: "Vision",
    key: "vis_index_grade",
    category: "Vision",
    index: METRICS_PLAYER.Vision,
  },
  {
    label: "Workload",
    key: "workload",
    category: "Workload",
    index: METRICS_PLAYER.Workload,
  },
  {
    label: "On-Base-U",
    key: "obu",
    category: "OBU",
    index: METRICS_PLAYER.Workload,
  },
];

export interface TeamDashMetric {
  key: string;
  label: string;
  variabledb?: string;
  category: string;
  children: TeamDashMetric[];
  group: string | undefined;
}

export function flattenTeamDashMetrics<T = any>(allItems?: any[]): T[] {
  return (allItems ?? []).flatMap((it) =>
    isEmpty(it?.children) ? it : flattenTeamDashMetrics(it?.children)
  );
}
