import React from "react";
import { Grid, Typography } from "@mui/material";
import Bar from "../common/Bar";
import { inRange } from "lodash";

interface RangeOfMotionChartProps {
  value: number;
}
interface FlexibilityRange {
  id: string;
  label: string;
  rangeFrom: number;
  rangeTo: number;
}
const options: FlexibilityRange[] = [
  {
    id: "highlyInflexible",
    label: "Very Tight",
    rangeFrom: 0,
    rangeTo: 25,
  },
  {
    id: "inflexible",
    label: "Tight",
    rangeFrom: 25,
    rangeTo: 40,
  },
  {
    id: "normal",
    label: "Normal",
    rangeFrom: 40,
    rangeTo: 60,
  },
  {
    id: "flexible",
    label: "Flexible",
    rangeFrom: 60,
    rangeTo: 75,
  },
  {
    id: "highlyFlexible",
    label: "Very Flexible",
    rangeFrom: 75,
    rangeTo: 80,
  },
];
const showLabel = (index: number) =>
  index === 0 ||
  index === options.length - 1 ||
  index === Math.floor(options.length / 2);

export function flexibilityLevelByValue(
  numericValue: number
): FlexibilityRange {
  return (
    options.find((it) => inRange(numericValue, it.rangeFrom, it.rangeTo)) ||
    options[0]
  );
}

const RangeOfMotionChart = ({
  value: numericValue,
}: RangeOfMotionChartProps) => {
  const { id } = flexibilityLevelByValue(numericValue);
  return (
    <Grid
      container
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
    >
      {options.map((option, index) => (
        <Grid item key={option.id} flexDirection="column">
          <Bar active={option.id === id} />

          <Grid mt={2} width="30px" height="30px">
            <Typography fontSize="9px" align="center" sx={{ color: "#9291a5" }}>
              {showLabel(index) ? option.label : "____"}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default RangeOfMotionChart;
