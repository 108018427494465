export const REPORT_SUMMARY_EVENTS = [
  "FFC",
  "MS",
  "MKH",
  "FT",
  "HS",
  "IFC",
  "MER",
  "MAX",
  "TMAX",
  "MIN",
  "ILO",
];

export const REPORT_SUMMARY_METRICS = {
  timeSeriesVariables: [
    "km_wb_trunk_angle_forwardtilt_ts",
    "km_wb_trunk_angle_rot_ts",
    "km_wb_trunk_angle_glovelean_ts",
    "km_wb_pelvis_angle_forwardtilt_ts",
    "km_wb_pelvis_angle_rot_ts",
    "km_wb_pelvis_angle_glovelean_ts",
    "km_r_knee_angle_flexion_ts",
    "pos_g_com_velocity_x_ts",
    "km_wb_spine_angle_glovelean_ts",
    "km_p_shoulder_angle_abd_ts",
    "km_p_shoulder_angle_rot_ts",
    "km_p_shoulder_angle_hadd_ts",
    "km_p_elbow_angle_flexion_ts",
    "km_l_shoulder_angle_hadd_ts",
    "km_wb_pelvis_velocity_rot_ts",
    "km_wb_trunk_velocity_rot_ts",
    "km_l_knee_angle_flexion_ts",
    "km_l_knee_velocity_flexion_ts",
    "pos_g_com_displacement_x_ts",
    "pos_g_com_velocity_mag_ts",
    "km_l_ankle_angle_flexion_ts",
    "km_r_hip_angle_flexion_ts",
    "km_l_knee_angle_valgus_ts",
    "km_wb_spine_angle_rot_ts",
    "km_wb_spine_angle_forwardtilt_ts",
  ],
  discreteVariables: [
    "KM_WB_Trunk_Angle_ROT_TS_MKH",
    "KM_WB_Trunk_Angle_ROT_TS_ILO",
    "KM_WB_Trunk_Angle_ForwardTilt_TS_MKH",
    "KM_WB_Trunk_Angle_ForwardTilt_TS_ILO",
    "KM_WB_Trunk_Angle_GloveLean_TS_MKH",
    "KM_WB_Trunk_Angle_GloveLean_TS_ILO",
    "KM_WB_Pelvis_Angle_ROT_TS_MKH",
    "KM_WB_Pelvis_Angle_ROT_TS_ILO",
    "KM_WB_Pelvis_Angle_ForwardTilt_TS_MKH",
    "KM_WB_Pelvis_Angle_ForwardTilt_TS_ILO",
    "KM_WB_Pelvis_Angle_GloveLean_TS_MKH",
    "KM_WB_Pelvis_Angle_GloveLean_TS_ILO",
    "KM_R_Knee_Angle_Flexion_TS_MKH",
    "KM_R_Knee_Angle_Flexion_TS_ILO",
    "POS_G_COM_Velocity_X_TS_MKH",
    "POS_G_COM_Velocity_X_TS_ILO",
    "KM_WB_Trunk_Angle_ForwardTilt_TS_MS",
    "KM_WB_Trunk_Angle_GloveLean_TS_MS",
    "KM_WB_Pelvis_Angle_ROT_TS_MS",
    "KM_WB_Pelvis_Angle_ForwardTilt_TS_MS",
    "KM_WB_Pelvis_Angle_GloveLean_TS_MS",
    "KM_R_Knee_Angle_Flexion_TS_MS",
    "POS_G_COM_Velocity_X_TS_MS",
    "KM_WB_Trunk_Angle_ForwardTilt_TS_FFC",
    "KM_WB_Trunk_Angle_GloveLean_TS_FFC",
    "KM_WB_Pelvis_Angle_ROT_TS_FFC",
    "KM_WB_Pelvis_Angle_ForwardTilt_TS_FFC",
    "KM_WB_Pelvis_Angle_GloveLean_TS_FFC",
    "KM_R_Knee_Angle_Flexion_TS_FFC",
    "POS_G_COM_Velocity_X_TS_FFC",
    "POS_G_COM_Velocity_X_TS_MIN",
    "KM_P_Shoulder_Angle_ABD_TS_MS",
    "KM_P_Shoulder_Angle_ROT_TS_MS",
    "KM_P_Elbow_Angle_Flexion_TS_MS",
    "KM_P_Shoulder_Angle_HAdd_TS_MS",
    "KM_L_Shoulder_Angle_HAdd_TS_MS",
    "KM_WB_Trunk_Angle_ROT_TS_MS",
    "KM_P_Shoulder_Angle_ROT_TS_FFC",
    "KM_P_Elbow_Angle_Flexion_TS_FFC",
    "KM_P_Shoulder_Angle_HAdd_TS_FFC",
    "KM_L_Shoulder_Angle_HAdd_TS_FFC",
    "KM_L_Shin_Angle_ELEV_TS_FFC",
    "KM_WB_Trunk_Angle_ROT_TS_FFC",
    "KM_WB_HipShoulder_Angle_SeparationAdd_TS_MAX",
    "KM_WB_Stride_Length_inches",
    "KM_WB_Stride_Length_ht",
    "KM_WB_Stride_Width_inches",
    "L_Knee_Extension_Total",
    "Lead_Knee_Stability_Frontal",
    "KM_L_Knee_Angle_Flexion_TS_BR",
    "KM_L_Knee_Angle_Flexion_TS_FFC",
    "KM_WB_Pelvis_Velocity_ROT_TS_MAX",
    "KM_WB_Trunk_Velocity_ROT_TS_MAX",
    "KM_WB_Trunk_Velocity_ROT_TS_TMAX",
    "KM_WB_Pelvis_Velocity_ROT_TS_TMAX",
    "KM_WB_Spine_Angle_ROT_TS_MIN",
    "KM_WB_Spine_Angle_ForwardTilt_TS_MIN",
    "KM_WB_Spine_Angle_GloveLean_TS_MAX",
    "PelvisTrunk_PeakVelocitySep_Time_Total",
  ],
  pitchMetricsVariables: [],
};

export const REPORT_SUMMARY_SECTIONS: {
  [key: string]: string[];
} = {
  lead: [
    "KM_WB_Trunk_Angle_ROT_TS",
    "KM_WB_Trunk_Angle_ROT_TS_ILO",
    "KM_WB_Trunk_Angle_ROT_TS_MKH",
    "KM_WB_Trunk_Angle_ForwardTilt_TS",
    "KM_WB_Trunk_Angle_ForwardTilt_TS_ILO",
    "KM_WB_Trunk_Angle_ForwardTilt_TS_MKH",
    "KM_WB_Trunk_Angle_GloveLean_TS",
    "KM_WB_Trunk_Angle_GloveLean_TS_ILO",
    "KM_WB_Trunk_Angle_GloveLean_TS_MKH",
    "KM_WB_Pelvis_Angle_ROT_TS",
    "KM_WB_Pelvis_Angle_ROT_TS_ILO",
    "KM_WB_Pelvis_Angle_ROT_TS_MKH",
    "KM_WB_Pelvis_Angle_ForwardTilt_TS",
    "KM_WB_Pelvis_Angle_ForwardTilt_TS_ILO",
    "KM_WB_Pelvis_Angle_ForwardTilt_TS_MKH",
    "KM_WB_Pelvis_Angle_GloveLean_TS",
    "KM_WB_Pelvis_Angle_GloveLean_TS_ILO",
    "KM_WB_Pelvis_Angle_GloveLean_TS_MKH",
    "KM_R_Knee_Angle_Flexion_TS",
    "KM_R_Knee_Angle_Flexion_TS_ILO",
    "KM_R_Knee_Angle_Flexion_TS_MKH",
    "POS_G_COM_Velocity_X_TS",
    "POS_G_COM_Velocity_X_TS_ILO",
    "POS_G_COM_Velocity_X_TS_MKH",
    "KM_WB_Pelvis_Velocity_ROT_TS",
    "KM_L_Knee_Velocity_Flexion_TS",
    "KM_L_Knee_Velocity_Flexion_TS_ILO",
  ],
  lHalfLoad: [
    "KM_WB_Trunk_Angle_ForwardTilt_TS",
    "KM_WB_Trunk_Angle_ForwardTilt_TS_MS",
    "KM_WB_Trunk_Angle_ForwardTilt_TS_FFC",
    "KM_WB_Trunk_Angle_GloveLean_TS",
    "KM_WB_Trunk_Angle_GloveLean_TS_MS",
    "KM_WB_Trunk_Angle_GloveLean_TS_FFC",
    "KM_WB_Pelvis_Angle_ROT_TS",
    "KM_WB_Pelvis_Angle_ROT_TS_MS",
    "KM_WB_Pelvis_Angle_ROT_TS_FFC",
    "KM_WB_Pelvis_Angle_ForwardTilt_TS",
    "KM_WB_Pelvis_Angle_ForwardTilt_TS_MS",
    "KM_WB_Pelvis_Angle_ForwardTilt_TS_FFC",
    "KM_WB_Pelvis_Angle_GloveLean_TS",
    "KM_WB_Pelvis_Angle_GloveLean_TS_MS",
    "KM_WB_Pelvis_Angle_GloveLean_TS_FFC",
    "KM_R_Knee_Angle_Flexion_TS",
    "KM_R_Knee_Angle_Flexion_TS_MS",
    "KM_R_Knee_Angle_Flexion_TS_FFC",
    "POS_G_COM_Velocity_Mag_TS",
    "POS_G_COM_Velocity_Mag_TS_MAX",
    "POS_G_COM_Velocity_X_TS",
    "POS_G_COM_Velocity_X_TS_FFC",
    "KM_WB_Spine_Angle_ForwardTilt_TS",
    "POS_G_COM_Displacement_X_TS",
    "KM_WB_Pelvis_Velocity_ROT_TS",
  ],
  uHalfLoad: [
    "KM_P_Shoulder_Angle_ELEV_TS",
    "KM_P_Shoulder_Angle_ELEV_TS_MS",
    "KM_P_Shoulder_Angle_ELEV_TS_FFC",
    "KM_P_Shoulder_Angle_ROT_TS",
    "KM_P_Shoulder_Angle_ROT_TS_MS",
    "KM_P_Shoulder_Angle_ROT_TS_FFC",
    "KM_P_Shoulder_Angle_HAdd_TS",
    "KM_P_Shoulder_Angle_HAdd_TS_MS",
    "KM_P_Shoulder_Angle_HAdd_TS_FFC",
    "KM_L_Shoulder_Angle_HAdd_TS",
    "KM_L_Shoulder_Angle_HAdd_TS_MS",
    "KM_L_Shoulder_Angle_HAdd_TS_FFC",
    "KM_P_Elbow_Angle_Flexion_TS",
    "KM_P_Elbow_Angle_Flexion_TS_MS",
    "KM_P_Elbow_Angle_Flexion_TS_FFC",
    "KM_WB_Trunk_Angle_ROT_TS",
    "KM_WB_Trunk_Angle_ROT_TS_MS",
    "KM_WB_Trunk_Angle_ROT_TS_FFC",
  ],
  separation: [
    "KM_WB_Spine_Angle_ROT_TS_MIN",
    "KM_WB_Spine_Angle_ForwardTilt_TS_MIN",
    "KM_WB_Spine_Angle_GloveLean_TS_MAX",
    "KM_WB_Pelvis_Velocity_ROT_TS_MAX",
    "KM_WB_Trunk_Velocity_ROT_TS_MAX",
    "KM_WB_Pelvis_Velocity_ROT_TS_TMAX",
    "KM_WB_Trunk_Velocity_ROT_TS_TMAX",
    "PelvisTrunk_PeakVelocitySep_Time_Total",
    "km_wb_spine_angle_rot_ts",
    "km_wb_spine_angle_forwardtilt_ts",
    "km_wb_spine_angle_glovelean_ts",
    "km_wb_pelvis_velocity_rot_ts",
    "km_wb_trunk_velocity_rot_ts",
  ],
  land: [
    "KM_WB_Stride_Length_inches",
    "KM_WB_Stride_Width_inches",
    "L_Knee_Extension_Total",
    "Lead_Knee_Stability_Frontal",
    "KM_L_Shin_Angle_ELEV_TS",
    "KM_L_Shin_Angle_ELEV_TS_FFC",
    "KM_L_Hip_Angle_Flexion_TS",
    "KM_L_Ankle_Angle_Flexion_TS",
    "KM_L_Knee_Angle_Flexion_TS",
    "KM_R_Hip_Angle_Flexion_TS",
  ],
};
