export interface ExpandedMetricInfo {
  metricId: string;
  metricName?: string;
  info?: string;
  correlationInfo?: string;
}

export const EXPANDED_METRICS_INFO: ExpandedMetricInfo[] = [
  {
    metricName: "CMJ Concentric Impulse (Ns)",
    info: "The countermovement jump is conducted on the forceplates. Athletes are instructed to lower into a squat position, and then immediately jump as high as they can while keeping thier hands on their hips. The concentric impulse represents the amount of lower body power an athlete can generate.",
    metricId: "pwr_cmj_conc_impulse",
  },
  {
    metricName: "CMJ Jump Height",
    info: "The countermovement jump is conducted on the forceplates. Athletes are instructed to lower into a squat position, and then immediately jump as high as they can while keeping thier hands on their hips. Countermovement jump height is measured in inches.",
    metricId: "pwr_cmj_height",
  },
  {
    metricName: "CMJ RSI Modified",
    info: "",
    metricId: "pwr_cmj_rsi_modified",
  },
  {
    metricName: "IMTP - Peak Vertical Force [N]",
    info: "The isometric mid-thigh pull (IMTP) is conducted on the forceplates. Athletes pull up on a stationary bar as if conducted a deadlift, while pushing into the forceplates. The peak vertical force represents the maximum amount of total body strength an athlete can produce",
    metricId: "pwr_iso_mtp",
  },
  {
    metricName: "Isometric Squat Peak Force (N)",
    info: "The isometric squat test is performed on the forceplates. The peak force represents the peak lower body force output.",
    metricId: "",
  },
  {
    metricName: "Dominant Hamstring Isometric Peak Force (N)",
    info: "The isometric hamstring strength test is performed on the force plates. Athletes lie supine with a bar across their lap, knees flexed to 120 degrees, and heels on the plates. Using one leg at a time, athletes are instructed to push up into the bar while driving their heel into the plate. The peak force represents the maximum amount of single leg hip extension strength an athlete can produce.",
    metricId: "pwr_iso_hamstring_dom",
  },
  {
    metricName: "Non-Dominant Hamstring Isometric Peak Force (N)",
    info: "The isometric hamstring strength test is performed on the force plates. Athletes lie supine with a bar across their lap, knees flexed to 120 degrees, and heels on the plates. Using one leg at a time, athletes are instructed to push up into the bar while driving their heel into the plate. The peak force represents the maximum amount of single leg hip extension strength an athlete can produce.",
    metricId: "pwr_iso_hamstring_nondom",
  },
  {
    metricName: "Dominant Grip at Side (lbs)",
    info: "Athletes perform a maximal effort grip squeeze with their arm at their side (shoulder in 0 degrees of flexion, elbow in 90 degrees of flexion)",
    metricId: "msk_grip_0_d_dom",
  },
  {
    metricName: "Dominant Grip at 90 (lbs)",
    info: "Athletes perform a maximal effort grip squeeze with their arm above their head (shoulder in 90 degrees of flexion and 90 degrees of horizontal abduction, elbow in 90 degrees of flexion)",
    metricId: "msk_grip_90_d_dom",
  },
  {
    metricName: "Non-Dominant Grip at Side (lbs)",
    info: "Athletes perform a maximal effort grip squeeze with their arm at their side (shoulder in 0 degrees of flexion, elbow in 90 degrees of flexion)",
    metricId: "msk_grip_0_d_nondom",
  },
  {
    metricName: "Non-Dominant Grip at 90 (lbs)",
    info: "Athletes perform a maximal effort grip squeeze with their arm above their head (shoulder in 90 degrees of flexion and 90 degrees of horizontal abduction, elbow in 90 degrees of flexion)",
    metricId: "msk_grip_90_d_nondom",
  },
  {
    metricName: "Tindeq Combined Row (N)",
    info: "Athletes perform a max effort upper body row on a 45 degree angle bench. Athletes pull with their elbows in 90 degrees of flexion and torso is flat against the bench. The maximum force represents the athlete's upper body pull strength.",
    metricId: "Calculation: pwr_row_test_left + pwr_row_test_right",
  },
  {
    metricName: "Tindeq Dominant Core (N)",
    info: "Athletes will perform a max effort core rotation from a kneeling position with their arms straight out, and the Tindeq attached to a squat rack. The maximum force represents the athlete's rotational core strength.",
    metricId: "msk_core_rot_d_dom",
  },
  {
    metricName: "Tindeq Non-Dominant Core (N)",
    info: "Athletes will perform a max effort core rotation from a kneeling position with their arms straight out, and the Tindeq attached to a squat rack. The maximum force represents the athlete's rotational core strength.",
    metricId: "msk_core_rot_d_nondom",
  },
  {
    metricName: "Dominant Shoulder Internal Rotation Strength (N)",
    info: "Athletes will use the Kangatech to build up to a maximum effort shoulder internal rotation. The athlete will lay supine on the Kangatech pads with their knees bent, shoulder abducted to aproximately 90 degrees, and their elbow flexed to 90 degrees. Athletes will keep their elbow on the pad and avoid using their lats to press into the Kangatech pad.",
    metricId: "msk_shoulder_ir_d_dom",
  },
  {
    metricName: "Non-Dominant Shoulder Internal Rotation Strength (N)",
    info: "Athletes will use the Kangatech to build up to a maximum effort shoulder internal rotation. The athlete will lay supine on the Kangatech pads with their knees bent, shoulder abducted to aproximately 90 degrees, and their elbow flexed to 90 degrees. Athletes will keep their elbow on the pad and avoid using their lats to press into the Kangatech pad.",
    metricId: "msk_shoulder_ir_d_nondom",
  },
  {
    metricName: "Dominant Shoulder External Rotation Strength (N)",
    info: "Athletes will use the Kangatech to build up to a maximum effort shoulder external rotation. The athlete will lay supine on the Kangatech pads with their knees bent, shoulder abducted to aproximately 90 degrees, and their elbow flexed to 90 degrees. Athletes will keep their elbow on the pad and avoid using their lats to press into the Kangatech pad.",
    metricId: "msk_shoulder_er_d_dom",
  },
  {
    metricName: "Non-Dominant Shoulder External Rotation Strength (N)",
    info: "Athletes will use the Kangatech to build up to a maximum effort shoulder external rotation. The athlete will lay supine on the Kangatech pads with their knees bent, shoulder abducted to aproximately 90 degrees, and their elbow flexed to 90 degrees. Athletes will keep their elbow on the pad and avoid using their lats to press into the Kangatech pad.",
    metricId: "msk_shoulder_er_d_nondom",
  },
  {
    metricName: "Kangatech Dominant Hip Abduction Strength (N)",
    info: "Athletes will lay in a supine position with their knees bent and between the pads on the Kangatech to push outward with maximal intent to assess maximal force for hip abduction.",
    metricId: "msk_hip_abd_nd_dom",
  },
  {
    metricName: "Kangatech Non-Dominant Hip Abduction Strength (N)",
    info: "Athletes will lay in a supine position with their knees bent and between the pads on the Kangatech to push outward with maximal intent to assess maximal force for hip abduction.",
    metricId: "msk_hip_abd_nd_nondom",
  },
  {
    metricName: "Kangatech Dominant Hip Adduction Strength (N)",
    info: "Athletes will lay in a supine position with their knees bent to squeeze the pads on the Kangatech with maximal intent to assess maximal force for hip adduction.",
    metricId: "msk_hip_add_nd_dom",
  },
  {
    metricName: "Kangatech Non-Dominant Hip Adduction Strength (N)",
    info: "Athletes will lay in a supine position with their knees bent to squeeze the pads on the Kangatech with maximal intent to assess maximal force for hip adduction.",
    metricId: "msk_hip_add_nd_nondom",
  },
  {
    metricName: "Kangatech Dominant Hip Internal Rotation Strength (N)",
    info: "Athletes will lay on their side with their knees bent, yoga block between their knees, and placing their top leg's lower shank beneath the Kangatech pad to internally rotate their hip with maximum effort to assess max force for hip internal rotation.",
    metricId: "msk_hip_ir_nd_dom",
  },
  {
    metricName: "Kangatech Non-Dominant Hip Internal Rotation Strength (N)",
    info: "Athletes will lay on their side with their knees bent, yoga block between their knees, and placing their top leg's lower shank beneath the Kangatech pad to internally rotate their hip with maximum effort to assess max force for hip internal rotation.",
    metricId: "msk_hip_ir_nd_nondom",
  },
  {
    metricName: "Kangatech Dominant Hip External Rotation Strength (N)",
    info: "Athletes will lay on their side with their knees bent, yoga block between their knees, placing the top leg on top of the Kangatech and the bottom leg's lower shank beneath the Kangatech pad to externally rotate their hip with maximum effort to assess max force for hip external rotation.",
    metricId: "msk_hip_er_nd_dom",
  },
  {
    metricName: "Kangatech Non-Dominant Hip External Rotation Strength (N)",
    info: "Athletes will lay on their side with their knees bent, yoga block between their knees, placing the top leg on top of the Kangatech and the bottom leg's lower shank beneath the Kangatech pad to externally rotate their hip with maximum effort to assess max force for hip external rotation.",
    metricId: "msk_hip_er_nd_nondom",
  },
  {
    metricName: "Dominant Shoulder External Rotation ROM (degrees)",
    info: "Range of motion measure of shoulder external rotation.",
    metricId: "rom_shoulder_er_d_dom",
  },
  {
    metricName: "Non-Dominant Shoulder External Rotation (degrees)",
    info: "Range of motion measure of shoulder external rotation.",
    metricId: "rom_shoulder_er_d_nondom",
  },
  {
    metricName: "Dominant Shoulder Internal Rotation (degrees)",
    info: "Range of motion measure of shoulder internal rotation.",
    metricId: "rom_shoulder_ir_d_dom",
  },
  {
    metricName: "Non-Dominant Shoulder Internal Rotation (degrees)",
    info: "Range of motion measure of shoulder internal rotation.",
    metricId: "rom_shoulder_ir_d_nondom",
  },
  {
    metricName: "Dominant Shoulder Total Rotation (degrees)",
    info: "Total range of motion of the shoulder (sum of internal and external rotation).",
    metricId: "rom_shoulder_trot_d_dom",
  },
  {
    metricName: "Non-Dominant Shoulder Total Rotation (degrees)",
    info: "Total range of motion of the shoulder (sum of internal and external rotation).",
    metricId: "rom_shoulder_trot_d_nondom",
  },
  {
    metricName: "Dominant Elbow Extenstion (degrees)",
    info: "Range of motion measure of elbow extension.",
    metricId: "rom_elbow_ext_d_dom",
  },
  {
    metricName: "Non-Dominant Elbow Extension (degrees)",
    info: "Range of motion measure of elbow extension.",
    metricId: "rom_elbow_ext_d_nondom",
  },
  {
    metricName: "Dominant Elbow Flexion (degrees)",
    info: "Range of motion measure of elbow flexion.",
    metricId: "rom_elbow_flex_d_dom",
  },
  {
    metricName: "Non-Dominant Elbow Flexion (degrees)",
    info: "Range of motion measure of elbow flexion.",
    metricId: "rom_elbow_flex_d_nondom",
  },
  {
    metricName: "Dominant Shoulder Flexion- Pinned (degrees)",
    info: "Range of motion measure of shoulder flexion with pinned scapular movement.",
    metricId: "rom_shoulder_flex_p_dom",
  },
  {
    metricName: "Non-Dominant Shoulder Flexion- Pinned (degrees)",
    info: "Range of motion measure of shoulder flexion with pinned scapular movement.",
    metricId: "rom_shoulder_flex_p_nondom",
  },
  {
    metricName: "Dominant Shoulder Flexion- Not Pinned (degrees)",
    info: "Range of motion measure of shoulder flexion without pinning scapular movement.",
    metricId: "rom_shoulder_flex_u_dom",
  },
  {
    metricName: "Non-Dominant Shoulder Flexion- Not Pinned (degrees)",
    info: "Range of motion measure of shoulder flexion without pinning scapular movement.",
    metricId: "rom_shoulder_flex_u_nondom",
  },
  {
    metricName: "Dominant Shoulder Horizontal Adduction (degrees)",
    info: "Range of motion measure of shoulder horizontal adduction.",
    metricId: "rom_shoulder_add_d_dom",
  },
  {
    metricName: "Non-Dominant Shoulder Horizontal Adduction (degrees)",
    info: "Range of motion measure of shoulder horizontal adduction.",
    metricId: "rom_shoulder_add_d_nondom",
  },
  {
    metricName: "Dominant Straight Leg Raise",
    info: "Range of motion measure of hip flexion.",
    metricId: "rom_hip_flex_slr_dom",
  },
  {
    metricName: "Non-Dominant Straight Leg Raise",
    info: "Range of motion measure of hip flexion.",
    metricId: "rom_hip_flex_slr_nondom",
  },
  {
    metricName: "Dominant Straight Leg Raise: Core",
    info: "Range of motion measure of hip flexion with core activation.",
    metricId: "rom_hip_flex_slrc_dom",
  },
  {
    metricName: "Non-Dominant Straight Leg Raise: Core",
    info: "Range of motion measure of hip flexion with core activation.",
    metricId: "rom_hip_flex_slrc_nondom",
  },
  {
    metricName: "Dominant Hamstring 90/90",
    info: "Range of motion measure of the hamstrings in hip flexion.",
    metricId: "rom_hamstring_90_dom",
  },
  {
    metricName: "Non-Dominant Hamstring 90/90",
    info: "Range of motion measure of the hamstrings in hip flexion.",
    metricId: "rom_hamstring_90_nondom",
  },
  {
    metricName: "Dominant Hip External Rotation",
    info: "Range of motion measure of hip external rotation.",
    metricId: "rom_hip_er_d_dom",
  },
  {
    metricName: "Non-Dominant Hip External Rotation",
    info: "Range of motion measure of hip external rotation.",
    metricId: "rom_hip_er_d_nondom",
  },
  {
    metricName: "Dominant Hip Internal Rotation",
    info: "Range of motion measure of hip internal rotation.",
    metricId: "rom_hip_ir_d_dom",
  },
  {
    metricName: "Non-Dominant Hip Internal Rotation",
    info: "Range of motion measure of hip internal rotation.",
    metricId: "rom_hip_ir_d_nondom",
  },
  {
    metricName: "Dominant Hip Total Rotation",
    info: "Total range of motion of the hip (sum of internal and external rotation).",
    metricId: "rom_hip_trot_d_dom",
  },
  {
    metricName: "Non-Dominant Hip Total Rotation",
    info: "Total range of motion of the hip (sum of internal and external rotation).",
    metricId: "rom_hip_trot_d_nondom",
  },
  {
    metricName: "Dominant Thoracic Rotation Measure",
    info: "Range of motion measure of thoracic rotation.",
    metricId: "rom_trunk_thor_d_dom",
  },
  {
    metricName: "Non-Dominant Thoracic Rotation Measure",
    info: "Range of motion measure of thoracic rotation.",
    metricId: "rom_trunk_thor_d_nondom",
  },
  {
    metricName: "Dominant Lumbar Rotation Clearance",
    info: "Lumbar rotation range of motion pass or fail (mobility/stability)",
    metricId: "rom_trunk_lumb_dom",
  },
  {
    metricName: "Non-Dominant Lumbar Rotation Clearance",
    info: "Lumbar rotation range of motion pass or fail (mobility/stability)",
    metricId: "rom_trunk_lumb_nondom",
  },
  {
    metricName: "Dominant Cervical Rotation Clearance",
    info: "Cervical rotation range of motion pass or fail (mobility/stability)",
    metricId: "rom_trunk_cerv_dom",
  },
  {
    metricName: "Non-Dominant Cervical Rotation Clearance",
    info: "Cervical rotation range of motion pass or fail (mobility/stability)",
    metricId: "rom_trunk_cerv_nondom",
  },
  {
    metricName: "Dominant Thoracic Rotation Clearance",
    info: "Thoracic rotation range of motion pass or fail (mobility/stability)",
    metricId: "rom_trunk_thor_pass_dom",
  },
  {
    metricName: "Non-Dominant Thoracic Rotation Clearance",
    info: "Thoracic rotation range of motion pass or fail (mobility/stability)",
    metricId: "rom_trunk_thor_pass_nondom",
  },
  {
    metricName: "Dominant Knee to Wall",
    info: "Range of motion measure of ankle dorsiflexion.",
    metricId: "rom_ankle_flex_dom",
  },
  {
    metricName: "Non-Dominant Knee to Wall",
    info: "Range of motion measure of ankle dorsiflexion.",
    metricId: "rom_ankle_flex_nondom",
  },
  {
    metricName: "Reaction Time (sec)",
    info: "Measures how quick an athlete can see and accurately react to a target. Using blazepods, the athlete must hit a series of lighted pods as quickly as possible, meaning lower scores indicate better reaction time.",
    metricId: "visl_reaction_time",
  },
  {
    metricName: "Concentration Time",
    info: "Measures ability to track multiple objects simultaneously with progressive difficulty. Higher scores indicate better concentration ability",
    metricId: "visl_concentration_time",
  },
  {
    metricName: "Advanced Vision Score",
    info: "An adaptation of the visual acuity assessment that evaluates visual capacity using target size, contrast, and presentation time creating a more sport relevant evaluation. Higher scores indicate better overall vision.",
    metricId: "visl_core_score_b",
  },
  {
    metricName: "Pursuits",
    info: "Ability to follow a predictably moving target at a constant speed (i.e., tracking fly ball). Measured as percent of time within the task the eyes were on the desired target.",
    metricId: "visn_perf_timing",
  },
  {
    metricName: "Saccades",
    info: "Ability to quickly move eyes between two fixed, predictable targets (i.e., picking up ball from pitcher's hand). Measured as percent of time within the task the eyes were on the desired target.",
    metricId: "visn_perf_working_memory",
  },
  {
    metricName: "Attention",
    info: "Ability to quickly move eyes between two unpredictable targets (i.e., locating batted ball after contact). Measured as percent of time within the task the eyes were on the desired target.",
    metricId: "visn_perf_attention",
  },
  {
    metricName: "Inhibition",
    info: "Ability to quickly move eyes away from an unpredictable target (i.e., maintaining focus on ball & filtering outside distractions). Measured as percent of time within the task the eyes were on the desired target.",
    metricId: "visn_perf_inhibition",
  },
  {
    metricName: "Visual Stability",
    info: "Ability to maintain focus on desired target with eyes while the head is moving. Measured as percent of time within the task the eyes were on the desired target.",
    metricId: "visn_perf_visual_stability",
  },
  {
    metricName: "Body Weight (LBS)",
    info: "Body weight in pounds.",
    metricId: "",
  },
  {
    metricName: "Lean Mass Index",
    info: "",
    metricId: "",
  },
  {
    metricName: "Skinfolds",
    info: "",
    metricId: "",
  },
  {
    metricName: "Height (inches)",
    info: "Player height in inches.",
    metricId: "bcmp_height",
  },
  {
    metricName: "Body Fat Percentage",
    info: "Player body fat percentage.",
    metricId: "",
  },
  {
    metricName: "Wingspan (inches)",
    info: "Player wingspan in inches.",
    metricId: "",
  },
  {
    metricName: "Player Age",
    info: "Player age in years.",
    metricId: "",
  },
  {
    metricName: "Hand Size",
    info: "Player hand size.",
    metricId: "",
  },
  {
    metricName: "GPS Acceleration Count",
    info: "The number of accelerations, defined as the count of efforts over 2.5 m/s² in a single day.",
    metricId: "gps_acceleration_count",
  },
  {
    metricName: "GPS Deceleration Count",
    info: "The number of decelerations, defined as the count of efforts under -2.5 m/s² in a single day.",
    metricId: "gps_deceleration_count",
  },
  {
    metricName: "GPS Distance (70%ile)",
    info: "The distance covered (in meters) at 70% of a player’s max speed or higher in a single day.",
    metricId: "gps_distance_70",
  },
  {
    metricName: "GPS Distance (80%ile)",
    info: "The distance covered (in meters) at 80% of a player’s max speed or higher in a single day.",
    metricId: "gps_distance_80",
  },
  {
    metricName: "GPS Distance (90%ile)",
    info: "The distance covered (in meters) at 90% of a player’s max speed or higher in a single day.",
    metricId: "gps_distance_90",
  },
  {
    metricName: "GPS Distance (95%ile)",
    info: "The distance covered (in meters) at 95% of a player’s max speed or higher in a single day.",
    metricId: "gps_distance_95",
  },
  {
    metricName: "GPS Efforts (70%ile)",
    info: "The number of running efforts at 70% of a player’s max speed or higher in a single day.",
    metricId: "gps_efforts_70",
  },
  {
    metricName: "GPS Efforts (80%ile)",
    info: "The number of running efforts at 80% of a player’s max speed or higher in a single day.",
    metricId: "gps_efforts_80",
  },
  {
    metricName: "GPS Efforts (90%ile)",
    info: "The number of running efforts at 90% of a player’s max speed or higher in a single day.",
    metricId: "gps_efforts_90",
  },
  {
    metricName: "GPS Efforts (95%ile)",
    info: "The number of running efforts at 95% of a player’s max speed or higher in a single day.",
    metricId: "gps_efforts_95",
  },
  {
    metricName: "GPS Maximum Acceleration",
    info: "The maximum acceleration (in meters per second squared) reached by a player in a single day.",
    metricId: "gps_max_acceleration",
  },
  {
    metricName: "GPS Maximum Deceleration",
    info: "The maximum deceleration (in meters per second squared) reached by a player in a single day.",
    metricId: "gps_max_deceleration",
  },
  {
    metricName: "GPS Maximum Velocity",
    info: "The maximum velocity (in miles per hour) reached by a player in a single day.",
    metricId: "gps_max_velocity",
  },
  {
    metricName: "GPS Total Distance",
    info: "The total distance (in meters) covered by a player in a single day.",
    metricId: "gps_total_distance_m",
  },
];
