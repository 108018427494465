import React from "react";
import { useFetchStuffDataQuery } from "../../services/performanceApi.service";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useSelector } from "react-redux";
import {
  selectComparingMovement,
  selectMovement,
  selectSelectedMotionType,
} from "../../redux/selectors";
import { Grid } from "@mui/material";
import BulletChart from "../charts/BulletChart";
import StrikeChart from "../charts/PitchChart";
import { flatten, isEmpty, isNil } from "lodash";
import useFetchSelfData from "../useFetchSelfData";

const BallDynamics = () => {
  const { trial: mainTrial = {}, player } = useSelector(selectMovement);
  const { trial: comparingTrial = {} } = useSelector(selectComparingMovement);
  const motionType = useSelector(selectSelectedMotionType);
  const { data: mainData = [] } = useFetchStuffDataQuery(
    mainTrial?.id ? { trial: mainTrial, motionType } : skipToken
  );
  const { data: comparingData = [] } = useFetchStuffDataQuery(
    comparingTrial?.id ? { trial: comparingTrial, motionType } : skipToken
  );
  const {
    discreteData = [],
    pitchData = [],
    isSelfCompare,
  } = useFetchSelfData(player);
  const selfData = flatten([...discreteData, pitchData]);
  const secondaryData = isSelfCompare
    ? selfData
    : comparingTrial?.id
    ? comparingData
    : [];

  return (
    <Grid container>
      <Grid flexDirection="column">
        <Grid container flexDirection="row">
          <Grid item xs={6}>
            <StrikeChart
              pitch={mainTrial}
              accentColor={!isEmpty(secondaryData) ? "main" : undefined}
            />
          </Grid>

          {!isNil(comparingTrial?.id) && (
            <Grid item xs={6} pl={2}>
              <StrikeChart
                pitch={comparingTrial}
                accentColor={isSelfCompare ? "self" : "secondary"}
              />
            </Grid>
          )}
        </Grid>

        <Grid container mt={2}>
          {mainData
            ?.filter((it) => it.value)
            .map(
              ({
                key,
                id,
                value,
                averageRange,
                mean,
                label,
                description,
                unit,
                decimals,
                systematicName,
              }) => {
                const secondaryValue = secondaryData.find(
                  (it: any) =>
                    it?.[isSelfCompare ? "id" : "systematicName"] ===
                    systematicName
                );

                return (
                  <Grid item key={id} xs={6}>
                    <BulletChart
                      key={key}
                      value={value}
                      averageRange={averageRange}
                      mean={mean}
                      title={!description ? label : `${label} (${description})`}
                      unit={unit}
                      isSelf={isSelfCompare}
                      decimals={decimals}
                      secondaryValue={secondaryValue?.value}
                    />
                  </Grid>
                );
              }
            )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BallDynamics;
