import React from "react";
import "./PitchChart.css";
import { Grid, Typography, Divider } from "@mui/material";
import { trialDescriptionText } from "../../utils/metrics";
import { Pitch } from "../../services/mockData";
import { PitchZone } from "./PitchZone";
import { useSelector } from "react-redux";
import { selectSelectedMotionType } from "../../redux/selectors";

interface PitchChartProps {
  pitch: Pitch;
  accentColor?: "main" | "secondary" | "self";
}

export const clamp = (min: number, max: number, value: number) => {
  return Math.min(Math.max(value, min), max);
};

const PitchChart = ({ pitch, accentColor }: PitchChartProps) => {
  const motionType = useSelector(selectSelectedMotionType);
  const label = trialDescriptionText(pitch, motionType);

  return (
    <Grid container pt={2}>
      <PitchZone pitch={pitch} />

      <Divider orientation="vertical" />

      <Grid className="ptd-pitch-chart-right" pt={1}>
        <Typography
          className={`ptd-primary-text ptd-${accentColor}-text-color`}
        >
          {label}
        </Typography>
        <Typography className="ptd-secondary-text">{pitch?.type}</Typography>
      </Grid>
    </Grid>
  );
};

export default PitchChart;
