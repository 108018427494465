import React, { useCallback, useMemo } from "react";
import { Slider, SliderMark, styled } from "@mui/material";
import { KeyframeData } from "../../services/mockData";
import { SliderMarkProps } from "./SliderMarkProps";
import { get, isNil } from "lodash";
import { VideoFrameTooltip } from "./VideoFrameTooltip";
import { useSelector } from "react-redux";
import { selectSelectedMotionType } from "../../redux/selectors";
import { MotionType } from "../common/MotionType";

interface VideoSeekerProps {
  currentFrame: number;
  handleScrubber: (newCurrentFrame: number) => void;
  keyframesData: KeyframeData[];
  segmentFrames:
    | {
        startFrame?: number | undefined;
        endFrame?: number | undefined;
      }
    | undefined;
}

const TRACK_COLOR = "var(--hoveredDigitalBlue)";
const HANDLE_COLOR = "#c3c5c6";

const keyframesDescription = {
  MKH: "Max Knee Height",
  HS: "Hand Separation",
  MS: "Mid Stride",
  MHS: "Max Hand Separation",
  IFC: "Initial Foot Contact",
  FFC: "Firm Foot Contact",
  MER: "Max External Rotation",
  BR: "Ball Release",
  MIR: "Max Internal Rotation",
  FT: "Follow Through",
  SET: "Stance",
  MHH: "Max Heel Height",
  LOAD: "Max Pelvis Drift",
  LFP: "Lead Foot Plant",
  MSEP: "Max Separation",
  DS: "Down Swing",
  FB: "Flat Bat",
  MBS: "Max Bat Speed",
  PFP: "Bat Parallel to Front of Plate",
  BC: "Ball Contact",
};

const StyledSlider = styled(Slider)({
  color: TRACK_COLOR,
  height: 5,
  borderRadius: "100px 0 0 100px",
  marginBottom: 0,
  "& .MuiSlider-thumb": {
    borderRadius: 10,
    height: 15,
    width: 3,
    color: HANDLE_COLOR,
  },
  "& .MuiSlider-rail": {
    backgroundColor: "var(--geekBlue)",
    opacity: 1,
    height: 5,
    borderRadius: "100px",
  },
  "& .MuiSlider-mark": {
    height: 25,
    width: 6,
    backgroundColor: "var(--activeDigitalBlue)",
    border: 0,
    borderRadius: 16,
  },
  "& .MuiSlider-mark:nth-child(n+11)": {
    height: 20,
    width: 4,
    backgroundColor: "var(--digital-blue-3)",
    border: 0,
    borderRadius: 16,
  },
  "& .MuiSlider-markLabel": {
    fontSize: "0.75rem",
  },
});

export default function VideoSeeker({
  currentFrame,
  handleScrubber,
  keyframesData,
  segmentFrames,
}: VideoSeekerProps) {
  const motionType = useSelector(selectSelectedMotionType);
  const PRIMARY_KEYFRAMES = useMemo(
    () =>
      motionType === MotionType.Batting
        ? ["SET", "LFP", "DS", "BC"]
        : ["MKH", "MS", "FFC", "BR"],
    [motionType]
  );
  const isPrimaryKeyframe = useCallback(
    (value: string) => PRIMARY_KEYFRAMES.includes(value.toUpperCase()),
    [PRIMARY_KEYFRAMES]
  );
  const keyframes = useMemo(() => {
    return keyframesData.map((it) => ({
      label: isPrimaryKeyframe(it.label) ? it.label : "",
      value: it.frame,
    }));
  }, [keyframesData]);

  const MarkWithTooltip = useCallback(
    (props: SliderMarkProps) => {
      const titleKey = get(keyframesData, props["data-index"] ?? -1)?.label;
      const title = get(keyframesDescription, titleKey?.toUpperCase());
      const Mark = <SliderMark {...props} />;

      return isNil(title) ? (
        Mark
      ) : (
        <VideoFrameTooltip
          title={title}
          isSecondary={!isPrimaryKeyframe(titleKey)}
          arrow
        >
          {Mark}
        </VideoFrameTooltip>
      );
    },
    [keyframesData]
  );

  const handleChange = useCallback(
    // eslint-disable-next-line no-unused-vars
    (_e: Event, value: any) => {
      handleScrubber(Number(value));
    },
    [handleScrubber]
  );

  const slots = useMemo(() => ({ mark: MarkWithTooltip }), [MarkWithTooltip]);
  return (
    <StyledSlider
      min={segmentFrames?.startFrame}
      max={segmentFrames?.endFrame}
      value={currentFrame}
      marks={keyframes}
      onChange={handleChange}
      slots={slots}
    />
  );
}
