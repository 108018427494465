import React from "react";
import { Grid, IconButton } from "@mui/material";
import { MinusCircleFilled, PlusCircleFilled } from "@ant-design/icons";

interface MetricsAddOrRemoveProps {
  metrics: any[];
  onAdd: (params?: any) => void;
  onRemove: (params?: any) => void;
  canAddMetric: boolean;
}

const MetricsAddOrRemove = ({
  metrics,
  canAddMetric,
  onAdd,
  onRemove,
}: MetricsAddOrRemoveProps) => {
  return (
    <Grid
      xs={"auto"}
      container
      item
      flexDirection="row"
      justifyContent="flex-end"
      alignItems="center"
    >
      {metrics.length > 2 && (
        <IconButton
          onClick={onRemove}
          sx={{ color: "var(--hoveredDigitalBlue)" }}
        >
          <MinusCircleFilled
            style={{
              backgroundColor: "white",
              borderRadius: "100%",
            }}
          />
        </IconButton>
      )}

      {canAddMetric && (
        <IconButton
          onClick={onAdd}
          disabled={!canAddMetric}
          sx={{ color: canAddMetric ? "var(--hoveredDigitalBlue)" : "white" }}
        >
          <PlusCircleFilled
            style={{ backgroundColor: "white", borderRadius: "100%" }}
          />
        </IconButton>
      )}
    </Grid>
  );
};

export default MetricsAddOrRemove;
