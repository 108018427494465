import * as THREE from "three";
import { shouldLoadHalloweenEasterEgg } from "./shouldLoadHalloweenEasterEgg";
import { ModelLoader } from "./ModelLoader";

export class HalloweenEasterEgg3D {
  constructor(scene) {
    this.scene = scene;
    this.initialPositionXCloud = -8;
    this.batMoveSpeed = 0.35;
    this.batRotationSpeed = 0.04;
    this.batDirection = new THREE.Vector3(
      Math.random(),
      Math.random(),
      Math.random()
    ).normalize();
  }

  async initialize() {
    if (shouldLoadHalloweenEasterEgg && !this.loadedHalloweenEasterEgg) {
      const modelLoader = new ModelLoader(this.scene);
      await this.loadCloud(modelLoader);
      await this.loadBat(modelLoader);
      await this.loadTombstone(modelLoader);
      this.loadFog();

      this.loadMoon();

      this.loadedHalloweenEasterEgg = true;
    }
  }

  update() {
    if (shouldLoadHalloweenEasterEgg) {
      this.animateCloud();
      this.animateBat();
      this.animateFog();
    }
  }

  loadMoon() {
    const moonMaterial = new THREE.MeshPhongMaterial({
      color: 0xffffe0,
      emissive: 0x665544,
      shininess: 10,
      specular: 0x555555, // A light specular highlight
    });

    const moon = new THREE.Mesh(
      new THREE.SphereGeometry(0.5, 32, 32),
      moonMaterial
    );
    moon.position.set(2, 5, 8); // adjust position as needed
    this.scene.add(moon);
  }

  loadFog() {
    this.scene.fog = new THREE.FogExp2(0x02031a, 0.05);
  }

  async loadCloud(modelLoader) {
    const modelInfo = {
      modelPath: "3d/models/halloween/cloud.glb",
      scaleFactor: 4,
      initialPosition: { x: this.initialPositionXCloud, y: 4, z: 5 },
      colorHex: 0xe0e0e0,
      objectName: "cloud",
    };

    this.cloud = await modelLoader.loadModel(modelInfo);
  }

  async loadBat(modelLoader) {
    const modelInfo = {
      modelPath: "3d/models/halloween/batfly.glb",
      scaleFactor: 0.1,
      initialPosition: { x: -2, y: 4, z: 5 },
      colorHex: 0x7c7c7c,
      objectName: "bat",
    };

    this.bat = await modelLoader.loadModel(modelInfo);
  }
  async loadTombstone(modelLoader) {
    const modelInfo = {
      modelPath: "3d/models/halloween/tombstone.glb",
      scaleFactor: 0.1,
      initialPosition: { x: 0, y: 0, z: 5 },
      colorHex: 0x7c7c7c,
      objectName: "Tombstone_RIP_obj",
    };

    this.tombstone = await modelLoader.loadModel(modelInfo);
  }

  update() {
    if (shouldLoadHalloweenEasterEgg) {
      this.animateCloud();
      this.animateBat();
      this.animateFog();
    }
  }

  animateFog() {
    let fogDirection = Math.random() < 0.5 ? 1 : -1;
    let fogSpeed = 0.0005;

    if (Math.random() < 0.03) {
      fogDirection = Math.random() < 0.5 ? 1 : -1;
    }
    this.scene.fog.density += fogSpeed * fogDirection;
    this.scene.fog.density = THREE.MathUtils.clamp(
      this.scene.fog.density,
      0.05,
      0.15
    );
  }

  animateBat() {
    if (this.bat) {
      this.bat.position.add(
        this.batDirection.clone().multiplyScalar(this.batMoveSpeed)
      );
      if (Math.random() < 0.05) {
        this.batDirection
          .add(
            new THREE.Vector3(
              Math.random() - 0.5,
              Math.random() - 0.5,
              Math.random() - 0.5
            )
          )
          .normalize();
      }
      const targetRotation = Math.atan2(
        this.batDirection.x,
        this.batDirection.z
      );
      this.bat.rotation.y +=
        (targetRotation - this.bat.rotation.y) * this.batRotationSpeed;

      const targetPitch = Math.asin(this.batDirection.y);
      this.bat.rotation.x +=
        (targetPitch - this.bat.rotation.x) * this.batRotationSpeed;

      if (this.bat.position.length() > 80) {
        this.bat.position.set(0, 5, 0);
        this.batDirection = new THREE.Vector3(
          Math.random(),
          Math.random(),
          Math.random()
        ).normalize();
      }
      this.bat.rotation.x = Math.PI / 2;
    }
  }

  animateCloud() {
    if (this.cloud) {
      this.cloud.position.x += 0.001;
      if (this.cloud.position.x >= 15) {
        this.cloud.position.x = this.initialPositionXCloud;
      }
    }
  }
}
