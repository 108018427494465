import { useDispatch, useSelector } from "react-redux";
import type { TypedUseSelectorHook } from "react-redux";
import type {
  PerformanceRootState,
  PerformanceAppDispatch,
} from "./performanceReduxModule";

export const usePerformanceAppDispatch: () => PerformanceAppDispatch =
  useDispatch;
export const usePerformanceAppSelector: TypedUseSelectorHook<PerformanceRootState> =
  useSelector;
