import React from "react";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import "../TeamDashboard.css";
import {
  usePerformanceAppDispatch,
  usePerformanceAppSelector,
} from "../../../redux/hooks";
import { setTeamPlayerPosition } from "../../../redux/movementSlice";

export enum TeamPlayerPosition {
  Pitcher = "Pitchers",
  PositionPlayer = "Position Players",
  All = "All",
}

function PositionSelector() {
  const dispatch = usePerformanceAppDispatch();
  const position = usePerformanceAppSelector(
    (state) => state.movement.teamPlayerPosition
  );
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newPosition: TeamPlayerPosition
  ) => {
    if (newPosition) {
      dispatch(setTeamPlayerPosition(newPosition));
    }
  };

  return (
    <ToggleButtonGroup
      size="small"
      value={position}
      defaultValue={TeamPlayerPosition.Pitcher}
      onChange={handleChange}
      exclusive
      className="ptd-team-positions-selector-container"
    >
      {Object.values(TeamPlayerPosition).map((it) => (
        <ToggleButton
          key={it}
          value={it}
          className="ptd-team-positions-selector"
        >
          {it}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}

export default PositionSelector;
