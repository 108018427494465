export const ScoutingGroup = {
  AMATEUR: "ama",
  PROFESSIONAL: "pro",
  INTERNATIONAL: "int",
};

export const AcquisitionGroup = {
  DOMESTIC_PROFESSIONAL: "domestic_professional",
  DOMESTIC_AMATEUR: "domestic_amateur",
  INTERNATIONAL_PROFESSIONAL: "international_professional",
  INTERNATIONAL_AMATEUR: "international_amateur",
};

export const determineScoutingGroup = (playerAcquisitionGroup) => {
  if (playerAcquisitionGroup) {
    if (playerAcquisitionGroup === AcquisitionGroup.DOMESTIC_PROFESSIONAL) {
      return ScoutingGroup.PROFESSIONAL;
    } else if (playerAcquisitionGroup === AcquisitionGroup.DOMESTIC_AMATEUR) {
      return ScoutingGroup.AMATEUR;
    } else if (
      playerAcquisitionGroup === AcquisitionGroup.INTERNATIONAL_AMATEUR
    ) {
      return ScoutingGroup.INTERNATIONAL;
    } else if (
      playerAcquisitionGroup === AcquisitionGroup.INTERNATIONAL_PROFESSIONAL
    ) {
      return ScoutingGroup.PROFESSIONAL;
    } else
      throw new Error(
        `Player acquisition group not recognized: ${playerAcquisitionGroup}`
      );
  }
  return null;
};
