import { ModelLoader } from "./ModelLoader";

export class Stadium3D {
  constructor(scene) {
    this.scene = scene;
    this.group = new THREE.Group();
    this.modelParts = {};
    this.model = null;
    this.loader = new ModelLoader();
    this.lights = [];
  }

  async initialize(battingMode) {
    if (!this.initialized) {
      const gltf = await this.loader.loadRawModel("3d/models/stadium.glb");
      const model = gltf.scene;
      model.scale.set(1, 1, 1);

      const propsMapping = {
        stadium: "Stadium_NYM",
      };

      Object.keys(propsMapping).forEach((key) => {
        const modelName = propsMapping[key];
        const modelPart = model.getObjectByName(modelName);

        this.modelParts[key] = modelPart;
        if (battingMode) {
          modelPart.position.set(0.0, 0.0, 0.0);
        } else {
          modelPart.position.set(-18.5, -0.25, -0.13);
        }
        modelPart.rotation.z = Math.PI / 2;
      });
      model.name = "Citi Field";
      this.model = model;

      this.addLights();
      this.group.add(this.model);
      this.lights.forEach((light) => this.group.add(light));
      this.initialized = true;
    }
    this.scene.add(this.group);
  }

  addLights() {
    const spotlight = new THREE.SpotLight(0xffffff, 0.55);
    spotlight.position.set(0, 10, 0); // Adjust height and position to focus on the pitcher's mound
    spotlight.angle = Math.PI / 4; // Adjust the light cone angle
    spotlight.penumbra = 0.5; // Soften the edge of the light cone

    this.lights.push(spotlight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.15);
    directionalLight.position.set(5, 2, 5); // Adjust the position based on your scene setup

    this.lights.push(directionalLight);

    const directionalLight2 = new THREE.DirectionalLight(0xffffff, 0.15);
    directionalLight2.position.set(-5, 2, 5); // Adjust the position based on your scene setup

    this.lights.push(directionalLight2);

    const directionalLight3 = new THREE.DirectionalLight(0xffffff, 0.15);
    directionalLight3.position.set(-5, 2, -5); // Adjust the position based on your scene setup

    this.lights.push(directionalLight3);

    const directionalLight4 = new THREE.DirectionalLight(0xffffff, 0.15);
    directionalLight4.position.set(5, 2, -5); // Adjust the position based on your scene setup

    this.lights.push(directionalLight4);

    const spotlightStadium = new THREE.SpotLight(0xffffff, 0.55);
    spotlightStadium.position.set(0, 120, -130);
    spotlightStadium.angle = Math.PI / 4;
    spotlightStadium.penumbra = 0.5;

    this.lights.push(spotlightStadium);
  }

  remove() {
    this.scene.remove(this.group);
  }
}
