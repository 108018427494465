type ObuValues = {
  [key: string]: {
    positive: string;
    negative: string[];
  };
};

export const obuValues: ObuValues = {
  obu_ankle_rocking_eversion_nohold_left: {
    positive: "Good",
    negative: ["Limited"],
  },
  obu_ankle_rocking_eversion_nohold_right: {
    positive: "Good",
    negative: ["Limited"],
  },
  obu_ankle_rocking_inversion_nohold_left: {
    positive: "Good",
    negative: ["Limited"],
  },
  obu_ankle_rocking_inversion_nohold_right: {
    positive: "Good",
    negative: ["Limited"],
  },
  obu_ankle_rocking_eversion_hold_left: {
    positive: "Improves",
    negative: ["No Change"],
  },
  obu_ankle_rocking_eversion_hold_right: {
    positive: "Improves",
    negative: ["No Change"],
  },
  obu_ankle_rocking_inversion_hold_left: {
    positive: "Improves",
    negative: ["No Change"],
  },
  obu_ankle_rocking_inversion_hold_right: {
    positive: "Improves",
    negative: ["No Change"],
  },
  obu_sl_hinge_stability_left: { positive: "Stable", negative: ["Unstable"] },
  obu_sl_hinge_stability_right: { positive: "Stable", negative: ["Unstable"] },
  obu_sl_hinge_foot_position_left: {
    positive: "Maintain Arch",
    negative: ["Flat or Loose Arch"],
  },
  obu_sl_hinge_foot_position_right: {
    positive: "Maintain Arch",
    negative: ["Flat or Loose Arch"],
  },
  obu_deep_squat_depth: { positive: "Good", negative: ["Limited"] },
  obu_deep_squat_stability: { positive: "Stable", negative: ["Unstable"] },
  obu_hip_ir_nohold_left: { positive: "Touches", negative: ["Short"] },
  obu_hip_ir_nohold_right: { positive: "Touches", negative: ["Short"] },
  obu_hip_ir_hold_left: { positive: "Improves", negative: ["No Change"] },
  obu_hip_ir_hold_right: { positive: "Improves", negative: ["No Change"] },
  obu_hip_ir_rotex_left: { positive: ">= 45", negative: ["< 45"] },
  obu_hip_ir_rotex_right: { positive: ">= 45", negative: ["< 45"] },
  obu_hip_er_left: { positive: ">= 45", negative: ["< 45"] },
  obu_hip_er_right: { positive: ">= 45", negative: ["< 45"] },
  obu_pelvic_tilt_rom: {
    positive: "Both Normal",
    negative: ["Difficulty Arching", "Difficulty Flattening"],
  },
  obu_pelvic_tilt_posture: {
    positive: "Neutral",
    negative: ["S-Posture", "C-Posture"],
  },
  obu_pelvic_tilt_stability: {
    positive: "Smooth Movement",
    negative: ["Shake and Bake"],
  },
  obu_pelvic_rotation_nohold_left: { positive: ">= 45", negative: ["< 45"] },
  obu_pelvic_rotation_nohold_right: { positive: ">= 45", negative: ["< 45"] },
  obu_pelvic_rotation_hold_left: {
    positive: "Improves",
    negative: ["No Change"],
  },
  obu_pelvic_rotation_hold_right: {
    positive: "Improves",
    negative: ["No Change"],
  },
  obu_pelvic_rotation_coordination_left: {
    positive: "Good Rotary Movement",
    negative: ["More Lateral Movement"],
  },
  obu_pelvic_rotation_coordination_right: {
    positive: "Good Rotary Movement",
    negative: ["More Lateral Movement"],
  },
  obu_trunk_rotation_nohold_left: { positive: ">= 45", negative: ["< 45"] },
  obu_trunk_rotation_nohold_right: { positive: ">= 45", negative: ["< 45"] },
  obu_trunk_rotation_hold_left: {
    positive: "Improves",
    negative: ["No Change"],
  },
  obu_trunk_rotation_hold_right: {
    positive: "Improves",
    negative: ["No Change"],
  },
  obu_lat_mobility: {
    positive: "Arms Above Eyes",
    negative: ["Arms Below Eyes"],
  },
  obu_shoulder_er_left: { positive: ">= 45", negative: ["< 45"] },
  obu_shoulder_er_right: { positive: ">= 45", negative: ["< 45"] },
  obu_shoulder_handslide_left: {
    positive: "Hands Past Elbow",
    negative: ["Hands Inside Elbow"],
  },
  obu_shoulder_handslide_right: {
    positive: "Hands Past Elbow",
    negative: ["Hands Inside Elbow"],
  },
  obu_shoulder_elbowup_left: {
    positive: "Elbow Equal or Above",
    negative: ["Elbow Below"],
  },
  obu_shoulder_elbowup_right: {
    positive: "Elbow Equal or Above",
    negative: ["Elbow Below"],
  },
  obu_shoulder_add_posture: { positive: "Aligned", negative: ["Rounded"] },
  obu_wrist_rotation_pronation_left: { positive: ">= 80", negative: ["< 80"] },
  obu_wrist_rotation_pronation_right: { positive: ">= 80", negative: ["< 80"] },
  obu_wrist_rotation_supination_left: { positive: ">= 80", negative: ["< 80"] },
  obu_wrist_rotation_supination_right: {
    positive: ">= 80",
    negative: ["< 80"],
  },
};
