import React, { MutableRefObject, RefObject, memo, useMemo } from "react";
import CollapsiblePanel from "../common/CollapsiblePanel";
import VideoReproduction from "./VideoReproduction";
import { MovementVideo } from "../../MovementVideo";
import { Tabs } from "antd";
import { findIndex, isEmpty } from "lodash";
import Scrollable from "../common/Scrollable";
import { Box } from "@mui/system";
import useVideoAngles from "../../hooks/useVideoAngles";

interface VideoPanelModalProps {
  videoRefs: MutableRefObject<RefObject<HTMLVideoElement>[]>;
  videos: MovementVideo[];
  comparingVideos: MovementVideo[];
  syncWorldAndVideo: () => void;
}

function VideoPanelModal({
  videoRefs,
  comparingVideos,
  syncWorldAndVideo,
}: VideoPanelModalProps) {
  const disableAllTab = useMemo(
    () => !isEmpty(comparingVideos),
    [comparingVideos]
  );
  const { selectedTab, setSelectedTab, tabs, angleTabs, tabsWithRef } =
    useVideoAngles({
      disableAllTab,
      videoRefs,
    });

  return (
    <CollapsiblePanel
      title="Videos"
      subtitle="Video"
      top={90}
      innerPosition={{ top: "0px" }}
      orientation="left"
      leftResize
      minWidth={300}
      initialWidth={550}
      minHeight={75}
    >
      {(height) => {
        if (isEmpty(angleTabs))
          return (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="100%"
            >
              <Box>No available videos...</Box>
            </Box>
          );

        return (
          <Tabs
            activeKey={selectedTab.toString()}
            onChange={(tab) => setSelectedTab(tab)}
            items={tabs.map((it, index) => {
              const { label, disabled } = it;
              return {
                label,
                key: `${index}`,
                disabled,
                children: (
                  <Scrollable height={height - 120}>
                    <VideoReproduction
                      tabsWithRef={tabsWithRef}
                      currentTab={label}
                      syncWorldAndVideo={syncWorldAndVideo}
                      setSelectedTab={(tabLabel) => {
                        setSelectedTab(videoIndexFromTab(tabs, tabLabel));
                      }}
                    />
                  </Scrollable>
                ),
              };
            })}
          />
        );
      }}
    </CollapsiblePanel>
  );
}

export default memo(VideoPanelModal);

export function videoIndexFromTab(tabs: any[], tabLabel: string) {
  return findIndex(tabs, (it) => it.label === tabLabel).toString();
}
