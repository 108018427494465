import React, { useEffect } from "react";
import { Tabs } from "antd";
import { useDispatch } from "react-redux";
import { setCurrentMetric } from "../../../redux/movementSlice";
import { labelToId } from "../../../utils/metrics";

const DiscreteTrialEvents = ({
  tabs,
}: {
  tabs: { id: string; label: string; children: JSX.Element }[];
}) => {
  const dispatch = useDispatch();

  const updateMetric = (value: string) => {
    const label = value.split("-")[0];
    const id = labelToId(label, tabs);

    dispatch(setCurrentMetric(id));
  };

  useEffect(() => {
    updateMetric(tabs[0].label);
  }, []);

  return (
    <>
      <Tabs
        onChange={updateMetric}
        defaultActiveKey="1"
        items={tabs.map(({ label, children }, index) => ({
          label,
          key: `${label}-${index}`,
          children,
        }))}
      />
    </>
  );
};

export default DiscreteTrialEvents;
