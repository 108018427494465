import React, { useState } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import LabeledSwitch from "./LabeledSwitch";
import "./LabeledSwitch.css";
import { MinusCircleOutlined, PlusCircleFilled } from "@ant-design/icons";
import {
  setSelfCompValue,
  setIsSelfCompare,
  setIsSecondarySelfCompare,
  setSecondarySelfCompValue,
  setSelfCompOptions,
  setSecondarySelfCompOptions,
} from "../../redux/movementSlice";
import { usePerformanceAppDispatch } from "../../redux/hooks";
import { findCompValue } from "../Metrics/CompSwitches";
import { useSelector } from "react-redux";
import {
  selectSelfCompSwitch,
  selectIsSelfCompare,
  selectIsSecondarySelfCompare,
  selectSecondarySelfCompSwitch,
  selectSelfCompOptions,
  selectSecondarySelfCompOptions,
} from "../../redux/selectors";
import { When } from "react-if";
import { BASE_SELF_COMP_OPTION } from "../../services/mockData";

interface SelfCompSwitchesProps {
  data: any[];
  isMultiple?: boolean;
}

const SelfCompSwitches = ({ data, isMultiple }: SelfCompSwitchesProps) => {
  const dispatch = usePerformanceAppDispatch();
  const isSelfCompare = useSelector(selectIsSelfCompare);
  const isSecondarySelfCompare = useSelector(selectIsSecondarySelfCompare);
  const selectedSelfComp = useSelector(selectSelfCompSwitch);
  const selectedSecondarySelfComp = useSelector(selectSecondarySelfCompSwitch);
  const selfValue = useSelector(selectSelfCompOptions);
  const secondarySelfValue = useSelector(selectSecondarySelfCompOptions);
  const [showSecondarySwitch, setShowSecondarySwitch] = useState(false);

  const handleRemoveSwitch = () => {
    setShowSecondarySwitch(false);
    dispatch(setIsSecondarySelfCompare(false));
    dispatch(setSecondarySelfCompValue(undefined));
    dispatch(setSecondarySelfCompOptions(BASE_SELF_COMP_OPTION));
  };

  return (
    <Grid container display="flex" alignItems="center" justifyContent="end">
      <Grid item xs={2.5}>
        <Typography className="ptd-switch">Self Comp</Typography>
      </Grid>

      <Grid container item xs={9.5} justifyContent="flex-end">
        <Grid item xs="auto">
          <LabeledSwitch
            label=""
            className="self-compare-switch"
            checked={isSelfCompare}
            value={selectedSelfComp}
            onClick={(value?: any) => {
              dispatch(setIsSelfCompare(value));
            }}
            onHandleData={(value) => {
              const current = findCompValue(data, value);

              dispatch(setSelfCompValue(current));
            }}
            data={data}
            isSelf
            disabled={selfValue.type === "Session" && !selfValue.payload?.date}
            selfValue={selfValue}
            setSelfValue={(value: any) => {
              dispatch(setSelfCompOptions(value));
            }}
          />
        </Grid>

        <When condition={isMultiple}>
          <Grid item xs="auto">
            <IconButton
              disabled={showSecondarySwitch}
              onClick={() => {
                setShowSecondarySwitch(true);
              }}
              sx={{
                "&:not(:disabled)": { color: "var(--hoveredDigitalBlue)" },
              }}
            >
              <PlusCircleFilled />
            </IconButton>
          </Grid>
        </When>
      </Grid>

      <When condition={showSecondarySwitch && isMultiple}>
        <Grid container item xs={9.5} justifyContent="flex-end">
          <Grid item xs="auto">
            <LabeledSwitch
              label=""
              className="self-compare-switch"
              checked={isSecondarySelfCompare}
              value={selectedSecondarySelfComp}
              onClick={(value?: any) => {
                dispatch(setIsSecondarySelfCompare(value));
              }}
              onHandleData={(value) => {
                const current = findCompValue(data, value);

                dispatch(setSecondarySelfCompValue(current));
              }}
              data={data}
              isSelf
              disabled={
                secondarySelfValue.type === "Session" &&
                !secondarySelfValue.payload?.date
              }
              selfValue={secondarySelfValue}
              setSelfValue={(value: any) => {
                dispatch(setSecondarySelfCompOptions(value));
              }}
              isSecondarySelf
            />
          </Grid>

          <Grid item xs="auto">
            <IconButton
              onClick={handleRemoveSwitch}
              style={{ color: "var(--hoveredDigitalBlue)" }}
            >
              <MinusCircleOutlined />
            </IconButton>
          </Grid>
        </Grid>
      </When>
    </Grid>
  );
};

export default SelfCompSwitches;
