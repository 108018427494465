import React, { useState } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import { Menu, MenuProps, Popover } from "antd";
import { DownOutlined } from "@ant-design/icons";
import LegendItem from "../common/LegendItem";

interface MetricSelectorProps {
  metric: { id: string; label: string };
  onChange: MenuProps["onClick"];
  items: any[];
  rotate?: boolean;
}

const MetricSelector = ({
  metric,
  onChange,
  items,
  rotate,
}: MetricSelectorProps) => {
  const [open, setOpen] = useState(false);

  const rotateStyle = {
    transform: rotate ? "rotate(-90deg)" : "rotate(0deg)",
  };

  const textRotateStyle = {
    whiteSpace: rotate ? "nowrap" : "",
    writingMode: rotate ? "vertical-lr" : "",
    transform: rotate ? "rotate(-180deg)" : "rotate(0deg)",
  };
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction={rotate ? "column-reverse" : "row"}
      item
      xs={12}
    >
      <Grid item xs="auto" mx={1}>
        <LegendItem
          metric={metric}
          noLabel
          rotateTooltipIcon={rotate ? 90 : 0}
        />
      </Grid>
      <Grid>
        <Typography
          variant="body1"
          fontWeight="bold"
          sx={{
            color: "#1890ff",
            ...rotateStyle,
            ...textRotateStyle,
          }}
        >
          {metric?.label}
        </Typography>
      </Grid>
      <Grid item>
        <Popover
          open={open}
          onOpenChange={setOpen}
          trigger="click"
          placement="rightBottom"
          overlayClassName="ptd-inner-menu"
          content={<Menu onClick={onChange} mode="vertical" items={items} />}
        >
          <IconButton size="small" style={rotateStyle}>
            <DownOutlined />
          </IconButton>
        </Popover>
      </Grid>
    </Grid>
  );
};

export default MetricSelector;
