import { useSelector } from "react-redux";
import {
  selectComparingMovement,
  selectCurrentFrameValue,
  selectFirstFrameValue,
  selectLastFrameValue,
  selectMaxValueScrubb,
  selectMovement,
  selectSelectedMotionType,
  selectVideoDurationValue,
} from "../redux/selectors";
import { frameToMilliFromBrOffset } from "../utils/metrics";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useFetchTrialKeyframesQuery } from "../services/performanceApi.service";
import { drop, isNil, orderBy, range, times } from "lodash";
import { useMemo, useCallback } from "react";
import { MotionType } from "../components/common/MotionType";
import { KeyFrame } from "../components/InteractiveReportsTool/interactiveReportApi.service";

const DEFAULT_FRAME_DURATION = 0.3333;

type UseFramesDataParams = {
  keyframesData?: KeyFrame[];
};

function useFramesData(params?: UseFramesDataParams) {
  const { trial } = useSelector(selectMovement);
  const { trial: comparingTrial } = useSelector(selectComparingMovement);
  const motionType = useSelector(selectSelectedMotionType);

  const { data: storeKeyframesData } = useFetchTrialKeyframesQuery(
    trial?.id ? { trial, motionType } : skipToken
  );

  const keyframesData =
    (!isNil(params?.keyframesData)
      ? params?.keyframesData
      : storeKeyframesData) || [];

  const { data: comparingKeyframesData = [] } = useFetchTrialKeyframesQuery(
    comparingTrial?.id ? { trial: comparingTrial, motionType } : skipToken
  );
  const currentFrame = useSelector(selectCurrentFrameValue);
  const firstFrameValue = useSelector(selectFirstFrameValue);
  const lastFrameValue = useSelector(selectLastFrameValue);
  const videoDurationValue = useSelector(selectVideoDurationValue);
  const maxValueScrubb = useSelector(selectMaxValueScrubb);
  const isBatting = motionType === MotionType.Batting;
  //Videos are not properly mocked for swings. Use standard default frame duration instead of calculating with random pitch video duration.
  const frameDuration = useMemo(
    () => (videoDurationValue / maxValueScrubb) * 10 || DEFAULT_FRAME_DURATION,
    [videoDurationValue, maxValueScrubb]
  );
  const getFrame = useCallback(
    (frame: string, data?: any[]) =>
      (data || keyframesData).find((it: any) => it.label === frame)?.frame,
    [keyframesData]
  );

  const getSyncReferenceFrame = useCallback(
    (data: any[]) => getFrame(isBatting ? "BC" : "BR", data),
    [getFrame]
  );
  const getFrameForMainTrial = useCallback(
    (frame: string) => getFrame(frame, keyframesData),
    [getFrame, keyframesData]
  );

  const brFrame = useMemo(
    () => getSyncReferenceFrame(keyframesData),
    [keyframesData]
  );
  const comparingBrFrame = useMemo(
    () => getSyncReferenceFrame(comparingKeyframesData),
    [comparingKeyframesData]
  );

  const toMillisecondsFromBrOffset = useCallback(
    (frame: number) => frameToMilliFromBrOffset(frame, brFrame, frameDuration),
    [brFrame, frameDuration]
  );
  const frameToMillis = useCallback(
    (frame: number) => {
      return frame * frameDuration;
    },
    [frameDuration]
  );
  const rangeToMs = useCallback(
    (start: number, end: number) =>
      range(start, end).map(toMillisecondsFromBrOffset),
    []
  );

  const syncTrials = useCallback(
    (currentMetricData: any, customBrFrame?: number) => {
      const secondaryBrFrame = customBrFrame || comparingBrFrame;
      const [first, second] = orderBy(
        [brFrame, secondaryBrFrame],
        (it: any) => it,
        "desc"
      );

      const offset = first - second;
      const isMainMinor = brFrame < secondaryBrFrame;

      if (offset === 0) {
        return currentMetricData;
      }

      if (isMainMinor) {
        return drop(currentMetricData, offset);
      } else {
        return times(offset, () => currentMetricData[0]).concat(
          currentMetricData
        );
      }
    },
    [brFrame, comparingBrFrame]
  );

  return {
    currentFrame,
    firstFrameValue,
    lastFrameValue,
    videoDurationValue,
    toMillisecondsFromBrOffset,
    keyframesData,
    getFrame,
    getFrameForMainTrial,
    frameToMillis,
    brFrame,
    rangeToMs,
    syncTrials,
  };
}

export default useFramesData;
