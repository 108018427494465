import {
  first,
  flatMap,
  flatten,
  inRange,
  isArray,
  isEmpty,
  isNil,
  kebabCase,
  last,
  range,
  mean,
  minBy,
} from "lodash";
import {
  getBounds,
  getMetricIdByEasyName,
  getPlayerMetricColor,
  metricColors,
} from "./metrics";
import { MemoryCache } from "./cache";
import dayjs, { Dayjs } from "dayjs";
import {
  InningSplitValue,
  DateIndexingValue,
} from "../services/performanceApi.service";
import { DataPoint } from "regression";
import {
  dataIndexingValueToXCoordinate,
  ExpandedMetricsDataIndexing,
} from "../components/PlayerDashboard/ExpandedMetricsDataIndexing";
import { Color } from "three";
import { ChartLayoutSection } from "../components/WorkloadBuilder/workloadBuilderApi.mock";
import { toLocaleString } from "./strings";

interface Coordinates {
  x: number;
  y: number;
  w: number;
  h: number;
}

const dataCache = new MemoryCache();

export const interpolateColor = (from: string, to: string, factor: number) =>
  "#" + new Color(from).lerp(new Color(to), factor).getHexString();

export const drawLine = (end: number, coordinates: Coordinates) => {
  const { x, y, w, h } = coordinates;
  const middle = x + w / 2;
  const drawLine = (offset: number) => [
    "M",
    middle + offset,
    y - 10,
    "L",
    middle + offset,
    y * 2 + h + 4,
  ];

  return flatten(range(0, end).map((n) => drawLine(n)));
};

const baseFormat = (element: any) => {
  const isSelf = element?.label?.includes("Self");
  const isSecondary = element?.isSecondary;

  return {
    ...element,
    id: `${element.id}_${element.index}`,
    data: element.data,
    color:
      element?.color?.startsWith("#") || element?.color?.startsWith("var(--")
        ? element.color
        : `var(--${
            element.color
              ? element.color
              : isSelf
              ? "self-accent-color"
              : metricColors[element.index]
          })`,
    dashStyle: element?.dashStyle
      ? element?.dashStyle
      : isSecondary
      ? "longDash"
      : "Solid",
    yAxis: element.yAxis,
  };
};

export const markerSVG = (color: string) => {
  const fixedColor = encodeURIComponent(color);

  return `url(data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='40'><line x1="3.5" y1="39" x2="3.5" stroke="${fixedColor}"/><line x1="6.5" y1="39" x2="6.5" stroke="${fixedColor}"/><line x1="7.5" y1="39" x2="7.5" stroke="${fixedColor}"/><line x1="8.5" y1="39" x2="8.5" stroke="${fixedColor}"/><line x1="5.5" y1="39" x2="5.5" stroke="${fixedColor}"/><line x1="4.5" y1="39" x2="4.5" stroke="${fixedColor}"/><line x1="9" y1="39.5" x2="3" y2="39.5" stroke="${fixedColor}"/><line x1="12" y1="0.5" y2="0.5" stroke="${fixedColor}"/><line x1="11" y1="2.5" x2="1" y2="2.5" stroke="${fixedColor}"/><line x1="10" y1="3.5" x2="2" y2="3.5" stroke="${fixedColor}"/><line x1="12" y1="1.5" y2="1.5" stroke="${fixedColor}"/></svg>)`;
};

export const getVarValue = (variableName: string) => {
  if (variableName.startsWith("#")) return variableName;
  const startsWithVar = variableName.startsWith("var(");
  const fixedVarName = startsWithVar
    ? variableName.replace("var(", "").replace(")", "")
    : variableName;

  return getComputedStyle(document.documentElement).getPropertyValue(
    fixedVarName
  );
};

export const formatData = (data: any[]) => {
  const units = Array.from(new Set(data.map((it) => it.unit))) || ["°"];

  return data.map((element) =>
    baseFormat({ ...element, yAxis: units.indexOf(element.unit) })
  );
};

export const formatSecondaryData = (data: any[]) => {
  const units = Array.from(new Set(data.map((it) => it.unit))) || ["°"];

  return data.map((element, index) => {
    const isSelf = element?.label?.includes("Self");

    return {
      ...baseFormat({ ...element, index, yAxis: units.indexOf(element.unit) }),
      ...(!isSelf
        ? {
            type: "arearange",
            lineWidth: 0,
            fillOpacity: 0.3,
            zIndex: 0,
            marker: { enabled: false },
            enableMouseTracking: false,
          }
        : {}),
    };
  });
};

export const formatPlotLine = (it: any) => {
  const isLiveCursor = it.label.text === "liveCursor";

  return {
    ...it,
    label: isLiveCursor ? undefined : it.label,
    dashStyle: isLiveCursor ? "Solid" : "longDash",
    width: isLiveCursor ? 2 : 1,
  };
};

export const genericMarker = (lineColor = "", fillColor = "white") => ({
  lineWidth: 3,
  fillColor,
  lineColor,
  symbol: "circle",
});

const filteredDataValuesY = (data: any, unit: string): any =>
  flatMap(
    data?.filter((it: any) => it.unit === unit).map((it: any) => it.data)
  ).map((it: any) => (isArray(it) ? it[1] : it));

const filteredSecondaryDataValuesY = (data: any, unit: string): any => {
  const filteredMetrics = data?.filter((it: any) => it.unit === unit);
  const mappedMetricsData = filteredMetrics.map((series: any) => {
    const isSelf = series?.label.includes("Self");

    return flatten(
      series.data.map((it: any) => (isSelf ? it[1] : [it.low, it.high]))
    );
  });

  return flatten(mappedMetricsData);
};

export const formatYAxes = (
  data: any[],
  secondaryData: any[],
  trendsTimeSeries: boolean
) => {
  const units = Array.from(new Set(data?.map((it) => it.unit)));
  const mainValuesY = filteredDataValuesY(data, units[0]);
  const comparingDataValuesY = filteredDataValuesY(data, units[1]);

  const mainSecondaryValuesY = filteredSecondaryDataValuesY(
    secondaryData,
    units[0]
  ).filter((it: any) => !isNil(it) && !isNaN(it));

  const comparingSecondaryDataValuesY = filteredSecondaryDataValuesY(
    secondaryData,
    units[1]
  ).filter((it: any) => !isNil(it) && !isNaN(it));

  const bounds = [
    getBounds([...mainValuesY, ...mainSecondaryValuesY]),
    getBounds([...comparingDataValuesY, ...comparingSecondaryDataValuesY]),
  ];

  return units?.map((unit, index) => {
    const currentBound = bounds[index];

    return {
      min: currentBound?.min || 0,
      max: currentBound?.max || 100,
      crosshair: {
        className: trendsTimeSeries ? "ptd-y-axis-crosshair" : "",
        width: trendsTimeSeries ? 2 : 0,
      },
      title: { text: "" },
      gridLineWidth: 1,
      gridLineDashStyle: "shortDash",
      labels: {
        formatter: function (thisPoint: any) {
          return `${thisPoint?.value?.toLocaleString()}${(unit || "").replace(
            /deg/gi,
            "°"
          )}`;
        },
      },
      opposite: index === 1,
    };
  });
};

const mapLineDataFromASeries = (aSeries: any, xValues: any) =>
  xValues.map((x: number, index: number) => {
    const filteredData = (aSeries?.data || []).filter(
      (it: number, index: number) => index > 0 && it !== 0
    );
    const limit = filteredData?.length;
    const fixedYValue = filteredData[index < limit ? index : limit - 1];

    return flatten([x, fixedYValue]);
  });

const mappedAreaRangeDataFromASeries = (aSeries: any) =>
  aSeries.data.map((it: any) => ({
    ...it,
    x: it.x * 100,
  }));

interface formatTimeSeriesAxesParams {
  data: any[];
  xValues: any[];
  key: string;
  ranges: {
    start: number;
    end: number;
  };
}

export const formatTimeSeriesAxes = async ({
  data,
  xValues,
  key,
  ranges,
}: formatTimeSeriesAxesParams) => {
  const cacheKey = JSON.stringify(data);
  const { start, end } = ranges;

  return await dataCache.withCache(
    `formatTimeSeriesAxes_${cacheKey}_${start}_${end}`,
    async () =>
      await data.map((aSeries) => {
        const isArearange = aSeries?.data?.[0]?.low !== undefined;
        const mappedData = isArearange
          ? mappedAreaRangeDataFromASeries(aSeries)
          : mapLineDataFromASeries(aSeries, xValues);

        const filteredData = mappedData.filter((it: any) => {
          const fixedXValue = isArearange ? it.x : it[0];
          const { min, max } = getBounds(xValues);

          return inRange(fixedXValue, min, max);
        });

        return {
          ...aSeries,
          id: `${key}_${aSeries.id}`,
          data: filteredData,
        };
      })
  );
};
export const fixOffsetValue = (
  value: number,
  { min, max }: { min: number; max: number }
) => {
  if (value < min) {
    return min;
  }

  if (value > max) {
    return max;
  }

  return value;
};

export const mergeUnits = (data: any, dataWithUnits: any) => {
  return data.map((metric: any) => {
    const current = dataWithUnits.find((it: any) => it.id === metric.id);

    if (!current) {
      return metric;
    }

    return { ...metric, unit: current.unit };
  });
};

export const fixId = (id: string, removeSuffix = false) => {
  const splittedId = id.split("_").slice(1, -1).join("_");

  return splittedId?.endsWith("_TS") && removeSuffix
    ? splittedId?.replace("_TS", "_")
    : splittedId;
};

interface PolarSeriesDataProps {
  data: any;
  symbol?: string;
  isArea?: boolean;
  color?: string;
  showTooltip?: boolean;
  pointPlacement?: string;
  categories: string[];
}

export const formatPolarSeriesData = ({
  data,
  symbol = "circle",
  isArea,
  color = "gray",
  showTooltip,
  pointPlacement,
  categories,
}: PolarSeriesDataProps) => ({
  type: isArea ? "area" : "line",
  color: isArea ? color : "transparent",
  enableMouseTracking: showTooltip,
  pointPlacement,
  categories,
  data: data.map((y: number, index: number) => ({
    y,
    marker: {
      symbol,
      fillColor: getPlayerMetricColor(getMetricIdByEasyName(categories[index])),
      lineWidth: 1,
      radius: isArea ? 5 : 7,
    },
  })),
});

export const toSeries = <T>(data: T, visible?: boolean) => ({
  visible: visible !== undefined ? visible : true,
  data: data,
});

export const updateOrReplaceSeries = (chart: any, series: any) => {
  if (chart?.series && chart?.series?.[series.index]) {
    chart.series[series.index].update(series);
  } else {
    chart?.addSeries(series);
  }
};

export const refreshSeries = (
  chart: Highcharts.Chart | undefined,
  series: any[]
) => {
  if (isNil(chart)) {
    return;
  }

  if (chart.series.length > series.length) {
    removeAllSeries(chart);
  }

  series.forEach((series, index) => {
    updateOrReplaceSeries(chart, { ...series, index });
  });
};

export const removeAllSeries = (chart: Highcharts.Chart) => {
  while (chart.series.length > 0) {
    chart.series[0].remove(true);
  }
};

export function getDatesInRange(from: Dayjs, to: Dayjs, length: number) {
  const delta = to.diff(from, "day");
  const interval = delta / (length - 1);

  return Array.from({ length }, (_, i) => from.add(i * interval, "day"));
}

export const getDateValue = (element: any, format = "YYYY-MM-DD") =>
  typeof element === "number" ? element : dayjs(element, format).valueOf();

export const fixDateValueIndex = (index: number) => index + 1;

export const formatToCandlestickData = (
  data: (InningSplitValue | DateIndexingValue)[],
  bounds: { high: number; low: number } | undefined,
  dataIndexing?: ExpandedMetricsDataIndexing
) =>
  data.map((point) => {
    const isDateIndexing = dataIndexing === ExpandedMetricsDataIndexing.Date;
    const x = isDateIndexing
      ? dataIndexingValueToXCoordinate(point)
      : fixDateValueIndex(
          data.findIndex(
            (it: any) => getDateValue(point?.date) === getDateValue(it?.date)
          )
        );
    const y = point.value;
    const top = point.top;
    const bottom = point.bottom;

    let color = "#dedede";

    if (bounds !== undefined) {
      const { high, low } = bounds;
      if (y >= low && y <= high) {
        color = "#dedede";
      } else if (y > high) {
        color = "#ebc3c3";
      } else {
        color = "#c3d8eb";
      }
    }

    return {
      x,
      y,
      open: top,
      close: bottom,
      high: top,
      low: bottom,
      color,
    };
  });

export function makeRegressionSeries(
  data?: DataPoint[],
  visible: boolean = true
) {
  return isNil(data)
    ? {}
    : {
        data,
        color: "#c2c2c2",
        dashStyle: "longDash",
        marker: false,
        enableMouseTracking: false,
        zIndex: 0,
        visible,
      };
}

export const formatSplitRegressionSeries: any = (
  splitRegressionData: any[],
  referenceData: any[],
  isDateIndexing: boolean
) =>
  splitRegressionData?.map(([x, y]) => [
    isDateIndexing
      ? x
      : fixDateValueIndex(
          referenceData?.findIndex(
            (it) => getDateValue(it.date) === getDateValue(x)
          )
        ),
    y,
  ]);

export function createMarker(style: React.CSSProperties) {
  const keys = Object.keys(style);
  const values = Object.values(style);

  const styleToString = keys.map((key, index) => {
    const formattedKey: string = kebabCase(key);
    const currentStyleValue = values[index];

    return `${formattedKey}: ${currentStyleValue};`;
  });

  return `<div style="${styleToString.join(" ")}"></div>`;
}

export function generateXValuesFromExtremes(
  extremes: { start: number; end: number },
  numberOfPoints: number
) {
  const { start, end } = extremes;
  const spacing = (end - start) / (numberOfPoints - 1);

  return range(0, numberOfPoints).map((it: number) => start + it * spacing);
}

export const getExtremes = (arrayData: any[]) => {
  const firstElement = first(arrayData);
  const lastElement = last(arrayData);

  return [firstElement, lastElement];
};

export const trialIndexOrDefault = (trialIndex: number | undefined) =>
  trialIndex === undefined ? 1 : trialIndex;

export const getPointsByTrialIndex = (
  points: any[] | undefined,
  trialIndex: number
) =>
  (points || []).filter(
    (it: any) => trialIndexOrDefault(it.point.trialIndex) === trialIndex
  );

export const generateCandleStickMarker = (chart: any, color: string) => {
  const point = chart.point;
  const openPixel = chart.series.chart.yAxis[0].toPixels(point.open);
  const closePixel = chart.series.chart.yAxis[0].toPixels(point.close);
  const height = Math.abs(openPixel - closePixel);
  const width = 12;

  return `<div style="height: ${height}px; z-index:0;">
  <div style="position: absolute; top: 0; left: -${
    width / 2
  }px; width: ${width}px; height: 1px; background-color: ${color};"></div>
  <div style="position: absolute; top: 0; width:1px; height: 100%; background-color: ${color};"></div>
  <div style="position: absolute; bottom: 0; left: -${
    width / 2
  }px; width: ${width}px; height: 1px; background-color: ${color};"></div>
  </div>`;
};

interface ParseObjecToStringHTML {
  children?: string;
  style?: React.CSSProperties;
}

export const parseObjecToStringHTML = ({
  children = "",
  style = {},
}: ParseObjecToStringHTML) => {
  const styleString = Object.entries(style)
    .map(([key, value]) => `${kebabCase(key)}: ${value};`)
    .join(" ");

  return `<div ${
    !isEmpty(style) ? `style="${styleString}"` : ""
  }>${children}</div>`;
};

interface MakeTooltipBoxParams extends ParseObjecToStringHTML {
  color?: string;
}

export const makeTooltipBox = ({
  children,
  color,
  style,
}: MakeTooltipBoxParams) =>
  parseObjecToStringHTML({
    children,
    style: {
      textAlign: "center",
      padding: "6px",
      borderRadius: "6px",
      border: `solid 1px ${color}`,
      backgroundColor: "#fff",
      fontSize: "14px",
      height: "28px",
      ...style,
    },
  });

type Average = {
  from: number;
  to: number;
};

export const getTickPositionsFromAverage = (average: Average) => {
  const { from, to } = average;
  const meanTick = mean([from, to]) || 0;
  const offset = Math.abs(from - meanTick);
  const start = from - offset;
  const end = to + offset;

  return { meanTick, offset, start, end };
};

export const findClosestPoint = (
  xValue: string | number | undefined,
  data: Highcharts.Point[]
) => minBy(data, (point) => Math.abs(point.x - +(xValue ?? 0)));

export const hoverPoint = (point?: Highcharts.Point) => {
  // For some reason, you have to reset the state first.
  // See https://www.highcharts.com/forum/viewtopic.php?p=167492#p167492
  point?.setState();
  point?.setState("hover");
};

export const zonesColors = ["red", "yellow", "green"];

// eslint-disable-next-line no-unused-vars
const createGradient = (
  startColor: React.CSSProperties["color"],
  endColor: React.CSSProperties["color"]
) => ({
  // Vertical gradient
  linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
  stops: [
    [0, startColor],
    [1, endColor],
  ],
});

type Zone = { value: number; color: React.CSSProperties["color"] | undefined };
export const getZones = (
  values: number[] = [0, 0, 0, 0, 0, 0],
  defaultZonesColors: React.CSSProperties["color"][] = []
) => {
  const colors = !isEmpty(defaultZonesColors)
    ? defaultZonesColors
    : zonesColors;

  const mapped = values.map((value, index) => ({
    value,
    color: index < 3 ? colors[index] : colors[5 - index],
  }));

  return mapped.concat({ value: Infinity, color: colors[0] });
};

export function getColorForValue(value: number, zones: Zone[]) {
  const validZones = zones.filter((item) => item.value !== null);

  const closestZone = validZones.reduce((prev, curr) => {
    return Math.abs(curr.value - value) < Math.abs(prev.value - value)
      ? curr
      : prev;
  });

  return closestZone.color;
}

export const tooltipValueOrDefault = (value?: number) =>
  `${!isNil(value) ? toLocaleString(value) : "-"}`;

export const wlbTooltipFormatters: Record<
  ChartLayoutSection,
  (points: any[]) => string
> = {
  chronic: (points) => tooltipValueOrDefault(points[0]?.y),
  dailyAcr: (points) => {
    const wl = tooltipValueOrDefault(points[0]?.y);
    const acr = tooltipValueOrDefault(points[1]?.y);
    return `WL: ${wl}, ACR: ${acr}`;
  },
  throwingFreq: (points) => {
    const value = tooltipValueOrDefault(points[0]?.y);
    return `${value} days per week`;
  },
  throwingVol: (points) => {
    const value = tooltipValueOrDefault(points[0]?.y);
    return `${value} throws`;
  },
  moundFreq: (points) => {
    const value = tooltipValueOrDefault(points[0]?.y);
    return `${value} days per week`;
  },
  intensity: (points) => {
    const value = tooltipValueOrDefault(points[0]?.y);
    return `${value}% intensity`;
  },
};
