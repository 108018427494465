import React, { ReactNode, useState } from "react";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { CloseOutlined } from "@ant-design/icons";
import Draggable from "react-draggable";
import "./CollapsiblePanel.css";
import { Card, Space } from "antd";
import Resizable from "./Resizable";
interface MyCollapsiblePanelProps {
  title: string;
  subtitle?: string;
  titleClassName?: string;
  orientation: "left" | "right";
  top: string | number;
  left?: string | number;
  headComponent?: React.ReactNode;
  children: (height: number) => JSX.Element;
  innerPosition?: {
    top: string | number;
    left?: string | number;
  };
  leftResize?: boolean;
  minWidth?: number;
  minHeight?: number;
  initialWidth?: number;
  initialHeight?: number;
  containerRef?: any;
  removeOnCollapsed?: boolean;
  setContainerWidth?: (value: number) => void;
  tabZIndex?: number;
  disabled?: boolean;
}

interface CollapsibleTitleProps {
  title: string;
  className?: string;
  handleClick: () => void;
  headComponent?: ReactNode;
}

const DEGREES = { left: 90, right: -90 };
const getOpposite = (side: "left" | "right") =>
  side === "left" ? "right" : "left";

function CollapsiblePanel({
  children,
  headComponent,
  title,
  subtitle,
  titleClassName,
  orientation,
  top,
  left,
  innerPosition,
  minWidth,
  minHeight,
  initialWidth,
  initialHeight,
  containerRef,
  tabZIndex,
  removeOnCollapsed = false,
  setContainerWidth,
  disabled,
}: MyCollapsiblePanelProps) {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => setIsOpen(!isOpen);

  return (
    <>
      <Box
        style={{
          top,
          [getOpposite(orientation)]: "-14px",
          left,
          position: "absolute",
          zIndex: tabZIndex ?? 110,
        }}
      >
        {!isOpen && (
          <Button
            onClick={handleClick}
            variant="text"
            className="ptd-text-button"
            sx={{
              transform: !isOpen && `rotate(${DEGREES[orientation]}deg)`,
            }}
            disabled={disabled}
          >
            {title}
          </Button>
        )}
        {(!removeOnCollapsed || isOpen) && (
          <Draggable handle=".title">
            <div
              ref={containerRef}
              style={{
                display: isOpen ? "block" : "none",
                position: "absolute",
                top: innerPosition?.top ? innerPosition?.top : 0,
                [getOpposite(orientation)]: innerPosition?.left
                  ? innerPosition?.left
                  : 0,
              }}
            >
              <Space direction="vertical">
                <Resizable
                  minWidth={minWidth}
                  minHeight={minHeight}
                  initialWidth={initialWidth}
                  initialHeight={initialHeight}
                >
                  {({ width, height }) => {
                    if (setContainerWidth !== undefined) {
                      setContainerWidth(width);
                    }

                    return (
                      <Card
                        bodyStyle={{
                          padding: "16px",
                          height: height,
                          width: width,
                        }}
                      >
                        <div className="title" style={{ cursor: "move" }}>
                          <CollapsibleTitle
                            title={subtitle ? subtitle : title}
                            className={titleClassName}
                            handleClick={handleClick}
                            headComponent={headComponent}
                          />
                        </div>
                        {children(height)}
                      </Card>
                    );
                  }}
                </Resizable>
              </Space>
            </div>
          </Draggable>
        )}
      </Box>
    </>
  );
}

const CollapsibleTitle = ({
  title,
  className,
  handleClick,
  headComponent,
}: CollapsibleTitleProps) => (
  <Grid container justifyContent={"space-between"} alignItems={"center"}>
    <Grid item xs={"auto"}>
      <Typography
        className={className}
        fontSize={14}
        sx={{ color: "var(--hoveredDigitalBlue)" }}
      >
        {title}
      </Typography>
    </Grid>
    <Grid item xs={6}>
      {headComponent && headComponent}
    </Grid>

    <Grid item>
      <IconButton sx={{ fontSize: 14 }} onClick={handleClick}>
        <CloseOutlined />
      </IconButton>
    </Grid>
  </Grid>
);

export default CollapsiblePanel;
