/* Commonly used selectors within the app */
export const selectUserStatus = (state) => state.app.user.loadStatus;

/**
 * We have three sets of user selectors.
 * 1. real user
 * 2. impersonated user
 * 3. user, aka "apparent user"
 * ...
 * "real user" (1) gets the information from state for the "user" object.
 * "impersonated (2) user" gets the information from the impersonated user.
 * "user" (3) gets information from the "apparent user", by composing the two above,
 * preferring impersonated user info when it's present. If no impersonated
 * info is found, "real user" info is used.
 * You should basically always use 3 because we want to compose real & impersonated!
 */

const selectRealUserRole = (state) => state.app.user.role?.name;
const selectRealUserRoleId = (state) => state.app.user.role?.id;
const selectRealUserPermissions = (state) => state.app.user.role.permissions;
const selectRealUserDepartment = (state) => state.app?.user?.role?.department;
const selectRealUserName = (state) => state.app?.user?.userName;
const selectRealUserPlayerId = (state) => state.app?.user?.playerId;
const selectRealUserPlayerPrimaryPosition = (state) =>
  state.app?.user?.playerInfo?.primaryPosition;
// We have to export real user email, as opposed to the other real selectors, bc we use real email as part of api request auth:
export const selectRealUserEmail = (state) => state.app.user?.email;

// TODO: when we get the nav bar no longer using real user id and preference for user pref, stop exporting them:
export const selectRealUserId = (state) => state.app.user?.id;
export const selectRealUserPreference = (state) => state.app?.user?.preference;
export const selectRealUserScoutingPermissions = (state) =>
  state.app?.user?.scoutingPermissions;

// Impersonated user selectors:
const selectImpersonatedUserRole = (state) =>
  state.app.impersonatedUser.role?.name;
const selectImpersonatedUserRoleId = (state) =>
  state.app.impersonatedUser.role?.id;
const selectImpersonatedUserId = (state) => state.app.impersonatedUser?.id;
const selectImpersonatedUserPermissions = (state) =>
  state.app.impersonatedUser.role.permissions;
const selectImpersonatedUserDepartment = (state) =>
  state.app.impersonatedUser.role.permissions;
const selectImpersonatedUserName = (state) =>
  state.app.impersonatedUser?.userName;
const selectImpersonatedPlayerId = (state) =>
  state.app.impersonatedUser?.playerId;
const selectImpersonatedUserPlayerPrimaryPosition = (state) =>
  state.app?.impersonatedUser?.playerInfo?.primaryPosition;

const selectImpersonatedUserScoutingPermissions = (state) =>
  state.app.impersonatedUser?.scoutingPermissions;

export const selectImpersonatedUserLoadStatus = (state) => {
  return state.app.impersonatedUser.loadStatus;
};
export const selectImpersonatedUserEmail = (state) => {
  return state.app.impersonatedUser.email;
};

export const selectIsImpersonationActive = (state) =>
  state.app.impersonatedUser?.id > 0;

// combined, aka "apparent" user selectors:
export const selectUserRole = (state) =>
  selectIsImpersonationActive(state)
    ? selectImpersonatedUserRole(state)
    : selectRealUserRole(state);

export const selectUserRoleId = (state) =>
  selectIsImpersonationActive(state)
    ? selectImpersonatedUserRoleId(state)
    : selectRealUserRoleId(state);

export const selectUserId = (state) =>
  selectIsImpersonationActive(state)
    ? selectImpersonatedUserId(state)
    : selectRealUserId(state);

export const selectUserEmail = (state) =>
  selectIsImpersonationActive(state)
    ? selectImpersonatedUserEmail(state)
    : selectRealUserEmail(state);

export const selectUserPermissions = (state) =>
  selectIsImpersonationActive(state)
    ? selectImpersonatedUserPermissions(state)
    : selectRealUserPermissions(state);

export const selectUserDepartment = (state) =>
  selectIsImpersonationActive(state)
    ? selectImpersonatedUserDepartment(state)
    : selectRealUserDepartment(state);

export const selectUserName = (state) =>
  selectIsImpersonationActive(state)
    ? selectImpersonatedUserName(state)
    : selectRealUserName(state);

export const selectUserPlayerId = (state) =>
  selectIsImpersonationActive(state)
    ? selectImpersonatedPlayerId(state)
    : selectRealUserPlayerId(state);

export const selectUserPlayerPrimaryPosition = (state) =>
  selectIsImpersonationActive(state)
    ? selectImpersonatedUserPlayerPrimaryPosition(state)
    : selectRealUserPlayerPrimaryPosition(state);

export const selectUserScoutingPermissions = (state) =>
  selectIsImpersonationActive(state)
    ? selectImpersonatedUserScoutingPermissions(state)
    : selectRealUserScoutingPermissions(state);

/**
 * UserAdmin slice selectors:
 */

export const selectUsersLoadStatus = (state) =>
  state.userAdmin.userInfo.loadStatus;
export const selectUsers = (state) => state.userAdmin.userInfo.users;
export const selectRolesLoadStatus = (state) =>
  state.userAdmin.roleInfo.loadStatus;
export const selectRolesByDepartment = (state) =>
  state.userAdmin.roleInfo.rolesByDepartment;
export const selectRolePermissionLoadStatus = (state) =>
  state.userAdmin.rolePermissionInfo.loadStatus;
export const selectRolePermissions = (state) =>
  state.userAdmin.rolePermissionInfo.resources;
export const selectSavePermissionChangesStatus = (state) =>
  state.userAdmin.rolePermissionInfo.saveChangesStatus;

export const selectPlayerPDPlan = (state) => state.player.pdPlan.data;
export const selectPlayerPDPlanLoadStatus = (state) =>
  state.player.pdPlan.loadStatus;
export const selectPlayerPDPlanErrorMsg = (state) =>
  state.player.pdPlan.errorMsg;

export const selectPlayerBio = (state) => state.player.bio.data;
export const selectPlayerLoadStatus = (state) => state.player.bio.loadStatus;
export const selectPlayerErrorMsg = (state) => state.player.bio.errorMsg;

export const selectPlayerFiles = (state) => state.player.playerFiles.data;
export const selectPlayerFilesLoadStatus = (state) =>
  state.player.playerFiles.loadStatus;
export const selectPlayerFilesErrorMsg = (state) =>
  state.player.playerFiles.errorMsg;

export const selectPlayerPositionTypeFocus = (state) =>
  state.player.playerTypeFocus;
export const selectIsProPlayer = (state) => state.player.isProPlayer;
export const selectAcquisitionGroup = (state) => state.player.acquisitionGroup;

export const selectPlayerStatCategories = (state) =>
  state.player.statCategories.data;
export const selectPlayerStatCategoriesLoadStatus = (state) =>
  state.player.statCategories.loadStatus;
export const selectPlayerStatCategoriesErrorMsg = (state) =>
  state.player.statCategories.errorMsg;

export const selectTeamData = (state) => state.team.teamInfo.data;
export const selectTeamLoadStatus = (state) => state.team.teamInfo.loadStatus;
export const selectTeamErrorMsg = (state) => state.team.teamInfo.errorMsg;

export const selectProTeamData = (state) => state.team.proTeams.data;
export const selectProTeamLoadStatus = (state) =>
  state.team.proTeams.loadStatus;
export const selectProTeamErrorMsg = (state) => state.team.proTeams.errorMsg;

// While this isn't strictly a selector, it parses values from okta state so it's been included in selectors:
export const selectOktaInfo = ({ authState }) => {
  const apiToken = authState.accessToken.accessToken;
  const email = authState.accessToken.claims.sub.toLowerCase();
  return { apiToken, email };
};
