import { useSelector } from "react-redux";
import { selectIsSelfCompare, selectSelfCompOptions } from "../redux/selectors";
import {
  useFetchDiscreteSelfDataQuery,
  useFetchPitchSelfDataQuery,
  useFetchTimeSeriesSelfDataQuery,
} from "../services/performanceApi.service";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { shouldFetchSelfCompData } from "../utils/metrics";
import { isNil } from "lodash";
import { SelfCompSelectedValue } from "./common/SelfMenu";

const useFetchSelfData = (
  player: any,
  paramSelfCompOptions?: SelfCompSelectedValue
) => {
  const isSelfCompare = useSelector(selectIsSelfCompare);
  const selfCompOptions = !isNil(paramSelfCompOptions)
    ? paramSelfCompOptions
    : useSelector(selectSelfCompOptions);

  const shouldFetch = shouldFetchSelfCompData(player, selfCompOptions);
  const { data: discreteData = [] } = useFetchDiscreteSelfDataQuery(
    shouldFetch ? { playerId: player?.id, selfCompOptions } : skipToken
  );
  const { data: pitchData = [] } = useFetchPitchSelfDataQuery(
    shouldFetch ? { playerId: player?.id, selfCompOptions } : skipToken
  );

  const mappedPlayer: any = { id: player?.id };
  const { data: timeSeriesData = {} } = useFetchTimeSeriesSelfDataQuery(
    shouldFetch ? { player: mappedPlayer, selfCompOptions } : skipToken
  );

  return {
    isSelfCompare,
    selfCompOptions,
    discreteData,
    pitchData,
    timeSeriesData,
  };
};

export default useFetchSelfData;
