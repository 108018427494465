/**
 * In react-router-dom 6+, you can only add <Route /> components under a
 * <Routes /> definition, so okta-react's <SecureRoute /> component will not work.
 * Thankfully, they offer this solution as a reasonable workaround.
 * https://github.com/okta/okta-react/tree/master/samples/routing/react-router-dom-v6
 */
import React, { useEffect } from "react";
import { useOktaAuth } from "../Components/common/useOktaAuth";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { Outlet } from "react-router-dom";
import { Spin } from "antd";

export const RequiredAuth = () => {
  const { oktaAuth, authState } = useOktaAuth();

  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin
      );
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }
  }, [oktaAuth, !!authState, authState?.isAuthenticated]);

  if (!authState || !authState?.isAuthenticated) {
    return <Spin />;
  }

  return <Outlet />;
};
