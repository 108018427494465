import { getApiLocation } from "../Components/api";

const addApiTokenToHeaders = (apiToken, headers) => {
  headers.set("x-access-token", `Bearer: ${apiToken}`);
  return headers;
};

const getBaseUrl = () => {
  const apiLocation = getApiLocation();
  return `${apiLocation}/api`;
};

export { addApiTokenToHeaders, getBaseUrl };
