import React, { useMemo, useState } from "react";
import CollapsiblePanel from "../common/CollapsiblePanel";
import { Tabs } from "antd";
import Scrollable from "../common/Scrollable";
import BallDynamics from "./BallDynamics";
import MatchupDetails from "./MatchupDetails";
import CompSwitches from "../Metrics/CompSwitches";
import { useFetchSwingPitchDataQuery } from "../../services/performanceApi.service";
import { useSelector } from "react-redux";
import {
  selectMovement,
  selectSelectedMotionType,
} from "../../redux/selectors";
import { skipToken } from "@reduxjs/toolkit/dist/query";

function BattedBallPanelModal() {
  const [containerWidth, setContainerWidth] = useState(0);
  const { trial = {} } = useSelector(selectMovement);
  const motionType = useSelector(selectSelectedMotionType);
  const { data: swingData = [] } = useFetchSwingPitchDataQuery(
    trial?.id ? { trial, motionType } : skipToken
  );
  const tabs = useMemo(
    () => [
      { key: "1", label: "Batted Ball Profile", children: <BallDynamics /> },
      {
        key: "2",
        label: "Pitch Profile",
        children: (
          <MatchupDetails
            dataRows={[
              {
                name: "Pitch Type",
                pathOrGetter: () => swingData?.pitch_type,
              },
              {
                name: "Velocity",
                pathOrGetter: () => swingData?.rel_speed,
              },
              {
                name: "Spin Rate",
                pathOrGetter: () => swingData?.spin_rate,
              },
              {
                name: "IVB",
                pathOrGetter: () => swingData?.vert_break_induced,
              },
              {
                name: "HB",
                pathOrGetter: () => swingData?.horz_break,
              },
            ]}
          />
        ),
      },
    ],
    [swingData, trial]
  );
  const mappedTabs = tabs.map((tab) => ({
    ...tab,
    children: {
      ...tab.children,
      props: {
        ...tab.children.props,
        containerWidth: containerWidth - 24,
      },
    },
  }));
  return (
    <CollapsiblePanel
      title="Batted"
      subtitle="BATTED BALL"
      top={180}
      innerPosition={{
        top: "0px",
      }}
      orientation="left"
      leftResize
      minWidth={600}
      initialWidth={700}
      minHeight={75}
      setContainerWidth={setContainerWidth}
      tabZIndex={11}
      headComponent={<CompSwitches />}
      removeOnCollapsed
    >
      {(height) => {
        return (
          <Tabs
            defaultActiveKey="1"
            items={mappedTabs.map(({ label, children }, index) => {
              return {
                label,
                key: `${label}-${index}`,
                children: (
                  <Scrollable height={height - 160}>{children}</Scrollable>
                ),
              };
            })}
          />
        );
      }}
    </CollapsiblePanel>
  );
}

export default BattedBallPanelModal;
