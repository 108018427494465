import { Dictionary, get, isNil, upperFirst } from "lodash";
import dayjs from "dayjs";

const temperatureColors = {
  hot: "#ff4d4f",
  cold: "#1890ff",
};

export type Temperature = "hot" | "cold";

export const temperatureMetricColor = (value?: Temperature) =>
  get(temperatureColors, value ?? "");

export const temperatureMetricLabel = (value?: Temperature) =>
  isNil(value) ? "-" : upperFirst(value);

export const temperatureForDate = (
  date: dayjs.Dayjs,
  values?: Dictionary<Temperature>
) => values?.[date.format("YYYY-MM-DD")];

export const shouldIncludeTemperature = (metricId?: string) =>
  metricId?.startsWith("rom_") ?? false;
