import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useFetchDiscreteSelfDataQuery,
  useFetchMetricsQuery,
} from "../services/performanceApi.service";
import {
  selectComparingMovement,
  selectExtraMovements,
  selectIsSelfCompare,
  selectMovement,
  selectSelectedMetric,
  selectSelectedMotionType,
  selectSelfCompOptions,
} from "../redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isNil } from "lodash";
import { setSelectedMetricValue } from "../redux/movementSlice";
import {
  useFetchExtraMovementsMetrics,
  SELF,
  METRIC,
} from "./useFetchExtraMovementsMetrics";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { shouldFetchSelfCompData } from "../utils/metrics";

export function useMetricDataItems() {
  const { trial } = useSelector(selectMovement);
  const motionType = useSelector(selectSelectedMotionType);
  const { data = [], isLoading } = useFetchMetricsQuery({
    metricId: METRIC,
    pitch: trial,
    motionType,
  });

  return { data, isLoading };
}

export const mapTimeSeriesId = (id: string) =>
  id.split("_TS_")[0].concat(id?.includes("_TS") ? "_TS" : "");

export const findMetric = (data: any[], metricId: any, isSelfComp = false) => {
  const mappedId = !isNil(metricId?.id) ? metricId.id : metricId;

  const sections = data.flatMap((section) => section.children);
  const metric = sections?.find((it) =>
    mappedId.includes(mapTimeSeriesId(it?.id))
  );
  const metricData = isSelfComp
    ? metric?.children?.find((inner: any) =>
        mappedId.includes(mapTimeSeriesId(inner?.id))
      )
    : metric;
  const event = metricData?.children?.find((it: any) => mappedId === it.key);

  const rest = !isNil(event) ? event : metric;

  return {
    ...rest,
    id: event?.key,
    systematicName: event?.systematicName,
    key: metric?.key,
    label: metric?.label,
    event: event?.label,
  };
};

export function useMetricData({
  useLocalState,
}: { useLocalState?: boolean } = {}) {
  const { trial, player } = useSelector(selectMovement);
  const isSelfCompare = useSelector(selectIsSelfCompare);
  const { data: metricsData = [], isLoading } = useMetricDataItems();
  const { trial: comparingTrial = {} } = useSelector(selectComparingMovement);
  const motionType = useSelector(selectSelectedMotionType);
  const isComparingTrial = useMemo(
    () => !isNil(comparingTrial?.id),
    [comparingTrial?.id]
  );
  const { data: compareMetricsData = [] } = useFetchMetricsQuery(
    isComparingTrial
      ? {
          metricId: METRIC,
          pitch: comparingTrial,
          motionType,
        }
      : skipToken
  );
  const selfCompOptions = useSelector(selectSelfCompOptions);
  const { data: selfCompData } = useFetchDiscreteSelfDataQuery(
    shouldFetchSelfCompData(player, selfCompOptions)
      ? {
          playerId: player.id,
          selfCompOptions,
          rawResponse: true,
        }
      : skipToken
  );
  const { data: selfMetricsData = [] } = useFetchMetricsQuery({
    pitch: trial,
    metricId: SELF,
    selfCompData,
    motionType,
  });

  const { extraMovementsMetrics } = useFetchExtraMovementsMetrics(
    useSelector(selectExtraMovements)
  );

  const dispatch = useDispatch();
  const [localSelectedMetric, setLocalSelectedMetric] = useState<any>({});
  const reduxSelectedMetric = useSelector(selectSelectedMetric);
  const selectedMetric = useLocalState
    ? localSelectedMetric
    : reduxSelectedMetric;

  const setSelectedMetric = useCallback(
    (aMetric: any) => {
      if (useLocalState) {
        setLocalSelectedMetric(aMetric);
      } else {
        dispatch(setSelectedMetricValue(aMetric));
      }
    },
    [useLocalState]
  );

  const [comparingMetric, setComparingMetric] = useState<any | undefined>({});

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const secondaryData = useMemo(
    () =>
      isSelfCompare
        ? selfMetricsData
        : isComparingTrial
        ? compareMetricsData
        : [],
    [isSelfCompare, isComparingTrial, compareMetricsData, selfMetricsData]
  );

  useEffect(() => {
    if (!isLoading && !isEmpty(metricsData)) {
      let currentMetricData;

      const category = metricsData[0];
      //Some pitches have badly formed files, the null-safe chaining avoids crashes
      const metric = category?.children?.[0];
      const event = metric?.children?.[0];

      if (!isNil(event?.key)) {
        currentMetricData = {
          ...event,
          id: metric?.id,
          key: metric?.key,
          label: metric?.label,
          event: event?.label,
        };
      }

      if (!isNil(currentMetricData)) {
        setSelectedMetric(currentMetricData);
      }
    }
  }, [isLoading, metricsData]);

  useEffect(() => {
    if (!isEmpty(secondaryData) && !isEmpty(selectedMetric)) {
      const compareMetric = findMetric(
        secondaryData,
        selectedMetric?.id,
        isSelfCompare
      );

      setComparingMetric(compareMetric);
    }
  }, [secondaryData, selectedMetric]);

  const handleOpenMetricChange = (event: any) => {
    if (isComparingTrial || isSelfCompare) {
      const newCompareMetric = findMetric(secondaryData, event.key);

      setComparingMetric(newCompareMetric);
    }

    const newSelectedMetric = findMetric(metricsData, event.key);

    if (!isNil(newSelectedMetric)) {
      setSelectedMetric(newSelectedMetric);
    }
  };

  const extraValues = useMemo(
    () =>
      extraMovementsMetrics
        .map((it) => ({
          color: it.color,
          value: findMetric(it.metrics, selectedMetric)?.value,
        }))
        .filter((it) => it.color && it.value),
    [extraMovementsMetrics, selectedMetric]
  );

  return {
    selectedMetric,
    comparingMetric,
    isComparingTrial,
    isSelfCompare,
    setSelectedMetric,
    items: metricsData,
    handleOpenMetricChange,
    menuIsOpen,
    setMenuIsOpen,
    extraValues,
  };
}
