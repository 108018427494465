import dayjs, { Dayjs } from "dayjs";
import { dateToISO } from "../services/performanceApi.adapter";
import { isNil } from "lodash";

export function recentItems(array: any[]) {
  return array
    .map((it) => it?.date || it)
    .filter((it: any) => isRecentItem(it));
}

export function formatDateToString(date: Dayjs, format: string) {
  return date.format(format);
}

export function getDateFromFromIsoString(date: any, format: string) {
  return dayjs(dateToISO(formatDateToString(date, format)));
}

export function isRecentItem(
  item: any,
  endDate?: Dayjs,
  format = "YYYY-MM-DD"
) {
  const date = item.date || item;
  const currentDate = getDateFromFromIsoString(dayjs(date, format), format);
  const previousDate = getDateFromFromIsoString(
    !isNil(endDate) ? endDate : dayjs(),
    format
  );
  const sevenDaysBeforePreviousDate = previousDate
    .subtract(7, "day")
    .endOf("day");

  if (!currentDate.isValid() || !previousDate.isValid()) {
    return false;
  }

  return currentDate.isBetween(sevenDaysBeforePreviousDate, previousDate);
}
