import { get } from "lodash";
import React, { useEffect } from "react";

export function useLoadVideo(
  videoRef?: React.RefObject<HTMLVideoElement>,
  videoUrl?: string
) {
  useEffect(() => {
    const currentVideoUrl = get(videoRef, "current.video.children[0].src");
    if (videoRef?.current && videoUrl && currentVideoUrl !== videoUrl) {
      videoRef.current.load();
    }
  }, [videoRef, videoUrl]);
}
