import { Tabs } from "antd";
import React from "react";
import Scrollable from "../../common/Scrollable";
import { TrialMetadata } from "./TrialMetadata";
import { DiscreteMetricDebugger } from "./DiscreteMetricDebugger";
import { VideoDebugger } from "./VideoDebugger";
import { useSelector } from "react-redux";
import { selectMovement } from "../../../redux/selectors";

const DebugTrials = (props: any) => {
  const { player, trial } = useSelector(selectMovement);
  const tabs = [
    {
      label: "Trial data",
      children: (
        <TrialMetadata
          player={player}
          trial={trial}
          dataRows={[
            { name: "Trial ID", pathOrGetter: "trial.id" },
            { name: "Timestamp", pathOrGetter: "trial.date" },
            { name: "Player name", pathOrGetter: "player.name" },
            { name: "Game Date", pathOrGetter: "trial.gameDate" },
          ]}
          includeCsvRows
        />
      ),
    },
    {
      label: "Discrete metrics",
      children: <DiscreteMetricDebugger trial={trial} />,
    },
    {
      label: "Videos",
      children: <VideoDebugger trial={trial} />,
    },
  ];

  return (
    <Tabs
      items={tabs.map(({ label, children }, index) => {
        const childrenWithProps = {
          ...children,
          props: { ...children.props, ...props },
        };

        return {
          label,
          key: `${label}-${index}`,
          children: <Scrollable>{childrenWithProps}</Scrollable>,
        };
      })}
    />
  );
};
export default DebugTrials;
