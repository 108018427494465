import {
  MutableRefObject,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { World3D } from "../three-js/world3d";
import { findLast, some } from "lodash";
import { useThrottledCallback, useWindowResize } from "beautiful-react-hooks";
import { useTrialsVideoSyncParams } from "./useTrialsVideoSyncParams";

interface Use3DAnimationVideoScrubberProps {
  wrapper: RefObject<HTMLDivElement>;
  videoRefs: MutableRefObject<RefObject<HTMLVideoElement>[]>;
  maxValueScrubb: number;
  onHandleScrubber?: (newCurrentFrame: number, options?: any) => void;
  onHandlePlay?: () => void;
  hideControlsGui?: boolean;
}

export enum Speed {
  FAST = 8,
  NORMAL = 4,
  SLOW = 2,
  SUPER_SLOW = 1,
}
//This variable is needed because of local offsetBR initial value gets captured by the lambda and does not reflect current value
let globalOffsetBR = 0;
let globalExtraMovementsOffsets: any = [];
export interface AnimationViewerData {
  world: World3D;
  handlePlay: () => void;
  handleScrubber: (newCurrentFrame: number, options?: any) => void;
  syncWorldAndVideo: () => void;
  currentFrame: number;
  setCurrentFrame: (frame: number) => void;
  currentSpeed: Speed;
  isPlaying: boolean;
  onNextFrame: () => void;
  onPreviousFrame: () => void;
  onSpeedChange: (speed: Speed) => void;
  onNextKeyframe: () => void;
  onLastKeyframe: () => void;
  keyframesData: any[];
}
function use3DAnimationVideoScrubber({
  wrapper,
  videoRefs,
  maxValueScrubb: mainVideoFrameCount,
  onHandleScrubber,
  onHandlePlay,
  hideControlsGui = false,
}: Use3DAnimationVideoScrubberProps): AnimationViewerData {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentFrame, setCurrentFrame] = useState(0);
  const [currentSpeed, setCurrentSpeed] = useState(Speed.NORMAL);
  const { offsetBR, mainKeyFrames, extraMovementsOffsets } =
    useTrialsVideoSyncParams();

  useEffect(() => {
    globalOffsetBR = offsetBR;
    globalExtraMovementsOffsets = extraMovementsOffsets;
  }, [offsetBR, extraMovementsOffsets]);

  const world = useMemo(
    () =>
      new World3D(
        wrapper,
        (frame: number) => {
          handleScrubber(frame, { skipWorldUpdate: true });
        },
        () => onPauseVideo(),
        hideControlsGui
      ),
    [wrapper]
  );
  useWindowResize(
    useThrottledCallback(
      () => {
        world.resize();
      },
      [world],
      100
    )
  );

  const handleScrubber = useCallback(
    (
      newCurrentFrame: number,
      { skipWorldUpdate }: { skipWorldUpdate?: boolean } = {
        skipWorldUpdate: false,
      }
    ) => {
      let mainVideoDuration = 0;
      let comparativeVideoDuration = 0;
      videoRefs.current.forEach((aVideoRef) => {
        if (aVideoRef.current) {
          const videoIsComparative =
            aVideoRef.current.getAttribute("data-is-comparative") === "true";
          if (videoIsComparative) {
            comparativeVideoDuration = aVideoRef.current.duration;
          } else {
            mainVideoDuration = mainVideoDuration || aVideoRef.current.duration;
          }
          const newCurrentTimeInMainVideo =
            (newCurrentFrame / mainVideoFrameCount) * mainVideoDuration;

          const currentVideoDuration = videoIsComparative
            ? comparativeVideoDuration
            : mainVideoDuration;
          const sourceElement: any = aVideoRef.current.children[0];
          const currentVideoSrc = sourceElement?.src || "";
          const extraVideoOffset = globalExtraMovementsOffsets?.find(
            (it: any) =>
              some(it.videos, (aVideo) => aVideo.videoUrl === currentVideoSrc)
          )?.offsetBR;
          const videoOffsetFrames = isFinite(extraVideoOffset)
            ? extraVideoOffset
            : videoIsComparative
            ? globalOffsetBR
            : 0;
          const videoOffset =
            videoOffsetFrames * (mainVideoDuration / mainVideoFrameCount);

          const syncedTime = newCurrentTimeInMainVideo + videoOffset;
          const cappedTime = Math.max(
            0,
            Math.min(syncedTime, currentVideoDuration)
          );
          if (isFinite(cappedTime)) {
            aVideoRef.current.currentTime = cappedTime;
          }
        }
      });
      if (!skipWorldUpdate) {
        world.updateFrame(newCurrentFrame);
      }
      setCurrentFrame(newCurrentFrame);
      if (onHandleScrubber) {
        onHandleScrubber(newCurrentFrame, { skipWorldUpdate });
      }
    },
    [
      world,
      videoRefs,
      mainVideoFrameCount,
      globalExtraMovementsOffsets,
      globalOffsetBR,
    ]
  );

  const handlePlay = useCallback(() => {
    setIsPlaying((prevState) => !prevState);
    world.togglePlayAnimation();
    if (onHandlePlay) onHandlePlay();
    /* 
    //This could cause some jitteriness, commenting it out for now.
    if (!isPlaying) {
      videoRefs.current.forEach((aVideoRef) => aVideoRef.current?.play());
    } else {
      videoRefs.current.forEach((aVideoRef) => aVideoRef.current?.pause());
    }*/
  }, [world]);

  const onNextKeyframe = useCallback(() => {
    const frame =
      mainKeyFrames
        .map((keyframe) => keyframe.frame)
        .sort((a, b) => a - b)
        .find((frame) => currentFrame < frame) || mainVideoFrameCount;

    handleScrubber(frame);
  }, [mainKeyFrames, currentFrame, handleScrubber, mainVideoFrameCount]);

  const onLastKeyframe = useCallback(() => {
    const sortedFrames = mainKeyFrames
      .map((keyframe) => keyframe.frame)
      .sort((a, b) => a - b);

    const frame =
      findLast(sortedFrames, (frame: number) => currentFrame > frame) || 0;

    handleScrubber(frame);
  }, [mainKeyFrames, currentFrame, handleScrubber]);

  const onSpeedChange = useCallback(
    (speed: Speed) => {
      setCurrentSpeed(speed);
      if (videoRefs.current.length > 0) {
        videoRefs.current.forEach((aVideoRef) => {
          if (aVideoRef.current) aVideoRef.current.playbackRate = speed;
        });
      }
    },
    [videoRefs]
  );

  const onNextFrame = useCallback(() => {
    if (currentFrame < mainVideoFrameCount) {
      world.calculateNextFrame();
      handleScrubber(world.currentFrame, { skipWorldUpdate: true });
    }
  }, [currentFrame, mainVideoFrameCount, world, handleScrubber]);

  const onPreviousFrame = useCallback(() => {
    if (currentFrame > 0) {
      world.previousFrame();
      handleScrubber(world.currentFrame, { skipWorldUpdate: true });
    }
  }, [currentFrame, world]);
  const syncWorldAndVideo = useCallback(() => {
    handleScrubber(world.currentFrame);
  }, [world, handleScrubber]);

  const onPauseVideo = useCallback(() => {
    videoRefs.current.forEach((aVideoRef) => {
      if (aVideoRef.current) {
        aVideoRef.current.pause();
        aVideoRef.current.currentTime = 0;
      }
    });
    setIsPlaying(false);
  }, [videoRefs]);

  return {
    world,
    handlePlay,
    handleScrubber,
    syncWorldAndVideo,
    currentFrame,
    setCurrentFrame,
    currentSpeed,
    isPlaying,
    onNextFrame,
    onPreviousFrame,
    onSpeedChange,
    onNextKeyframe,
    onLastKeyframe,
    keyframesData: mainKeyFrames,
  };
}

export { use3DAnimationVideoScrubber };
