import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LOAD_STATUS } from "../../constants";

const initialState = {
  teamInfo: {
    loadStatus: LOAD_STATUS.UNINITIALIZED,
    data: {},
    errorMsg: "",
  },
  proTeams: {
    loadStatus: LOAD_STATUS.UNINITIALIZED,
    data: {},
    errorMsg: "",
  },
};

export const getTeamDataById = createAsyncThunk(
  "team/getTeamDataById",
  async (args) => {
    const { skylineApi, teamId } = args || {};
    const response = await skylineApi.get(`/api/teams/singleTeam/${teamId}`);
    return response.data;
  }
);

export const allProTeams = createAsyncThunk(
  "team/allProTeams",
  async (args) => {
    const { skylineApi, showInactive } = args || {};
    const fullEndpoint =
      `/api/teams/allProTeams` + (showInactive ? "?showInactive=true" : "");
    const response = await skylineApi.get(fullEndpoint);
    return response.data;
  }
);

const teamSlice = createSlice({
  name: "team",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getTeamDataById.pending, (state) => {
        state.teamInfo.loadStatus = LOAD_STATUS.LOADING;
      })
      .addCase(getTeamDataById.fulfilled, (state, action) => {
        state.teamInfo.data = action.payload;
        state.teamInfo.loadStatus = LOAD_STATUS.READY;
      })
      .addCase(getTeamDataById.rejected, (state, action) => {
        state.teamInfo.loadStatus = LOAD_STATUS.ERROR;
        state.teamInfo.errorMsg = action.error.message;
      })
      .addCase(allProTeams.pending, (state) => {
        state.proTeams.loadStatus = LOAD_STATUS.LOADING;
      })
      .addCase(allProTeams.fulfilled, (state, action) => {
        state.proTeams.data = action.payload;
        state.proTeams.loadStatus = LOAD_STATUS.READY;
      })
      .addCase(allProTeams.rejected, (state, action) => {
        state.proTeams.loadStatus = LOAD_STATUS.ERROR;
        state.proTeams.errorMsg = action.error.message;
      });
  },
});

export default teamSlice.reducer;
