import { isEmpty } from "lodash";
import * as THREE from "three";
const DEFAULT_SCALE = 10;

export class BatterMiddleJoint3D {
  constructor(
    model,
    {
      jointCoordinates,
      name,
      scaleSeg,
      rotationExtra,
      distalName,
      positionWeight,
      scale,
    }
  ) {
    // Filtering possible empty joint coordinates to avoid app crashes.
    const filteredJointCoordinates = jointCoordinates.filter(
      (it) => !isEmpty(it)
    );

    this.segment = model;
    Object.assign(this, {
      jointCoordinates: filteredJointCoordinates,
      name,
      scaleSeg,
      rotationExtra,
      distalName,
      positionWeight,
      scale: scale || DEFAULT_SCALE,
    });
    this.rotationCache = {};
    this.positionCache = {};
  }

  render(currentFrame) {
    //this.jointCoordinates[1].add(new THREE.Vector3(0,1,5));//) = this.jointCoordinates[1] + shift;
    //currentFrame = currentFrame+1;
    if (this.segment) {
      this.segment.scale.set(this.scaleSeg, this.scaleSeg, this.scaleSeg);
      const drawableFrame = Math.min(
        this.jointCoordinates.length - 2,
        currentFrame
      );
      var { euler, dcm, rotMatSeg, rotMat2Seg, rotMat3Seg } =
        this.segmentRotation(drawableFrame);
      this.segment.rotation.copy(euler);
      // draw helper axes on this.segment

      // get distal this.segment position
      let positionMiddle = this.segmentPosition(
        drawableFrame,
        dcm,
        rotMatSeg,
        rotMat2Seg,
        rotMat3Seg
      );

      this.segment.position.copy(positionMiddle);
    }
  }

  segmentPosition(drawableFrame, dcm, rotMatSeg, rotMat2Seg, rotMat3Seg) {
    /*if (this.positionCache[drawableFrame]) {
      return this.positionCache[drawableFrame];
    }*/
    let dcmDistal =
      this.jointCoordinates[drawableFrame][this.distalName].elements;
    var matDistal = new THREE.Matrix4();
    matDistal.set(
      dcmDistal[0],
      dcmDistal[1],
      dcmDistal[2],
      0,
      dcmDistal[4],
      dcmDistal[5],
      dcmDistal[6],
      0,
      dcmDistal[8],
      dcmDistal[9],
      dcmDistal[10],
      0,
      0,
      0,
      1
    );
    matDistal.multiply(rotMatSeg);
    matDistal.multiply(rotMat2Seg);
    matDistal.multiply(rotMat3Seg);

    //console.log(dcm);
    let positionDistal = new THREE.Vector3();
    var position = new THREE.Vector3();
    let rotMat = new THREE.Matrix4();
    let rotMat2 = new THREE.Matrix4();
    let rotMat3 = new THREE.Matrix4();
    rotMat.makeRotationX((-0 * Math.PI) / 2); //-90
    rotMat2.makeRotationZ((Math.PI / 180) * 180);
    rotMat3.makeRotationY(0 * Math.PI * 2);
    position.set(
      dcm[3] * this.scale,
      dcm[7] * this.scale,
      dcm[11] * this.scale
    );
    positionDistal.set(
      dcmDistal[3] * this.scale,
      dcmDistal[7] * this.scale,
      dcmDistal[11] * this.scale
    );

    let positionMiddle = new THREE.Vector3();
    let pw1 = this.positionWeight;
    let pw2 = 1 - pw1;
    positionMiddle.set(
      position.x * pw1 + positionDistal.x * pw2,
      position.y * pw1 + positionDistal.y * pw2,
      position.z * pw1 + positionDistal.z * pw2
    );

    this.positionCache[drawableFrame] = positionMiddle;

    return positionMiddle;
  }

  segmentRotation(drawableFrame) {
    /*if (this.rotationCache[drawableFrame]) {
      return this.rotationCache[drawableFrame];
    }*/
    var euler = new THREE.Euler();
    var dcm = this.jointCoordinates[drawableFrame][this.name].elements;
    var mat = new THREE.Matrix4();
    let rotMatSeg = new THREE.Matrix4();
    let rotMat2Seg = new THREE.Matrix4();
    let rotMat3Seg = new THREE.Matrix4();
    let angleX = 180 + this.rotationExtra.x;
    let angleY = 0 + this.rotationExtra.y;
    let angleZ = 180 + this.rotationExtra.z;
    rotMatSeg.makeRotationX((angleX * Math.PI) / 180); //0*Math.PI/2);//-90
    rotMat2Seg.makeRotationZ((angleZ * Math.PI) / 180); //-Math.PI/180*(180));
    rotMat3Seg.makeRotationY((angleY * Math.PI) / 180); //0*Math.PI);
    mat.set(
      dcm[0],
      dcm[1],
      dcm[2],
      0,
      dcm[4],
      dcm[5],
      dcm[6],
      0,
      dcm[8],
      dcm[9],
      dcm[10],
      0,
      0,
      0,
      1
    );
    mat.transpose();
    mat.multiply(rotMat2Seg);
    mat.multiply(rotMatSeg);
    mat.multiply(rotMat3Seg);
    euler.setFromRotationMatrix(mat);
    return { euler, dcm, rotMatSeg, rotMat2Seg, rotMat3Seg };
  }
}
