import React from "react";
import {
  TrialDataRow,
  TrialMetadata,
} from "../Metrics/BiomechanicsSections/TrialMetadata";
import { useSelector } from "react-redux";
import { selectMovement } from "../../redux/selectors";
import { Grid } from "@mui/material";

export default function MatchupDetails({
  dataRows,
}: {
  dataRows?: TrialDataRow[];
}) {
  const { player, trial } = useSelector(selectMovement);
  return (
    <Grid container>
      <Grid item xs={12}>
        <TrialMetadata
          player={player}
          trial={trial}
          dataRows={dataRows || []}
        />
      </Grid>
    </Grid>
  );
}
