// names from the design sheet.
export const palette = {
  bluePrint: "#1A4A89",
  digitalOrange: "#FF5910",
  activeDigitalBlue: "#004DBF",
  hoveredGrey: "#93A0A3",
  activeGrey: "#373C40",
  hoveredDigitalOrange: "#EE6644",
  activeDigitalOrange: "#E82D02",
  lightOrange: "#FFD591",
  lighterOrange: "#ffe7ba",
  digitalBlue: "#002D72",
  geekBlue: "#D6E4FF",
  white: "#FFFFFF",
  black: "#000000",
  green: "#389E0D",
  red: "#F5222D",
  neutral: "#F0F0F0",
  cbHigh: "#0000FF",
  pdGreen: "#f6ffed",
  pdRed: "#fff1f0",
};

export default palette;
