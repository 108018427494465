import { useOktaAuth } from "../common/useOktaAuth";
import { selectOktaInfo } from "../Redux/selectors";
import { skylineApiClient } from "../common/skylineApiClient";

const useApiCredentials = () => {
  const { apiToken } = selectOktaInfo(useOktaAuth());
  return { apiToken };
};

/**
 * @returns an api client obj with get and post functions that, when you use it, automatically includes api creds
 */
const useSkylineApi = () => {
  const { apiToken } = useApiCredentials();
  return skylineApiClient(apiToken);
};

export default useSkylineApi;
