import { DataPoint } from "regression";
import {
  DateIndexingValue,
  InningSplitValue,
} from "../../services/performanceApi.service";
import dayjs from "dayjs";
import { fixDateValueIndex, getDateValue } from "../../utils/charts";

export enum ExpandedMetricsDataIndexing {
  TestNumber = "Test No. Indexing",
  Date = "Date Indexing",
  AllPitches = "All Pitches",
  InningSplit = "Inning Split",
}

export type DataIndexingValue =
  | DataPoint
  | DateIndexingValue
  | InningSplitValue;

export function dataIndexingValueToDataPoint(
  value: DataIndexingValue
): DataPoint {
  // All pitches
  if (Array.isArray(value)) {
    return value;
  }

  if ("inning" in value) {
    // Inning split
    return [value.inning, value.value];
  } else {
    // Date indexing
    return [dayjs(value.date, "YYYY-MM-DD").valueOf(), value.value];
  }
}

export function dataIndexingValueToXCoordinate(
  value: DataIndexingValue
): number {
  return dataIndexingValueToDataPoint(value)[0];
}

export function formatDataIndexingXCoordinate(value: DataIndexingValue) {
  // All pitches
  if (Array.isArray(value)) {
    return value[0].toString();
  }

  if ("inning" in value) {
    // Inning split
    return value.inning.toString();
  } else {
    // Date indexing
    return dayjs(value.date, "YYYY-MM-DD").format("MM/DD");
  }
}

export const formatSecondarySeriesXAxis = (
  mainData: any[],
  secondaryData: any[],
  isDateIndexing: boolean
) =>
  secondaryData.map((it: any) => ({
    ...it,
    x: isDateIndexing
      ? it.x
      : fixDateValueIndex(
          mainData.findIndex(
            (element) => getDateValue(element?.date) === getDateValue(it?.x)
          )
        ),
  }));
