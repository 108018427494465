type HydrationDictionary = { [key: string]: string };

export const getIsHydration = (metric: any) =>
  isHydrationMetric(metric?.metric);

export const isHydrationMetric = (metricId: string) =>
  metricId === "bcmp_hydration_color";

const findInDictionaryOrDefault = (
  key: string,
  dictionary: HydrationDictionary
) => {
  if (Object.keys(dictionary).includes(key)) {
    return dictionary[key];
  }

  return "";
};

const hydrationColors: HydrationDictionary = {
  Black: "#0d0d0d",
  Red: "#f02c1f",
  Yellow: "#f0ef4b",
  Green: "#3ba837",
  Blue: "#2f57bd",
};

export const getHydrationColor = (color: string) =>
  findInDictionaryOrDefault(color, hydrationColors);

const hydrationLabels: HydrationDictionary = {
  Black: "Did not test",
  Red: "Significantly Dehydrated",
  Yellow: "Dehydrated",
  Green: "Well Hydrated",
  Blue: "Over Hydrated",
};

export const getHydrationLabel = (value: string) =>
  findInDictionaryOrDefault(value, hydrationLabels);

const hydrationAxisValues: HydrationDictionary = {
  Black: "0",
  Red: "1",
  Yellow: "2",
  Green: "3",
  Blue: "4",
};

export const getHydrationAxisValues = (value: string) =>
  Number(findInDictionaryOrDefault(value, hydrationAxisValues));

export const getHydrationValueByAxis = (value: string) =>
  Object.keys(hydrationAxisValues).find(
    (key) => hydrationAxisValues[key] === value
  ) || "";
