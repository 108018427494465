import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LOAD_STATUS, PLAYER_TYPE } from "../../constants";
import { AcquisitionGroup } from "../../Scouting/scoutingUtils";

const { DOMESTIC_PROFESSIONAL, INTERNATIONAL_PROFESSIONAL } = AcquisitionGroup;

const initialState = {
  bio: {
    loadStatus: LOAD_STATUS.UNINITIALIZED,
    data: {},
    errorMsg: "",
  },
  playerTypeFocus: "",
  playerFiles: {
    loadStatus: LOAD_STATUS.UNINITIALIZED,
    data: [],
    errorMsg: "",
  },
  isProPlayer: false,
  statCategories: {
    loadStatus: LOAD_STATUS.UNINITIALIZED,
    data: [],
    errorMsg: "",
  },
  pdPlan: {
    loadStatus: LOAD_STATUS.UNINITIALIZED,
    data: {},
    errorMsg: "",
  },
};

export const getPlayerPdPlan = createAsyncThunk(
  "pdPlan/getPdPlanByPlayerId",
  async (args) => {
    const { skylineApi, playerId } = args || {};
    const response = await skylineApi.get(`/api/pdPlans/${playerId}`);
    return response.data;
  }
);

export const getPlayerById = createAsyncThunk(
  "player/getPlayerById",
  async (args) => {
    const { skylineApi, displayId, metsId } = args || {};
    const url = !!displayId
      ? `/api/players/${displayId}`
      : `/api/players/metsId/${metsId}`;
    const response = await skylineApi.get(url);
    return response.data;
  }
);

export const getPlayerFiles = createAsyncThunk(
  "player/getPlayerFiles",
  async (args) => {
    const { skylineApi, playerId } = args || {};
    const url = `/api/playerFiles/player/${playerId}`;
    const response = await skylineApi.get(url);
    return response.data.map((pf) => {
      const uploader =
        pf.FileUploader?.firstName || pf.FileUploader?.lastName
          ? `${pf.FileUploader.firstName} ${pf.FileUploader.lastName}`
          : pf.createdBy;
      return {
        ...pf,
        uploader,
      };
    });
  }
);

// getStatCategoriesById fetches the unique stat categories for a given player (indicated by metsId)
export const getStatCategoriesById = createAsyncThunk(
  "stats/stat_categories",
  async (args) => {
    const { skylineApi, metsId, levels } = args || {};
    const response = await skylineApi.get(
      `/api/stats/stat_categories/${metsId}?levels=${levels?.join(",")}`
    );
    return response.data;
  }
);

/* TODO: when we lock down a schema and create
 * real db entities for Rocks and goals, we should
 * refactor these manual ID creations, and let the db do the work */
const getHighestId = (array) => {
  // find the maximum of all `id` properties from the given array
  // The `?? 0` ensures that undefined values become numeric,
  // or else we'd end up with NaN
  return array?.length ? Math.max(...array.map((i) => i.id ?? 0)) : 0;
};

/**
 * 2023/03/28 - BM Notes:
 * Updating a large nested object containing
 * nested arrays can get kind of hairy but I tried
 * to stick to Redux recommendations:
 * https://redux.js.org/usage/structuring-reducers/immutable-update-patterns/
 */
const playerSlice = createSlice({
  name: "player",
  initialState,
  reducers: {
    setPlayerTypeFocus: (state, action) => {
      return {
        ...state,
        playerTypeFocus: action.payload,
      };
    },
    /**
     * Resets the PDPlan state to discard local changes
     * @param {} state
     * @returns
     */
    resetPdPlan: (state) => {
      return {
        ...state,
        pdPlan: initialState.pdPlan,
      };
    },
    /**
     * Adds a new Rock to the current pdPlan.
     * TODO: when we lock down a schema and create
     * real db entities for Rocks and goals, we should
     * refactor these manual ID creations, and let the db do the work
     * @param {} state
     * @param {} action - payload can be empty
     * @returns updated state with a newly appended Rock
     */
    addNewPdPlanRock: (state, action) => {
      return {
        ...state,
        pdPlan: {
          ...state.pdPlan,
          data: {
            ...state.pdPlan.data,
            pdPlan: {
              ...state.pdPlan.data.pdPlan,
              rocks: [
                ...(state.pdPlan.data.pdPlan?.rocks || []),
                {
                  id: getHighestId(state.pdPlan.data.pdPlan?.rocks) + 1,
                  priority: action.payload?.priority || null,
                  category: action.payload?.category,
                  title: `${action.payload?.category} Rock`,
                  specification: null,
                  goals: [],
                  visible_to_players: false,
                  is_archived: false,
                },
              ],
            },
          },
        },
      };
    },
    /**
     * Finds and overwrites a Rock in the current pdPlan
     * @param {} state
     * @param {} action - payload is a Rock object like:
     * {
     * id: number,
     * category: string,
     * specification: string,
     * visible_to_players: bool,
     * title: string,
     * is_archived: bool,
     * goals: array,
     * priority: string,
     * }
     * @returns
     */
    updatePdPlanRock: (state, action) => {
      return {
        ...state,
        pdPlan: {
          ...state.pdPlan,
          data: {
            ...state.pdPlan.data,
            pdPlan: {
              ...state.pdPlan.data.pdPlan,
              rocks: state.pdPlan.data.pdPlan?.rocks?.map((rock) => {
                if (rock.id === action.payload?.id) {
                  return action.payload;
                }
                return rock;
              }),
            },
          },
        },
      };
    },
    /**
     * Finds a Rock in the current pdPlan and appends a new Goal
     * @param {} state
     * @param {} action - payload is an object:
     * {
     * rockId: number,
     * id: number,
     * definition: string,
     * why: string,
     * how: string,
     * is_archived: bool,
     * metrics: array,
     * focus: string,
     * }
     * @returns
     */
    addNewPdPlanGoal: (state, action) => {
      return {
        ...state,
        pdPlan: {
          ...state.pdPlan,
          data: {
            ...state.pdPlan.data,
            pdPlan: {
              ...state.pdPlan.data.pdPlan,
              rocks: state.pdPlan.data.pdPlan?.rocks?.map((rock) => {
                // if this is the rock we're looking for...
                if (rock.id === action.payload?.rockId) {
                  return {
                    ...rock,
                    // ...then append a new goal
                    goals: [
                      ...(rock.goals || []),
                      {
                        id: getHighestId(rock?.goals) + 1,
                        rockId: rock.id,
                        definition: "",
                        why: "",
                        how: "",
                        metrics: [],
                        focus: "",
                        is_archived: false,
                      },
                    ],
                  };
                }
                // otherwise, make no modifications
                return rock;
              }),
            },
          },
        },
      };
    },
    /**
     * Finds and overwrites a specific Rock's Goal in the current pdPlan
     * @param {} state
     * @param {} action - payload is a Goal object like:
     * {
     * rockId: number,
     * id: number,
     * definition: string,
     * why: string,
     * how: string,
     * is_archived: bool,
     * metrics: array,
     * focus: string,
     * }
     * @returns
     */
    updatePdPlanGoal: (state, action) => {
      return {
        ...state,
        pdPlan: {
          ...state.pdPlan,
          data: {
            ...state.pdPlan.data,
            pdPlan: {
              ...state.pdPlan.data.pdPlan,
              rocks: state.pdPlan.data.pdPlan?.rocks?.map((rock) => {
                // if this is the rock we're looking for...
                if (rock.id === action.payload?.rockId) {
                  return {
                    ...rock,
                    goals: rock.goals.map((goal) => {
                      // ...and if this is the goal we're looking for...
                      if (goal.id === action.payload?.id) {
                        // ...overwrite it with the payload
                        return action.payload;
                      }
                      // otherwise keep the goal unmodified
                      return goal;
                    }),
                  };
                }
                // otherwise keep the rock unmodified
                return rock;
              }),
            },
          },
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPlayerPdPlan.pending, (state) => {
        state.pdPlan.loadStatus = LOAD_STATUS.LOADING;
      })
      .addCase(getPlayerPdPlan.fulfilled, (state, action) => {
        state.pdPlan.data = action.payload;
        state.pdPlan.loadStatus = LOAD_STATUS.READY;
      })
      .addCase(getPlayerPdPlan.rejected, (state, action) => {
        state.pdPlan.loadStatus = LOAD_STATUS.ERROR;
        state.pdPlan.errorMsg = action.error.message;
      })
      .addCase(getPlayerFiles.pending, (state) => {
        state.playerFiles.loadStatus = LOAD_STATUS.LOADING;
      })
      .addCase(getPlayerFiles.fulfilled, (state, action) => {
        state.playerFiles.data = action.payload;
        state.playerFiles.loadStatus = LOAD_STATUS.READY;
      })
      .addCase(getPlayerFiles.rejected, (state, action) => {
        state.playerFiles.loadStatus = LOAD_STATUS.ERROR;
        state.playerFiles.errorMsg = action.error.message;
      })
      .addCase(getPlayerById.pending, (state) => {
        state.bio.loadStatus = LOAD_STATUS.LOADING;
      })
      .addCase(getPlayerById.fulfilled, (state, action) => {
        const primaryPosition = (state.playerTypeFocus =
          action.payload.primaryPosition?.toLowerCase());

        state.playerTypeFocus =
          primaryPosition === "p" ||
          primaryPosition === "sp" ||
          primaryPosition === "rp" ||
          primaryPosition === "twp"
            ? PLAYER_TYPE.PITCHER
            : PLAYER_TYPE.POSITION_PLAYER;

        state.bio.data = action.payload;

        const hasProAcquisitionGroup = [
          DOMESTIC_PROFESSIONAL,
          INTERNATIONAL_PROFESSIONAL,
        ].includes(action.payload.acquisitionGroup);

        state.isProPlayer = hasProAcquisitionGroup;
        state.acquisitionGroup = action.payload.acquisitionGroup;

        state.bio.loadStatus = LOAD_STATUS.READY;
      })
      .addCase(getPlayerById.rejected, (state, action) => {
        state.bio.loadStatus = LOAD_STATUS.ERROR;
        state.bio.errorMsg = action.error.message;
      })
      .addCase(getStatCategoriesById.pending, (state) => {
        state.statCategories.loadStatus = LOAD_STATUS.LOADING;
      })
      .addCase(getStatCategoriesById.fulfilled, (state, action) => {
        state.statCategories.data = action.payload;
        state.statCategories.loadStatus = LOAD_STATUS.READY;
      })
      .addCase(getStatCategoriesById.rejected, (state, action) => {
        state.statCategories.loadStatus = LOAD_STATUS.ERROR;
        state.statCategories.data = [];
        state.statCategories.errorMsg = action.error.message;
      });
  },
});
export const {
  setPlayerTypeFocus,
  addNewPdPlanRock,
  updatePdPlanRock,
  addNewPdPlanGoal,
  updatePdPlanGoal,
  resetPdPlan,
} = playerSlice.actions;

export default playerSlice.reducer;
