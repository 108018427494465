import React from "react";
import { useFetchTrendsDiscreteAllPitchesQuery } from "../../services/performanceApi.service";
import { GradientLineChart } from "../charts";
import { toSeries } from "../../utils/charts";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Stack } from "@mui/material";
import TrendsInfoTable from "./TrendsInfoTable";
import { TrendsDiscreteMetricChartProps } from "./DiscreteMetric";
import useSplitRegressions from "./useSplitRegressions";

export type AllPitchesChartProps = TrendsDiscreteMetricChartProps & {
  containerWidth: any;
};

export default function AllPitchesChart({
  metric,
  containerWidth,
  dataComp,
  timePeriod,
}: AllPitchesChartProps) {
  const { data } = useFetchTrendsDiscreteAllPitchesQuery(
    metric?.id
      ? {
          metricId: metric?.id,
          timePeriod,
        }
      : skipToken
  );

  const { splitRegression1, splitRegression2, onApply, onReset } =
    useSplitRegressions(data);

  return (
    <Stack>
      <GradientLineChart
        yAxis={{ title: metric?.label || "" }}
        width={containerWidth}
        data={data?.values}
        secondaryData={toSeries(data?.dataComp, dataComp)}
        // Regressions returns readonly DataPoint[] and `readonly` keyword throws syntax error
        // @ts-expect-error
        regressionsData={data?.regression?.points ?? []}
        // Regressions returns readonly DataPoint[] and `readonly` keyword throws syntax error
        // @ts-expect-error
        splitRegression1Data={splitRegression1?.result?.points}
        // Regressions returns readonly DataPoint[] and `readonly` keyword throws syntax error
        // @ts-expect-error
        splitRegression2Data={splitRegression2?.result?.points}
        isDataCompare={dataComp}
      />
      <TrendsInfoTable
        data={data}
        onApply={onApply}
        onReset={onReset}
        countLabel="Pitches"
        splitRegression1={splitRegression1}
        splitRegression2={splitRegression2}
      />
    </Stack>
  );
}
