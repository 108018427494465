import React from "react";
import { Grid } from "@mui/material";
import { Switch } from "antd";
import Selector from "./Selector";
import "./MetricSelector.css";

interface MetricSelectorProps {
  value: any;
  onChange: (param?: any) => void;
  options: any[];
  getOptionLabel?: (option: any) => string;
  isOptionEqualToValue?: (option: any, value: any) => boolean;
  checked: boolean;
  setChecked: (value: boolean) => void;
  color?: string;
  width?: string;
}

const MetricSelector = ({
  value,
  onChange,
  options,
  getOptionLabel,
  isOptionEqualToValue,
  checked,
  setChecked,
  color,
  width,
}: MetricSelectorProps) => {
  return (
    <Grid container alignItems="center" p={1}>
      <Grid item xs>
        <Selector
          className="ptd-metric-selector"
          value={value}
          label={value?.name || ""}
          onChange={onChange}
          options={options}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={isOptionEqualToValue}
          color={`var(--${color})`}
          isMetricSelector
          width={width}
        />
      </Grid>

      <Grid item xs="auto" pl={2}>
        <Switch
          checked={checked}
          onClick={setChecked}
          style={checked ? { backgroundColor: `var(--${color})` } : {}}
        />
      </Grid>
    </Grid>
  );
};

export default MetricSelector;
