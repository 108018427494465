import React, { useCallback, useEffect, useMemo, useState } from "react";
import LineChart from "../../charts/LineChart";
import MetricMenuSelector from "../../common/MetricMenuSelector";
import LegendItem from "../../common/LegendItem";
import { Grid } from "@mui/material";
import {
  replace,
  toMetric,
  metricsLimit,
  basicFrame,
  toPlotLineMetrics,
  addIndex,
  filterByCurrentMetrics,
  addTrialIndex,
  formatSelfData,
  shouldFetchSelfCompData,
} from "../../../utils/metrics";
import {
  useFetchMetricsQuery,
  useFetchTimeSeriesDataCompQuery,
  useFetchTimeSeriesDataQuery,
  useFetchTimeSeriesSelfDataMultiQuery,
  useFetchTrialKeyframesQuery,
} from "../../../services/performanceApi.service";
import { useDispatch, useSelector } from "react-redux";
import {
  selectMovement,
  selectIsSelfCompare,
  selectIsDataCompare,
  selectComparingMovement,
  selectTimeSeriesMetrics,
  selectSelfCompOptions,
  selectSelectedMotionType,
  selectIsSecondarySelfCompare,
  selectSecondarySelfCompOptions,
} from "../../../redux/selectors";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import useFramesData from "../../../hooks/useFramesData";
import { first, isEmpty } from "lodash";
import { setTimeSeriesMetrics } from "../../../redux/movementSlice";
import { AddAndRemoveControls } from "./AddAndRemoveControls";
import { getIsPitching } from "../../../utils/motionType";

interface TimeSeriesProps {
  parentHeight?: number;
}

const METRIC = "mKHShort";
const BATTING_METRIC = "MHH";
const METRICS_TO_REMOVE: string[] = [
  "Glove Arm Rotation",
  "Lead Leg Rotation",
  "Lead Wrist Ulnar Deviation",
  "Lead Wrist Pronation",
  "Back Knee Valgus",
  "Lead Knee Valgus",
  "Glove Arm Yaw Velocity",
  "Glove Arm Pitch/Elevation Velocity",
  "Glove Arm Total Angular Velocity",
  "Throwing Shoulder Rotation Energy",
  "Throwing Elbow Flexion Energy",
  "Throwing Wrist Flexion Energy",
];

const colors = {
  primary: "borderBlue",
  secondary: "activeDigitalOrange",
};

const dashStyles = {
  primary: "Solid",
  secondary: "longDash",
};

const TimeSeries = (props: TimeSeriesProps) => {
  const dispatch = useDispatch();
  const isSelfCompare = useSelector(selectIsSelfCompare);
  const isSecondarySelfCompare = useSelector(selectIsSecondarySelfCompare);
  const isDataCompare = useSelector(selectIsDataCompare);
  const { trial, player } = useSelector(selectMovement);
  const { trial: comparingTrial } = useSelector(selectComparingMovement);
  const motionType = useSelector(selectSelectedMotionType);
  const { data: menuItems } = useFetchMetricsQuery({
    metricId: getIsPitching(motionType) ? METRIC : BATTING_METRIC,
    pitch: trial,
    motionType,
  });
  const { data: compData = [] } = useFetchTimeSeriesDataCompQuery(
    player?.id ? { motionType } : skipToken
  );
  const { lastFrameValue, toMillisecondsFromBrOffset, syncTrials } =
    useFramesData();
  const { data = [], isLoading } = useFetchTimeSeriesDataQuery({
    trial,
    motionType,
  });
  const { data: comparingData = [] } = useFetchTimeSeriesDataQuery({
    trial: comparingTrial,
    motionType,
  });

  const { data: keyframesData = [] } = useFetchTrialKeyframesQuery(
    trial?.id ? { trial, motionType } : skipToken
  );
  const metrics: TimeSeriesMetric[] = useSelector(selectTimeSeriesMetrics);
  const setMetrics = (value: any) => {
    dispatch(setTimeSeriesMetrics(value));
  };
  const [compareMetrics, setCompareMetrics] = useState<TimeSeriesMetric[]>([]);
  const selfCompOptions = useSelector(selectSelfCompOptions);
  const secondarySelfCompOptions = useSelector(selectSecondarySelfCompOptions);
  const { data: selfDataAllMetrics } = useFetchTimeSeriesSelfDataMultiQuery(
    !isEmpty(metrics) && shouldFetchSelfCompData(player, selfCompOptions)
      ? {
          player,
          metricIds: metrics.map((it) => it.id),
          selfCompOptions,
        }
      : skipToken
  );
  const { data: secondarySelfDataAllMetrics } =
    useFetchTimeSeriesSelfDataMultiQuery(
      !isEmpty(metrics) &&
        shouldFetchSelfCompData(player, secondarySelfCompOptions)
        ? {
            player,
            metricIds: metrics.map((it) => it.id),
            selfCompOptions: secondarySelfCompOptions,
          }
        : skipToken
    );

  const comp = isDataCompare
    ? filterByCurrentMetrics(compData, metrics).map((it) => ({
        ...it,
        color: colors.primary,
      }))
    : [];

  const filterNoDataLabel = useCallback(
    (object: any) => {
      if (object?.children) {
        const firstChildrenObject = object?.children
          .map((firstChildren: any) => {
            if (firstChildren?.children) {
              const secondChildrenObject = firstChildren?.children.filter(
                (secondChildren: any) => {
                  return data.some(
                    (metric) =>
                      metric.id.includes(secondChildren.key) &&
                      !METRICS_TO_REMOVE.includes(secondChildren.label)
                  );
                }
              );
              return { ...firstChildren, children: secondChildrenObject };
            }
          })
          .filter(Boolean);
        return { ...object, children: firstChildrenObject };
      }
    },
    [data]
  );

  const removeEmptyObject = (data: any) => {
    return data
      ?.map((node: any) => {
        if (node.children) {
          const children = removeEmptyObject(node.children);
          if (children.length === 0) {
            return undefined;
          }
          return { ...node, children };
        }
        return node;
      })
      ?.filter(Boolean);
  };

  const items = useMemo(() => {
    const filteredData = menuItems?.map(filterNoDataLabel);
    return removeEmptyObject(filteredData);
  }, [menuItems, data]);
  useEffect(() => {
    if (!isLoading && data) {
      if (isEmpty(metrics)) {
        setMetrics([toMetric(data[0])]);
      } else {
        const newMetrics = metrics.map((it) =>
          toMetric(data.find((timeSeries) => timeSeries.id === it.id))
        );
        setMetrics(newMetrics);
      }
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (!comparingTrial && !isEmpty(compareMetrics)) {
      setCompareMetrics([]);
    }

    if (!isEmpty(comparingData)) {
      const mappedMetrics = metrics.map((it, index) => {
        const current = comparingData.find((metric) => metric.id === it.id);

        return toMetric(
          !current
            ? { data: [] }
            : {
                ...current,
                data: syncTrials(current.data),
                isSecondary: true,
                visible: metrics[index].visible,
              }
        );
      });

      setCompareMetrics(mappedMetrics);
    }
  }, [comparingData, comparingTrial, metrics]);

  const fixedKeyFrames = toPlotLineMetrics([
    basicFrame(0),
    ...keyframesData,
    basicFrame(lastFrameValue),
  ]).map((it) => ({
    ...it,
    value: toMillisecondsFromBrOffset(it.value),
  }));

  const canAddMetric = metrics.length < metricsLimit;

  const onChangeMetric = (metricId: string, index: number) => {
    const metric = data.find((metricValue: any) =>
      metricValue?.id?.includes(metricId)
    );

    updateMetric(metric, index);
  };

  const updateMetric = (value: any, index: number) => {
    setMetrics(replace(metrics, index, toMetric(value)));
  };

  const addMetric = () => {
    setMetrics([...metrics, toMetric(data[0])]);
  };

  const removeMetric = () => {
    setMetrics(metrics.filter((_, index) => index !== metrics.length - 1));
  };

  const setColorAndDashToMetric = (metrics: any, color: string) =>
    metrics.map((metric: any, index: number) => ({
      ...metric,
      color,
      dashStyle: dashStyles[index === 0 ? "primary" : "secondary"],
    }));

  const coloredMetrics = useMemo(
    () => setColorAndDashToMetric(metrics, colors.primary),
    [metrics]
  );
  const coloredCompareMetrics = useMemo(
    () => setColorAndDashToMetric(compareMetrics, colors.secondary),
    [compareMetrics]
  );

  const visibleColoredMetrics = coloredMetrics.filter((it: any) => it.visible);
  const visibleColoredCompareMetrics = coloredCompareMetrics.filter(
    (it: any) =>
      it.visible && visibleColoredMetrics.some((main: any) => main.id === it.id)
  );
  const lineChartData = [
    ...addIndex(addTrialIndex(visibleColoredMetrics, 1)),
    ...addIndex(
      addTrialIndex(
        visibleColoredCompareMetrics.map((it: any) => ({
          ...it,
          id: `compare_${it.id}`,
        })),
        2
      )
    ),
  ];

  const mappedPrimarySelfData =
    selfDataAllMetrics?.map((selfData, i) => {
      if (
        isSelfCompare &&
        !isEmpty(selfData?.data) &&
        !isEmpty(lineChartData)
      ) {
        const current = lineChartData.find((it) => it?.id === selfData?.id);

        return first(
          formatSelfData({
            metric: current || selfData,
            data: syncTrials(selfData?.data, selfData?.brFrame),
            visible: isSelfCompare && current?.visible,
            dashStyle: i === 0 ? "Solid" : "longDash",
          })
        );
      }
    }) || [];

  const mappedSecondarySelfData =
    secondarySelfDataAllMetrics?.map((selfData) => {
      if (
        isSecondarySelfCompare &&
        !isEmpty(selfData?.data) &&
        !isEmpty(lineChartData)
      ) {
        const current = lineChartData.find((it) => it?.id === selfData?.id);

        return first(
          formatSelfData({
            metric: current || selfData,
            data: syncTrials(selfData?.data, selfData?.brFrame),
            visible: isSecondarySelfCompare && current?.visible,
            dashStyle: "longDash",
          })
        );
      }
    }) || [];

  const self = [...mappedPrimarySelfData, ...mappedSecondarySelfData];

  const secondaryData = [...comp, ...self];

  const legendMetrics: any[] = comparingTrial?.id
    ? [
        {
          label: "Trial 1",
          icon: "solid",
          color: coloredMetrics[0]?.color,
        },
        {
          label: "Trial 2",
          icon: "solid",
          color: coloredCompareMetrics[0]?.color,
        },
      ]
    : [];

  return (
    <Grid container flexDirection="column">
      <Grid container item flexDirection="row">
        <Grid xs={10} container item flexDirection="row" spacing={1}>
          {coloredMetrics.map((metric: any, index: number) => (
            <Grid item xs={6} key={"metric-selector-" + index}>
              <MetricMenuSelector
                value={metric}
                onChange={(key) => {
                  onChangeMetric(key, index);
                }}
                checked={metric.visible}
                setChecked={(value: boolean) => {
                  updateMetric({ ...metric, visible: value }, index);
                }}
                color={metric.color}
                items={items}
                symbol={`${
                  metric?.dashStyle === "Solid" ? "solid" : "dashed"
                }Line`}
              />
            </Grid>
          ))}
        </Grid>

        <Grid
          xs={2}
          container
          item
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <AddAndRemoveControls
            removeMetric={removeMetric}
            addMetric={addMetric}
            canAddMetric={canAddMetric}
            canRemoveMetric={metrics.length > 1}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {!isEmpty(lineChartData) && (
          <LineChart
            showTooltip
            noTooltipLineBreak
            data={lineChartData}
            secondaryData={secondaryData}
            height={`${props?.parentHeight ? props.parentHeight - 250 : 280}px`}
            plotLines={fixedKeyFrames}
            stickyTracking
          />
        )}
      </Grid>

      <Grid container item flexDirection="row" justifyContent="center">
        {coloredMetrics.map((metric: any, index: number) => (
          <Grid item key={"legend-item-" + index} m={2}>
            <LegendItem
              metric={metric}
              color="hoveredGrey"
              icon={index === 0 ? "solid" : "dashed"}
            />
          </Grid>
        ))}
      </Grid>

      <Grid container item flexDirection="row" justifyContent="center">
        {legendMetrics.map((metric, index) => (
          <Grid item key={"legend-trial-" + index} m={2}>
            <LegendItem
              metric={metric}
              icon={metric.icon}
              color={metric.color}
              noTooltip
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

interface TimeSeriesMetric {
  id: string;
  label: string;
  data: number[];
  visible: boolean;
  color?: string;
}

export default TimeSeries;
