import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectComparingMovement,
  selectExtraMovements,
  selectMovement,
} from "../redux/selectors";
import {
  useFetchTrialVideosQuery,
  useSearchPlayerDetailsQuery,
} from "../services/performanceApi.service";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  flow,
  intersectionBy,
  isEmpty,
  map,
  orderBy,
  take,
  uniqBy,
} from "lodash";
import { useFetchExtraVideosDetails } from "./useFetchExtraVideosDetails";
import { Player } from "../services/mockData";
import { MovementVideo } from "../MovementVideo";

interface TabsType {
  label: string;
  disabled: boolean;
}

const MAX_VIDEO_ANGLES_TABS = 8;

const useVideoAngles = ({
  disableAllTab,
  videoRefs,
}: {
  disableAllTab: boolean;
  videoRefs: any;
}) => {
  const { player, trial } = useSelector(selectMovement);
  const { player: comparingPlayer, trial: comparingTrial } = useSelector(
    selectComparingMovement
  );
  const { data: playerDetails } = useSearchPlayerDetailsQuery(
    player?.id || skipToken
  );
  const { data: comparingPlayerDetails } = useSearchPlayerDetailsQuery(
    comparingPlayer?.id || skipToken
  );

  const [selectedTab, setSelectedTab] = useState(disableAllTab ? "1" : "0");
  const { data: rawTrialVideos } = useFetchTrialVideosQuery(
    trial?.id ? trial : skipToken
  );
  const trialVideos = rawTrialVideos || [];
  const { data: rawComparingTrialVideos } = useFetchTrialVideosQuery(
    comparingTrial?.id ? comparingTrial : skipToken
  );
  const extraMovements = useSelector(selectExtraMovements);
  const { extraVideosDetails } = useFetchExtraVideosDetails(extraMovements);

  const comparingTrialVideos = comparingTrial?.id
    ? rawComparingTrialVideos
    : undefined;

  const getIntersection = (
    trialVideos: MovementVideo[],
    comparingTrialVideos: MovementVideo[] | undefined
  ) =>
    intersectionBy(trialVideos, comparingTrialVideos || trialVideos, "angle");

  const getUniqueMarks = (intersectionTabs: MovementVideo[]) =>
    uniqBy(
      map(intersectionTabs, (video) => ({
        label: video.angle,
        disabled: false,
      })),
      "label"
    );

  const buildResult = (disableAllTab: boolean, uniqTabs: TabsType[]) => [
    { label: "All", disabled: disableAllTab },
    ...uniqTabs,
  ];

  const mainAndComparingVideosIntersection = flow(
    getIntersection,
    getUniqueMarks,
    (uniqTabs) => orderBy(uniqTabs, "label", "asc"),
    (sortedTabs) => take(sortedTabs, MAX_VIDEO_ANGLES_TABS),
    (uniqTabs) => buildResult(disableAllTab, uniqTabs)
  );

  const { tabs, fullMainVideos, fullComparingVideos, fullExtraVideos } =
    useMemo(() => {
      const findOpenSideVideo = (
        aPlayer: Player,
        pitchVideos: any[] | undefined
      ) => {
        const openSideAngle = aPlayer?.throws === "R" ? "3BP" : "1BP";
        const openSideVideo = pitchVideos?.find(
          (it) => it.angle === openSideAngle
        );
        if (openSideVideo) {
          return [
            {
              ...openSideVideo,
              angle: "OS",
              videoUrl: openSideVideo.videoUrl,
            },
          ];
        } else {
          return [];
        }
      };
      const fullMainVideos = trialVideos.concat(
        !isEmpty(comparingTrialVideos)
          ? findOpenSideVideo(playerDetails, trialVideos)
          : []
      );
      const fullComparingVideos = comparingTrialVideos?.concat(
        !isEmpty(comparingTrialVideos)
          ? findOpenSideVideo(comparingPlayerDetails, comparingTrialVideos)
          : []
      );

      const videoIntersection = mainAndComparingVideosIntersection(
        fullMainVideos,
        fullComparingVideos
      );
      const fullExtraVideos = extraVideosDetails;
      return {
        tabs: videoIntersection,
        fullMainVideos,
        fullComparingVideos,
        fullExtraVideos,
      };
    }, [
      trialVideos,
      comparingTrialVideos,
      disableAllTab,
      extraMovements,
      extraVideosDetails,
    ]);

  useEffect(() => {
    if (selectedTab === "0" && disableAllTab) {
      setSelectedTab("1");
    }
  }, [selectedTab, disableAllTab]);
  const angleTabs = useMemo(
    () => tabs.filter((tab) => tab.label !== "All"),
    [tabs]
  );

  const tabsWithRef = useMemo(
    () =>
      angleTabs.map(({ label }, index) => ({
        label: label,
        videoUrl: findVideoUrlForAngle(fullMainVideos, label),
        comparingVideoUrl: findVideoUrlForAngle(fullComparingVideos, label),
        videoRef: videoRefs.current[index],
        mainAngleRef: videoRefs.current[index + angleTabs.length],
        secondaryAngleRef: videoRefs.current[index + angleTabs.length * 2],
        extraVideos: filterAngleExtraVideos(
          angleTabs,
          fullExtraVideos,
          label,
          videoRefs.current,
          index
        ),
      })),
    [angleTabs, fullMainVideos, fullComparingVideos, fullExtraVideos]
  );
  return {
    selectedTab,
    setSelectedTab,
    tabs,
    fullMainVideos,
    fullComparingVideos,
    fullExtraVideos,
    tabsWithRef,
    angleTabs,
  };
};
function findVideoUrlForAngle(
  theVideos: MovementVideo[] | undefined,
  tab: string
): string {
  return theVideos?.find((aVideo) => aVideo.angle === tab)?.videoUrl || "";
}

function filterAngleExtraVideos(
  angleTabs: any[],
  fullExtraVideos: any[],
  tab: string,
  videoRefs: any[],
  index: number
) {
  return fullExtraVideos?.map(
    ({ videos, playerDetails, movement, movementIndex, color }) => ({
      playerDetails,
      movement,
      color,
      videoUrl: findVideoUrlForAngle(videos, tab),
      videoRef: videoRefs[index + angleTabs.length * (3 + movementIndex)], //TODO: THIS MIGHT BE WRONG?
    })
  );
}
export default useVideoAngles;
