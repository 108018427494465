import React from "react";
import { Grid, Divider, ToggleButton } from "@mui/material";
import PeriodSelector from "../common/PeriodSelector";
import { useSelector } from "react-redux";
import { selectPeriodSelectorValue } from "../../redux/selectors";
import PitchSelector from "./PitchSelector";
import { Case, Default, Switch } from "react-if";
import DataIndexSelector from "../PlayerDashboard/DataIndexSelector";
import { TabNames } from "./TrendsPanelModal";
import { ExpandedMetricsDataIndexing } from "../PlayerDashboard/ExpandedMetricsDataIndexing";

const { AllPitches, Date, InningSplit, TestNumber } =
  ExpandedMetricsDataIndexing;

interface TrendsPitchesPeriodSelectorProps {
  tabName: string;
}

function TrendsPitchesPeriodSelector({
  tabName,
}: TrendsPitchesPeriodSelectorProps) {
  const timePeriod = useSelector(selectPeriodSelectorValue);

  return (
    <Grid container p={1} pb={0}>
      <Grid container item xs={12} justifyContent="space-between">
        <Grid item xs="auto">
          <Switch>
            <Case condition={tabName === TabNames.TIME_SERIES}>
              <ToggleButton
                className="ptd-disabled-pitch-selector"
                value={""}
                selected
                disabled
              >
                Time Indexing
              </ToggleButton>
            </Case>
            <Case
              condition={
                timePeriod?.label !== "1D" && tabName === TabNames.DISCRETE
              }
            >
              <DataIndexSelector options={[Date, TestNumber]} />
            </Case>
            <Case condition={tabName === TabNames.CORRELATION}>
              <PitchSelector showAllPitches={timePeriod?.label !== "1D"} />
            </Case>
            <Default>
              <DataIndexSelector options={[AllPitches, InningSplit]} />
            </Default>
          </Switch>
        </Grid>
        <Grid item xs="auto">
          <PeriodSelector condensed shouldUseDefaultValue={false} />
        </Grid>
      </Grid>
      <Grid item xs={12} py={1}>
        <Divider />
      </Grid>
    </Grid>
  );
}

export default TrendsPitchesPeriodSelector;
