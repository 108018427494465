import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { addApiTokenToHeaders, getBaseUrl } from "./common";

// initialize an empty api service that we'll inject endpoints into later as needed
export const emptySkylineApi = createApi({
  reducerPath: "skylineApi",
  baseQuery: fetchBaseQuery({
    baseUrl: getBaseUrl(),
    prepareHeaders: (headers, { getState }) => {
      const apiToken = getState().app.apiToken;
      return addApiTokenToHeaders(apiToken, headers);
    },
  }),

  endpoints: () => ({}),
});
