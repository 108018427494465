import React, { useEffect, useState } from "react";
import TrendsChartLayout from "./TrendsChartLayout";
import { TabNames } from "./TrendsPanelModal";
import { Period } from "../common/PeriodSelector";
import MetricSelector from "./MetricSelector";
import { useMetricData, findMetric } from "../../hooks/useMetricData";
import { isEmpty, isNil } from "lodash";
import {
  selectDataIndexingValue,
  selectPeriodSelectorValue,
} from "../../redux/selectors";
import { useSelector } from "react-redux";
import { ExpandedMetricsDataIndexing } from "../PlayerDashboard/ExpandedMetricsDataIndexing";
import { Grid } from "@mui/material";
import AllPitchesChart from "./AllPitchesChart";
import InningSplitChart from "./InningSplitChart";
import DateIndexingChart from "./DateIndexingChart";
import { Case, Switch } from "react-if";
import { VisibilityOfSeries } from "./VisibleChartSeriesToggles";
import ExportCSVButton from "./ExportCSVButton";

interface DiscreteMetricProps extends VisibilityOfSeries {
  containerWidth?: any;
}
interface ExportableData {
  exportableData?: any;
  setExportableData?: (newExportableData: any) => void;
}

export interface TrendsDiscreteMetricChartProps
  extends VisibilityOfSeries,
    ExportableData {
  metric: any;
  dataComp: boolean;
  selfComp?: boolean;
  timePeriod: Period;
  dataIndexing?: ExpandedMetricsDataIndexing;
}

const { InningSplit, AllPitches, Date, TestNumber } =
  ExpandedMetricsDataIndexing;

function DiscreteMetric({
  containerWidth,
  visibleChartSeries,
  onChangeVisibleChartSeries,
}: DiscreteMetricProps) {
  const dataIndexing = useSelector(selectDataIndexingValue);
  const [metric, setMetric] = useState<any>();
  const [exportableData, setExportableData] =
    useState<ExportableData["exportableData"]>();
  const timePeriod = useSelector(selectPeriodSelectorValue);
  const [dataComp, setDataComp] = useState<boolean>(false);
  const [selfComp, setSelfComp] = useState<boolean>(false);

  const { items } = useMetricData();
  useEffect(() => {
    if (!isEmpty(items) && isEmpty(metric)) {
      onChangeMetric({ key: "KM_WB_Stride_Length_ht" });
    }
  }, [items, metric]);

  const onChangeMetric = (event: any) => {
    const newMetric = findMetric(items, event.key);

    if (!isNil(newMetric?.id)) {
      setMetric(newMetric);
    }
  };

  return (
    <TrendsChartLayout
      tabName={TabNames.DISCRETE}
      setDataComp={setDataComp}
      setSelfComp={setSelfComp}
      selfComp={selfComp}
    >
      <Grid container item flexDirection="row" alignItems="center">
        <Grid item xs={11.5}>
          <MetricSelector
            metric={metric}
            onChange={onChangeMetric}
            items={items}
          />
        </Grid>

        <Grid item xs={0.5}>
          <ExportCSVButton
            fileName="DiscreteMetricData.csv"
            data={exportableData}
            disabled={isNil(exportableData)}
            headers={[
              "player",
              "pitchType",
              "sup75Perc",
              "metric",
              "date",
              "value",
            ]}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Switch>
          <Case condition={dataIndexing === InningSplit}>
            <InningSplitChart
              metric={metric}
              dataComp={dataComp}
              timePeriod={timePeriod}
            />
          </Case>
          <Case condition={dataIndexing === AllPitches}>
            <AllPitchesChart
              metric={metric}
              dataComp={dataComp}
              containerWidth={containerWidth}
              timePeriod={timePeriod}
            />
          </Case>
          <Case condition={[Date, TestNumber].includes(dataIndexing)}>
            <DateIndexingChart
              metric={metric}
              dataComp={dataComp}
              selfComp={selfComp}
              timePeriod={timePeriod}
              dataIndexing={dataIndexing}
              visibleChartSeries={visibleChartSeries}
              onChangeVisibleChartSeries={onChangeVisibleChartSeries}
              exportableData={exportableData}
              setExportableData={setExportableData}
            />
          </Case>
        </Switch>
      </Grid>
    </TrendsChartLayout>
  );
}

export default DiscreteMetric;
