import React from "react";
import DiscreteTrialEvent from "./ThrowEvents/DiscreteTrialEvent";
import DiscreteTrialEvents from "./DiscreteTrialEvents";

const ThrowEvents = () => {
  const tabs = [
    {
      id: "mKHShort",
      label: "Max Knee Height",
      children: <DiscreteTrialEvent event="MKH" />,
    },
    {
      id: "midStride",
      label: "Mid Stride",
      children: <DiscreteTrialEvent event="MS" />,
    },
    {
      id: "initialFootContact",
      label: "Foot Contact",
      children: <DiscreteTrialEvent event="IFC" />,
    },
    {
      id: "firmFootContact",
      label: "Max Ext. Rot.",
      children: <DiscreteTrialEvent event="MER" />,
    },
    {
      id: "release",
      label: "Release",
      children: <DiscreteTrialEvent event="BR" />,
    },
    {
      id: "followThrough",
      label: "Follow Through",
      children: <DiscreteTrialEvent event="FT" />,
    },
  ];

  return <DiscreteTrialEvents tabs={tabs} />;
};

export default ThrowEvents;
