import { Grid, Typography } from "@mui/material";
import React from "react";
import "./LegendItem.css";
import { InfoCircleOutlined } from "@ant-design/icons";
import { MetricTooltip } from "./MetricTooltip";
import { Unless } from "react-if";

interface LegendItemProps {
  metric: any;
  color?: string;
  opacity?: number;
  noTooltip?: boolean;
  noLabel?: boolean;
  icon?: "circle" | "dashed" | "solid";
  rotateTooltipIcon?: number;
}

const LegendItem = ({
  metric,
  color = "hoveredGrey",
  noTooltip = false,
  noLabel = false,
  opacity = 1,
  icon = "circle",
  rotateTooltipIcon,
}: LegendItemProps) => (
  <Grid
    container
    flexDirection="row"
    alignItems="center"
    justifyContent="center"
  >
    <Unless condition={noLabel}>
      <Grid item>
        {icon === "circle" ? (
          <span
            className="ptd-legend-item-dot"
            style={{ backgroundColor: `var(--${color})`, opacity }}
          />
        ) : (
          <span
            className={`ptd-legend-item-${icon}`}
            style={{ borderColor: `var(--${color})` }}
          />
        )}
      </Grid>

      <Grid item ml={1} mr={1}>
        <Typography className="ptd-legend-item-text">
          {metric?.label}
        </Typography>
      </Grid>
    </Unless>
    {!noTooltip && (
      <Grid item>
        <MetricTooltip {...metric} title={metric?.label} isUnitInTitle noSlider>
          <InfoCircleOutlined
            className="ptd-legend-item-info"
            rotate={rotateTooltipIcon}
          />
        </MetricTooltip>
      </Grid>
    )}
  </Grid>
);

export default LegendItem;
