import React, { useMemo, useRef, useState } from "react";
import CollapsiblePanel from "../common/CollapsiblePanel";
import { Tabs } from "antd";
import Stride from "./BiomechanicsSections/Stride";
import BodyPart from "./BiomechanicsSections/BodyPart";
import ThrowEvents from "./BiomechanicsSections/ThrowEvents";
import SwingEvents from "./BiomechanicsSections/SwingEvents";
import TimeSeries from "./BiomechanicsSections/TimeSeries";
import Sequence from "./BiomechanicsSections/Sequence";
import Metric from "./BiomechanicsSections/Metric";
import DebugTrials from "./BiomechanicsSections/DebugTrials";
import Scrollable from "../common/Scrollable";
import "./style.css";
import { AnimationViewerData } from "../../hooks/use3DAnimationVideoScrubber";
import CompSwitches from "./CompSwitches";
import { useSelector } from "react-redux";
import { selectSelectedMotionType } from "../../redux/selectors";
import { MotionType } from "../common/MotionType";
import BatDynamics from "./BiomechanicsSections/BatDynamics";

export interface BiomechanicsMetricsPanelProps {
  handleScrubber: AnimationViewerData["handleScrubber"];
  keyframesData: AnimationViewerData["keyframesData"];
}

const panelInnerPosition = {
  top: "-20px",
  left: "36px",
};

const BiomechanicsMetricsPanel = (
  videoScrubberProps: BiomechanicsMetricsPanelProps
) => {
  const containerRef = useRef();
  const motionType = useSelector(selectSelectedMotionType);
  const tabs = useMemo(() => {
    let baseTabs = [
      { label: "Stride", children: <Stride /> },
      { label: "Body Part", children: <BodyPart /> },
      {
        label: "Metric",
        children: <Metric {...videoScrubberProps} />,
      },
      motionType === MotionType.Batting
        ? { label: "Swing Events", children: <SwingEvents /> }
        : { label: "Throw Events", children: <ThrowEvents /> },
      { label: "Time Series", children: <TimeSeries /> },
      { label: "Sequence", children: <Sequence /> },
      { label: "Debug", children: <DebugTrials /> },
    ];

    if (motionType === MotionType.Batting) {
      baseTabs = [
        {
          label: "Bat Dynamics",
          children: <BatDynamics />,
        },
        ...baseTabs,
      ];
    }

    return baseTabs;
  }, [videoScrubberProps]);

  const [activeTab, setActiveTab] = useState(tabs[0].label);

  const HeadComponent = useMemo(
    () => <CompSwitches activeTab={activeTab} />,
    [activeTab]
  );

  return (
    <CollapsiblePanel
      title="Biomechanics"
      orientation="right"
      top="110px"
      containerRef={containerRef}
      minWidth={475}
      initialWidth={630}
      minHeight={43}
      initialHeight={75}
      removeOnCollapsed
      left={"20px"}
      titleClassName="ptd-uppercase-title"
      innerPosition={panelInnerPosition}
      headComponent={HeadComponent}
    >
      {(height) => (
        <Tabs
          className={"ptd-tabs"}
          activeKey={activeTab}
          onChange={(it) => setActiveTab(it)}
          items={tabs.map(({ label, children }) => {
            const childrenWithProps = {
              ...children,
              props: {
                ...children.props,
                parentHeight: height,
                container: containerRef.current,
              },
            };
            return {
              label,
              key: label,
              children: (
                <Scrollable height={height - 150} prefix={label}>
                  {activeTab === label && childrenWithProps}
                </Scrollable>
              ),
            };
          })}
        />
      )}
    </CollapsiblePanel>
  );
};

export default BiomechanicsMetricsPanel;
