import React from "react";
import { BodyPartTabs } from "../../../../services/mockData";
import BodyPartsLayout, { BodyPartsLayoutChildren } from "./BodyPartsLayout";

function LeadLeg({ container }: BodyPartsLayoutChildren) {
  return (
    <BodyPartsLayout
      container={container}
      bodyPartName={BodyPartTabs.LeadLeg}
    />
  );
}

export default LeadLeg;
