import "./ReactotronConfig";
import React from "react";
import ReactDOM from "react-dom";
import OktaLogin from "./OktaLogin/OktaLogin.js";
import { createReduxStore } from "./createReduxStore";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { isStandalonePerformanceDashboard } from "./Components/App/isPerformanceDashboard";
import { StandalonePerformanceDashboard } from "./performance-dashboard/StandalonePerformanceDashboard";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import { defaultTheme } from "@ant-design/compatible";
import { ConfigProvider } from "antd";

const standalonePerformanceDashboard = isStandalonePerformanceDashboard();

const LD_CLIENT_KEY = process.env.REACT_APP_LD_CLIENT_ID;

(async () => {
  const store = await createReduxStore();

  const LDProvider = await asyncWithLDProvider({
    clientSideID: LD_CLIENT_KEY,
  });

  ReactDOM.render(
    <React.StrictMode>
      <ConfigProvider
        theme={{
          ...defaultTheme,
          token: {
            ...defaultTheme.token,
            fontFamily: "Lato",
          },
          hashed: false,
        }}
      >
        <LDProvider>
          <Provider store={store}>
            <I18nextProvider i18n={i18n}>
              {standalonePerformanceDashboard ? (
                <StandalonePerformanceDashboard />
              ) : (
                <OktaLogin />
              )}
            </I18nextProvider>
          </Provider>
        </LDProvider>
      </ConfigProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
})();
