import React, { useState } from "react";
import { Grid, Stack } from "@mui/material";
import BulletChart from "../../charts/BulletChart";
import { useSelector } from "react-redux";
import {
  selectComparingMovement,
  selectMovement,
  selectSelectedMotionType,
} from "../../../redux/selectors";
import { useFetchStrideDataQuery } from "../../../services/performanceApi.service";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Tabs, Typography } from "antd";
import { isEmpty } from "lodash";
import useFetchSelfData from "../../useFetchSelfData";
import { getIsPitching } from "../../../utils/motionType";

const TABS = [
  {
    id: "stance",
    label: "Stance",
    event: "SET",
  },
  {
    id: "footPlant",
    label: "Foot Plant",
    event: "LFP",
  },
];

interface BattingTabsOrChildrenProps {
  children: any;
  tabs: { id: string; label: string; event: string }[];
  onlyChildren: boolean;
  onChange: (newEvent: string) => void;
}

const BattingTabsOrChildren = ({
  onlyChildren,
  tabs,
  onChange,
  children,
}: BattingTabsOrChildrenProps) => {
  if (onlyChildren) {
    return children;
  }

  return (
    <Tabs
      onChange={onChange}
      defaultActiveKey="1"
      items={tabs.map(({ id, label }) => ({
        label,
        key: id,
        children,
      }))}
    />
  );
};

const Stride = () => {
  const { Text } = Typography;
  const { trial: mainTrial = {}, player } = useSelector(selectMovement);
  const { trial: compareTrial = {} } = useSelector(selectComparingMovement);
  const motionType = useSelector(selectSelectedMotionType);
  const isPitching = getIsPitching(motionType);
  const [currentEvent, setCurrentEvent] = useState<string | null>(
    isPitching ? null : TABS[0]?.event
  );
  const { data: mainData = { data: [], referenceData: [] } } =
    useFetchStrideDataQuery(
      mainTrial?.id
        ? { trial: mainTrial, motionType, event: currentEvent }
        : skipToken
    );
  const { data: compareData = { data: [], referenceData: [] } } =
    useFetchStrideDataQuery(
      compareTrial?.id
        ? { trial: compareTrial, motionType, event: currentEvent }
        : skipToken
    );
  const { discreteData: selfData = [], isSelfCompare } =
    useFetchSelfData(player);

  const dataOrEmpty = (data: any[]) => (!isEmpty(data) ? data : []);
  const getValue = (data: any[], item: any) =>
    data.find((it: any) => it.id === item.id)?.value;

  const mappedCompareData = dataOrEmpty(compareData.data);
  const mappedReferenceData = dataOrEmpty(compareData.referenceData);
  const alternativeData = isSelfCompare ? selfData : mappedCompareData;
  const alternativeReferenceData = isSelfCompare
    ? selfData
    : mappedReferenceData;

  const onChangeEvent = (newEventId: string) => {
    const newEvent = TABS.find((it) => it.id === newEventId);

    setCurrentEvent(newEvent?.event || null);
  };

  return (
    <BattingTabsOrChildren
      onlyChildren={isPitching}
      onChange={onChangeEvent}
      tabs={TABS}
    >
      <Stack spacing={2} py={2} px={1}>
        <Grid container>
          {mainData.data.map((item: any, index: number) => {
            const secondaryValue = getValue(alternativeData, item);

            return (
              <Grid key={item.label + index} item xs={6}>
                <BulletChart
                  value={item.value}
                  averageRange={item.averageRange}
                  mean={item.mean}
                  title={item.label}
                  unit={item.unit}
                  secondaryValue={secondaryValue}
                  isSelf={isSelfCompare}
                  decimals={item.decimals}
                />
              </Grid>
            );
          })}
        </Grid>

        {/* <Grid item>TODO: NEW CHART</Grid> */}

        <Text underline>REFERENCE DATA</Text>

        <Grid container>
          {mainData.referenceData.map((item: any, index: number) => {
            const secondaryValue = getValue(alternativeReferenceData, item);
            const showableUnit = item.label.includes(item.unit)
              ? ""
              : item.unit
              ? `(${item.unit})`
              : "";
            return (
              <Grid item key={item.label + index} xs={6}>
                <BulletChart
                  value={item.value}
                  averageRange={item.averageRange}
                  mean={item.mean}
                  title={`${item.label} ${showableUnit}`}
                  unit={item.unit}
                  secondaryValue={secondaryValue}
                  isSelf={isSelfCompare}
                  decimals={item.decimals}
                />
              </Grid>
            );
          })}
        </Grid>
      </Stack>
    </BattingTabsOrChildren>
  );
};

export default Stride;
