import React, { useEffect, useMemo, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { selectComparingMovement, selectMovement } from "../../redux/selectors";
import { isEmpty } from "lodash";
import { Switch } from "antd";
import "./MovementAngleVideo.css";
import { If, Then, Else } from "react-if";
import OverlayVideo from "./OverlayVideo";
import NoOverlayVideo from "./NoOverlayVideo";

interface MovementAngleVideoProps {
  videoRef?: React.RefObject<HTMLVideoElement>;
  videoUrl: string;
  comparingVideoRef?: React.RefObject<HTMLVideoElement>;
  comparingVideoUrl?: string;
  extraVideos?: any[];
  syncWorldAndVideo: () => void;
}

export function MovementAngleVideo({
  videoRef,
  videoUrl,
  comparingVideoRef,
  comparingVideoUrl,
  extraVideos,
  syncWorldAndVideo,
}: MovementAngleVideoProps) {
  const movement = useSelector(selectMovement);
  const comparingMovement = useSelector(selectComparingMovement);
  const [showOverlayView, setShowOverlayView] = useState(false);
  const [mirrorMainTrialVideo, setMirrorMainTrialVideo] = useState(false);
  const isComparingMovement = useMemo(
    () => !isEmpty(comparingMovement) && comparingVideoUrl,
    [comparingMovement, comparingVideoUrl]
  );
  const shouldShowOverlayView = comparingMovement?.trial?.id && showOverlayView;
  useEffect(() => {
    syncWorldAndVideo();
  }, [shouldShowOverlayView]);
  return (
    <Grid container direction="column" alignContent={"center"} pr={1}>
      {isComparingMovement && (
        <Grid container>
          <Grid container item alignItems="center" xs={6}>
            <Typography fontSize={14} sx={{ mr: 1 }}>
              Overlay
            </Typography>
            <Switch
              size="small"
              checked={shouldShowOverlayView}
              onChange={() => setShowOverlayView((prevState) => !prevState)}
            />
          </Grid>
          <Grid container item alignItems="center" xs={6}>
            <Typography fontSize={14} sx={{ mr: 1 }}>
              Mirror
            </Typography>
            <Switch
              size="small"
              checked={mirrorMainTrialVideo}
              onChange={() =>
                setMirrorMainTrialVideo((prevState) => !prevState)
              }
            />
          </Grid>
        </Grid>
      )}
      <If condition={!shouldShowOverlayView}>
        <Then>
          <NoOverlayVideo
            trial={movement.trial}
            videoRef={videoRef}
            videoUrl={videoUrl}
            isComparingMovement={isComparingMovement}
            mirrorMainTrialVideo={mirrorMainTrialVideo}
            comparingVideoRef={comparingVideoRef}
            comparingVideoUrl={comparingVideoUrl}
            comparingMovement={comparingMovement}
            extraVideos={extraVideos}
          />
        </Then>
        <Else>
          <OverlayVideo
            videoRef={videoRef}
            videoUrl={videoUrl}
            mirrorMainTrialVideo={mirrorMainTrialVideo}
            comparingVideoRef={comparingVideoRef}
            comparingVideoUrl={comparingVideoUrl}
            movement={movement}
            comparingMovement={comparingMovement}
            extraVideos={extraVideos}
          />
        </Else>
      </If>
    </Grid>
  );
}
