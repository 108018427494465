import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import TrendsPitchesPeriodSelector from "./TrendsPitchesPeriodSelector";
import PitchTypesSelector from "./PitchTypesSelector";
import LabeledSwitch from "../common/LabeledSwitch";
import {
  useFetchDataCompSwitchQuery,
  useFetchSelfCompSwitchQuery,
} from "../../services/performanceApi.service";
import {
  selectDataCompSwitch,
  selectSelectedMotionType,
  selectSelfCompSwitch,
} from "../../redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { setDataCompValue, setSelfCompValue } from "../../redux/movementSlice";
import { isNil } from "lodash";
import { If } from "react-if";
import { TabNames } from "./TrendsPanelModal";
import { usePerformanceAppSelector } from "../../redux/hooks";

interface TrendsChartLayoutProps {
  children: any;
  tabName: string;
  setDataComp?: (value: boolean) => void;
  setSelfComp?: (value: boolean) => void;
  selfComp?: boolean;
}

const TrendsChartLayout = ({
  children,
  tabName,
  setDataComp,
  selfComp: isSelfCompare = false,
  setSelfComp: setIsSelfCompare = () => {},
}: TrendsChartLayoutProps) => {
  const dispatch = useDispatch();
  const [isDataCompVisible, setIsDataCompVisible] = useState(true);
  const motionType = usePerformanceAppSelector(selectSelectedMotionType);
  const { data: dataCompValues } = useFetchDataCompSwitchQuery(motionType);
  const { data: selfCompValues } = useFetchSelfCompSwitchQuery();
  const selectedDataComp = useSelector(selectDataCompSwitch);
  const selectedSelfComp = useSelector(selectSelfCompSwitch);

  useEffect(() => {
    if (!isNil(setDataComp)) {
      setDataComp(isDataCompVisible);
    }
  }, [isDataCompVisible]);

  const handleDataFromSwitch = (
    data: any,
    handler: (param?: any) => void,
    id: number
  ) => {
    const value = data.find((item: any) => item.id == id);
    dispatch(handler(value));
  };

  return (
    <Grid container>
      <Grid container>
        <TrendsPitchesPeriodSelector tabName={tabName} />
      </Grid>

      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={4}>
          <PitchTypesSelector />
        </Grid>

        <Grid container item xs={8} justifyContent="flex-end">
          <If
            condition={
              tabName === TabNames.DISCRETE || tabName === TabNames.TIME_SERIES
            }
          >
            <LabeledSwitch
              label="Data Comp"
              checked={isDataCompVisible}
              onClick={setIsDataCompVisible}
              data={dataCompValues ? dataCompValues : []}
              onHandleData={(id) => {
                handleDataFromSwitch(dataCompValues, setDataCompValue, id);
              }}
              value={selectedDataComp}
            />
          </If>

          <If condition={tabName === TabNames.DISCRETE}>
            <LabeledSwitch
              label="Self Comp"
              checked={isSelfCompare}
              onClick={setIsSelfCompare}
              data={selfCompValues ? selfCompValues : []}
              onHandleData={(id) => {
                handleDataFromSwitch(selfCompValues, setSelfCompValue, id);
              }}
              value={selectedSelfComp}
              isSelf
              color="var(--activeDigitalOrange)"
              noMenu
            />
          </If>
        </Grid>
      </Grid>

      <Grid container p={3} pt={0} pb={2} ml={-4}>
        {children}
      </Grid>
    </Grid>
  );
};

export default TrendsChartLayout;
