import React from "react";
import { IconButton } from "@mui/material";
import { PlusCircleFilled, MinusCircleFilled } from "@ant-design/icons";

export const AddAndRemoveControls = ({
  canRemoveMetric,
  removeMetric,
  addMetric,
  canAddMetric,
}: {
  canRemoveMetric: boolean;
  removeMetric: () => void;
  addMetric: () => void;
  canAddMetric: boolean;
}) => (
  <>
    {canRemoveMetric && (
      <IconButton
        onClick={removeMetric}
        sx={{ color: "var(--hoveredDigitalBlue)" }}
      >
        <MinusCircleFilled
          style={{
            backgroundColor: "white",
            borderRadius: "100%",
          }}
        />
      </IconButton>
    )}

    <IconButton
      onClick={addMetric}
      disabled={!canAddMetric}
      sx={{ color: canAddMetric ? "var(--hoveredDigitalBlue)" : "white" }}
    >
      <PlusCircleFilled
        style={{ backgroundColor: "white", borderRadius: "100%" }}
      />
    </IconButton>
  </>
);
