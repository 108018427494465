export const BELL_CHARTS_POINTS = [
  0.17574773449362974, 0.21644211072415645, 0.26465173789930185,
  0.3212837088900748, 0.3872430425519378, 0.46340371105267447,
  0.5505748330425382, 0.6494626328441915, 0.7606291173304212,
  0.8844487831533251, 1.0210650140949675, 1.1703481348341662,
  1.3318573250395693, 1.5048087385528834, 1.688052191564085, 1.8800586615986266,
  2.0789205642244664, 2.2823663450082727, 2.4877903502065553, 2.692298242824916,
  2.8927674445375446, 3.0859212522331907, 3.268414451984985, 3.436927488537333,
  3.588265600485802, 3.719458851240194, 3.827858715744999, 3.9112268520741575,
  3.96781190880828, 3.9964106892092026, 3.9964106892092026, 3.96781190880828,
  3.9112268520741575, 3.827858715744999, 3.719458851240194, 3.588265600485802,
  3.4369274885373327, 3.268414451984985, 3.0859212522331916, 2.8927674445375446,
  2.6922982428249163, 2.487790350206555, 2.2823663450082727, 2.078920564224467,
  1.8800586615986266, 1.688052191564085, 1.5048087385528834, 1.3318573250395693,
  1.1703481348341656, 1.0210650140949675, 0.8844487831533251,
  0.7606291173304212, 0.6494626328441915, 0.5505748330425377,
  0.46340371105267447, 0.38724304255193814, 0.3212837088900748,
  0.26465173789930185, 0.21644211072415637, 0.17574773449362974,
];
