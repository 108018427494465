import React, { useState } from "react";
import CollapsiblePanel from "../common/CollapsiblePanel";
import { Tabs } from "antd";
import Scrollable from "../common/Scrollable";
import BallDynamics from "./BallDynamics";
import MatchupDetails from "./MatchupDetails";
import CompSwitches from "../Metrics/CompSwitches";

const tabs = [
  { key: "1", label: "Ball Dynamics", children: <BallDynamics /> },
  {
    key: "2",
    label: "Matchup Details",
    children: (
      <MatchupDetails
        dataRows={[
          { name: "Date", pathOrGetter: "trial.date" },
          { name: "Away Team", pathOrGetter: "trial.homeTeam.name" },
          { name: "Home Team", pathOrGetter: "trial.homeTeam.name" },
          { name: "Stadium", pathOrGetter: "trial.stadium" },
          { name: "Pitcher Name", pathOrGetter: "trial.pitcher.name" },
          { name: "Batter Name", pathOrGetter: "trial.batter.name" },
          {
            name: "Batter Handedness",
            pathOrGetter: "trial.batter.bats",
          },
          { name: "Inning", pathOrGetter: "trial.inning" },
          { name: "Outs", pathOrGetter: "trial.outs" },
          {
            name: "Count (Balls/Strikes)",
            pathOrGetter: ({ trial }) =>
              `${trial?.balls ?? ""}-${trial?.strikes ?? ""}`,
          },
          { name: "Strikes", pathOrGetter: "trial.strikes" },
          { name: "Play Outcome", pathOrGetter: "trial.outcome" },
          { name: "Pitch Velocity", pathOrGetter: "trial.speed" },
          { name: "Hit Velocity", pathOrGetter: "trial.hitSpeed" },
          { name: "Hit Launch Angle", pathOrGetter: "trial.launchAngle" },
        ]}
      />
    ),
  },
];

function StuffPanelModal() {
  const [containerWidth, setContainerWidth] = useState(0);

  const mappedTabs = tabs.map((tab) => ({
    ...tab,
    children: {
      ...tab.children,
      props: {
        ...tab.children.props,
        containerWidth: containerWidth - 24,
      },
    },
  }));

  return (
    <CollapsiblePanel
      title="Stuff"
      subtitle="STUFF"
      top={180}
      innerPosition={{
        top: "0px",
      }}
      orientation="left"
      leftResize
      minWidth={600}
      initialWidth={700}
      minHeight={75}
      setContainerWidth={setContainerWidth}
      tabZIndex={11}
      headComponent={<CompSwitches />}
      removeOnCollapsed
    >
      {(height) => {
        return (
          <Tabs
            defaultActiveKey="1"
            items={mappedTabs.map(({ label, children }, index) => {
              return {
                label,
                key: `${label}-${index}`,
                children: (
                  <Scrollable height={height - 160}>{children}</Scrollable>
                ),
              };
            })}
          />
        );
      }}
    </CollapsiblePanel>
  );
}

export default StuffPanelModal;
