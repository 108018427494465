import * as THREE from "three";

export class StandardLights3D {
  constructor(scene) {
    this.scene = scene;
    this.group = new THREE.Group();
    this.lights = [];
    this.lightsInitialized = false;
  }

  initialize() {
    if (!this.lightsInitialized) {
      const light = new THREE.PointLight(0xffffff, 1, 100); // white light
      light.position.set(0, 5, 0); // position the light
      this.lights.push(light);

      const light2 = new THREE.PointLight(0xffffff, 1, 100); // white light
      light2.position.set(-5, 5, 0); // position the light
      this.lights.push(light2);

      const light3 = new THREE.PointLight(0xffffff, 1, 100); // white light
      light3.position.set(-5, 5, 5); // position the light
      this.lights.push(light3);

      const light4 = new THREE.PointLight(0xffffff, 1, 100); // white light
      light4.position.set(5, 5, -5); // position the light
      this.lights.push(light4);

      const light5 = new THREE.PointLight(0xffffff, 1, 100); // white light
      light5.position.set(5, 5, 0); // position the light
      this.lights.push(light5);
      this.lights.forEach((light) => this.group.add(light));
      this.lightsInitialized = true;
    }
  }

  toggleLightsOn() {
    this.initialize();
    this.scene.add(this.group);
  }

  toggleLightsOff() {
    this.initialize();
    this.scene.remove(this.group);
  }
}
