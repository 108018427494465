import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { Menu, Popover, Switch } from "antd";
import "./MetricSelector.css";
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@mui/icons-material";
import Symbol, { SymbolVariants } from "./Symbol";

interface MetricMenuSelectorProps {
  value: any;
  onChange: (key: string) => void;
  checked: boolean;
  setChecked: (value: boolean) => void;
  color?: string;
  items?: any[];
  symbol?: SymbolVariants;
}
const MetricMenuSelector = ({
  value,
  onChange,
  checked,
  setChecked,
  color,
  items,
  symbol = "circle",
}: MetricMenuSelectorProps) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const ChangeMetric = () => (
    <Menu
      onClick={(e: any) => {
        onChange(e?.key);
        setMenuIsOpen(false);
      }}
      mode="vertical"
      items={items}
    />
  );

  return (
    <Grid container item alignItems="center">
      <Grid item xs={9} sx={{ cursor: "pointer" }}>
        <Popover
          open={menuIsOpen}
          onOpenChange={() => setMenuIsOpen((prevState) => !prevState)}
          trigger={"click"}
          placement="bottomLeft"
          overlayClassName="ptd-inner-menu"
          content={ChangeMetric}
        >
          <Grid
            container
            alignItems={"center"}
            p={1}
            sx={{ border: "1px solid var(--neutral-5)", borderRadius: 16 }}
          >
            <Grid container item xs="auto">
              <Symbol variant={symbol} color="hoveredGrey" />
            </Grid>
            <Grid
              item
              xs
              pl={1}
              sx={{
                overflow: "hidden",
              }}
            >
              <Typography
                fontSize={14}
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {value?.label}
              </Typography>
            </Grid>
            <Grid container item xs="auto" pl={1}>
              {menuIsOpen ? (
                <KeyboardArrowUpIcon fontSize="small" />
              ) : (
                <KeyboardArrowDownIcon fontSize="small" />
              )}
            </Grid>
          </Grid>
        </Popover>
      </Grid>

      <Grid item xs="auto" pl={1}>
        <Switch
          checked={checked}
          onClick={setChecked}
          style={checked ? { backgroundColor: `var(--${color})` } : {}}
        />
      </Grid>
    </Grid>
  );
};

export default MetricMenuSelector;
