import { useFlags } from "launchdarkly-react-client-sdk";

const developmentFlags = {
  ptdEnabledTrendDetection: true,
  ptdPlayerPagePrioritiesChipEnabled: true,
  ptdEnableWorkloadWidget: true,
  ptdEnabledHeaderExportButton: true,
  ptdEnabledTrendsOnTeamTable: true,
  ptdEnabledDataEntryEdit: true,
  ptdEnabledDataEntryDelete: true,
  ptdEnabledTrendOnMetricGroupRow: true,
  ptdEnabledTrendsPanelModal: true,
  ptdBattingBiomechViewerEnabled: true,
  ptdEnabledAppStore: true,
};

export const useFeatureFlags = () => {
  const productionFlags = useFlags();
  return process.env.REACT_APP_USE_LOCAL_FLAGS_OVERRIDE === "true"
    ? developmentFlags
    : productionFlags;
};
