import React from "react";
import { BodyPartTabs } from "../../../../services/mockData";
import BodyPartsLayout, { BodyPartsLayoutChildren } from "./BodyPartsLayout";

function GloveArm({ container }: BodyPartsLayoutChildren) {
  return (
    <BodyPartsLayout
      container={container}
      bodyPartName={BodyPartTabs.GloveArm}
    />
  );
}

export default GloveArm;
