import React, { useState } from "react";
import { BodyPartMetric } from "../../../../services/mockData";
import "./BodyPartTable.css";
import TableWithoutCompare from "./TableWithoutCompare";
import TableWithCompare from "./TableWithCompare";
import { isEmpty } from "lodash";
import { Grid } from "@mui/material";
import { MetricType } from "../../../../hooks/useBodyPartData";

interface BodyPartTableProps {
  data: BodyPartMetric[];
  comparingData: BodyPartMetric[];
  isSelfCompare: boolean;
  handleMetric: (metric: MetricType) => void;
}

function BodyPartTable({
  data,
  comparingData,
  isSelfCompare,
  handleMetric,
}: BodyPartTableProps) {
  const [selectedRowKey, setSelectedRowKey] = useState<number>(data[0]?.id);

  return (
    <Grid container>
      <Grid item xs={12}>
        {isEmpty(comparingData) ? (
          <TableWithoutCompare
            data={data}
            handleMetric={handleMetric}
            selectedRowKey={selectedRowKey}
            setSelectedRowKey={setSelectedRowKey}
          />
        ) : (
          <TableWithCompare
            data={data}
            handleMetric={handleMetric}
            comparingData={comparingData}
            isSelfCompare={isSelfCompare}
            selectedRowKey={selectedRowKey}
            setSelectedRowKey={setSelectedRowKey}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default BodyPartTable;
