import React, { useEffect, useState } from "react";
import { max as arrayMax, min as arrayMin } from "lodash";
import { DataPoint, Result } from "regression";
import {
  parseObjecToStringHTML,
  updateOrReplaceSeries,
} from "../../utils/charts";
import { VisibleChartSeries } from "./VisibleChartSeriesToggles";
import dayjs from "dayjs";
import MemoizedHighcharts from "../charts/MemoizedHighcharts";

interface CorrelationChartProps {
  data?: DataPoint[];
  regression?: Result;
  visibleChartSeries?: VisibleChartSeries;
  metrics: {
    metric1: any;
    metric2: any;
  };
  commonValues: { [metricId: string]: string | number; date: string }[];
}

const makeBounds = (values: number[]) => {
  const max = arrayMax(values) ?? 5;
  const min = arrayMin(values) ?? -5;
  const delta = (max - min) * 0.1;
  return { min: min - delta, max: max + delta };
};

const makeAxis = (values?: number[]) => {
  const bounds = makeBounds(values ?? []);

  return {
    ...bounds,
    title: {
      text: "",
    },
  };
};

function CorrelationChart({
  data,
  regression,
  visibleChartSeries,
  metrics,
  commonValues,
}: CorrelationChartProps) {
  const [chart, setChart] = useState<any>(null);
  const regressionSeries = {
    data: regression?.points ?? [],
    color: "#ff0407",
    dashStyle: "longDash",
    lineWidth: 1,
    marker: false,
    enableMouseTracking: false,
    zIndex: 2,
    visible: visibleChartSeries?.fullRegression,
  };
  const mainSeries = {
    type: "line",
    lineColor: "transparent",
    data: data?.map(([x, y, date]: any[]) => ({ x, y, date })),
    marker: {
      enabled: true,
      lineWidth: 1,
      states: {
        hover: {
          fillColor: "var(--ptd-tooltip-accent-color)",
          lineColor: "var(--ptd-tooltip-accent-color)",
        },
        normal: {
          fillColor: "#393939",
          lineColor: "#393939",
        },
      },
    },
  };

  const series = [mainSeries, regressionSeries];

  // Highcharts doesn't play well with dynamic data, so we completely replace the series when data changes.
  // If we don't it this way, the chart can crash with the message "Cannot read properties of null (reading 'length')".
  useEffect(() => {
    if (chart) {
      series.forEach((series, index) => {
        updateOrReplaceSeries(chart, { ...series, index });
      });
    }
  }, [chart, series]);

  const { metric1, metric2 } = metrics;

  const options = {
    chart: {
      height: 360,
      events: {
        load: function () {
          setChart(this);
        },
      },
    },
    title: {
      text: "",
    },
    credits: { enabled: false },
    tooltip: {
      enabled: true,
      split: true,
      shared: true,
      outside: true,
      useHTML: true,
      style: {
        opacity: 0,
        zIndex: 1000,
      },
      formatter: function (): string {
        const chart: any = this;
        const date = dayjs(chart.point.date).format("MM/DD/YY");
        const currentDateValues = commonValues.find(
          (it) => it.date === chart.point.date
        );
        const dot = parseObjecToStringHTML({
          style: {
            height: "8px",
            width: "8px",
            margin: "8px",
            backgroundColor: "#0091ff",
            borderRadius: "100%",
          },
        });

        const dateTooltipBox = parseObjecToStringHTML({
          children: `${dot}${date}`,
          style: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          },
        });
        const metric1TooltipBox = parseObjecToStringHTML({
          children: `${metric1?.label}: ${Number(
            currentDateValues?.[metric1?.id]
          ).toFixed(1)}`,
        });
        const metric2TooltipBox = parseObjecToStringHTML({
          children: `${metric2?.label}: ${Number(
            currentDateValues?.[metric2?.id]
          ).toFixed(1)}`,
        });

        const metricsTooltipBox = parseObjecToStringHTML({
          children: `${metric1TooltipBox}${metric2TooltipBox}`,
          style: {
            display: "grid",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
          },
        });

        return parseObjecToStringHTML({
          children: `${dateTooltipBox}${metricsTooltipBox}`,
          style: {
            display: "grid",
            flexDirection: "column",
            justifyContent: "start",
            backgroundColor: "white",
            borderRadius: "4px",
            boxShadow: "1px 1px 5px",
            padding: "8px",
          },
        });
      },
    },
    series: [],
    legend: false,
    xAxis: makeAxis(data?.map(([x]) => x)),
    yAxis: makeAxis(data?.map(([, y]) => y)),
  };

  return <MemoizedHighcharts options={options} />;
}

export default CorrelationChart;
