import React, { ReactNode, memo, useMemo, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { Typography as AntdTypography } from "antd";
import ValueBox from "./ValueBox";
import BulletChart from "../charts/BulletChart";
import { Metric } from "../../services/mockMetrics";
import { isEmpty, isNil, indexOf } from "lodash";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { selectSelectedMotionType } from "../../redux/selectors";
import { MotionType } from "./MotionType";
const imagesByMetric = (fullMetricTitle: string, motionType: MotionType) => {
  const firstParenthesesIndex = indexOf(fullMetricTitle.split(""), "(");
  const simpleMetricName =
    firstParenthesesIndex === -1
      ? fullMetricTitle
      : fullMetricTitle.slice(0, firstParenthesesIndex - 1);
  const mappedMetricName = simpleMetricName.replace(".", "").trim();

  return `3d/images/Legend_Performance/Kinatrax_${
    motionType === MotionType.Batting ? "Batting" : "Pitching"
  }_${encodeURIComponent(mappedMetricName)}.png`;
};

export interface MetricDescriptionTooltipProps {
  title: string;
  value: number;
  extraValues?: { color: string; value: number }[];
  averageRange: {
    from: number;
    to: number;
  };
  min: number;
  max: number;
  unit: string;
  description?: string;
  isComparingTrial?: boolean;
  isSelfCompare?: boolean;
  secondaryMetric?: Metric;
  MetricSelector?: ReactNode;
  isUnitInTitle?: boolean;
  noSlider?: boolean;
  imageToggle?: boolean;
  decimals?: number;
}
export const MetricDescriptionTooltipContent = memo(
  function MetricDescriptionTooltipContent({
    title,
    value,
    averageRange,
    unit,
    secondaryMetric,
    MetricSelector,
    description,
    isComparingTrial = false,
    isSelfCompare = false,
    noSlider = false,
    imageToggle = false,
    extraValues,
    decimals,
  }: MetricDescriptionTooltipProps): JSX.Element {
    const motionType = useSelector(selectSelectedMotionType);
    const metricImage = useMemo(
      () => imagesByMetric(title, motionType),
      [title, motionType]
    );
    const [showImage, setShowImage] = useState(!imageToggle);
    const toggleImage = () => setShowImage(!showImage);
    return (
      <Grid container justifyContent={"center"} textAlign={"center"} p={2}>
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          item
          xs={12}
        >
          {imageToggle && (
            <Grid item mr={1} sx={{ cursor: "pointer" }} onClick={toggleImage}>
              <InfoCircleOutlined className="ptd-legend-item-info" />
            </Grid>
          )}

          <Typography fontWeight={"bold"}>{title}</Typography>
          {MetricSelector && MetricSelector}
        </Grid>
        {!noSlider && (
          <>
            <Grid container item xs={"auto"} pb={1} spacing={1}>
              <Grid item>
                <ValueBox
                  value={value}
                  range={averageRange}
                  decimals={decimals}
                  accentColor={
                    isComparingTrial || isSelfCompare
                      ? `var(--main-accent-color)`
                      : undefined
                  }
                />
              </Grid>
              <Grid item>
                {(isComparingTrial || isSelfCompare) && secondaryMetric && (
                  <ValueBox
                    value={secondaryMetric?.value}
                    range={secondaryMetric?.averageRange}
                    accentColor={`var(--${
                      isSelfCompare ? "self" : "secondary"
                    }-accent-color)`}
                    decimals={decimals}
                  />
                )}
              </Grid>

              {!isEmpty(extraValues) &&
                extraValues?.map(({ value, color }) => (
                  <Grid item key={`extraValueBox-${color}`}>
                    {secondaryMetric && (
                      <ValueBox
                        value={value}
                        range={secondaryMetric?.averageRange}
                        accentColor={color}
                        decimals={decimals}
                      />
                    )}
                  </Grid>
                ))}
            </Grid>
            <Grid item xs={12}>
              <BulletChart
                key={"Main metric"}
                dontShowValueBox
                value={value}
                averageRange={averageRange}
                width={250}
                secondaryValue={
                  isComparingTrial || isSelfCompare
                    ? secondaryMetric?.value
                    : undefined
                }
                extraValues={extraValues}
                isSelf={isSelfCompare}
                unit={unit}
                decimals={decimals}
              />
            </Grid>
          </>
        )}
        {showImage && (
          <Grid item xs={12}>
            <img
              src={metricImage}
              onError={(e) => {
                const imgElement: any = e.target;
                imgElement.style.display = "none";
              }}
              // Hack to undo the above when next image exists
              onLoad={(e) => {
                const imgElement: any = e.target;
                imgElement.style.display = "inline";
              }}
              alt="Picture of metric data"
              style={{ height: "175px" }}
            />
          </Grid>
        )}
        {!isNil(description) && (
          <Grid item xs={12}>
            <AntdTypography.Text style={{ fontWeight: "bold" }}>
              Description:{" "}
            </AntdTypography.Text>
            <AntdTypography.Text>{description}</AntdTypography.Text>
          </Grid>
        )}
      </Grid>
    );
  }
);
