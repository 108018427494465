import React, { useEffect } from "react";
import { Input } from "antd";
import { Box, Typography } from "@mui/material";
import "./SegmentFrameSelector.css";
import useFramesData from "../../hooks/useFramesData";
import { isStandalonePerformanceDashboard } from "../../../Components/App/isPerformanceDashboard";
import { useSelector } from "react-redux";
import { selectSelectedMotionType } from "../../redux/selectors";
import { MotionType } from "../common/MotionType";

interface SegmentFrame {
  startFrame?: number;
  endFrame?: number;
}
export function SegmentFrameSelector({
  maxValueScrubb,
  labelInput,
  value,
  frame,
  setSegmentFrames,
}: {
  maxValueScrubb: number;
  frame: "startFrame" | "endFrame";
  labelInput: string;
  value?: SegmentFrame;
  setSegmentFrames?: (segment: any) => void;
}) {
  const isStartFrame = frame === "startFrame";
  const min = isStartFrame ? 0 : 1;
  const max = isStartFrame ? maxValueScrubb - 1 : maxValueScrubb;

  const { getFrame } = useFramesData();
  const motionType = useSelector(selectSelectedMotionType);
  const initialValueReferenceFrame = getFrame(isStartFrame ? "MKH" : "BR");
  const initialValueOffset = isStartFrame ? -220 : 200;
  const initialValue = Math.max(
    1,
    Math.min(initialValueReferenceFrame + initialValueOffset, maxValueScrubb)
  );

  const setFrameValue = (newValue: number) => {
    if (setSegmentFrames && value && value[frame] !== newValue) {
      setSegmentFrames((currentValue: SegmentFrame) => ({
        ...currentValue,
        [frame]: newValue,
      }));
    }
  };
  useEffect(() => {
    const value =
      isStandalonePerformanceDashboard() || motionType === MotionType.Batting
        ? isStartFrame
          ? 1
          : maxValueScrubb
        : initialValue;
    setFrameValue(value);
  }, [initialValue, maxValueScrubb]);
  return (
    <Box display={"flex"} alignItems={"center"}>
      <Typography fontSize={12}>{`${labelInput}:`}</Typography>
      <Input
        type="number"
        min={min}
        max={max}
        bordered={false}
        className="ptd-frame-input"
        style={{
          borderBottom: "1px dashed black",
        }}
        value={value ? value[frame] : isStartFrame ? min : max}
        onChange={(e) => {
          setFrameValue(Number(e.target.value));
        }}
      />
    </Box>
  );
}
