import React, { memo } from "react";
import {
  FastBackwardOutlined,
  FastForwardOutlined,
  StepBackwardOutlined,
  StepForwardOutlined,
} from "@ant-design/icons";
import PlayButton from "../PlayButton/PlayButton";
import VideoSpeedSelect from "../VideoSpeedSelect/VideoSpeedSelect";
import { Col, Row } from "antd";
import { Speed } from "../../hooks/use3DAnimationVideoScrubber";

interface VideoControlPanelProps {
  isPlaying: boolean;
  playButtonOnClick: () => void;
  onPreviousFrame: () => void;
  onNextFrame: () => void;
  currentSpeed: Speed;
  onSpeedChange: (speed: Speed) => void;
  onNextKeyframe: () => void;
  onLastKeyframe: () => void;
}

export const bigButtonStyle = {
  fontSize: "1.5rem",
};

const mediumButtonStyle = {
  fontSize: "1.25rem",
};

const alignItemsCenter = { display: "flex", alignItems: "center" };

function VideoControlPanel({
  isPlaying,
  playButtonOnClick,
  onNextFrame,
  onPreviousFrame,
  onSpeedChange,
  currentSpeed,
  onNextKeyframe,
  onLastKeyframe,
}: VideoControlPanelProps) {
  return (
    <Row align="middle">
      <Col span={4}>
        <PlayButton isPlaying={isPlaying} onClick={playButtonOnClick} />
      </Col>
      <Col span={4}>
        <StepBackwardOutlined
          style={bigButtonStyle}
          onClick={onPreviousFrame}
        />
      </Col>
      <Col span={4}>
        <StepForwardOutlined style={bigButtonStyle} onClick={onNextFrame} />
      </Col>
      <Col span={4} style={alignItemsCenter}>
        <FastBackwardOutlined
          style={mediumButtonStyle}
          onClick={onLastKeyframe}
        />
      </Col>
      <Col span={4} style={alignItemsCenter}>
        <FastForwardOutlined
          style={mediumButtonStyle}
          onClick={onNextKeyframe}
        />
      </Col>
      <Col span={4}>
        <VideoSpeedSelect
          onChange={onSpeedChange}
          currentSpeed={currentSpeed}
        />
      </Col>
    </Row>
  );
}

export default memo(VideoControlPanel);
