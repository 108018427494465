import { useEffect, useState } from "react";
import {
  useLazyFetchTrialCoordinatesQuery,
  useLazyFetchTrialKeyframesQuery,
  useLazyFetchTrialVideosQuery,
} from "../services/performanceApi.service";
import { syncTrialsToKeyFrame } from "../syncTrialsToKeyFrame";
import { useSelector } from "react-redux";
import { selectSelectedMotionType } from "../redux/selectors";
import { useSyncReferenceKeyFrame } from "../useSyncReferenceKeyFrame";

export function useFetchExtraMovementsDetails(
  extraMovements: any[],
  syncOptions?: { jointCoordinates: any[]; mainKeyFrames?: any[] }
) {
  const [extraMovementsDetails, setExtraMovementsDetails] = useState<any[]>([]);
  const { jointCoordinates, mainKeyFrames } = syncOptions || {};
  const [fetchCoordinates] = useLazyFetchTrialCoordinatesQuery();
  const [fetchKeyFrames] = useLazyFetchTrialKeyframesQuery();
  const [fetchVideos] = useLazyFetchTrialVideosQuery();
  const motionType = useSelector(selectSelectedMotionType);
  const { syncReferenceKeyFrame } = useSyncReferenceKeyFrame();
  useEffect(() => {
    Promise.all(
      extraMovements
        ?.filter((it: any) => it.trial?.id)
        .map((it: any) => {
          return Promise.all([
            fetchCoordinates({ trial: it.trial, motionType }).unwrap(),
            fetchKeyFrames({ trial: it.trial, motionType }).unwrap(),
            fetchVideos(it.trial).unwrap(),
          ]).then(([coordinates, keyFrames, videos]) => ({
            movement: it,
            coordinates: jointCoordinates
              ? syncTrialsToKeyFrame({
                  jointCoordinates,
                  comparingJointCoordinates: coordinates,
                  mainKeyFrames: mainKeyFrames || [],
                  comparingKeyFrames: keyFrames,
                  keyFrame: syncReferenceKeyFrame,
                })
              : [],
            keyFrames,
            videos,
          }));
        })
    ).then((it) => setExtraMovementsDetails(it));
  }, [extraMovements, jointCoordinates, mainKeyFrames]);
  return { extraMovementsDetails };
}
