import { groupBy, isNil, map, sortBy } from "lodash";
import regression, { DataPoint, Options } from "regression";
import {
  ExpandedMetricsDataIndexing,
  dataIndexingValueToXCoordinate,
} from "../components/PlayerDashboard/ExpandedMetricsDataIndexing";

const regressionOptions: Options = { precision: 10 };

// In this context, it makes sense to assume that 13 digits number are Unix timestamps
const isUnixTimestamp = (value: number) =>
  !isNil(value) && value.toString().length === 13;

// regression lib cannot handle numbers > 1M, so we transform the unix timestamps to decimal numbers
const xToRegressionX =
  (shouldTransform: boolean) =>
  ([x, y]: DataPoint): DataPoint =>
    [shouldTransform ? x / 1000000 : x, y];

const regressionXToX =
  (shouldTransform: boolean) =>
  ([x, y]: DataPoint): DataPoint =>
    [shouldTransform ? x * 1000000 : x, y];

export class RegressionCalculator {
  linearFromDataPoints(points: DataPoint[]) {
    const isDateIndexing = isUnixTimestamp(points[0]?.[0]);
    const result = regression.linear(
      points.map(xToRegressionX(isDateIndexing)),
      regressionOptions
    );
    return {
      ...result,
      points: result.points.map(regressionXToX(isDateIndexing)),
    };
  }

  linear(metrics: any, dataIndexing: ExpandedMetricsDataIndexing) {
    const metricsPoints = metrics
      .flatMap((it: { date: string; metrics: any[] }) =>
        it.metrics.map((metric) => ({ date: it.date, ...metric }))
      )
      .filter((it: any) => !isNil(it.value));

    const pointXIndex = {
      [ExpandedMetricsDataIndexing.TestNumber]: (points: any[], point: any) =>
        points.indexOf(point) + 1,
      [ExpandedMetricsDataIndexing.AllPitches]: (points: any[], point: any) =>
        points.indexOf(point) + 1,
      [ExpandedMetricsDataIndexing.Date]: (_points: any[], point: any) =>
        dataIndexingValueToXCoordinate(point),
      [ExpandedMetricsDataIndexing.InningSplit]: (_points: any[], point: any) =>
        dataIndexingValueToXCoordinate(point),
    }[dataIndexing];

    const regressions = map(
      groupBy(metricsPoints, "metric"),
      (points, metric) => {
        const isDateIndexing =
          dataIndexing === ExpandedMetricsDataIndexing.Date;
        const originalPoints: DataPoint[] = points.map((it) => [
          pointXIndex(points, it),
          it.value,
        ]);
        const linearRegression = regression.linear(
          sortBy(originalPoints, (it) => it[0]).map(
            xToRegressionX(isDateIndexing)
          ),
          regressionOptions
        );

        return {
          metric,
          linearRegression: {
            ...linearRegression,
            points: linearRegression.points.map(regressionXToX(isDateIndexing)),
          },
        };
      }
    );

    return regressions;
  }
}
