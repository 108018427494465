import { flattenTeamDashMetrics } from "./useTeamPlayers";
import { useEffect, useState } from "react";
import { TeamDashColumn } from "./useUpdateMetric";
import _, { isEmpty, map, mapValues, mergeWith } from "lodash";
import { usePerformanceAppSelector } from "../redux/hooks";
import { TeamPlayerPosition } from "../components/TeamDashboard/TeamDataSelector/PositionSelector";

export const STORAGE_KEY = "team-dash-metric-columns";

type TeamDashMetric = TeamDashColumn & { children: any[] };

const { Pitcher, PositionPlayer, All } = TeamPlayerPosition;

const pitcherColumns = [
  "rom_shoulder_ir_d_left",
  "rom_shoulder_er_d_left",
  "msk_shoulder_ir_d_left",
  "msk_shoulder_er_d_left",
  "pwr_iso_mtp",
  "pwr_cmj_conc_impulse",
  "msk_grip_0_d_left",
  "msk_grip_90_d_left",
  "msk_grip_90_d_left",
  "msk_grip_90_d_left",
  // "pwr_core_test_left",
  // "pwr_core_test_right",
];

const defaultColumnsKeys = {
  [Pitcher]: pitcherColumns,
  [PositionPlayer]: [
    "rom_hip_trot_d_left",
    "rom_hip_trot_d_right",
    "msk_grip_0_d_left",
    "pwr_cmj_conc_impulse",
    "pwr_cmj_rsi_modified",
    "pwr_cmj_conc_impulse",
    "msk_grip_0_d_left",
    // "pwr_core_test_left",
    // "gps_max_velocity",
    // "rom_trunk_thor_d_left",
    // "rom_trunk_thor_d_right",
    "bcmp_weight",
    "bcmp_weight",
    "bcmp_weight",
  ],
  [All]: pitcherColumns,
};

export const readSelectedTeamDashColumns = (): Partial<
  typeof defaultColumnsKeys
> => {
  const defaultValue = {
    [Pitcher]: undefined,
    [PositionPlayer]: undefined,
    [All]: undefined,
  };

  try {
    const selectedKeys = localStorage.getItem(STORAGE_KEY);
    return selectedKeys ? JSON.parse(selectedKeys) : defaultValue;
  } catch (_e) {
    return defaultValue;
  }
};

export function usePersistentTeamDashColumns({
  overrideInitialState,
  allMetrics,
}: {
  overrideInitialState?: TeamDashColumn[];
  allMetrics: (TeamDashMetric | null)[];
}) {
  const position = usePerformanceAppSelector(
    (state) => state.movement.teamPlayerPosition
  );

  const flattenedMetrics = flattenTeamDashMetrics(allMetrics);
  const defaultInitialKeys = defaultColumnsKeys[position];

  const keysToMetrics = (keys: string[]): TeamDashColumn[] =>
    keys.map((key) => flattenedMetrics.find((metric) => metric.key === key));

  // If any metric doesn't exist anymore, the default one will be used instead.
  // Thus, we garantee that we'll always have the same amount of columns
  const selectedColumnsOrDefaults = (keys: string[]) =>
    _(overrideInitialState ?? keysToMetrics(keys))
      .zipWith(keysToMetrics(defaultInitialKeys), (x, y) => x ?? y)
      .compact()
      .value();

  const initialColumns = () => {
    const initialKeys = mergeWith(
      readSelectedTeamDashColumns(),
      defaultColumnsKeys,
      (x, y) => x ?? y
    );

    return mapValues(initialKeys, selectedColumnsOrDefaults);
  };

  const writeState = () => {
    const values = mapValues(metricsPerPosition, (it) => map(it, "key"));
    localStorage.setItem(STORAGE_KEY, JSON.stringify(values));
  };

  const [metricsPerPosition, setMetricsPerPosition] = useState(
    initialColumns()
  );

  useEffect(() => {
    // Don't persist if metrics are not loaded yet
    if (isEmpty(allMetrics)) {
      return;
    }

    if (isEmpty(metricsPerPosition[All])) {
      setMetricsPerPosition(initialColumns());
    } else {
      writeState();
    }
  }, [metricsPerPosition, allMetrics]);

  const setMetrics = (value: TeamDashColumn[]) => {
    setMetricsPerPosition({ ...metricsPerPosition, [position]: value });
  };

  return {
    metrics: metricsPerPosition[position],
    setMetrics,
    resetToDefaultMetrics: () =>
      setMetrics(keysToMetrics(defaultColumnsKeys[position])),
  };
}
