import React from "react";
import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";

interface VideoFrameTooltipProps extends TooltipProps {
  isSecondary: boolean;
}

export const VideoFrameTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ),
  { shouldForwardProp: (prop) => prop !== "isSecondary" }
)<VideoFrameTooltipProps>(({ isSecondary }) => {
  const color = `var(--${
    isSecondary ? "digital-blue-3" : "activeDigitalBlue"
  })`;
  return {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: color,
      color: "white",
      fontSize: 15,
      padding: 10,
    },
    [`& .${tooltipClasses.arrow}`]: {
      color,
    },
  };
});
