import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Grid,
  GridProps,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import "../PlayerDashboard/PlayerMetricsModalSections/PlayerMetricsModalSections.css";
import { selectPeriodSelectorValue } from "../../redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { setPeriodSelectorValue } from "../../redux/movementSlice";
import dayjs from "dayjs";
import DateRangePopover from "./DateRangePopover";
import { compact, isNil, times } from "lodash";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { includeIf } from "../../utils/object";
import { DatePicker } from "antd";

const { RangePicker } = DatePicker;

export const monthsAgo = (months: number) => {
  return dayjs().subtract(months, "months").toString();
};

export const daysCount = (period: Period) =>
  dayjs(period.endDate).diff(period.startDate, "day") + 1;

export const daysInPeriod = (period: Period) =>
  times(daysCount(period), (i) => dayjs(period.startDate).add(i, "day"));

export const formatPeriodLabel = (
  startDate: Period["startDate"],
  endDate: Period["endDate"]
) =>
  `${dayjs(startDate).format("MM/DD/YYYY")} - ${dayjs(endDate).format(
    "MM/DD/YYYY"
  )}`;

export interface Period {
  startDate: string;
  endDate: string;
  label: string;
  id?: string;
}

export const DEFAULT_PERIOD = {
  label: "YTD",
  startDate: dayjs().startOf("year").toString(),
  endDate: dayjs().toString(),
};

interface PeriodSelectorProps {
  value?: Period;
  onChange?: (value: Period) => void;
  condensed?: boolean;
  shouldUseDefaultValue?: boolean;
  containerProps?: GridProps;
}

interface PeriodButton {
  label: string;
  value?: string;
}

const CUSTOM_PERIOD_EXPANDED_LABEL = "Custom expanded";
const CUSTOM_PERIOD_CONDENSED_LABEL = "Custom";

function PeriodSelector({
  condensed = false,
  value,
  onChange,
  shouldUseDefaultValue = true,
  containerProps,
}: PeriodSelectorProps) {
  const dispatch = useDispatch();
  const timePeriod = !isNil(value)
    ? value
    : useSelector(selectPeriodSelectorValue);
  const [dateRangeOpen, setDateRangeOpen] = useState(false);

  const PERIOD_VALUES: PeriodButton[] = useMemo(
    () =>
      compact([
        {
          label: "1M",
          value: monthsAgo(1),
        },
        {
          label: "3M",
          value: monthsAgo(3),
        },
        {
          label: "6M",
          value: monthsAgo(6),
        },
        {
          label: "YTD",
          value: dayjs().startOf("year").toString(),
        },
        {
          label: "1Y",
          value: monthsAgo(12),
        },
        {
          label: "All",
          value: dayjs.unix(0).toString(),
        },
        includeIf(condensed, {
          label: CUSTOM_PERIOD_CONDENSED_LABEL,
        }),
      ]),
    [condensed]
  );

  const isCustomPeriod = useCallback(
    (period: PeriodButton) =>
      [CUSTOM_PERIOD_CONDENSED_LABEL, CUSTOM_PERIOD_EXPANDED_LABEL].includes(
        period.label
      ),
    []
  );

  const handlePeriodSelectChange = (newSelectedPeriod: Period) => {
    if (!isNil(onChange)) {
      onChange(newSelectedPeriod);
    } else {
      dispatch(setPeriodSelectorValue(newSelectedPeriod));
    }
  };

  const allPeriods = useMemo(() => {
    return PERIOD_VALUES.map(({ label, value }) => ({
      label,
      startDate: value ?? "",
      endDate: dayjs().toString(),
    }));
  }, [PERIOD_VALUES]);

  useEffect(() => {
    if (shouldUseDefaultValue) {
      const defaultPeriod = allPeriods.filter(
        (it) => it.label === DEFAULT_PERIOD.label
      )[0];

      handlePeriodSelectChange(defaultPeriod);
    }
  }, [allPeriods]);

  const [anchorEl, setAnchorEl] = useState<any>(null);

  return (
    <Grid container {...containerProps}>
      <ToggleButtonGroup
        className="ptd-toggle-container"
        value={timePeriod}
        exclusive
        size="small"
        onChange={(event, newSelectedPeriod) => {
          if (
            isNil(newSelectedPeriod) ||
            newSelectedPeriod.label === CUSTOM_PERIOD_EXPANDED_LABEL
          ) {
            return;
          }

          if (isCustomPeriod(newSelectedPeriod)) {
            setDateRangeOpen(true);
            setAnchorEl(event.currentTarget);
          } else {
            handlePeriodSelectChange(newSelectedPeriod);
          }
        }}
      >
        {allPeriods.map((periodOption, index) => (
          <ToggleButton
            key={index}
            className="ptd-period-selector-values"
            value={periodOption}
            selected={periodOption.label === timePeriod.label}
          >
            {periodOption.label}
            {isCustomPeriod(periodOption) && (
              <KeyboardArrowDownIcon fontSize="small" />
            )}
          </ToggleButton>
        ))}
        {!condensed && (
          <ToggleButton
            className="ptd-period-selector-values"
            value={{ label: CUSTOM_PERIOD_EXPANDED_LABEL }}
            selected={isCustomPeriod(timePeriod)}
          >
            <RangePicker
              bordered={false}
              onChange={(newValue) =>
                handlePeriodSelectChange({
                  startDate:
                    newValue?.[0]?.toISOString() || dayjs().toISOString(),
                  endDate:
                    newValue?.[1]?.toISOString() || dayjs().toISOString(),
                  label: CUSTOM_PERIOD_EXPANDED_LABEL,
                })
              }
            />
          </ToggleButton>
        )}
      </ToggleButtonGroup>

      <DateRangePopover
        open={dateRangeOpen}
        setOpen={setDateRangeOpen}
        onSuccess={(newSelectedPeriod) => {
          handlePeriodSelectChange(newSelectedPeriod);
        }}
        initialPeriod={isCustomPeriod(timePeriod) ? timePeriod : undefined}
        anchorEl={anchorEl}
      />
    </Grid>
  );
}

export default PeriodSelector;
