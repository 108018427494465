import { useSelector } from "react-redux";
import {
  selectIsSelfCompare,
  selectMovement,
  selectComparingMovement,
  selectCurrentMetric,
  selectSelectedMotionType,
} from "../redux/selectors";
import { useFetchMetricsQuery } from "../services/performanceApi.service";

function useThrowEventReduxData() {
  const isSelfCompare = useSelector(selectIsSelfCompare);
  const { trial: mainTrial, player } = useSelector(selectMovement);
  const { trial: secondaryTrial } = useSelector(selectComparingMovement);
  const currentMetric = useSelector(selectCurrentMetric);
  const motionType = useSelector(selectSelectedMotionType);
  const { data: referenceMetricData = [] } = useFetchMetricsQuery({
    metricId: currentMetric,
    pitch: mainTrial,
    motionType,
  });

  return {
    isSelfCompare,
    mainTrial,
    secondaryTrial,
    player,
    currentMetric,
    referenceMetricData,
  };
}

export { useThrowEventReduxData };
