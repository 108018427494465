import { useSelector } from "react-redux";
import {
  selectComparingMovement,
  selectIsDataCompare,
  selectIsSelfCompare,
  selectMovement,
  selectSelectedMotionType,
  selectSelfCompOptions,
} from "../redux/selectors";
import {
  useFetchBodyPartDataCompQuery,
  useFetchBodyPartMetricsQuery,
  useFetchTimeSeriesDataQuery,
  useFetchTimeSeriesSelfDataQuery,
  useFetchTrialKeyframesQuery,
} from "../services/performanceApi.service";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useCallback, useEffect, useMemo, useState } from "react";
import { isEmpty, isNil, last } from "lodash";
import {
  filterByCurrentMetrics,
  formatSelfData,
  toMetric,
  shouldFetchSelfCompData,
} from "../utils/metrics";
import useFramesData from "./useFramesData";
import useFetchSelfData from "../components/useFetchSelfData";
import { BodyPartTabs } from "../services/mockData";

interface useBodyPartDataProps {
  bodyPartName: BodyPartTabs;
}

export interface MetricType {
  id: number;
  data: number[];
  label?: string;
  name?: string;
  unit?: string;
}

function useBodyPartData({ bodyPartName }: useBodyPartDataProps) {
  const { trial, player } = useSelector(selectMovement);
  const { trial: comparingTrial } = useSelector(selectComparingMovement);
  const motionType = useSelector(selectSelectedMotionType);
  const isSelfCompare = useSelector(selectIsSelfCompare);
  const isDataCompare = useSelector(selectIsDataCompare);
  const selfCompOptions = useSelector(selectSelfCompOptions);
  const { data: keyframesData = [] } = useFetchTrialKeyframesQuery(
    trial?.id ? { trial, motionType } : skipToken
  );
  const { data, isLoading } = useFetchBodyPartMetricsQuery({
    pitch: trial,
    bodyPart: bodyPartName,
    motionType,
  });
  const { data: comparingTrialData } = useFetchBodyPartMetricsQuery(
    comparingTrial?.id
      ? {
          pitch: comparingTrial,
          bodyPart: bodyPartName,
          motionType,
        }
      : skipToken
  );
  const { data: timeSeriesData } = useFetchTimeSeriesDataQuery(
    trial?.id ? { trial, motionType } : skipToken
  );
  const { data: comparingTimeSeriesData } = useFetchTimeSeriesDataQuery(
    comparingTrial?.id ? { trial: comparingTrial, motionType } : skipToken
  );

  const [metrics, setMetrics] = useState<MetricType[]>([]);

  const { data: selfData = { data: [], brFrame: 0 } } =
    useFetchTimeSeriesSelfDataQuery(
      metrics[0]?.id && shouldFetchSelfCompData(player, selfCompOptions)
        ? { player, metricId: `${metrics[0]?.id}`, selfCompOptions }
        : skipToken
    );

  const { discreteData: rawDiscreteSelfData = [] } = useFetchSelfData(player);
  const discreteSelfData =
    data?.map((metric) => {
      const events = {};
      const selfMetrics = rawDiscreteSelfData.filter((it) =>
        it.id.includes(metric.id)
      );

      selfMetrics.forEach((it: any) => {
        const key: string = last(it.id.toLowerCase().split("_")) || "";
        const mappedKey = key === "ffc" ? "fc" : key;

        Object.assign(events, { [mappedKey]: Number(it.value).toFixed(0) });
      });

      return {
        id: metric.id,
        key: metric.key,
        name: metric.name,
        unit: metric.unit,
        events,
      };
    }) || [];

  const { data: compData = [] } = useFetchBodyPartDataCompQuery(
    player?.id ? { motionType } : skipToken
  );
  const { syncTrials } = useFramesData();

  const self = useMemo(
    () =>
      formatSelfData({
        metric: metrics[0],
        data: syncTrials(selfData?.data, selfData?.brFrame),
        visible: isSelfCompare,
      }),
    [metrics[0], selfData?.data, selfData?.brFrame, isSelfCompare]
  );
  const comp = useMemo(
    () => (isDataCompare ? filterByCurrentMetrics(compData, metrics) : []),
    [isDataCompare, compData, metrics]
  );
  const secondaryData = useMemo(() => [...comp, ...self], [comp, self]);
  const comparingData = useMemo(
    () => (comparingTrial?.id ? comparingTrialData : []),
    [comparingTrial, comparingTrialData]
  );

  useEffect(() => {
    const initialData = data?.[0];
    if (!isLoading && isEmpty(metrics) && !isNil(initialData)) {
      handleMetric(initialData);
    }
  }, [data, timeSeriesData]);

  useEffect(() => {
    if (!isEmpty(metrics)) {
      handleMetric(metrics[0]);
    }
  }, [comparingTrial?.id, comparingTimeSeriesData]);

  const handleMetric = useCallback(
    (metric: MetricType) => {
      try {
        const findTimeSeries = (aTimeSeries?: any[]) => {
          return aTimeSeries?.filter((it) => it.id === metric.id) || [];
        };

        let newMetrics = findTimeSeries(timeSeriesData);

        if (comparingTrial?.id && !isEmpty(comparingTimeSeriesData)) {
          const newComparingData = findTimeSeries(comparingTimeSeriesData).map(
            (it) => ({
              ...it,
              id: `compare_${it.id}`,
              data: syncTrials(it.data),
            })
          );

          newMetrics = newMetrics.concat(newComparingData);
        }

        setMetrics(newMetrics.map(toMetric));
      } catch (e) {
        console.log("error handling metric", e);
      }
    },
    [timeSeriesData, comparingTimeSeriesData, comparingTrial?.id, syncTrials]
  );

  return {
    data,
    comparingData,
    isSelfCompare,
    isDataCompare,
    metrics,
    handleMetric,
    secondaryData,
    keyframesData,
    discreteSelfData,
  };
}

export default useBodyPartData;
