import dayjs from "dayjs";
import { downloadParsedCSV } from "../three-js/loadCSV";
import { searchPlayerDetails } from "./performanceApi.service";
import { PlayerDashboardDataComp } from "../components/PlayerDashboard/PlayerDashboardDataComp";
import { isPlayerPitcher } from "./performanceApi.adapter";
import { MemoryCache } from "../utils/cache";
import { clone, isEmpty, isNil } from "lodash";
const cache = new MemoryCache();
const DATA_COMP_LEVEL_NAMES_BY_ABBREVIATION: any = {
  "A+": "High A",
  A: "Low A",
  RCL: "FCL",
};

const buildCurrentPlayerValues = (
  currentPlayerNormativeRanges: string[][],
  metricNameColumn: number,
  valueColumn: number
) =>
  currentPlayerNormativeRanges.reduce(
    (acc, row) => ({
      ...acc,
      [row[metricNameColumn]]: Number(row[valueColumn]),
    }),
    {}
  );
const DATA_COMP_FETCH_STRATEGIES: any = {
  [PlayerDashboardDataComp.PlayerAvg]: {
    filePath: "player/data/player_normative_ranges.csv",
    buildDataComp: ({ file, player }: { file: string[][]; player: any }) => {
      return cache.withCache(player.metsId, async () => {
        const PLAYER_ID_INDEX = 0;
        const METRIC_NAME_INDEX = 1;
        const METRIC_MEAN_INDEX = 2;
        const METRIC_STDDEV_INDEX = 3;

        const currentPlayerNormativeRanges = file.filter(
          (it) => it[PLAYER_ID_INDEX] === player.metsId
        );

        const means = buildCurrentPlayerValues(
          currentPlayerNormativeRanges,
          METRIC_NAME_INDEX,
          METRIC_MEAN_INDEX
        );
        const stdDevs = buildCurrentPlayerValues(
          currentPlayerNormativeRanges,
          METRIC_NAME_INDEX,
          METRIC_STDDEV_INDEX
        );
        return { means, stdDevs };
      });
    },
  },
  [PlayerDashboardDataComp.TeamAvg]: {
    filePath: "player/data/team_normative_ranges.csv",
    buildDataComp: ({
      file,
      player,
      position,
    }: {
      file: string[][];
      player: any;
      position?: "Pitcher" | "Position Player";
    }) => {
      const assignedPlayerLevel =
        DATA_COMP_LEVEL_NAMES_BY_ABBREVIATION[player.levelAbbreviation] ||
        player.levelAbbreviation;
      const playerLevel =
        isEmpty(assignedPlayerLevel) &&
        player.acquisitionGroup === "international_amateur"
          ? "DSL"
          : assignedPlayerLevel;
      const playerPositionGroup = !isNil(position)
        ? position
        : isPlayerPitcher(player)
        ? "Pitcher"
        : "Position Player";
      return cache.withCache(
        `${playerLevel}/${playerPositionGroup}/teamAvg`,
        async () => {
          const METRIC_NAME_INDEX = 0;
          const PLAYER_LEVEL_INDEX = 1;
          const PLAYER_POSITION_GROUP_INDEX = 2;
          const METRIC_MEAN_INDEX = 3;
          const METRIC_STDDEV_INDEX = 4;

          const currentLevelAndPositionNormativeRanges = file.filter(
            (it) =>
              it[PLAYER_LEVEL_INDEX] === playerLevel &&
              it[PLAYER_POSITION_GROUP_INDEX] === playerPositionGroup
          );

          const means = buildCurrentPlayerValues(
            currentLevelAndPositionNormativeRanges,
            METRIC_NAME_INDEX,
            METRIC_MEAN_INDEX
          );
          const stdDevs = buildCurrentPlayerValues(
            currentLevelAndPositionNormativeRanges,
            METRIC_NAME_INDEX,
            METRIC_STDDEV_INDEX
          );
          return { means, stdDevs };
        }
      );
    },
  },
};

export async function fetchRawPlayerDashboard(
  playerId: string,
  timePeriod: { startDate: string; endDate: string } | null,
  baseQuery: any
) {
  return cache.withCache(
    `fetchRawPlayerDashboard-${playerId}`,
    async () =>
      await baseQuery(
        `api/external/performance-dash/players/${playerId}/dashboard${
          timePeriod
            ? `?fromDate=${new Date(
                timePeriod.startDate
              ).toISOString()}&toDate=${new Date(
                timePeriod.endDate
              ).toISOString()}`
            : ""
        }`
      )
  );
}

export async function playerDashboardWithDataComp({
  playerId,
  baseQuery,
  timePeriod,
  selectedDataComp,
  position,
}: {
  playerId: string;
  baseQuery: any;
  timePeriod: any;
  selectedDataComp?: PlayerDashboardDataComp;
  position?: "Pitcher" | "Position Player";
}) {
  const dataCompFetchStrategy =
    DATA_COMP_FETCH_STRATEGIES[
      selectedDataComp || PlayerDashboardDataComp.PlayerAvg
    ] || DATA_COMP_FETCH_STRATEGIES[PlayerDashboardDataComp.PlayerAvg];
  const [dashboardResponse, metricConventions, player, normativeRangesFile] =
    await Promise.all([
      fetchRawPlayerDashboard(playerId, timePeriod, baseQuery),
      fetchPlayerMetricsConvention(),
      cache.withCache(
        `${playerId}/searchPlayerDetails`,
        async () => await searchPlayerDetails(baseQuery, playerId)
      ),
      cache.withCache(
        `${position}_${dataCompFetchStrategy.filePath}`,
        async () => await downloadParsedCSV(dataCompFetchStrategy.filePath)
      ),
    ]);

  if (dashboardResponse.error?.status === 403) {
    throw new Error(dashboardResponse.error.data?.message);
  }
  const { means, stdDevs } = await dataCompFetchStrategy.buildDataComp({
    file: normativeRangesFile,
    player,
    position,
  });
  const dashboardData: any = dashboardResponse.data;
  const timePeriodOrDefault = timePeriod
    ? timePeriod
    : {
        startDate: dayjs.unix(0).toString(),
        endDate: dayjs().toString(),
      };

  return {
    player,
    means,
    stdDevs,
    dashboardData,
    timePeriodOrDefault,
    metricConventions,
  };
}

export function fetchPlayerMetricsConvention() {
  return cache.withCache(
    "player/data/metricsConvention.csv",
    async () => await downloadParsedCSV("player/data/metricsConvention.csv")
  );
}

export function fetchTeamMetricsConvention() {
  return (
    cache
      .withCache(
        "team/data/metricsConvention.csv",
        async () => await downloadParsedCSV("team/data/metricsConvention.csv")
      )
      //Some methods have effect over the result of this download. Return a fresh copy each time.
      .then((it) => clone(it))
  );
}

export async function metricKeyToLabel(
  metricKey: string,
  defaultValue?: string
) {
  const metricKeyToLabelDictionary = await buildMetricKeyToLabelDictionary();
  return metricKeyToLabelDictionary[metricKey] || defaultValue;
}

async function buildMetricKeyToLabelDictionary() {
  return cache.withCache("metricKeyToLabelDictionary", async () => {
    const teamMetricsConvention = await fetchTeamMetricsConvention();
    const metricsDictionary: any = {};
    teamMetricsConvention.forEach(
      (it: string[]) => (metricsDictionary[it[4]] = it[3])
    );
    return metricsDictionary;
  });
}
