import { useSelector } from "react-redux";
import {
  addIndex,
  addTrialIndex,
  basicFrame,
  filterByCurrentMetrics,
  formatSelfData,
  notIsSecondary,
  replace,
  toMetric,
  toPlotLineMetrics,
} from "../utils/metrics";
import {
  useFetchSequenceDataCompQuery,
  useFetchSequenceDataQuery,
  useFetchDiscreteDataCompQuery,
  useFetchSequenceRangeDataQuery,
  useFetchTimeSeriesSelfDataMultiQuery,
} from "../services/performanceApi.service";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import useFramesData from "./useFramesData";
import { isEmpty, take, round, first } from "lodash";
import {
  selectComparingMovement,
  selectIsDataCompare,
  selectIsSelfCompare,
  selectMovement,
  selectSelectedMotionType,
  selectSelfCompOptions,
} from "../redux/selectors";
import { useEffect, useMemo, useState } from "react";
import useFetchSelfData from "../components/useFetchSelfData";
import { MotionType } from "../components/common/MotionType";

export function useSequence() {
  const isSelfCompare = useSelector(selectIsSelfCompare);
  const isDataCompare = useSelector(selectIsDataCompare);
  const { trial, player } = useSelector(selectMovement);
  const selfCompOptions = useSelector(selectSelfCompOptions);
  const { trial: secondaryTrial } = useSelector(selectComparingMovement);
  const motionType = useSelector(selectSelectedMotionType);
  const METRICS_LIMIT = motionType === MotionType.Batting ? 5 : 4;
  const { data = [], isLoading } = useFetchSequenceDataQuery({
    trial,
    motionType,
  });
  const [metrics, setMetrics] = useState<any[]>(
    take(data, METRICS_LIMIT).map((it) => toMetric(it))
  );
  const { data: secondaryData = [] } = useFetchSequenceDataQuery(
    secondaryTrial?.id ? { trial: secondaryTrial, motionType } : skipToken
  );
  const { data: selfDataAllMetrics } = useFetchTimeSeriesSelfDataMultiQuery(
    player?.id && !isEmpty(metrics)
      ? {
          player,
          metricIds: metrics.map((it) => it.id),
          selfCompOptions,
        }
      : skipToken
  );
  const { discreteData: rawDiscreteSelfData = [] } = useFetchSelfData(player);
  const { data: compData = [] } = useFetchSequenceDataCompQuery(
    trial?.id ? { motionType } : skipToken
  );
  const { data: discreteDataCompValues } = useFetchDiscreteDataCompQuery({
    motionType,
  });
  const { data: primaryRangeData } = useFetchSequenceRangeDataQuery(
    trial?.id ? { trial, motionType } : skipToken
  );
  const { data: secondaryRangeData } = useFetchSequenceRangeDataQuery(
    secondaryTrial?.id ? { trial: secondaryTrial, motionType } : skipToken
  );
  const { syncTrials } = useFramesData();
  const [secondaryMetrics, setSecondaryMetrics] = useState<any[]>([]);

  useEffect(() => {
    if (!isLoading) {
      setMetrics(take(data, METRICS_LIMIT).map((it) => toMetric(it)));
    }
  }, [isLoading]);

  useEffect(() => {
    if (!secondaryTrial && !isEmpty(secondaryMetrics)) {
      setSecondaryMetrics([]);
    }

    if (secondaryTrial && !isEmpty(secondaryData)) {
      const metricsKeys = metrics.map((it) => it.label);
      const filteredMetrics = secondaryData.filter((it) =>
        metricsKeys.some((key) => key.includes(it.label))
      );

      setSecondaryMetrics(
        filteredMetrics.map((it, index) => ({
          ...it,
          isSecondary: true,
          visible: metrics[index].visible,
          data: syncTrials(it.data),
        }))
      );
    }
  }, [secondaryData, secondaryTrial, metrics]);

  const { lastFrameValue, keyframesData, toMillisecondsFromBrOffset } =
    useFramesData();

  const getSelfValue = (id: string) =>
    rawDiscreteSelfData?.find((it) => it?.id === id)?.value;

  const getMetricColorById = (metricId: any) =>
    metrics.find((it) => it.id === metricId)?.color || "";

  const visibleMetrics = metrics.filter((it) => it.visible);
  const visibleCompareMetrics = secondaryMetrics.filter((it) => it.visible);

  const lineChartData = [
    ...addTrialIndex(addIndex(visibleMetrics), 1),
    ...addTrialIndex(
      addIndex(
        visibleCompareMetrics.map((it) => ({
          ...it,
          id: `compare_${it.id}`,
        }))
      ),
      2
    ),
  ];

  const filteredCompData = filterByCurrentMetrics(compData, metrics);
  const coloredCompData = filteredCompData.map((it) => ({
    ...it,
    color: getMetricColorById(it.id),
  }));

  const comp = isDataCompare ? coloredCompData : [];
  const mappedSelfData =
    selfDataAllMetrics?.map((selfData) => {
      if (
        isSelfCompare &&
        !isEmpty(selfData?.data) &&
        !isEmpty(lineChartData)
      ) {
        return first(
          formatSelfData({
            metric: { id: selfData?.id },
            data: syncTrials(selfData?.data, selfData?.brFrame),
            visible: isSelfCompare,
            dashStyle: "longDash",
          })
        );
      }
    }) || [];
  const self = filterByCurrentMetrics(mappedSelfData, metrics);
  const hasLineChartData = !isEmpty(lineChartData);

  const alternativeData = [...comp, ...self];
  const canAddMetric = metrics.filter(notIsSecondary).length < METRICS_LIMIT;

  const updateMetric = (value: any, index: number) => {
    setMetrics(replace(metrics, index, toMetric(value)));
  };

  const addMetric = () => {
    setMetrics([...metrics, toMetric(data[0])]);
  };

  const removeMetric = () => {
    setMetrics(metrics.filter((_, index) => index !== metrics.length - 1));
  };

  const fixedKeyFrames = toPlotLineMetrics([
    basicFrame(0),
    ...keyframesData,
    basicFrame(lastFrameValue),
  ]).map((it) => ({
    ...it,
    value: toMillisecondsFromBrOffset(it.value),
  }));
  const findSequenceRangeValues = (
    rangeDataList: any[] | undefined,
    metricId: string
  ) => {
    return rangeDataList?.find((primaryMetric) => {
      return primaryMetric.some((range: any) =>
        range?.label.includes(metricId)
      );
    });
  };

  const rangeValues = useMemo(() => {
    return metrics
      .filter((it) => it.visible)
      .map((metric) => {
        if (isEmpty(primaryRangeData)) return [];
        const primaryRangeMetric = findSequenceRangeValues(
          primaryRangeData,
          metric.id
        );
        const metricDataComp =
          primaryRangeMetric?.map((it: any) =>
            discreteDataCompValues?.find((dataComp) => dataComp.id === it.label)
          ) || [];
        const secondaryRangeMetric = findSequenceRangeValues(
          secondaryRangeData,
          metric.id
        );
        const velocityDataComp = {
          averageRange: metricDataComp[0]?.averageRange,
          max: metricDataComp[0]?.max,
          min: metricDataComp[0]?.min,
        };
        const timeDataComp = {
          averageRange: {
            from: metricDataComp[1]?.averageRange?.from * 1000,
            to: metricDataComp[1]?.averageRange?.to * 1000,
          },
          max: metricDataComp[1]?.max * 1000,
          min: metricDataComp[1]?.min * 1000,
        };
        return [
          {
            ...metric,
            ...velocityDataComp,
            description: `(${metric.unit})`,
            value: round(primaryRangeMetric?.[0].value) || "-",
            unit: "°",
            secondaryValue: isSelfCompare
              ? getSelfValue(primaryRangeMetric?.[0].label)
              : !secondaryTrial?.id
              ? null
              : round(secondaryRangeMetric?.[0].value) || "-",
          },
          {
            ...metric,
            ...timeDataComp,
            label: "",
            description: "",
            value: round(primaryRangeMetric?.[1].value) || "-",
            unit: "°",
            secondaryValue: isSelfCompare
              ? getSelfValue(primaryRangeMetric?.[1].label) * 1000
              : !secondaryTrial?.id
              ? null
              : round(secondaryRangeMetric?.[1].value) || "-",
          },
        ];
      });
  }, [
    metrics,
    primaryRangeData,
    secondaryRangeData,
    secondaryTrial?.id,
    discreteDataCompValues,
    rawDiscreteSelfData,
    isSelfCompare,
  ]);

  return {
    rangeValues,
    hasLineChartData,
    lineChartData,
    alternativeData,
    fixedKeyFrames,
    metrics,
    data,
    updateMetric,
    addMetric,
    removeMetric,
    canAddMetric,
  };
}
