import React from "react";

interface BarProps {
  active?: boolean;
}

const Bar = ({ active }: BarProps) => {
  const width = "32px";
  const height = active ? "12px" : "8px";
  const color = active ? "#9F79C1" : "#E2D7EC";
  const borderRadius = active ? "10%" : "20%";

  return (
    <div
      style={{
        height,
        width,
        borderRadius,
        margin: "2px",
        backgroundColor: color,
      }}
    />
  );
};

export default Bar;
