import { AbstractGfx } from "./guiGraphics3D";
let swingPlaneFolder = [];
let motionPathFolder = [];
let segmentLineFolder = [];
let syncFolder = [];
const syncFolderParams = {
  translateX: 0,
  translateY: 0,
  sync: "BR",
  reflect: false,
};

let toggleHeadLineController,
  toggleShoulderLineController,
  toggleSpineLineController,
  toggleHipLineController;

let toggleHeadMotionPathController,
  toggleHipMotionPathController,
  toggleRightWristMotionPathController,
  toggleRightForearmMotionPathController,
  toggleLeftWristMotionPathController,
  toggleLeftForearmMotionPathController,
  toggleLeftShinMotionPathController,
  toggleLeftAnkleMotionPathController,
  toggleRightShinMotionPathController,
  toggleRightAnkleMotionPathController,
  toggleBallMotionPathController;

let toggleSwingPlaneController;
const swingPlaneParams = {
  toggleBatPath: false,
  togglePerfectPath: false,
};

const motionPathParams = {
  toggleHeadPath: false,
  toggleHipPath: false,
  toggleRightWristPath: false,
  toggleRightForearmPath: false,
  toggleLeftWristPath: false,
  toggleLeftForearmPath: false,
  toggleLeftShinPath: false,
  toggleLeftAnklePath: false,
  toggleRightShinPath: false,
  toggleRightAnklePath: false,
  toggleBallPath: false,
};

const segmentLineParms = {
  toggleShoulderLine: false,
  toggleHipLine: false,
  toggleHeadLine: false,
  toggleSpineLine: false,
  toggleShoulderPlane: false,
  toggleHipPlane: false,
  toggleSpinePlane: false,
};

export class PitchingGfx extends AbstractGfx {
  motionPathsToggles = {
    names: [
      "Head",
      "Hips",
      "RightHand",
      "RightForearm",
      "LeftHand",
      "LeftForearm",
      "LeftShin",
      "LeftAnkle",
      "RightShin",
      "RightAnkle",
      "Center",
    ],
    drawn: [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
    visible: [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
    paths: [[], [], [], [], [], [], [], [], [], [], []],
    colors: [
      "0xff5733",
      "0x33ff57",
      "0x3357ff",
      "0xff33a8",
      "0x33fff6",
      "0xffff33",
      "0xff8c33",
      "0x845f17",
      "0x0027b2",
      "0x2f9E84",
      "0xc10000",
    ],
    uuids: [[], [], [], [], [], [], [], [], [], [], []],
  };

  segmentLineToggles = {
    names: ["Head", "Spine", "Hips", "Spine2"],
    positionNames: ["Head", "Spine", "Hips", "Spine2"],
    drawn: [false, false, false, false],
    visible: [false, false, false, false],
    paths: [[], [], [], []],
    colors: ["0xff5733", "0xa833ff", "0x33ff57", "0xffff33"],
    uuids: [[], [], [], []],
  };
  swingPlaneToggles = {
    names: ["plane", "ope"],
    drawn: [false, false],
    visible: [false, false],
    uuidsPlane: [[], []],
    uuidsComponents: [[], []],
  };

  resetGui(world) {
    try {
      if (!world.battingMode) {
        // reset lines
        toggleHeadLineController.object.toggleHeadLine = false;
        toggleShoulderLineController.object.toggleShoulderLine = false;
        toggleSpineLineController.object.toggleSpineLine = false;
        toggleHipLineController.object.toggleHipLine = false;

        this.toggleLinePaths("Head", 0, world, false);
        this.toggleLinePaths("Spine", 0, world, false);
        this.toggleLinePaths("Hips", 0, world, false);
        this.toggleLinePaths("Spine2", 90, world, false);
        toggleHeadLineController.updateDisplay();
        toggleShoulderLineController.updateDisplay();
        toggleSpineLineController.updateDisplay();
        toggleHipLineController.updateDisplay();

        // reset paths
        toggleHeadMotionPathController.object.toggleHeadPath = false;
        toggleHipMotionPathController.object.toggleHipPath = false;
        toggleBallMotionPathController.object.toggleBallPath = false;

        toggleRightWristMotionPathController.object.toggleRightWristPath = false;
        toggleRightForearmMotionPathController.object.toggleRightForearmPath = false;
        toggleLeftWristMotionPathController.object.toggleLeftWristPath = false;
        toggleLeftForearmMotionPathController.object.toggleLeftForearmPath = false;
        toggleLeftShinMotionPathController.object.toggleLeftShinPath = false;
        toggleLeftAnkleMotionPathController.object.toggleLeftAnklePath = false;
        toggleRightShinMotionPathController.object.toggleRightShinPath = false;
        toggleRightAnkleMotionPathController.object.toggleRightAnklePath = false;

        this.toggleMotionPaths("Head", world, false);
        this.toggleMotionPaths("Hips", world, false);
        this.toggleMotionPaths("Center", world, false);
        this.toggleMotionPaths("RightHand", world, false);
        this.toggleMotionPaths("RightForearm", world, false);
        this.toggleMotionPaths("LeftHand", world, false);
        this.toggleMotionPaths("LeftForearm", world, false);
        this.toggleMotionPaths("LeftShin", world, false);
        this.toggleMotionPaths("LeftAnkle", world, false);
        this.toggleMotionPaths("RightShin", world, false);
        this.toggleMotionPaths("RightAnkle", world, false);

        toggleHeadMotionPathController.updateDisplay();
        toggleHipMotionPathController.updateDisplay();
        toggleBallMotionPathController.updateDisplay();

        toggleRightWristMotionPathController.updateDisplay();
        toggleRightForearmMotionPathController.updateDisplay();
        toggleLeftWristMotionPathController.updateDisplay();
        toggleLeftForearmMotionPathController.updateDisplay();
        toggleLeftShinMotionPathController.updateDisplay();
        toggleLeftAnkleMotionPathController.updateDisplay();
        toggleRightShinMotionPathController.updateDisplay();
        toggleRightAnkleMotionPathController.updateDisplay();

        toggleSwingPlaneController.object.toggleBatPath = false;
        this.togglePlanePathVisibility(world, false);
        toggleSwingPlaneController.updateDisplay();
      }
    } catch (error) {}
  }

  initGui(gui, world) {
    swingPlaneFolder = gui.addFolder("Swing Plane Graphics");
    motionPathFolder = gui.addFolder("Motion Paths");
    segmentLineFolder = gui.addFolder("Segment Line Helpers");
    syncFolder = gui.addFolder("Sync & Positioning");

    this.initStyles(gui, world);

    syncFolder
      .add(syncFolderParams, "translateX", -1.0, 1.0)
      .name("Translate X")
      .onChange((value) => {
        this.translatePerson(value, world, "X");
      });
    syncFolder
      .add(syncFolderParams, "translateY", -1.0, 1.0)
      .name("Translate Y")
      .onChange((value) => {
        this.translatePerson(value, world, "Y");
      });
    syncFolder
      .add(syncFolderParams, "sync", ["ILO", "MKH", "MS", "FFC", "BR"])
      .name("Sync")
      .onChange((syncReferenceKeyFrame) => {
        this.changeSyncReferenceKeyFrame(world, syncReferenceKeyFrame);
      });

    syncFolder
      .add(syncFolderParams, "reflect")
      .name("3D Reflection")
      .onChange(async () => {
        await this.toggleReflection(world);
      });

    toggleHeadLineController = segmentLineFolder
      .add(segmentLineParms, "toggleHeadLine")
      .name("Head Line")
      .onChange((value) => {
        this.toggleLinePaths("Head", 0, world, value);
      });

    toggleShoulderLineController = segmentLineFolder
      .add(segmentLineParms, "toggleShoulderLine")
      .name("Shoulder Line")
      .onChange((value) => {
        this.toggleLinePaths("Spine", 0, world, value);
      });
    toggleHipLineController = segmentLineFolder
      .add(segmentLineParms, "toggleHipLine")
      .name("Hip Line")
      .onChange((value) => {
        this.toggleLinePaths("Hips", 0, world, value);
      });
    toggleSpineLineController = segmentLineFolder
      .add(segmentLineParms, "toggleSpineLine")
      .name("Spine Line")
      .onChange((value) => {
        this.toggleLinePaths("Spine2", 90, world, value);
      });

    toggleHeadMotionPathController = motionPathFolder
      .add(motionPathParams, "toggleHeadPath")
      .name("Head Path")
      .onChange((value) => {
        this.toggleMotionPaths("Head", world, value);
      });

    toggleHipMotionPathController = motionPathFolder
      .add(motionPathParams, "toggleHipPath")
      .name("Hip Path")
      .onChange((value) => {
        this.toggleMotionPaths("Hips", world, value);
      });

    toggleRightWristMotionPathController = motionPathFolder
      .add(motionPathParams, "toggleRightWristPath")
      .name("Right Hand Path")
      .onChange((value) => {
        this.toggleMotionPaths("RightHand", world, value);
      });
    toggleRightForearmMotionPathController = motionPathFolder
      .add(motionPathParams, "toggleRightForearmPath")
      .name("Right Elbow Path")
      .onChange((value) => {
        this.toggleMotionPaths("RightForearm", world, value);
      });
    toggleLeftWristMotionPathController = motionPathFolder
      .add(motionPathParams, "toggleLeftWristPath")
      .name("Left Hand Path")
      .onChange((value) => {
        this.toggleMotionPaths("LeftHand", world, value);
      });
    toggleLeftForearmMotionPathController = motionPathFolder
      .add(motionPathParams, "toggleLeftForearmPath")
      .name("Left Elbow Path")
      .onChange((value) => {
        this.toggleMotionPaths("LeftForearm", world, value);
      });
    toggleLeftShinMotionPathController = motionPathFolder
      .add(motionPathParams, "toggleLeftShinPath")
      .name("Left Knee Path")
      .onChange((value) => {
        this.toggleMotionPaths("LeftShin", world, value);
      });

    toggleLeftAnkleMotionPathController = motionPathFolder
      .add(motionPathParams, "toggleLeftAnklePath")
      .name("Left Foot Path")
      .onChange((value) => {
        this.toggleMotionPaths("LeftAnkle", world, value);
      });
    toggleRightShinMotionPathController = motionPathFolder
      .add(motionPathParams, "toggleRightShinPath")
      .name("Right Knee Path")
      .onChange((value) => {
        this.toggleMotionPaths("RightShin", world, value);
      });

    toggleRightAnkleMotionPathController = motionPathFolder
      .add(motionPathParams, "toggleRightAnklePath")
      .name("Right Foot Path")
      .onChange((value) => {
        this.toggleMotionPaths("RightAnkle", world, value);
      });

    toggleBallMotionPathController = motionPathFolder
      .add(motionPathParams, "toggleBallPath")
      .name("Ball Path")
      .onChange((value) => {
        this.toggleMotionPaths("Center", world, value);
      });

    toggleSwingPlaneController = swingPlaneFolder
      .add(swingPlaneParams, "toggleBatPath")
      .name("Arm Plane")
      .onChange((value) => {
        this.togglePlanePathVisibility(world, value);
      });
    // TODO - I need some help styling this lil-gui element. Been chasing my tail for a bit on it and am going to just keep it to default for now.

    swingPlaneFolder.open();
    segmentLineFolder.open();
    motionPathFolder.open();
    syncFolder.open();
    gui.close();
  }

  getNameAndDistalNameForPlaneToggle(world, key) {
    let name = "";
    let distalName = "";
    if (world[key].handedness === "right") {
      distalName = "RightHand";
      name = "RightForearm";
    } else {
      distalName = "LeftHand";
      name = "LeftForearm";
    }
    return { name, distalName };
  }
}
export const pitchingGfx = new PitchingGfx();
