import * as THREE from "three";
import OrientationGizmo from "three-orientation-gizmo";
//OrientationGizmo depends on a global THREE reference.
global.THREE = THREE;

export class Gizmo3D {
  constructor(camera, controls, hideControlsGui) {
    this.camera = camera;
    this.controls = controls;
    this.hideControlsGui = hideControlsGui;
  }
  initialize() {
    const orientationGizmo = new OrientationGizmo(this.camera, {
      size: 100,
      padding: 10,
      //Flip default Y and Z colors to match physics engine
      colors: {
        x: ["#f73c3c", "#942424"],
        y: ["#178cf0", "#0e5490"],
        z: ["#6ccb26", "#417a17"],
      },
    });
    orientationGizmo.style =
      "position: absolute; bottom: 80px; right: 10px;" +
      (this.hideControlsGui ? "display: none" : "");

    orientationGizmo.onAxisSelected = function (axis) {
      var distance = this.camera.position.distanceTo(this.controls.target);
      this.camera.position.copy(
        axis.direction.multiplyScalar(distance).add(this.controls.target)
      );
      this.camera.lookAt(this.controls.target);
    }.bind(this);

    //Flip default Y and Z labels to match physics engine
    orientationGizmo.bubbles
      .filter((it) => it.axis !== "x")
      .forEach((it) => {
        if (it.label) {
          it.label = it.label === "Z" ? "Y" : "Z";
        }
      });
    return orientationGizmo;
  }
}
