import React, { useEffect } from "react";
import { TrendsDiscreteMetricChartProps } from "./DiscreteMetric";
import { Stack } from "@mui/material";
import { RegressionPillChart } from "../charts";
import TrendsInfoTable from "./TrendsInfoTable";
import { useFetchTrendsDiscreteDateIndexingQuery } from "../../services/performanceApi.service";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import useSplitRegressions from "./useSplitRegressions";
import { useSelector } from "react-redux";
import { selectMovement, selectSelfCompOptions } from "../../redux/selectors";
import VisibleChartSeriesToggles from "./VisibleChartSeriesToggles";
import { isEqual, isNil } from "lodash";

export default function DateIndexingChart({
  metric,
  timePeriod,
  dataComp,
  selfComp,
  dataIndexing,
  visibleChartSeries,
  onChangeVisibleChartSeries,
  exportableData,
  setExportableData,
}: TrendsDiscreteMetricChartProps) {
  const selfCompOptions = useSelector(selectSelfCompOptions);
  const { player } = useSelector(selectMovement);
  const { data } = useFetchTrendsDiscreteDateIndexingQuery(
    metric?.id && player?.id
      ? {
          player,
          metricId: metric?.id,
          timePeriod,
          selfCompOptions,
          dataIndexing,
        }
      : skipToken
  );

  const { splitRegression1, splitRegression2, onApply, onReset } =
    useSplitRegressions(data);

  useEffect(() => {
    if (setExportableData) {
      const newData = data?.values?.map((it) => ({
        ...it,
        metric: metric?.id,
        player: player?.id,
      }));

      if (!isNil(data?.values) && !isEqual(newData, exportableData)) {
        setExportableData(newData);
      }
    }
  }, [
    data?.values,
    data?.regression?.points,
    splitRegression1?.result?.points,
    splitRegression2?.result?.points,
  ]);

  return (
    <Stack>
      <RegressionPillChart
        data={data?.values || []}
        // Regressions returns readonly DataPoint[] and `readonly` keyword throws syntax error
        // @ts-expect-error
        regressionsData={data?.regression?.points || []}
        // Regressions returns readonly DataPoint[] and `readonly` keyword throws syntax error
        // @ts-expect-error
        splitRegression1Data={splitRegression1?.result?.points}
        // Regressions returns readonly DataPoint[] and `readonly` keyword throws syntax error
        // @ts-expect-error
        splitRegression2Data={splitRegression2?.result?.points}
        dataComp={data?.dataComp ?? []}
        selfComp={data?.selfComp ?? []}
        isDataCompActive={dataComp}
        isSelfCompActive={selfComp}
        formatXAsDate
        dataIndexing={dataIndexing}
        visibleChartSeries={visibleChartSeries}
      />

      <Stack justifyContent="center">
        <VisibleChartSeriesToggles
          visibleChartSeries={visibleChartSeries}
          onChangeVisibleChartSeries={onChangeVisibleChartSeries}
        />
      </Stack>

      <TrendsInfoTable
        data={data}
        countLabel="Days"
        onApply={onApply}
        onReset={onReset}
        splitRegression1={splitRegression1}
        splitRegression2={splitRegression2}
        visibleChartSeries={visibleChartSeries}
        onChangeVisibleChartSeries={onChangeVisibleChartSeries}
      />
    </Stack>
  );
}
